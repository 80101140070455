import { HEX_COLORS, FONT_FAMILIES } from 'constants/stylesVariables';
import styled from '@mui/styled-engine';
import Spacer from '@shared/spacer';
import { Footer } from '@components/index';

const BottomFooter = ({ children }) => {
  return (
    <StyledBottomContainer>
      <Spacer size={111} />
      <StyledJoinText>{children}</StyledJoinText>
      <Spacer size={81} />
      <Footer />
      <Spacer size={31} />
    </StyledBottomContainer>
  );
};

const StyledBottomContainer = styled('div')(
  () => `
  background: linear-gradient(289.39deg, ${HEX_COLORS.turquoise} 10.27%, ${HEX_COLORS.blue} 32.38%, ${HEX_COLORS.blue2} 65.55%);
`
);

const StyledJoinText = styled('p')(
  () => `
  font-size: 20px;
  text-align: center;
  white-space: pre-wrap;
  font-family: ${FONT_FAMILIES.proximaNovaSemibold};
  color: ${HEX_COLORS.white};

  .__link {
    cursor: pointer;
    
    &:hover {
      text-decoration: underline;
    }
  }
`
);

export default BottomFooter;
