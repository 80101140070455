import { uuid } from '@utils/uuid';
import React, { useEffect, useRef } from 'react';
import Textblock from 'textblock';
import { defaultProps, props } from './props';

const TextBlock = ({
  minWidth,
  maxWidth,
  minWidthFontSize,
  maxWidthFontSize,
  minWidthLineHeight,
  maxWidthLineHeight,
  container,
  fontSizeUnits,
  children,
}) => {
  const textblockId = useRef(`block-${uuid()}`);

  useEffect(() => {
    Textblock([
      {
        target: `.${textblockId.current}`,
        minWidth: minWidth,
        maxWidth: maxWidth,
        minWidth_FontSize: minWidthFontSize,
        maxWidth_FontSize: maxWidthFontSize,
        minWidth_LineHeight: minWidthLineHeight,
        maxWidth_LineHeight: maxWidthLineHeight,
        container,
        fontSize_units: fontSizeUnits,
      },
    ]);
  }, [
    minWidth,
    maxWidth,
    minWidthFontSize,
    maxWidthFontSize,
    minWidthLineHeight,
    maxWidthLineHeight,
    container,
    fontSizeUnits,
  ]);

  return typeof children === 'function' ? (
    children(textblockId.current)
  ) : (
    <div className={textblockId.current}>{children}</div>
  );
};

TextBlock.propTypes = props;
TextBlock.defaultProps = defaultProps;

export default React.memo(TextBlock);
