import React, { useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from 'react-router-dom';

const ArrowBackTitle = ({ children }) => {
  const history = useHistory();

  const handleBackClick = useCallback(() => history.goBack(), [history]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <ArrowBackIcon onClick={handleBackClick} sx={{ mr: 1, cursor: 'pointer' }} />
      <Typography variant="h4">{children}</Typography>
    </Box>
  );
};

export default ArrowBackTitle;
