/* 
input UpdatePodcasterInput {
  podcasterId: String!
  fullName: String
  description: String
  availableInGeos: [Alpha2Codes]
  owningCompanyName: String
  contentAgreementType: [ContentAgreementType]
  owners: [OwnerInput]!
} */

export const updatePodcaster = /* GraphQL */ `
  mutation updatePodcaster($input: UpdatePodcasterInput!) {
    updatePodcaster(input: $input) {
      name
      owningCompanyName
      owners {
        name
        isHostCoHost
        ownerIsAccountUser
        phoneCode
        phoneNumber
        id
        email
      }
      phoneCode
      phoneNumber
      updatedAt
      optedIntoTospp
      isActivated
      description
      createdAt
      contentAgreementType
      availableInGeos
      PK
    }
  }
`;

export const activateDeactivatePodcasts = /* GraphQL */ `
  mutation activateDeactivatePodcasts($input: PodcastStatusInput!) {
    batchWritePodcasts: MPCBatchWriteShowsV2(input: $input)
  }
`;

export const deletePodcasts = /* GraphQL */ `
  mutation softDeletePodcasts($input: DeletePodcastsInput!) {
    softDeletePodcasts(input: $input)
  }
`;
