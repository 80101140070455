import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Button, Box, Chip, Tooltip, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';
import pluralize from 'pluralize';
import {
  EntitiesDataGrid,
  HierarchyHeader,
  SimpleDropdown,
  SkeletonLoading,
} from '@components/index';
import { PodcastOwners } from '@components/containers/AddPodcast';
import { EntitiesIcon } from '@components/icons/index';
import useInfiniteRow from 'hooks/useInfiniteRow';
import { useEntityNames } from 'contexts/EntitiesNamesContext';
import AddYourPodcastContextProvider from 'contexts/AddYourPodcastContext';
import { useAuth } from 'contexts/AuthContext';
import { record } from '@utils/analytics';
import { TAP_ADD_PODCAST, TAP_PODCAST_ITEM } from 'constants/analytics';
import { getFeedIngestionStatus, getPodcastAutoType } from '@utils/m11n';
import { AddPodcastModal } from '../index';
import { requestAPIGraphQL } from '@services/appSyncAPI';
import { getPodcasterById, listPodcastsForPodcaster } from '@graphql/queries/podcasters';
import {
  activateDeactivatePodcasts,
  deletePodcasts,
  updatePodcaster,
} from '@graphql/mutations/podcasters';
import { MPC_USER, PODCASTER, PODCAST_PREFIX } from 'constants/idPrefixes';
import { STRINGS } from 'constants/strings';
import { HEX_COLORS } from 'constants/stylesVariables';
import TOAST_OPTIONS from 'constants/toastOptions';
import { FEED_INGESTION_STATUS } from 'constants/m11n';
import { STATUS_LABELS } from 'constants/activeStatus';
import { DEFAULT_LIMIT } from 'constants/appSync';
import { PODCASTER_VIEW_ACTIONS } from 'constants/actionsList';
import { MPC_ROUTES } from 'constants/routing';
import { uuid } from '@utils/uuid';
import { getPodcasterName } from '@utils/getPodcasterName';
import { getAvailableInGeosPayload } from '@utils/getAvailableInGeos';
import { getActiveStatusValue, getIsActive } from '@utils/getActiveStatus';
import {
  cleanPhoneNumber,
  extractPhoneCodeNumber,
  formatPhoneNumber,
} from '@utils/phone';
import { replaceLiterals } from '@utils/replaceLiterals';
import MySwal from '@utils/swalWrapper';
import { categoriesWithSubsToText } from '@utils/categories';
import { showPromiseError } from '@utils/promiseError';
import PodcasterDetail from './detail';
import EditPodcaster from './edit';

const PODCAST_ACTIONS = {
  ACTIVATE: { key: 'activate', label: 'Activate Podcasts' },
  DEACTIVATE: { key: 'deactivate', label: 'Deactivate Podcasts' },
  DELETE: { key: 'delete', label: 'Delete Podcasts' },
};

const CELL_STYLES_CENTERED = {
  display: 'flex',
  alignItems: 'center',
  minWidth: 0,
};

const CELL_PADDING = { paddingTop: 3 };

const PODCASTS_BLOCK = DEFAULT_LIMIT;

const PodcasterContainer = ({ id }) => {
  const [editMode, setEditMode] = useState(false);
  const [selectedPodcasts, setSelectedPodcasts] = useState([]);
  const [podcaster, setPodcaster] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openAddPodcast, setOpenAddPodcast] = useState(false);
  const gridRef = useRef();
  const isMounted = useRef(false);

  const history = useHistory();
  const { pathname } = useLocation();
  const setEntity = useEntityNames()[1];
  const { permissions } = useAuth();

  const { getDataSource } = useInfiniteRow({
    query: listPodcastsForPodcaster,
    payload: {
      podcasterId: `${PODCASTER}${id}`,
      limit: PODCASTS_BLOCK,
    },
    responseKeyName: 'podcastsList',
  });

  const podcastActions = useMemo(() => {
    const podcastsLength = selectedPodcasts.length;
    if (podcastsLength === 0) {
      return [];
    }

    let tempActions = cloneDeep(PODCAST_ACTIONS);

    if (!permissions[PODCASTER_VIEW_ACTIONS.ACTIVATE_PODCASTS]) {
      delete tempActions.ACTIVATE;
    }

    if (!permissions[PODCASTER_VIEW_ACTIONS.DEACTIVATE_PODCASTS]) {
      delete tempActions.DEACTIVATE;
    }

    if (!permissions[PODCASTER_VIEW_ACTIONS.DELETE_PODCASTS]) {
      delete tempActions.DELETE;
    }

    if ('ACTIVATE' in tempActions && 'DEACTIVATE' in tempActions) {
      const activatedLength = selectedPodcasts.reduce(
        (accumulator, current) => (accumulator += current.isActivated),
        0
      );

      if (podcastsLength === 1) {
        tempActions.ACTIVATE.label = pluralize.singular(tempActions.ACTIVATE.label);
        tempActions.DEACTIVATE.label = pluralize.singular(tempActions.DEACTIVATE.label);

        activatedLength
          ? (tempActions.ACTIVATE.disabled = true)
          : (tempActions.DEACTIVATE.disabled = true);
      } else {
        if (activatedLength === podcastsLength) {
          tempActions.ACTIVATE.disabled = true;
        }

        if (activatedLength === 0) {
          tempActions.DEACTIVATE.disabled = true;
        }
      }
    }

    if ('DELETE' in tempActions && podcastsLength === 1) {
      tempActions.DELETE.label = pluralize.singular(tempActions.DELETE.label);
    }

    return Object.values(tempActions);
  }, [selectedPodcasts, permissions]);

  // Get array of stages for create podcast flow. This injects stage for show and select podcast hosts
  const podcastStages = useMemo(() => {
    return [
      {
        breadcrumbHeaderTitle: 'Podcast Owners',
        component: PodcastOwners,
      },
    ];
  }, []);

  const columnDefs = useMemo(
    () => [
      {
        field: '',
        headerName: '',
        width: 60,
        cellStyle: CELL_STYLES_CENTERED,
        checkboxSelection: ({ data }) => {
          if (!data) return false;

          const { isDeleted } = data;

          if (isDeleted) return false;

          return true;
        },
      },
      {
        width: 60,
        cellRenderer: ({ data }) => (
          <EntitiesIcon
            centerIcon
            defaultImageDimensions
            type={STRINGS.PODCAST}
            podcastShowArt={data?.showArtSmall}
            height={24}
            width={24}
          />
        ),
      },
      {
        field: 'isDeleted',
        hide: true,
      },
      {
        field: 'title',
        headerName: 'Title',
        width: 330,
        cellRenderer: (params) => {
          const { value, data } = params;

          if (!data) return '';

          const { feedIngestionState, email } = data;

          if (feedIngestionState !== FEED_INGESTION_STATUS.ready.key) {
            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: 0,
                }}
              >
                <Tooltip
                  title={replaceLiterals(
                    STRINGS.WE_ARE_CURRENTLY_PROCESSING_THIS_PODCAST_WELL_SEND_AN_EMAIL_TO_EMAIL_WHEN_ITS_COMPLETE,
                    { email }
                  )}
                >
                  <WarningIcon sx={{ fontSize: 14, mr: 1 }} color="warning" />
                </Tooltip>
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {value}
                </span>
              </Box>
            );
          }

          return value;
        },
        cellStyle: CELL_PADDING,
        tooltipField: 'title',
      },
      {
        field: 'description',
        headerName: 'Description',
        minWidth: 300,
        flex: 1,
        tooltipField: 'description',
        cellStyle: CELL_PADDING,
      },
      {
        field: 'rssFeedLink',
        headerName: 'RSS Feed',
        cellRenderer: (params) => {
          return (
            <a href={params.value} target="_blank" rel="noreferrer">
              Link
            </a>
          );
        },
        width: 110,
        cellStyle: CELL_STYLES_CENTERED,
      },
      {
        field: 'categoriesWithSubs',
        headerName: 'Categories',
        width: 250,
        cellRenderer: ({ value }) => categoriesWithSubsToText(value),
        cellStyle: CELL_PADDING,
      },
      {
        field: 'isActivated',
        headerName: 'Status',
        cellRenderer: ({ value, data }) => {
          if (!data) return '';

          const { isDeleted } = data;

          if (isDeleted) return STRINGS.DELETED;

          return STATUS_LABELS[getActiveStatusValue(value)];
        },
        width: 100,
        cellStyle: CELL_STYLES_CENTERED,
      },
      {
        headerName: 'Marbylization',
        children: [
          {
            field: 'm11nIsEnabled',
            headerName: 'Enabled',
            cellRenderer: (params) => {
              const { value } = params;

              if (value) {
                return <CheckIcon color="success" />;
              }

              return <CloseIcon color="error" />;
            },
            width: 100,
            cellStyle: {
              justifyContent: 'center',
              borderLeft: `1px solid ${HEX_COLORS.dimGray}`,
              ...CELL_STYLES_CENTERED,
            },
          },
          {
            field: 'm11nAutoType',
            headerName: 'Auto Type',
            cellRenderer: (params) => {
              const { value } = params;

              return <Chip {...getPodcastAutoType(value)} />;
            },
            minWidth: 100,
            maxWidth: 140,
            cellStyle: { justifyContent: 'center', ...CELL_STYLES_CENTERED },
          },
          {
            field: 'feedIngestionState',
            headerName: 'Ingestion Status',
            cellRenderer: (params) => {
              const { value } = params;

              if (!value) return '';

              return <Chip {...getFeedIngestionStatus(value)} />;
            },
            width: 155,
            cellStyle: {
              justifyContent: 'center',
              ...CELL_STYLES_CENTERED,
            },
          },
        ],
      },
    ],
    []
  );

  const handleAddPodcast = () => {
    record(TAP_ADD_PODCAST);
    setOpenAddPodcast(true);
  };

  const setDataSource = useCallback(
    () => gridRef.current.api.setDatasource(getDataSource()),
    [getDataSource]
  );

  const getRowId = useCallback(function (params) {
    return params.data.PK;
  }, []);

  const handlePodcastClick = ({
    PK: podcastId,
    feedIngestionState,
    isDeleted,
    title,
  }) => {
    if (isDeleted || selectedPodcasts.length) return;

    if (feedIngestionState === FEED_INGESTION_STATUS.pending.key) {
      toast.warn(
        STRINGS.RSS_FEED_IS_BEING_PROCESSED_FOR_THIS_PODCAST_PLEASE_TRY_LATER,
        TOAST_OPTIONS
      );
      return;
    }

    if (!permissions[PODCASTER_VIEW_ACTIONS.GO_TO_PODCAST_PAGE]) {
      return;
    }

    const _podcastId = podcastId.replace(PODCAST_PREFIX, '');
    record(TAP_PODCAST_ITEM, { id: _podcastId });
    setEntity(_podcastId, title);
    history.push(`${pathname}${MPC_ROUTES.PODCAST}/${_podcastId}`);
  };

  const loadPodcaster = useCallback(() => {
    requestAPIGraphQL(getPodcasterById, { podcasterId: `${PODCASTER}${id}` })
      .then(({ data: { getPodcasterById: getPodcasterByIdRes } }) => {
        if (!getPodcasterByIdRes) return;

        let { owners, owningCompanyName, name } = getPodcasterByIdRes;

        if (!name) {
          name = getPodcasterName(owners, owningCompanyName);
        }

        owners = owners.map((_own) => ({
          ..._own,
          phoneCode: _own.phoneCode.trim() === '+' ? '+1' : _own.phoneCode,
          phone: _own.phoneNumber
            ? `${_own.phoneCode} ${formatPhoneNumber(_own.phoneNumber)}`
            : '',
        }));

        isMounted.current && setPodcaster({ ...getPodcasterByIdRes, owners, name });
      })
      .catch(showPromiseError);
  }, [id]);

  const onClickAction = (action) => {
    const { ids, hash } = selectedPodcasts.reduce(
      (obj, pod) => ({
        ids: [...obj.ids, pod.PK],
        hash: { ...obj.hash, [pod.PK]: pod.title },
      }),
      { ids: [], hash: [] }
    );
    let query = null;
    let message = '';
    let payload = {
      podcasterId: podcaster.PK,
      podcastIds: ids,
    };
    let confirmationMessage = '';

    const actionCaller = (actionPromise, successMessage) => {
      setLoading(true);

      return actionPromise
        .then(() => {
          toast.success(successMessage, TOAST_OPTIONS);
          const { podcastIds } = payload;
          switch (action) {
            case PODCAST_ACTIONS.ACTIVATE.key:
            case PODCAST_ACTIONS.DEACTIVATE.key: {
              const { toggle } = payload;

              podcastIds.forEach((_podId) => {
                const rowNode = gridRef.current.api.getRowNode(_podId);
                rowNode.setDataValue('isActivated', toggle);
              });
              break;
            }

            case PODCAST_ACTIONS.DELETE.key: {
              podcastIds.forEach((_podId) => {
                const rowNode = gridRef.current.api.getRowNode(_podId);
                rowNode.setDataValue('isDeleted', true);
                rowNode.setDataValue('isActivated', null);
              });
              break;
            }

            default:
              break;
          }
        })
        .catch(showPromiseError)
        .finally(() => {
          gridRef.current.api.deselectAll();
          setLoading(false);
        });
    };

    const getMessage = (singleMessage, multipleMessage) => {
      return ids.length === 1
        ? replaceLiterals(singleMessage, {
            podcastName: hash[ids[0]],
          })
        : replaceLiterals(multipleMessage, {
            count: ids.length,
          });
    };

    switch (action) {
      case PODCAST_ACTIONS.ACTIVATE.key: {
        payload.toggle = true;
        query = activateDeactivatePodcasts;
        message = getMessage(
          STRINGS.PODCAST_NAME_HAS_BEEN_ACTIVATED,
          STRINGS.PODCASTS_HAVE_BEEN_ACTIVATED
        );
        break;
      }

      case PODCAST_ACTIONS.DEACTIVATE.key: {
        payload.toggle = false;
        query = activateDeactivatePodcasts;
        message = getMessage(
          STRINGS.PODCAST_NAME_HAS_BEEN_DEACTIVATED,
          STRINGS.PODCASTS_HAVE_BEEN_DEACTIVATED
        );
        break;
      }

      case PODCAST_ACTIONS.DELETE.key: {
        delete payload.podcasterId;
        query = deletePodcasts;
        message = getMessage(
          STRINGS.PODCAST_NAME_HAS_BEEN_DElETED,
          STRINGS.PODCASTS_HAVE_BEEN_DELETED
        );
        confirmationMessage = getMessage(
          STRINGS.ARE_YOU_SURE_DO_YOU_WANT_TO_DELETE_PODCAST_NAME,
          STRINGS.ARE_YOU_SURE_DO_YOU_WANT_TO_DELETE_SELECTED_PODCASTS
        );

        break;
      }

      default:
        return;
    }

    if (confirmationMessage) {
      MySwal.fire({
        html: <Typography variant="body1">{confirmationMessage}</Typography>,
        showCancelButton: true,
        confirmButtonText: STRINGS.CONFIRM,
        denyButtonText: STRINGS.CANCEL,
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          actionCaller(requestAPIGraphQL(query, { input: payload }), message);
        }
      });

      return;
    }

    actionCaller(requestAPIGraphQL(query, { input: payload }), message);
  };

  const handleSubmitPodcaster = useCallback(
    (values) => {
      const {
        id: podcasterId,
        description,
        availableInGeos,
        owningCompanyName,
        contentAgreementType,
        isActivated,
        owners,
        name,
      } = values;

      let formatedOwners = owners.map((_owner) => {
        const { id: ownerId, name: ownerName, email, ownerIsAccountUser } = _owner;
        let { phone } = _owner;
        phone = cleanPhoneNumber(phone);
        const { code: phoneCode, number: phoneNumber } = extractPhoneCodeNumber(phone);

        return {
          id: ownerId || `${MPC_USER}${uuid()}`,
          name: ownerName,
          email,
          phoneCode: `+${phoneCode}`,
          phoneNumber,
          ownerIsAccountUser: !!ownerIsAccountUser,
        };
      });

      let podcasterName = name || getPodcasterName(formatedOwners, owningCompanyName);

      const payload = {
        podcasterId,
        name: podcasterName,
        description,
        ...(availableInGeos && {
          availableInGeos: getAvailableInGeosPayload(availableInGeos),
        }),
        owningCompanyName,
        ...(contentAgreementType && { contentAgreementType }),
        ...(isActivated && { isActivated: getIsActive(isActivated) }),
        owners: formatedOwners,
      };

      requestAPIGraphQL(updatePodcaster, { input: payload })
        .then(() => {
          loadPodcaster();
          setEntity(podcasterId.replace(PODCASTER, ''), podcasterName);
          toast.success(STRINGS.PODCASTER_UPDATED, TOAST_OPTIONS);
          setEditMode(false);
        })
        .catch(showPromiseError);
    },
    [loadPodcaster, setEntity]
  );

  const getRowStyle = useCallback(({ data }) => {
    if (!data) return;

    const { isDeleted } = data;

    if (isDeleted) {
      return { color: 'gray', cursor: 'normal' };
    }
  }, []);

  const renderExpanded = useCallback(() => {
    if (editMode) {
      return (
        <EditPodcaster
          onCancel={() => setEditMode(false)}
          onSubmit={handleSubmitPodcaster}
          {...podcaster}
        />
      );
    }

    return <PodcasterDetail onClickEdit={() => setEditMode(true)} {...podcaster} />;
  }, [editMode, podcaster, handleSubmitPodcaster]);

  const renderAddPodcastButton = useCallback(() => {
    return permissions[PODCASTER_VIEW_ACTIONS.ADD_PODCAST] ? (
      <Button onClick={handleAddPodcast} variant="outlined" color="secondary">
        {STRINGS.ADD_PODCAST}
      </Button>
    ) : null;
  }, [permissions]);

  const initLoad = useCallback(() => {
    isMounted.current = true;

    loadPodcaster();

    return () => {
      isMounted.current = false;
    };
  }, [loadPodcaster]);

  const handlePodcastSubmit = useCallback(() => {
    loadPodcaster();
    setDataSource();
  }, [loadPodcaster, setDataSource]);

  useEffect(() => {
    initLoad();
  }, [initLoad]);

  if (!podcaster) {
    return <SkeletonLoading />;
  }

  return (
    <>
      <HierarchyHeader
        title={podcaster.name}
        headerType={STRINGS.PODCASTER}
        {...(permissions[PODCASTER_VIEW_ACTIONS.PODCASTER_DETAILS] && {
          renderExpandedContent: renderExpanded,
        })}
      />
      <Box sx={{ display: 'flex', mt: 2, justifyContent: 'space-between' }}>
        {renderAddPodcastButton()}
        <SimpleDropdown
          disabled={selectedPodcasts.length === 0 || loading}
          actions={Object.values(podcastActions)}
          onClickAction={onClickAction}
        />
      </Box>

      <EntitiesDataGrid
        ref={gridRef}
        columnDefs={columnDefs}
        onCellClicked={({ data, column }) => {
          if (column.colId !== 'rssFeedLink') {
            handlePodcastClick(data);
          }
        }}
        onSelectionChanged={(evt) => {
          setSelectedPodcasts(evt.api.getSelectedRows());
        }}
        rowModelType={'infinite'}
        onGridReady={setDataSource}
        cacheBlockSize={PODCASTS_BLOCK}
        getRowId={getRowId}
        getRowStyle={getRowStyle}
      />

      <AddYourPodcastContextProvider initPodcaster={podcaster}>
        <AddPodcastModal
          open={openAddPodcast}
          onClose={() => setOpenAddPodcast(false)}
          onFinish={handlePodcastSubmit}
          stages={podcastStages}
        />
      </AddYourPodcastContextProvider>
    </>
  );
};

export default PodcasterContainer;
