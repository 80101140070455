import React from 'react';
import { Button } from '@mui/material';
import { STRINGS } from 'constants/strings';
import { TAP_LOGOUT } from 'constants/analytics';
import { useLocation } from 'react-router-dom';
import { record } from '@utils/analytics';

const LogoutButton = ({ onLogout }) => {
  const { pathname } = useLocation();

  return (
    <Button
      onClick={() => {
        record(TAP_LOGOUT, { pathname });
        onLogout();
      }}
      color="secondary"
      variant="outlined"
    >
      {STRINGS.LOGOUT}
    </Button>
  );
};

export default LogoutButton;
