import React, { useCallback, useState } from 'react';
import { Typography, Paper } from '@mui/material';
import {
  StyledExpandedBox,
  StyledExpandedIcon,
  StyledExpandedContainer,
  StyledTopContainer,
  StyledTitleContainer,
  StyledTitle,
  StyledDescription,
  StyledOptionsContainer,
} from './styled';
import { STRINGS } from 'constants/strings';
import { record } from '@utils/analytics';
import {
  TAP_SHOW_NETWORK_DETAILS,
  TAP_HIDE_NETWORK_DETAILS,
  TAP_SHOW_PODCASTER_DETAILS,
  TAP_HIDE_PODCASTER_DETAILS,
  TAP_SHOW_PODCAST_DETAILS,
  TAP_HIDE_PODCAST_DETAILS,
} from 'constants/analytics';
import { EntitiesIcon } from '@components/icons/index';
import { defaultProps, props } from './props';

const EXPANDED_CLASS = 'open';

const eventsByType = {
  [STRINGS.NETWORK]: {
    show: TAP_SHOW_NETWORK_DETAILS,
    hide: TAP_HIDE_NETWORK_DETAILS,
  },
  [STRINGS.PODCASTER]: {
    show: TAP_SHOW_PODCASTER_DETAILS,
    hide: TAP_HIDE_PODCASTER_DETAILS,
  },
  [STRINGS.PODCAST]: {
    show: TAP_SHOW_PODCAST_DETAILS,
    hide: TAP_HIDE_PODCAST_DETAILS,
  },
};

const HierarchyHeader = ({
  title,
  description,
  renderOptions,
  renderExpandedContent,
  headerType,
  podcastShowArt,
}) => {
  const [expanded, setExpanded] = useState(false);

  const renderExpanded = useCallback(() => {
    const handleExpandedButton = () => {
      const eventType = eventsByType[headerType];

      if (eventType) {
        if (expanded) record(eventType.show);
        else record(eventType.hide);
      }

      setExpanded(!expanded);
    };

    const expandedText = !expanded ? 'Show' : 'Hide';

    return (
      !!renderExpandedContent && (
        <>
          <StyledExpandedBox className={`${expanded ? EXPANDED_CLASS : ''}`}>
            {!!renderExpandedContent && renderExpandedContent()}
          </StyledExpandedBox>
          <StyledExpandedContainer role="button" onClick={handleExpandedButton}>
            <Typography>
              {expandedText} {headerType} Details
            </Typography>
            <StyledExpandedIcon
              className={`${expanded ? EXPANDED_CLASS : ''}`}
              fontSize="large"
            />
          </StyledExpandedContainer>
        </>
      )
    );
  }, [renderExpandedContent, expanded, headerType]);

  const renderHeaderType = useCallback(() => {
    const size = headerType === STRINGS.PODCAST ? 45 : 55;

    return (
      <EntitiesIcon
        height={size}
        marginTop={headerType === STRINGS.PODCAST ? 17 : 12}
        podcastShowArt={podcastShowArt}
        type={headerType || ''}
        width={size}
      />
    );
  }, [headerType, podcastShowArt]);

  return (
    <Paper
      elevation={2}
      sx={{
        p: 2,
        position: 'relative',
        bgcolor: 'primary.main',
        color: 'secondary.main',
      }}
    >
      <StyledTopContainer>
        <StyledTitleContainer>
          {renderHeaderType()}
          <StyledTitle variant="h2" sx={{ mr: 2 }}>
            {title}
          </StyledTitle>
          {description && (
            <StyledDescription variant="body2">{description}</StyledDescription>
          )}
        </StyledTitleContainer>
        <StyledOptionsContainer>{renderOptions()}</StyledOptionsContainer>
      </StyledTopContainer>
      {renderExpanded()}
    </Paper>
  );
};

HierarchyHeader.propTypes = props;
HierarchyHeader.defaultProps = defaultProps;

export default HierarchyHeader;
