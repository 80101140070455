import PropTypes from 'prop-types';

export const props = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

export const defaultProps = {};
