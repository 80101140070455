import styled from '@mui/styled-engine';
import { Box, Select, Typography } from '@mui/material';

export const StyledMainContainer = styled(Box)(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing(5)};

    @media (max-width: ${theme.breakpoints.values.sm}px) {
      padding: ${theme.spacing(2.5)};
    }
  `
);

export const StyledMarbylText = styled(Typography)(
  () => `
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    line-height: 1.3;
  `
);

export const StyledFiltersContainer = styled(Box)(
  ({ theme }) => `
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: ${theme.spacing(5.625)};

    div:not(:last-child) {
      margin-right: ${theme.spacing(1)};
    }

    & > * {
      margin-bottom: ${theme.spacing(1)};
      margin-top: ${theme.spacing(1)};
    }

    @media (max-width: ${theme.breakpoints.values.sm}px) {
      margin: 0;

      & > * {
        margin-bottom: ${theme.spacing(0.5)};
        margin-top: ${theme.spacing(0.5)};
      }
    }
  `
);

export const StyledFilterSelect = styled(Select)(
  () => `
    height: 35px;
    width: 200px;
  `
);

export const StyledScrollContainer = styled(Box)(
  ({ theme }) => `
    max-height: 615px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 7px;
    }
    
    &::-webkit-scrollbar-thumb {
        background: #bfbfbf;
        border-radius: 10px;
        height: 42px;
    }

    .List {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: auto;
      overflow: hidden !important;

      @media (min-width: ${theme.breakpoints.values.md}px) {
        grid-template-columns: auto auto;
      }
  
      @media (min-width: ${theme.breakpoints.values.lg}px) {
        grid-template-columns: auto auto auto;
      }
  `
);

export const StyledAllItemsText = styled('p')(
  () => `
    text-align: center;
  `
);
