import ClaimPodcastPage from '@pages/ClaimPodcastPage/ClaimPodcastPage';
import NotFoundPage from '@pages/NotFoundPage/NotFoundPage';
import IndyPodcastsPage from '@pages/IndyPodcastsPage/IndyPodcastsPage';
import PartnersLandingPage from '@pages/PartnersLandingPage/PartnersLandingPage';
import { PARTNERS_ROUTES } from 'constants/routing';
import React from 'react';
import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { CommonSwitch } from './common';

const PartnersRoutes = () => {
  const { url } = useRouteMatch();

  const routes = useMemo(
    () => [
      {
        exact: true,
        path: url,
        childComponent: <PartnersLandingPage />,
      },
      {
        path: PARTNERS_ROUTES.CLAIM,
        childComponent: <ClaimPodcastPage />,
        privateRoute: true,
      },
      {
        path: PARTNERS_ROUTES.PODCASTS,
        childComponent: <IndyPodcastsPage />,
        privateRoute: true,
      },
      {
        path: null,
        childComponent: <NotFoundPage />,
      },
    ],
    [url]
  );

  return <CommonSwitch routes={routes} />;
};

export default PartnersRoutes;
