import { DynamicForm } from '@components/index';
import { getActiveStatusValue } from '@utils/getActiveStatus';
import { getAvailableInGeos } from '@utils/getAvailableInGeos';
import { PODCASTER_VIEW_ACTIONS } from 'constants/actionsList';
import { STATUS_ACTIVE, STATUS_INACTIVE, STATUS_LABELS } from 'constants/activeStatus';
import { FIELD_TYPES } from 'constants/dynamicFormTypes';
import { AGREEMENT_TYPES, AGREEMENT_TYPES_LABELS } from 'constants/podcaster';
import { STRINGS } from 'constants/strings';
import { useAuth } from 'contexts/AuthContext';
import React, { useMemo } from 'react';

const EditPodcaster = ({
  onCancel,
  PK,
  name,
  availableInGeos,
  owners = [],
  owningCompanyName,
  contentAgreementType,
  isActivated,
  description,
  onSubmit,
}) => {
  const { permissions } = useAuth();

  const canEditGeo = useMemo(
    () => !!permissions[PODCASTER_VIEW_ACTIONS.EDIT_PODCASTER_GEO_AVAILABILITY],
    [permissions]
  );

  const canEditAgreement = useMemo(
    () => !!permissions[PODCASTER_VIEW_ACTIONS.CHANGE_AGREEMENT_TYPE],
    [permissions]
  );

  const canEditOwner = useMemo(
    () => !!permissions[PODCASTER_VIEW_ACTIONS.EDIT_OWNER_INFO],
    [permissions]
  );

  const canToggleStatus = useMemo(
    () => !!permissions[PODCASTER_VIEW_ACTIONS.DEACTIVATE_ACCOUNT],
    [permissions]
  );

  const fields = useMemo(() => {
    return [
      {
        type: FIELD_TYPES.TEXT,
        label: 'Name',
        name: 'name',
        required: true,
        color: 'secondary',
        value: '',
      },
      ...(canEditGeo
        ? [
            {
              type: FIELD_TYPES.COUNTRIES_SELECT,
              name: 'availableInGeos',
              color: 'secondary',
              label: STRINGS.GEOGRAPHIC_AVAILABILITY,
            },
          ]
        : []),
      {
        type: FIELD_TYPES.TEXT,
        label: 'Description',
        name: 'description',
        color: 'secondary',
      },
      ...(canEditOwner
        ? [
            {
              type: FIELD_TYPES.LIST,
              label: 'OWNER INFORMATION',
              name: 'owners',
              addButtonLabel: 'Add new Owner',
              headerColumns: [{ name: 'Name' }, { name: 'Email' }, { name: 'Phone' }],
              inputs: [
                {
                  required: true,
                  name: 'name',
                  label: 'Name',
                  type: 'text',
                  defaultValue: '',
                },
                {
                  required: true,
                  name: 'email',
                  label: 'Email',
                  type: 'email',
                  defaultValue: '',
                },
                {
                  name: 'phone',
                  label: 'Phone',
                  type: 'phone',
                  defaultValue: '',
                },
              ],
            },
            {
              type: FIELD_TYPES.TEXT,
              label: 'Company Name',
              name: 'owningCompanyName',
              color: 'secondary',
              value: '',
            },
          ]
        : []),
      ...(canToggleStatus || canEditAgreement
        ? [
            {
              type: FIELD_TYPES.TITLE,
              label: 'Admin Settings',
            },
          ]
        : []),
      ...(canToggleStatus
        ? [
            {
              type: FIELD_TYPES.SELECT,
              label: 'Status',
              name: 'isActivated',
              items: [STATUS_ACTIVE, STATUS_INACTIVE].map((_status) => ({
                value: _status,
                label: STATUS_LABELS[_status],
              })),
              md: 6,
              color: 'secondary',
            },
          ]
        : []),
      ...(canEditAgreement
        ? [
            {
              type: FIELD_TYPES.SELECT,
              label: 'Agreement',
              name: 'contentAgreementType',
              items: Object.values(AGREEMENT_TYPES).map((_key) => ({
                value: _key,
                label: AGREEMENT_TYPES_LABELS[_key],
              })),
              md: 6,
              color: 'secondary',
            },
          ]
        : []),
    ];
  }, [canEditGeo, canEditAgreement, canEditOwner, canToggleStatus]);

  const initialValues = useMemo(() => {
    return {
      name,
      description,
      ...(canEditGeo && {
        availableInGeos: getAvailableInGeos(availableInGeos),
      }),
      owners,
      owningCompanyName,
      ...(canToggleStatus && {
        isActivated: getActiveStatusValue(isActivated),
      }),
      ...(canEditAgreement && { contentAgreementType }),
    };
  }, [
    name,
    description,
    availableInGeos,
    owners,
    owningCompanyName,
    isActivated,
    contentAgreementType,
    canEditGeo,
    canEditAgreement,
    canToggleStatus,
  ]);

  const handleSubmit = (values) => onSubmit({ id: PK, ...values });

  return (
    <DynamicForm
      formHeader={`${STRINGS.PODCASTER_DETAILS}:`}
      formHeaderVariant={'h5'}
      initialValues={initialValues}
      fields={fields}
      onCancel={onCancel}
      onSubmit={handleSubmit}
    />
  );
};

export default EditPodcaster;
