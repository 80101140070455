import React, { useState, useCallback, useEffect } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import { Select } from '@components/';
import { useMPC } from 'contexts/MPCContext';
import { STRINGS } from 'constants/strings';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  boxShadow: 24,
  p: 4,
};

const TOOLBAR_CONTAINER_STYLES = {
  display: 'flex',
  cursor: 'pointer',
  mb: 0.5,
};

const SwitchAccountsModal = () => {
  const [openModal, setOpenModal] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState({ value: '', label: '' });
  const {
    accounts: availableAccounts,
    selectedAccount: selectedAccountMPC,
    saveSelectedUserAccount,
    loading,
  } = useMPC();
  const theme = useTheme();

  useEffect(() => {
    setSelectedAccount({
      value: selectedAccountMPC?.accountId,
      label: selectedAccountMPC?.entityName,
    });
  }, [selectedAccountMPC]);

  const toggleModal = () => setOpenModal((prevOpen) => !prevOpen);

  const retrieveAccounts = useCallback(() => {
    const retrievedAccounts = availableAccounts.map((availableAccount) => ({
      ...availableAccount,
      value: availableAccount?.accountId,
      label: availableAccount?.entityName,
    }));

    setAccounts(retrievedAccounts);
  }, [availableAccounts]);

  useEffect(() => {
    retrieveAccounts();
  }, [retrieveAccounts]);

  const onSaveAccount = () => {
    saveSelectedUserAccount(selectedAccount, toggleModal);
  };

  const disableSaveBtn = () => selectedAccount.value === '';

  if (accounts?.length < 2) return null;

  return (
    <>
      <Box
        sx={TOOLBAR_CONTAINER_STYLES}
        onClick={toggleModal}
        color="secondary"
        aria-label="switch accounts"
      >
        <div style={{ minWidth: 40 }}>
          <SwitchAccountIcon />
        </div>
        <Typography>Switch Accounts</Typography>
      </Box>
      <Modal open={openModal} onClose={toggleModal}>
        <Box sx={{ ...style, bgcolor: theme.palette.primary.main }}>
          <Typography>Switch Accounts</Typography>
          <Box sx={{ marginTop: 1, marginBottom: '50px' }}>
            <Select
              onSelect={setSelectedAccount}
              optionsList={accounts}
              selectedValue={selectedAccount}
              disabled={loading}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={toggleModal}
              variant="outlined"
              color="secondary"
              disabled={loading}
            >
              {STRINGS.CANCEL}
            </Button>
            <Button
              sx={{ ml: 2 }}
              onClick={onSaveAccount}
              variant="contained"
              color="error"
              disabled={loading || disableSaveBtn()}
            >
              {STRINGS.SAVE}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SwitchAccountsModal;
