import styled from '@mui/styled-engine';
import { HEX_COLORS } from 'constants/stylesVariables';

export const StyledAuthForm = styled('form')(
  ({ theme, maxWidth }) => `
  margin: 0 auto;
  width: 100%;
  background-color: ${HEX_COLORS.white};
  padding: ${theme.spacing(5)};
  border-radius: ${theme.spacing(3.5)};
  max-width: ${maxWidth || '400'}px;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    max-height: 80vh;
    max-width: 80%;
    overflow: auto;

    &::-webkit-scrollbar { display: none; }
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    max-height: 90%;
    overflow: auto;
    max-width: 90%;
    padding: ${theme.spacing(2.5)};
  }
`
);
