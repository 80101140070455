import React from 'react';
import { Box, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { props } from './props';

const Stepper = ({ steps, activeStep }) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 2 }}>
      {steps.map((step, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
          }}
        >
          <Typography sx={{ ...(activeStep === index && { fontWeight: 600 }) }}>
            {step}
          </Typography>
          {index < steps.length - 1 && <ChevronRightIcon />}
        </Box>
      ))}
    </Box>
  );
};

Stepper.propTypes = props;

export default Stepper;
