export const extractPhoneCodeNumber = (phone) => {
  let number = '';
  let code = '';

  code = phone.substring(0, phone.length - 10);
  number = phone.substring(phone.length - 10);

  return {
    code,
    number,
  };
};

export const cleanPhoneNumber = (phone) => (phone ? phone.replace(/\D+/g, '') : '');

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
};
