export const getAllAccountsByUserId = /* GraphQL */ `
  query getAllAccountsByUserId($userId: String!) {
    getAllAccountsByUserId: MPCGetAllAccounts(userId: $userId) {
      userAccounts {
        accountId
        entityId
        entityAccountId
        entityName
        createdAt
        deleted
        accountRole
        updatedAt
        accountOwner
        isActivated
      }
    }
  }
`;
