import { HEX_COLORS } from 'constants/stylesVariables';

export const PALETTE = {
  mode: 'dark',
  primary: {
    main: HEX_COLORS.darkJungleGreen,
  },
  secondary: {
    main: HEX_COLORS.white,
  },
};

export const LIGHT_PALETTE = {
  mode: 'light',
  primary: {
    main: '#f7f7f7',
  },
  secondary: {
    main: HEX_COLORS.black,
  },
  primaryDark: {
    main: HEX_COLORS.darkJungleGreen,
  },
  secondaryDark: {
    main: HEX_COLORS.white,
  },
  error: {
    main: HEX_COLORS.electricCrimson,
  },
  warning: {
    main: HEX_COLORS.brightYellow,
  },
  info: {
    main: HEX_COLORS.buttonBlue,
  },
  success: {
    main: HEX_COLORS.mantis,
  },
  main: {
    main: HEX_COLORS.white,
  },
  blue: {
    main: HEX_COLORS.blueBerry,
  },
  celticBlue: {
    main: HEX_COLORS.celticBlue,
  },
};
