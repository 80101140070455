import { ACCOUNT_TYPES } from 'constants/accountTypes';

export const getAccountType = (defaultRole) => {
  if (defaultRole.toLowerCase().includes('treegoat')) {
    return ACCOUNT_TYPES.TGM;
  } else if (defaultRole.toLowerCase().includes('podcaster')) {
    return ACCOUNT_TYPES.PODCASTER;
  }

  return ACCOUNT_TYPES.PODCAST_NETWORK;
};
