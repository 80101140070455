import styled from '@mui/styled-engine';
import { HEX_COLORS, FONT_FAMILIES } from 'constants/stylesVariables';

export const StyledButtonLink = styled('span')(
  ({ isUnderline, isLandingPage }) =>
    `
    color: ${isLandingPage ? HEX_COLORS.white : '#337ab7'};
    cursor: pointer;
    ${isUnderline ? 'text-decoration: underline;' : ''}
    ${
      isLandingPage &&
      `
      font-family: ${FONT_FAMILIES.proximaNovaRegular};
      font-size: 22px;
      font-weight: 500;
      
    `
    }

    &:hover {
      color: ${isLandingPage ? HEX_COLORS.white : '#23527c'};
      ${!isLandingPage && 'text-decoration: underline;'}
    }
  `
);
