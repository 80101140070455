import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Fade,
  CircularProgress,
} from '@mui/material';
import styled from '@mui/styled-engine';
import Spacer from '@shared/spacer';
import {
  getUserByEmail,
  getRolesByAccountType,
  getAllEntitiesByAccountType,
} from '@graphql/queries/users';
import { toast } from 'react-toastify';
import { sendInvitationUserCreation } from '@graphql/mutations/users';
import { STRINGS } from 'constants/strings';
import { useAuth } from 'contexts/AuthContext';
import { useDeps } from 'contexts/DepsContext';
import { useMPC } from 'contexts/MPCContext';
import { useToaster } from 'contexts/ToasterContext';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { emailRegex } from '@utils/regex';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { SkeletonLoading, MessageIcon } from '@components/index';
import { replaceLiterals } from '@utils/replaceLiterals';
import { ROLES } from 'constants/userRoles';
import { StyledEmailText } from './AddUserPage.styles';

const StyledRow = styled(Box)(
  ({ theme }) => `
    display: flex;
    margin-bottom: ${theme.spacing(2)};
    align-items: flex-start;
  `
);

const StyledInputBox = styled(Box)(
  () => `
    flex: 1;
    position: relative;
  `
);

const AddUserPage = () => {
  const [userExist, setUserExist] = useState(true);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [nextPressed, setNextPressed] = useState(false);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
  const [isLoadingRoles, setIsLoadingRoles] = useState(false);
  const [isLoadingSendInvitation, setIsLoadingSendInvitation] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [roles, setRoles] = useState([]);
  const {
    user: {
      userAccount: { userId: loggedUserId },
    },
  } = useAuth();
  const { selectedAccount } = useMPC();
  const { httpClient } = useDeps();
  const { resetWidth, setWidth } = useToaster();
  const history = useHistory();
  const { handleSubmit, setValue, control } = useForm({
    defaultValues: {
      account: '',
      role: '',
      email: '',
      firstName: '',
      lastName: '',
    },
  });
  const formValues = useWatch({ control });
  const { account, email, role } = formValues;
  const isLoading = isLoadingAccounts || isLoadingRoles;

  const redirectToList = useCallback(() => history.goBack(), [history]);

  const onCancel = useCallback(() => {
    resetWidth();

    redirectToList();
  }, [redirectToList, resetWidth]);

  const loadAccounts = useCallback(() => {
    setIsLoadingAccounts(true);

    httpClient(getAllEntitiesByAccountType, { entityId: selectedAccount?.entityId })
      .then(({ data: { response } }) => {
        const accountsList = response?.items?.map((item) => ({
          ...item,
          name: item.itemType === 'PODCAST_NETWORK' ? item.podcastNetworkName : item.name,
        }));
        const loggedInUserPK =
          accountsList?.find((item) => item.PK === selectedAccount?.entityId)?.PK || '';

        setAccounts(accountsList);
        setValue('account', loggedInUserPK);
      })
      .finally(() => {
        setIsLoadingAccounts(false);
      });
  }, [httpClient, setValue, selectedAccount]);

  const loadRoles = useCallback(() => {
    const selectedAccountType =
      accounts?.find((item) => item.PK === account)?.accountType || '';

    setIsLoadingRoles(true);

    httpClient(getRolesByAccountType, {
      accountType: selectedAccountType,
      loggedInUserAccountRole: selectedAccount?.userDefaultAccountRole,
    })
      .then(({ data: { response } }) => {
        const retrievedRoles = ROLES.reduce((accum, role) => {
          if (response.includes(role.PK)) {
            return [...accum, role];
          }

          return accum;
        }, []);

        setRoles(retrievedRoles);
      })
      .finally(() => {
        setIsLoadingRoles(false);
      });
  }, [httpClient, account, accounts, selectedAccount]);

  const onSubmit = useCallback(
    (data) => {
      setIsLoadingSendInvitation(true);

      const selectedAccountId =
        accounts?.find((item) => item.PK === account)?.accountId || '';
      const payload = {
        accountRole: data.role,
        invitedBy: loggedUserId,
        parentAccountId: selectedAccountId,
        userId: userId,
        userEmail: data.email,
        userFirstName: data.firstName,
        userLastName: data.lastName,
      };

      setWidth(400);

      httpClient(sendInvitationUserCreation, { ...payload })
        .then(({ data: { MPCSendInvitationUserCreation: response } }) => {
          if (response) {
            const accountName = accounts?.find((item) => item.PK === account)?.name || '';
            const successMessage = replaceLiterals(
              STRINGS.AN_EMAIL_HAS_BEEN_SENT_TO_EMAIL_ONCE_THEY_CONFIRM_THEIR_INVITATION_THEY_WILL_APPEAR_IN_THE_USERS_LIST,
              {
                email: payload.userEmail,
                account: accountName,
              }
            );

            toast.success(
              <MessageIcon
                text={successMessage.split('<br/>')}
                icon="none"
                typographyVariant="body1"
              />,
              {
                onClose: () => resetWidth(),
              }
            );

            redirectToList();
          }
        })
        .catch((err) => {
          toast.error(err.message);

          resetWidth();
        })
        .finally(() => {
          setIsLoadingSendInvitation(false);
        });
    },
    [
      account,
      accounts,
      loggedUserId,
      userId,
      httpClient,
      redirectToList,
      resetWidth,
      setWidth,
    ]
  );

  useEffect(() => {
    loadAccounts();
  }, [loadAccounts]);

  useEffect(() => {
    if (account) {
      setValue('role', '');
      loadRoles();
    }
  }, [setValue, account, loadRoles]);

  const handleUserSearch = useCallback(() => {
    setLoading(true);
    setNextPressed(false);

    httpClient(getUserByEmail, {
      email,
    })
      .then(({ data: { response: user } }) => {
        const hasUser = !Object.values(user).every((x) => x === null);

        setUserExist(hasUser);

        setUserId(hasUser ? user.PK : null);
        setValue('firstName', hasUser ? user.givenName : '');
        setValue('lastName', hasUser ? user.familyName : '');
      })
      .finally(() => {
        setNextPressed(true);
        setLoading(false);
      });
  }, [email, setValue, httpClient]);

  const handleResetEmail = useCallback(() => {
    setValue('email', '');
    setValue('firstName', '');
    setValue('lastName', '');

    setUserId(null);
    setUserExist(true);
    setNextPressed(false);
  }, [setValue]);

  const renderNextButton = useCallback(() => {
    const isValidEmail = emailRegex.test(email);

    return (
      !nextPressed && (
        <Fade in={isValidEmail}>
          <Button
            sx={{ mt: 2, mb: 2 }}
            disabled={loading}
            onClick={handleUserSearch}
            variant="contained"
            color="success"
          >
            {STRINGS.NEXT}
          </Button>
        </Fade>
      )
    );
  }, [email, loading, nextPressed, handleUserSearch]);

  const renderInviteText = useCallback(() => {
    const { firstName, lastName } = formValues;
    const showInvite = firstName && lastName && email;
    const emailLabel =
      STRINGS.AN_EMAIL_WILL_BE_SENT_TO_EMAIL_ONCE_CONFIRMED_USER_WILL_HAVE_ACCESS_TO_ACCOUNT_WITH_ROLE_OF_ROLE.split(
        '{email}'
      );
    const accountName = accounts?.find((item) => item.PK === account)?.name || '';
    const selectedRole = ROLES.find((_role) => _role.PK === role)?.name || '';

    return (
      <Fade in={!!showInvite} timeout={{ enter: 2000 }}>
        <Typography sx={{ mb: 4, mt: 4, textAlign: 'left' }} variant="h5">
          {emailLabel[0]}
          <StyledEmailText>{email}</StyledEmailText>.
          <br />
          {replaceLiterals(emailLabel[1], {
            account: accountName,
            role: selectedRole,
          })}
        </Typography>
      </Fade>
    );
  }, [formValues, accounts, email, account, role]);

  const renderEmailInput = useCallback(() => {
    return (
      <>
        <Fade in={Boolean(role)}>
          <Box>
            <StyledInputBox>
              <Box sx={{ position: 'relative' }}>
                {nextPressed && (
                  <CloseIcon
                    onClick={handleResetEmail}
                    sx={{
                      position: 'absolute',
                      zIndex: 1,
                      cursor: 'pointer',
                      top: 8,
                      right: 5,
                    }}
                  />
                )}

                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      color="secondary"
                      size="small"
                      id="email-basic"
                      label="Enter your email"
                      variant="outlined"
                      type="email"
                      required
                      disabled={loading || nextPressed}
                    />
                  )}
                />
              </Box>
              {userExist && nextPressed && email && (
                <MessageIcon
                  text={STRINGS.A_USER_ALREADY_EXISTS_WITH_THIS_EMAIL}
                  icon="check"
                />
              )}
              {!userExist && (
                <MessageIcon text={STRINGS.NO_USER_WITH_EMAIL_EXISTS_ENTER_USER_NAME} />
              )}
            </StyledInputBox>
            {renderNextButton()}
            <Spacer size={16} />
          </Box>
        </Fade>
      </>
    );
  }, [
    control,
    email,
    role,
    loading,
    userExist,
    nextPressed,
    handleResetEmail,
    renderNextButton,
  ]);

  const renderNamesInputs = useCallback(() => {
    return (
      <Fade in={Boolean(nextPressed)}>
        <Box>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                color="secondary"
                size="small"
                id="first-name-input"
                label="First Name"
                variant="outlined"
                required
              />
            )}
          />

          <Spacer size={16} />

          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                color="secondary"
                size="small"
                id="last-name-input"
                label="Last Name"
                variant="outlined"
                required
              />
            )}
          />
        </Box>
      </Fade>
    );
  }, [control, nextPressed]);

  const isSubmitDisabled = useMemo(() => {
    const { email, role, firstName, lastName } = formValues;

    return !email || !role || !firstName || !lastName;
  }, [formValues]);

  const renderDropdown = useCallback(
    (controllerName, labelId, labelText, selectId, items, defaultValue = '') => {
      const list = items?.map((item) => (
        <MenuItem key={item.PK} value={item.PK}>
          {item.name}
        </MenuItem>
      ));

      return (
        <StyledRow>
          <StyledInputBox>
            <Controller
              name={controllerName}
              control={control}
              render={({ field }) => (
                <FormControl color="secondary" size="small" fullWidth>
                  <InputLabel id={labelId}>{labelText}</InputLabel>
                  <Select
                    {...field}
                    labelId={labelId}
                    id={selectId}
                    defaultValue={defaultValue}
                    label={labelText}
                    required
                  >
                    {list}
                  </Select>
                </FormControl>
              )}
            />
          </StyledInputBox>
        </StyledRow>
      );
    },
    [control]
  );

  const renderAccountsDropdown = useCallback(
    () =>
      renderDropdown(
        'account',
        'account-select-label',
        STRINGS.SELECT_AN_ACCOUNT_FOR_USER,
        'account-select',
        accounts
      ),
    [accounts, renderDropdown]
  );

  const renderRoleDropdown = useCallback(() => {
    return (
      <Fade in={!!account}>
        {renderDropdown(
          'role',
          'role-select-label',
          STRINGS.SELECT_A_ROLE_FOR_ACCOUNT,
          'role-select',
          roles
        )}
      </Fade>
    );
  }, [account, roles, renderDropdown]);

  return isLoading ? (
    <SkeletonLoading height={650} />
  ) : (
    <Paper
      onSubmit={handleSubmit(onSubmit)}
      component="form"
      sx={{
        p: 2,
        bgcolor: 'primary.main',
        color: 'secondary.main',
        // width: "50%"
      }}
      elevation={2}
    >
      <Box sx={{ width: '50%', margin: '0 auto' }}>
        <Typography variant="h4">{STRINGS.NEW_ACCOUNT_USER}</Typography>

        <Spacer size={16} />

        {renderAccountsDropdown()}
        {renderRoleDropdown()}

        {renderEmailInput()}

        {renderNamesInputs()}

        {renderInviteText()}

        <StyledRow sx={{ justifyContent: 'flex-end' }}>
          <Button onClick={onCancel} variant="contained" color="error">
            {STRINGS.CANCEL}
          </Button>
          <Spacer size={16} />
          <Button
            disabled={isSubmitDisabled || isLoadingSendInvitation}
            type="submit"
            variant="contained"
            color="success"
          >
            {isLoadingSendInvitation ? (
              <CircularProgress color="inherit" size={24.5} />
            ) : (
              STRINGS.DONE
            )}
          </Button>
        </StyledRow>
      </Box>
    </Paper>
  );
};

export default AddUserPage;
