import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDeps } from 'contexts/DepsContext';
import { useMPC } from 'contexts/MPCContext';
import useQuery from 'hooks/useQuery';
import { getInvitationTokenInfo } from '@graphql/queries/users';
import { addUserToAccount, rejectInvitation } from '@graphql/mutations/users';
import { requestAPIGraphQL } from '@services/appSyncAPI';
import { ConfirmPage } from '@components/index';
import { MAIN_ROUTES } from 'constants/routing';
import { STRINGS } from 'constants/strings';

const ConfirmAccountUser = () => {
  const [loading, setLoading] = useState(false);
  const [denyLoading, setDenyLoading] = useState(false);
  const [tokenInfo, setTokenInfo] = useState({});
  const { httpClient } = useDeps();
  const { selectUserAccountAndRedirect } = useMPC();
  const history = useHistory();
  const query = useQuery();

  const redirectToMPC = () => history.push(MAIN_ROUTES.MPC);

  const fetchInvitationTokenInfo = useCallback(() => {
    const verification = query?.get('verification');

    if (verification) {
      requestAPIGraphQL(getInvitationTokenInfo, { token: verification })
        .then(({ data }) => {
          if (data?.response) {
            setTokenInfo({
              ...data?.response,
              token: verification,
            });
          }
        })
        .catch(({ errors }) => {
          const errorsMessage = errors[0]?.message;
          const isNotForCurrentUser =
            errorsMessage === STRINGS.THE_INVITATION_DOES_NOT_BELONG_TO_THE_USER;
          const message = isNotForCurrentUser
            ? errorsMessage
            : 'There was an error trying to retrieve the information of the invitation.';

          toast.error(message);

          if (isNotForCurrentUser) redirectToMPC();
        });
    }
  }, []);

  useEffect(() => {
    fetchInvitationTokenInfo();
  }, []);

  const toggleLoading = (toggle) => toggle((prevLoading) => !prevLoading);

  const onConfirm = useCallback(() => {
    toggleLoading(setLoading);

    httpClient(addUserToAccount, { token: tokenInfo.token })
      .then(({ data }) => {
        if (data?.MPCAddUserToAccount) {
          selectUserAccountAndRedirect(tokenInfo?.parentAccountId);
        }
      })
      .catch(() => {
        toast.error('There was an error trying to accept the invitation.');
      })
      .finally(() => toggleLoading(setLoading));
  }, [tokenInfo]);

  const onDeny = useCallback(() => {
    toggleLoading(setDenyLoading);

    httpClient(rejectInvitation, { token: tokenInfo.token })
      .then(({ data }) => {
        if (data?.MPCRejectInvitation) {
          toast.info(
            `You have denied the invitation for access to ${tokenInfo.parentEntityName}`
          );

          redirectToMPC();
        }
      })
      .catch(() => {
        toast.error('There was an error trying to deny the invitation.');
      })
      .finally(() => toggleLoading(setDenyLoading));
  }, [tokenInfo]);

  return (
    <ConfirmPage
      title="Confirm Account Creation"
      loading={loading}
      secondLoading={denyLoading}
      hideFields
      submitBtnText="Confirm"
      secondBtnText="Deny"
      onSubmit={onConfirm}
      onCancel={onDeny}
    />
  );
};

export default ConfirmAccountUser;
