import PropTypes from 'prop-types';
import LogoIconContainer from './logoIconContainer';
import { HEX_COLORS } from 'constants/stylesVariables';

const Icon = ({ dotFill, width, height, isRecording }) => (
  <LogoIconContainer isRecording={isRecording}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 60 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.1317 43.2844C53.6211 43.2844 52.1809 41.7284 52.1809 39.0148V18.486C52.1809 15.2151 51.3823 12.6732 49.8067 10.9318C48.2763 9.24046 46.0807 8.38301 43.2807 8.38301C40.5975 8.38301 38.3234 9.32483 36.7066 11.1074C35.079 12.9008 34.2186 15.4231 34.2186 18.4026V39.0982C34.2186 41.7196 32.7421 43.2844 30.2678 43.2844C27.9182 43.2844 26.4005 41.6411 26.4005 39.0982V17.9278C26.3141 15.1524 25.4606 12.7998 23.9203 11.1025C22.3035 9.32091 20.0294 8.38007 17.3452 8.38007C14.5472 8.38007 12.3516 9.23752 10.8212 10.9289C9.24459 12.6703 8.44502 15.2122 8.44502 18.4831V26.2119C8.44502 28.9246 7.00481 30.4805 4.49427 30.4805C2.07202 30.4805 0.626907 28.8843 0.626907 26.2119V18.3212C0.626907 13.1559 2.13481 8.78525 4.98676 5.68116C7.85344 2.56235 11.8984 0.913177 16.684 0.913177C23.0962 0.913177 26.5928 3.42078 29.9431 7.48533C30.0383 7.48533 30.4297 7.48534 30.5102 7.48632C33.4985 3.40999 39.0513 0.913177 43.9439 0.913177C48.7285 0.913177 52.7725 2.56137 55.6392 5.68116C58.4921 8.78525 60 13.1559 60 18.3212V39.0168C60 41.6882 58.5539 43.2844 56.1317 43.2844Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.52664 43.3982C3.18356 43.3982 2.03375 42.9459 1.19984 42.0905C0.426763 41.2968 0 40.2166 0 39.0501C0 36.3502 1.81889 34.5363 4.52565 34.5363C5.85107 34.5363 6.97832 34.9866 7.78377 35.8391C8.54704 36.6465 8.96792 37.7875 8.96792 39.0511C8.9689 41.2104 7.44334 43.3982 4.52664 43.3982Z"
        fill={dotFill}
      />
    </svg>
  </LogoIconContainer>
);

Icon.propTypes = {
  dotFill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  isRecording: PropTypes.bool,
};

Icon.defaultProps = {
  dotFill: HEX_COLORS.malachite,
  width: 80,
  height: 80,
  isRecording: false,
};

export default Icon;
