import { ToastContainer, Zoom } from 'react-toastify';
import { useToaster } from 'contexts/ToasterContext';
import 'react-toastify/dist/ReactToastify.min.css';

const ToasterContainer = () => {
  const { toasterWidth } = useToaster();

  return (
    <ToastContainer
      position="bottom-left"
      autoClose={10000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      transition={Zoom}
      style={{ width: toasterWidth }}
    />
  );
};

export default ToasterContainer;
