import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import podcastPlaceholderImg from '@images/podcast.jpeg';
import { props } from './props';

const PodcastArtTitle = ({ podcast }) => {
  const podcastImage = useMemo(() => {
    return (
      podcast?.imageUrl ||
      podcast?.itunes?.image ||
      podcast?.image?.url ||
      podcastPlaceholderImg
    );
  }, [podcast]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
      <Box sx={{ width: 150, height: 150, mr: 1 }}>
        <img
          style={{
            width: 'inherit',
            height: 'inherit',
            objectFit: 'contain',
          }}
          src={podcastImage}
          alt="Podcast Art"
        />
      </Box>
      <Typography variant="subtitle2">{podcast?.title || 'N/A'}</Typography>
    </Box>
  );
};

PodcastArtTitle.propTypes = props;

export default PodcastArtTitle;
