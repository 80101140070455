import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { MAIN_ROUTES } from 'constants/routing';
import { SIGN_IN_REDIRECT_KEY } from 'constants/localStorageKeys';
import { useAuth } from 'contexts/AuthContext';

const PrivateRoute = ({
  component: Component,
  path,
  exact,
  redirectTo,
  render,
  ...rest
}) => {
  const location = useLocation();
  const { authed } = useAuth();

  if (!authed) {
    const pathnamee = redirectTo || MAIN_ROUTES.LOGIN;

    if (pathnamee === MAIN_ROUTES.LOGIN) localStorage.setItem(SIGN_IN_REDIRECT_KEY, path);

    return <Redirect to={{ ...location, pathname: pathnamee }} />;
  }

  return render ? (
    <Route path={path} exact={exact} render={render} />
  ) : (
    <Route path={path} exact={exact}>
      <Component {...rest} />
    </Route>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  path: PropTypes.string,
  exact: PropTypes.bool,
  render: PropTypes.func,
};

export default PrivateRoute;
