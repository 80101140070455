export const getTranscript = /* GraphQL */ `
  query getTranscript($episodeId: String!) {
    MPCGetTranscriptByEpisodeId(episodeId: $episodeId) {
      results {
        transcripts {
          transcript
        }
      }
    }
  }
`;

export const exportTranscript = /* GraphQL */ `
  query exportTranscript($episodeId: String!, $exportFormat: String!) {
    MPCExportTranscript(episodeId: $episodeId, exportFormat: $exportFormat)
  }
`;

export const getAllTranscriptReviewRequests = /* GraphQL */ `
  query listAllTranscriptReviewRequests(
    $filterBy: ContentReportFilters
    $itemId: String!
    $limit: Int
    $status: String
  ) {
    listAllTranscriptReviewRequests(
      itemId: $itemId
      filterBy: $filterBy
      limit: $limit
      status: $status
    ) {
      items {
        comment
        status
        createdAt
        requesterId
        transcriptFragment
        PK
        SK
      }
    }
  }
`;

export const getTranscriptDetails = /* GraphQL */ `
  query details($transcriptId: String!) {
    transcriptDetails: listTranscriptReviewById(TranscriptReviewId: $transcriptId) {
      TranscriptReview {
        createdAt
        comment
        transcriptFragment
        status
      }
      TranscriptText
      User {
        name
      }
    }
  }
`;
