import React from 'react';
import PropTypes from 'prop-types';
import PrivateRoute from '@components/privateRoute/index';
import { Switch, Route } from 'react-router';

export const CommonSwitch = ({ routes }) => (
  <Switch>
    {routes?.map((item, index) => {
      const {
        path,
        childComponent,
        privateRoute = false,
        exact = false,
        redirectTo,
      } = item;

      if (privateRoute) {
        return (
          <PrivateRoute
            {...(!!redirectTo && { redirectTo })}
            key={index}
            exact={exact}
            path={path}
            component={() => childComponent}
          />
        );
      }

      return (
        <Route {...(!!exact && { exact })} key={`${path}-${index}`} path={path}>
          {!!childComponent ? childComponent : null}
        </Route>
      );
    })}
  </Switch>
);

CommonSwitch.propTypes = {
  routes: PropTypes.array,
};

CommonSwitch.defaultProps = {
  routes: [],
};
