import React, { useCallback, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import { errorLog } from '@utils/logs';
import { props } from './props';

const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb({
      name: file.name,
      content: reader.result,
    });
  };
  reader.onerror = function (error) {
    errorLog(error);
  };
};

const ImagePreview = ({ label, name, src, onSelectedImage, color }) => {
  const onSelectImg = (e) => {
    e.preventDefault();

    const file = e.target.files[0];

    getBase64(file, (result) => {
      onSelectedImage(result.content);
    });
  };

  const onClearImg = useCallback(() => onSelectedImage(''), [onSelectedImage]);

  const renderPreviewImage = useCallback(() => {
    return (
      src && (
        <Box sx={{ mt: 1 }}>
          <label role="button" htmlFor={name}>
            <img style={{ width: 250 }} src={src} alt="Preview" />
          </label>
        </Box>
      )
    );
  }, [src, name]);

  const renderAddButton = useCallback(() => {
    return (
      !src && (
        <label role="button" htmlFor={name}>
          <Button color={color} variant="outlined" component="span">
            {label}
          </Button>
        </label>
      )
    );
  }, [src, name, label, color]);

  const renderClearButton = useCallback(() => {
    return (
      !!src && (
        <Button color="error" onClick={onClearImg} variant="outlined" component="span">
          Clear
        </Button>
      )
    );
  }, [src, onClearImg]);

  useEffect(() => {
    if (src) {
      document.getElementById(name).value = '';
    }
  }, [src, name]);

  return (
    <>
      <input
        accept="image/*"
        id={name}
        onChange={onSelectImg}
        type="file"
        style={{ display: 'none' }}
      />
      {renderAddButton()}
      {renderClearButton()}
      {renderPreviewImage()}
    </>
  );
};

ImagePreview.propTypes = props;

export default ImagePreview;
