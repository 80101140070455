export const getMarbylById = /* GraphQL */ `
  query getMarbylById($marbylId: String!) {
    MPCGetMarbylById(marbylId: $marbylId) {
      PK
      createdAt
      source
      modelName
      modelVersion
      genre
      subgenre
      isActivated
      isActivatedUpdated
      text
    }
  }
`;
