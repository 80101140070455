import PropTypes from 'prop-types';
import { MainMarbylIcon } from '@components/icons/index';
import { StyledTopbar } from './styled';

const Topbar = ({ isDarkTheme, item }) => (
  <StyledTopbar>
    <MainMarbylIcon isDarkTheme={isDarkTheme} />
    {item}
  </StyledTopbar>
);

Topbar.defaultProps = {
  isDarkTheme: true,
  item: null,
};

Topbar.propTypes = {
  isDarkTheme: PropTypes.bool,
  item: PropTypes.node,
};

export default Topbar;
