import React from 'react';
import { useTheme } from '@mui/material/styles/';
import { props, defaultProps } from './entitiesIconsProps';

const PodcasterIcon = ({ width, height }) => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      width={width}
      height={height}
    >
      <path
        d="M224 312c-32.88 0-64 8.625-64 43.75c0 33.13 12.88 104.5 20.62 132.8C185.8 507.7 205.1 512 224 512s38.25-4.375 43.38-23.38C275.1 459.9 288 388.8 288 355.8C288 320.6 256.9 312 224 312zM237 478.5c-6 2-20 2-26 0C203.5 449.5 192 383.4 192 355.8c0-16.25 64-16.38 64 0C256 383.4 244.5 449.6 237 478.5zM224 160C188.6 160 160 188.6 160 224s28.62 64 64 64s64-28.62 64-64S259.4 160 224 160zM224 256C206.4 256 192 241.6 192 224s14.38-32 32-32s32 14.38 32 32S241.6 256 224 256zM224 0C100.5 0 0 100.5 0 224c0 69.39 31.41 133.8 86.16 176.6c6.875 5.406 16.97 4.203 22.44-2.75c5.469-6.969 4.219-17.02-2.75-22.47C58.91 338.7 32 283.5 32 224c0-105.9 86.13-192 192-192s192 86.13 192 192c0 59.48-26.91 114.7-73.84 151.4c-6.969 5.453-8.219 15.5-2.75 22.47C342.6 401.9 347.3 403.1 352 403.1c3.469 0 6.938-1.109 9.844-3.391C416.6 357.8 448 293.4 448 224C448 100.5 347.5 0 224 0zM368 224c0-79.41-64.59-144-144-144S80 144.6 80 224c0 25.34 6.656 50.25 19.28 72.02c4.438 7.672 14.25 10.2 21.88 5.828c7.625-4.422 10.25-14.22 5.812-21.86C117.2 263.1 112 243.7 112 224c0-61.75 50.25-112 112-112s112 50.25 112 112c0 19.72-5.188 39.08-14.97 55.98c-4.438 7.641-1.812 17.44 5.812 21.86C329.4 303.3 332.1 304 334.9 304c5.5 0 10.88-2.859 13.84-7.984C361.3 274.3 368 249.3 368 224z"
        fill={theme.palette.secondary.main}
      />
    </svg>
  );
};

PodcasterIcon.propTypes = props;

PodcasterIcon.defaultProps = defaultProps;

export default PodcasterIcon;
