import { useCallback, useEffect, useState } from 'react';

const useInfiniteScroll = (props) => {
  const { getItems } = props;
  const [items, setItems] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [total, setTotal] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const loadNextPage = useCallback((...args) => {
    setIsNextPageLoading(true);
  }, []);

  useEffect(() => {
    if (isNextPageLoading) {
      getItems()
        .then((response) => {
          const { data, hasNextPage: APIHasNextPage, total: _total } = response;
          _total && setTotal(_total);
          setHasNextPage(!!APIHasNextPage);
          setItems((prev) => [...prev, ...data]);
          setIsNextPageLoading(false);
        })
        .catch(() => {});
    }
  }, [isNextPageLoading, getItems]);

  return {
    items,
    hasNextPage,
    isNextPageLoading,
    total,
    loadNextPage,
  };
};

export default useInfiniteScroll;
