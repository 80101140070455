import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const TranscriptText = ({ selectedText, prevText, lastText }) => {
  const theme = useTheme();

  return (
    <Box sx={{ margin: '24px 0' }}>
      <Typography
        sx={{ display: 'inline', color: theme.palette.secondary.main, opacity: 0.5 }}
      >
        {prevText}
      </Typography>
      <Typography sx={{ display: 'inline', color: theme.palette.secondary.main }}>
        {selectedText}
      </Typography>
      <Typography
        sx={{ display: 'inline', color: theme.palette.secondary.main, opacity: 0.5 }}
      >
        {lastText}
      </Typography>
    </Box>
  );
};

TranscriptText.propTypes = {
  selectedText: PropTypes.string.isRequired,
  prevText: PropTypes.string.isRequired,
  lastText: PropTypes.string.isRequired,
};

export default TranscriptText;
