export const activateDeactivateEpisodes = /* GraphQL */ `
  mutation activateDeactivateEpisodes($episodeIds: [String]!, $isActivated: Boolean!) {
    batchWriteEpisodes: MPCBatchWriteEpisodesV2(
      input: { episodeIds: $episodeIds, isActivated: $isActivated }
    )
  }
`;

export const enableDisableEpisodesMarbylization = /* GraphQL */ `
  mutation enableDisableEpisodesMarbylization(
    $episodeIds: [String]!
    $isEnabled: Boolean!
  ) {
    batchWriteEpisodes: MPCBatchWriteEpisodesV2(
      input: { episodeIds: $episodeIds, m11nIsEnabled: $isEnabled }
    )
  }
`;
