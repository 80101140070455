import PropTypes from 'prop-types';

export const props = {
  searchText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onClickItem: PropTypes.func.isRequired,
  searchHeight: PropTypes.number,
  onLoad: PropTypes.func.isRequired,
};

export const defaultProps = {};
