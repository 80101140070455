import React, { useCallback } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { StyledFullHeight, StyledInnerContainer } from '@shared/styledContainers';
import { Topbar } from '@components/index';
import { useEffect } from 'react';
import { useState } from 'react';
import { requestAPIGraphQL } from '@services/appSyncAPI';
import { getOpenSourceNotices } from '@graphql/queries/main';
import Spacer from '@shared/spacer';
import { Link } from 'react-router-dom';
import { MAIN_ROUTES } from 'constants/routing';
import { STRINGS } from 'constants/strings';

const OpenSourceAttributionsPage = () => {
  const [html, setHtml] = useState('');

  useEffect(() => {
    const init = () => {
      requestAPIGraphQL(getOpenSourceNotices, null, {
        authMode: 'API_KEY',
      }).then(({ data: { getOpenSourceNotices: url } }) => {
        fetch(url)
          .then((response) => response.text())
          .then((htmlRes) => setHtml(htmlRes));
      });
    };

    init();
  }, []);

  const renderLink = useCallback(
    () => (
      <Link style={{ color: 'red' }} to={MAIN_ROUTES.TERMS}>
        {STRINGS.MARBYL_TERMS_OF_SERVICE}
      </Link>
    ),
    []
  );

  return (
    <StyledFullHeight isDarkTheme={false}>
      <StyledInnerContainer>
        <Topbar item={renderLink()} isDarkTheme={false} />
        <Spacer size={50} />
        <Box
          sx={{
            maxWidth: 780,
            margin: '0 auto',
            ...(!html && {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
            }),
          }}
        >
          {!!html ? (
            <div
              style={{ color: '#222222' }}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          ) : (
            <CircularProgress color="info" />
          )}
        </Box>
      </StyledInnerContainer>
    </StyledFullHeight>
  );
};

export default OpenSourceAttributionsPage;
