import styled from '@mui/styled-engine';
import AppBar from '@mui/material/AppBar';

export const StyledAppBar = styled(AppBar)(
  () => `
  background-color: #fff;
  z-index: 1200;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 1px 0 rgba(32, 33, 36, 0.18);
  `
);
