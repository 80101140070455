import styled from '@mui/styled-engine';
import { Link } from 'react-router-dom';

export const StyledBreadcrums = styled('nav')(() => ``);

export const StyledOl = styled('ol')(
  () => `
  display: flex;
  list-style: none;
  flex-wrap: wrap;
`
);

export const StyledLink = styled(Link)(() => ``);

export const StyledLi = styled('li')(
  ({ theme, active }) => `
  margin-right: ${theme.spacing(2)};
  color: ${active ? theme.palette.success.main : theme.palette.primary.main};

  a { 
    color: inherit;
  }
`
);
