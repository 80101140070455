import PropTypes from 'prop-types';

export const props = {
  checkbox: PropTypes.shape({
    label: PropTypes.string.isRequired,
    initialValue: PropTypes.bool,
  }),
  select: PropTypes.shape({
    handleChange: PropTypes.func.isRequired,
    checkedPlaceholder: PropTypes.string.isRequired,
    uncheckedPlaceholder: PropTypes.string.isRequired,
    required: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    valueName: PropTypes.string.isRequired,
    labelName: PropTypes.string.isRequired,
    helperText: PropTypes.string.isRequired,
  }),
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'success', 'warning', 'info']),
};

export const defaultProps = {
  checkbox: {
    initialValue: false,
  },
  color: 'primary',
};
