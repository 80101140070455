import styled from '@mui/styled-engine';
import { Box } from '@mui/material';

export const StyledImg = styled('img')(
  ({ theme }) => `
  border-radius: ${theme.shape.borderRadius}px;
  margin-right: ${theme.spacing(1)};
  width: 65px;
  height: 65px;
`
);

export const StyledOl = styled(Box)(({ theme }) => ({
  top: 80,
  border: 1,
  borderTop: 0,
  position: 'absolute',
  listStyle: 'none',
  width: '100%',
  zIndex: 2,
  backgroundColor: theme.palette.secondary.main,
  boxShadow: 1,
  p: 1,
}));

export const StyledLi = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  padding: theme.spacing(2),
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
  '.selected': {
    backgroundColor: theme.palette.secondary.dark,
  },
}));
