export const getTermsAndConditions = /* GraphQL */ `
  query getTermsAndConditions {
    getTermsAndConditions
  }
`;

export const getPrivacyPolicy = /* GraphQL */ `
  query getPrivacyPolicy {
    getPrivacyPolicy
  }
`;

export const getOpenSourceNotices = /* GraphQL */ `
  query getOpenSourceNotices {
    getOpenSourceNotices
  }
`;

export const getBackendVersion = /* GraphQL */ `
  query getBackendVersion {
    getBackendVersion {
      name
      value
    }
  }
`;
