import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button } from '@mui/material';
import ReactSelect from 'react-select';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { isEqual, cloneDeep } from 'lodash';
import { EntitiesDataGrid, HierarchyHeader, InfiniteSelect } from '@components/index';
import { ListContainer, ClearFiltersContainer, colorStyles } from './styles';
import { useEntityNames } from 'contexts/EntitiesNamesContext';
import { REVISION_STATUS } from 'constants/revisionTypes';
import useInfiniteRow from 'hooks/useInfiniteRow';

import { NETWORK } from 'constants/idPrefixes';
import { STRINGS } from 'constants/strings';
import {
  PAGE_BLOCK,
  SELECT_BLOCK,
  filtersKeys,
  nextFilters,
  STATUS_OPTIONS,
} from './constants';

const rootId = `${NETWORK}tgm`;

const MenuList = (props) => {
  return (
    <ListContainer>
      <div>{props.children}</div>
    </ListContainer>
  );
};

const FiltersTable = ({ title, columnDefs, filtersQuery, entityInfo, itemType }) => {
  const [filters, setFilters] = useState(
    Object.values(filtersKeys).reduce((obj, key) => ({ ...obj, [key]: '' }), {})
  );
  const [selectedStatus, setSelectedStatus] = useState(STATUS_OPTIONS[0]);
  const gridRef = useRef();
  const mounted = useRef(false);
  const history = useHistory();
  const { url } = useRouteMatch();
  const lastFilters = useRef(filters);
  const setEntity = useEntityNames()[1];

  const {
    network: networkFilter,
    account: podcasterFilter,
    show: showFilter,
    episode: episodeFilter,
    marbyl: marbylFilter,
  } = filters;

  const getPayload = useCallback(() => {
    let obj = {
      limit: PAGE_BLOCK,
      itemId: rootId,
    };

    if (marbylFilter) {
      obj.filterBy = filtersKeys.MARBYL;
      obj.itemId = marbylFilter.value;
    } else if (episodeFilter) {
      obj.filterBy = filtersKeys.EPISODE;
      obj.itemId = episodeFilter.value;
    } else if (showFilter) {
      obj.filterBy = filtersKeys.SHOW;
      obj.itemId = showFilter.value;
    } else if (podcasterFilter) {
      obj.filterBy = filtersKeys.PODCASTER;
      obj.itemId = podcasterFilter.value;
    } else if (networkFilter) {
      obj.filterBy = filtersKeys.NETWORK;
      obj.itemId = networkFilter.value;
    }

    obj.status =
      selectedStatus.value !== 'default'
        ? selectedStatus?.value?.toUpperCase()
        : selectedStatus?.value;

    return obj;
  }, [
    marbylFilter,
    episodeFilter,
    showFilter,
    podcasterFilter,
    selectedStatus,
    networkFilter,
  ]);

  const { getDataSource } = useInfiniteRow({
    query: filtersQuery.main.query,
    payload: {
      ...getPayload(),
      limit: PAGE_BLOCK,
    },
    responseKeyName: filtersQuery.main.responseKeyName,
  });

  const setDataSource = useCallback(
    () => gridRef?.current?.api?.setDatasource(getDataSource()),
    [getDataSource]
  );

  const getRowId = useCallback(function (params) {
    return params.data.reportId;
  }, []);

  const networkPayload = useMemo(() => {
    return {
      networkId: rootId,
      limit: SELECT_BLOCK,
    };
  }, []);

  const podcasterPayload = useMemo(() => {
    if (!networkFilter?.value) {
      return {
        networkId: rootId,
        limit: SELECT_BLOCK,
      };
    }

    return {
      networkId: `${NETWORK}${networkFilter.value.split('#')[1]}`,
      limit: SELECT_BLOCK,
    };
  }, [networkFilter]);

  const podcastQueryPayload = useMemo(() => {
    if (!!podcasterFilter?.value) {
      return {
        query: filtersQuery.podcast.query,
        payload: {
          podcasterId: podcasterFilter.value,
          limit: SELECT_BLOCK,
        },
      };
    }

    if (!!networkFilter?.value) {
      return {
        query: filtersQuery.podcast.secondary,
        payload: {
          networkId: networkFilter.value,
          limit: SELECT_BLOCK,
        },
      };
    }

    return {
      query: filtersQuery.podcast.secondary,
      payload: {
        networkId: rootId,
        limit: SELECT_BLOCK,
      },
    };
  }, [podcasterFilter, networkFilter]);

  const showPayload = useMemo(() => {
    if (!showFilter?.value) return {};

    return {
      showId: showFilter.value,
      limit: SELECT_BLOCK,
    };
  }, [showFilter]);

  const episodePayload = useMemo(() => {
    if (!episodeFilter?.value) return {};

    return {
      episodeId: episodeFilter.value,
      limit: SELECT_BLOCK,
    };
  }, [episodeFilter]);

  const handleClearFilters = useCallback(() => {
    setFilters(
      Object.values(filtersKeys).reduce((obj, key) => ({ ...obj, [key]: '' }), {})
    );
    setSelectedStatus(STATUS_OPTIONS[0]);
  }, []);

  const handleFilterSelect = (key, value) => {
    setFilters((prev) => {
      const _nextFiltersValues = nextFilters[key].reduce(
        (obj, _key) => ({ ...obj, [_key]: '' }),
        {}
      );

      return { ...prev, ..._nextFiltersValues, [key]: value };
    });
  };

  const handleRowClick = ({ data }) => {
    const _id = data[entityInfo.idName]?.replace(entityInfo.replacedString, '');

    setEntity(_id, entityInfo.entity);
    history.push(`${url}/${_id}`);
  };

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  /** This effect will check if filters changed and will load
   * the grid again. First validation is for preventing effect to be
   * trigerred when component is been mounted.
   */
  useEffect(() => {
    if (isEqual(lastFilters.current, filters)) return;

    setDataSource();
    lastFilters.current = cloneDeep(filters);
  }, [filters, setDataSource]);

  useEffect(() => {
    setDataSource();
  }, [selectedStatus, setDataSource]);

  const renderFilterTitle = (label) => (
    <Typography variant="body2" sx={{ marginBottom: 1 }}>
      {label}
    </Typography>
  );

  const renderInfiniteSelect = (
    title,
    placeholder,
    query,
    payload,
    responseKeyName,
    value,
    filtersKey,
    isDisabled = false
  ) => (
    <Grid item lg={4} sm={6} xs={12}>
      {renderFilterTitle(title)}
      <InfiniteSelect
        placeholder={placeholder}
        query={query}
        payload={{ ...payload, itemType }}
        responseKeyName={responseKeyName}
        value={value}
        onChange={(newValue) => handleFilterSelect(filtersKey, newValue)}
        isDisabled={isDisabled}
      />
    </Grid>
  );

  return (
    <>
      <HierarchyHeader title={title} />

      <Grid container sx={{ margin: '24px 0' }}>
        <Grid container spacing={2} item lg={10} md={12} xs={12}>
          <Grid container item spacing={2}>
            <Grid item lg={4} sm={6} xs={12}>
              {renderFilterTitle('Status')}
              <ReactSelect
                styles={{ ...colorStyles }}
                options={STATUS_OPTIONS}
                components={{ MenuList }}
                value={selectedStatus}
                onChange={setSelectedStatus}
              />
            </Grid>
            {renderInfiniteSelect(
              STRINGS.NETWORK,
              'Select Network',
              filtersQuery.network.query,
              networkPayload,
              filtersQuery.network.responseKeyName,
              networkFilter,
              filtersKeys.NETWORK,
              !!podcasterFilter?.value || !!showFilter?.value
            )}
            {renderInfiniteSelect(
              STRINGS.PODCASTER,
              'Select Podcaster',
              filtersQuery.podcaster.query,
              podcasterPayload,
              filtersQuery.podcaster.responseKeyName,
              podcasterFilter,
              filtersKeys.PODCASTER,
              !!showFilter?.value
            )}
          </Grid>
          <Grid container item spacing={2}>
            {renderInfiniteSelect(
              STRINGS.PODCAST,
              'Select Podcast',
              podcastQueryPayload.query,
              podcastQueryPayload.payload,
              filtersQuery.podcast.responseKeyName,
              showFilter,
              filtersKeys.SHOW
            )}
            {showFilter?.value &&
              renderInfiniteSelect(
                STRINGS.EPISODE,
                'Select Episode',
                filtersQuery.episode.query,
                showPayload,
                filtersQuery.episode.responseKeyName,
                episodeFilter,
                filtersKeys.EPISODE
              )}
            {episodeFilter?.value &&
              filtersQuery?.marbyl &&
              renderInfiniteSelect(
                'Marbyl',
                'Select Marbyl',
                filtersQuery.marbyl.query,
                episodePayload,
                filtersQuery.marbyl.responseKeyName,
                marbylFilter,
                filtersKeys.MARBYL
              )}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          item
          lg={2}
          md={12}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
            marginTop: 0,
          }}
        >
          <ClearFiltersContainer>
            <Button color="error" variant="outlined" onClick={handleClearFilters}>
              {STRINGS.CLEAR_FILTERS}
            </Button>
          </ClearFiltersContainer>
        </Grid>
      </Grid>

      <EntitiesDataGrid
        ref={gridRef}
        columnDefs={columnDefs}
        onCellClicked={handleRowClick}
        rowModelType={'infinite'}
        onGridReady={setDataSource}
        cacheBlockSize={PAGE_BLOCK}
        getRowId={getRowId}
      />
    </>
  );
};

FiltersTable.defaultProps = {
  itemType: REVISION_STATUS.REPORT,
};

FiltersTable.propTypes = {
  title: PropTypes.string.isRequired,
  columnDefs: PropTypes.array.isRequired,
  filtersQuery: PropTypes.shape({
    main: PropTypes.shape({
      query: PropTypes.string.isRequired,
      responseKeyName: PropTypes.string.isRequired,
    }),
    network: PropTypes.shape({
      query: PropTypes.string.isRequired,
      responseKeyName: PropTypes.string.isRequired,
    }),
    podcaster: PropTypes.shape({
      query: PropTypes.string.isRequired,
      responseKeyName: PropTypes.string.isRequired,
    }),
    podcast: PropTypes.shape({
      query: PropTypes.string.isRequired,
      responseKeyName: PropTypes.string.isRequired,
      secondary: PropTypes.string,
    }),
    episode: PropTypes.shape({
      query: PropTypes.string.isRequired,
      responseKeyName: PropTypes.string.isRequired,
    }),
    marbyl: PropTypes.shape({
      query: PropTypes.string.isRequired,
      responseKeyName: PropTypes.string.isRequired,
    }),
  }).isRequired,
  entityInfo: PropTypes.shape({
    idName: PropTypes.string.isRequired,
    replacedString: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired,
  }),
  itemType: PropTypes.oneOf([
    REVISION_STATUS.REPORT,
    REVISION_STATUS.TRANSCRIPT_REVIEW_ITEM,
  ]),
};

export default FiltersTable;
