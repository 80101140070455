import { DEFAULT_LIMIT } from 'constants/appSync';

export const PAGE_BLOCK = DEFAULT_LIMIT;
export const SELECT_BLOCK = 100;

export const filtersKeys = {
  NETWORK: 'network',
  PODCASTER: 'account',
  SHOW: 'show',
  EPISODE: 'episode',
  MARBYL: 'marbyl',
};

export const nextFilters = {
  [filtersKeys.NETWORK]: [
    filtersKeys.PODCASTER,
    filtersKeys.SHOW,
    filtersKeys.EPISODE,
    filtersKeys.MARBYL,
  ],
  [filtersKeys.PODCASTER]: [filtersKeys.SHOW, filtersKeys.EPISODE, filtersKeys.MARBYL],
  [filtersKeys.SHOW]: [filtersKeys.EPISODE, filtersKeys.MARBYL],
  [filtersKeys.EPISODE]: [filtersKeys.MARBYL],
  [filtersKeys.MARBYL]: [],
};

export const STATUS_OPTIONS = [
  {
    value: 'default',
    label: 'Select Status',
  },
  {
    value: 'OPEN',
    label: 'Open',
  },
  {
    value: 'RESOLVED',
    label: 'Resolved',
  },
];
