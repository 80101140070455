import PropTypes from 'prop-types';

export const props = {
  // Are there more items to load?
  // (This information comes from the most recent API request.)
  hasNextPage: PropTypes.bool.isRequired,

  // Are we currently loading a page of items?
  // (This may be an in-flight flag in your Redux store for example.)
  isNextPageLoading: PropTypes.bool.isRequired,

  // Array of items loaded so far.
  items: PropTypes.array.isRequired,

  // Callback function responsible for loading the next page of items.
  loadNextPage: PropTypes.func.isRequired,

  // Component to Render
  renderComponent: PropTypes.func.isRequired,

  // Render loading component
  renderLoading: PropTypes.func,

  // Table width
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  // Table height
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  // Item size
  itemSize: PropTypes.number,

  // List styles
  style: PropTypes.object,

  // Total of rows
  count: PropTypes.number,
};

export const defaultProps = {
  width: 1200,
  height: 260,
  itemSize: 50,
  style: {},
  count: 0,
};
