import PropTypes from 'prop-types';

export const props = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export const defaultProps = {
  width: 32,
  height: 32,
};
