import { useMPC } from 'contexts/MPCContext';
import { useMemo } from 'react';

const useAccountEmail = () => {
  const { selectedAccount } = useMPC();

  const accountEmail = useMemo(() => selectedAccount.email, [selectedAccount]);

  return accountEmail;
};

export default useAccountEmail;
