export const listMarbylsByEntityId = /* GraphQL */ `
  query listMarbylsByEntityId(
    $entityId: String!
    $limit: Int!
    $dateFilterPreference: FilterMarbylListDashboard!
    $sortPreference: SortMarbylListDashboard!
    $madeBy: MadeBy
    $nextToken: String
  ) {
    MPCListMarbylsByEntityId(
      entityId: $entityId
      limit: $limit
      dateFilterPreference: $dateFilterPreference
      sortPreference: $sortPreference
      madeBy: $madeBy
      nextToken: $nextToken
    ) {
      items {
        createdAt
        entityType
        episodeId
        episodeTitle
        marbylId
        marbylStartTime
        marbylText
        sharesCount
        showId
        showTitle
        userId
        userName
        viewersCount
        networkId
        networkName
      }
      nextToken
      totalResults
    }
  }
`;
