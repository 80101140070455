import React, { useMemo } from 'react';
import { useRouteMatch, useParams } from 'react-router';
import { PodcasterContainer } from '@components/containers/index';

import PodcastRoutes from '@routes/PodcastRoutes';
import { CommonSwitch } from './common';
import { MPC_ROUTES } from 'constants/routing';

const PodcasterRoutes = () => {
  const { url } = useRouteMatch();
  const { id } = useParams();

  const routes = useMemo(
    () => [
      {
        exact: true,
        path: url,
        childComponent: <PodcasterContainer id={id} />,
      },
      {
        path: `${url}${MPC_ROUTES.PODCAST}${MPC_ROUTES.ID}`,
        childComponent: <PodcastRoutes />,
      },
    ],
    [id, url]
  );

  return (
    <>
      <CommonSwitch routes={routes} />
    </>
  );
};

export default PodcasterRoutes;
