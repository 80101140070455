import PropTypes from 'prop-types';

export const props = {
  minFontSize: PropTypes.number,
  maxFontSize: PropTypes.number,
  minLineHeight: PropTypes.number,
  maxLineHeight: PropTypes.number,
  minViewportWidth: PropTypes.number,
  maxViewportWidth: PropTypes.number,
  fontSizeUnit: PropTypes.oneOf(['em', 'px', 'rem']),
  children: PropTypes.func,
};

export const defaultProps = {
  minViewportWidth: 768,
  maxViewportWidth: 1024,
  fontSizeUnit: 'rem',
};
