export const PODCAST_NETWORK_VIEW_ACTIONS = {
  GO_TO_CONTENT_REPORTS_PAGE: 'goToContentReportsPage',
};

export const PODCASTER_VIEW_ACTIONS = {
  PODCASTER_DETAILS: 'podcasterDetails',
  EDIT_PODCASTER_INFO: 'editPodcasterInfo',
  EDIT_OWNER_INFO: 'editOwnerInfo',
  DEACTIVATE_ACCOUNT: 'deactivateAccount',
  EDIT_PODCASTER_GEO_AVAILABILITY: 'editPodcasterGeoAvailability',
  CHANGE_AGREEMENT_TYPE: 'changeAgreementType',
  VIEW_PODCASTS: 'viewPodcasts',
  ADD_PODCAST: 'addPodcast',
  ACTIVATE_PODCASTS: 'activatePodcasts',
  DEACTIVATE_PODCASTS: 'deactivatePodcasts',
  DELETE_PODCASTS: 'deletePodcasts',
  GO_TO_PODCAST_PAGE: 'goToPodcastPage',
  GO_TO_USER_MANAGEMENT_PAGE: 'goToUserManagementPage',
};

export const PODCAST_VIEW_ACTIONS = {
  VIEW_PODCAST_DETAILS: 'viewPodcastDetails',
  RSS_SOURCED_DATA: 'rssSourcedData',
  PLATFORM_SOURCED_DATA: 'platformSourcedData',
  M11N_RELATED_DATA: 'm11Related',
  PROCESS_STATUS_RELATED: 'processStatusRelated',
  METRICS_HISTORICAL_RELATED: 'metricsHistoricalRelated',
  DASHBOARD_EDITABLE_DATA: 'dashboardEditableData',
  EDIT_PODCAST_BASIC_INFO: 'editPodcastBasicInfo',
  EDIT_GEO_AVAILABILITY: 'editGeoAvailability',
  TOGGLE_ACTIVATION_PODCAST: 'toggleActivationPodcast',
  TOGGLE_M11N: 'toggleM11n',
  EDIT_M11N_OPTIONS: 'editM11nOptions',
  DELETE: 'delete',
  RELOAD_RSS: 'reloadRss',
  VIEW_EPISODES: 'viewEpisodes',
  TOGGLE_M11N_EPISODES: 'toggleM11nEpisodes',
  UPDATE_EPISODES_AVAILABILITY: 'updateEpisodesAvailability',
  TOGGLE_EPISODES: 'toggleEpisodes',
  DELETE_EPISODES: 'deleteEpisodes',
  GO_TO_EPISODE_VIEW: 'goToEpisodeView',
};

export const EPISODE_VIEW_ACTIONS = {
  VIEW_EPISODE_DETAILS: 'viewEpisodesDetails',
  VIEW_RSS_SOURCED_DATA: 'viewRssSourcedData',
  VIEW_M11N_DATA: 'viewM11nData',
  VIEW_METRICS_HISTORICAL_DATA: 'viewMetricsHistoricalData',
  VIEW_PROCESS_STATUS_DATA: 'viewProcessStatusData',
  DEACTIVATE_REACTIVATE_EPISODE: 'deleteReactivateEpisode',
  ENABLE_DISABLE_M11N: 'enableDisableM11n',
  LIST_MARBYLS: 'listMarbyls',
  TOGGLE_MARBYLS_STATUS: 'toggleMarbylsStatus',
};
