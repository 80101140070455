import { STAGE } from 'constants/secrets';
import { useAuth } from 'contexts/AuthContext';
import { useMemo } from 'react';

const usePodcastEmail = (podcast) => {
  const { user } = useAuth();
  const { email } = user.attributes;

  const podcastEmail = useMemo(
    () => (!STAGE || STAGE !== 'prod' ? email : podcast?.itunes?.owner?.email || ''),
    [podcast, email]
  );

  return podcastEmail;
};

export default usePodcastEmail;
