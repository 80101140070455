export const listChildrenByPodcastNetworkId = /* GraphQL */ `
  query listChildrenByPodcastNetworkId(
    $networkdId: String!
    $limit: Int
    $nextToken: String
  ) {
    listChildrenByPodcastNetworkId(
      podcastNetworkId: $networkdId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        PK
        availableInGeos
        itemType
        name
        podcastNetworkName
        showArtSmall
        title
      }
      nextToken
    }
  }
`;

export const getNetworkById = /* GraphQL */ `
  query getNetworkById($networkId: String!) {
    getPodcastNetworkById(podcastNetworkId: $networkId) {
      PK
      availableInGeos
      contentAgreementType
      createdAt
      description
      isActivated
      entity: legalEntityName
      entityType: legalEntityType
      name: podcastNetworkName
      updatedAt
    }
  }
`;
