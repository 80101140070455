import styled from '@mui/styled-engine';
import { Box } from '@mui/material';
import { DESKTOP_BREAKPOINT } from 'constants/breakpoints';
import { HEX_COLORS } from 'constants/stylesVariables';

export const StyledFullHeight = styled(Box)(
  ({ isDarkTheme = true }) => `
  background-color: ${isDarkTheme ? HEX_COLORS.black : HEX_COLORS.lightGray};
  height: 100vh;
  overflow: auto;
  &::-webkit-scrollbar { display: none; };
`
);

export const StyledInnerContainer = styled(Box)(
  ({ fullWidth = false }) => `
  display: flex;
  flex-direction: column;
  height: inherit;
  margin: 0 auto;
  max-width: ${fullWidth ? '100%' : '1440px'};
  min-width: 320px;
  padding-left: ${fullWidth ? '0' : '10px'};
  padding-right: ${fullWidth ? '0' : '10px'};

  @media only screen and ${DESKTOP_BREAKPOINT} {
    padding-left: ${fullWidth ? '0' : '20px'};
    padding-right: ${fullWidth ? '0' : '20px'};
  }
`
);
