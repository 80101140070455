import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';

const ENTITIES_NAMES_KEY = 'entitiesNames';

const initialValues = {};

const EntitiesNamesContext = createContext(initialValues);

export const useEntityNames = () => {
  return useContext(EntitiesNamesContext);
};

const EntitiesNamesProvider = ({ children }) => {
  const [entNames, setEntNames] = useState(initialValues);
  const [mounted, setMounted] = useState(false);

  const setEntityName = useCallback(
    (id, name) => {
      if (!mounted) return;

      setEntNames((prev) => {
        const temp = {
          ...prev,
          [id]: name,
        };

        localStorage.setItem(ENTITIES_NAMES_KEY, JSON.stringify(temp));

        return temp;
      });
    },
    [mounted]
  );

  useEffect(() => {
    const storageItem = localStorage.getItem(ENTITIES_NAMES_KEY);

    if (storageItem) {
      setEntNames(JSON.parse(storageItem));
    }

    setMounted(true);
  }, []);

  return (
    <EntitiesNamesContext.Provider value={[entNames, setEntityName]}>
      {children}
    </EntitiesNamesContext.Provider>
  );
};

EntitiesNamesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EntitiesNamesProvider;
