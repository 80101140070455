import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const DepsContext = createContext({});

export const useDeps = () => {
  return useContext(DepsContext);
};

const DepsProvider = ({ children, ...services }) => {
  return <DepsContext.Provider value={services}>{children}</DepsContext.Provider>;
};

DepsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DepsProvider;
