import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { STRINGS } from 'constants/strings';
import { PodcastSearchResults } from '@components/index';
import { urlRegex } from '@utils/regex';
import { CORS_PROXY } from 'constants/secrets';
import InfoIcon from '@mui/icons-material/Info';
import useClickOutside from 'hooks/useClickOutside';
import { StyledInvertedButton } from '@shared/styledInputs';
import { API_SEARCH, RSS_DIRECT_ENTRY } from 'constants/analytics';
import { record } from '@utils/analytics';
import { props, defaultProps } from './props';

const RSS_INPUT_OPTIONS = {
  URL: {
    type: 'URL',
    textLabel: STRINGS.PASTE_IN_RSS_FEED_URL,
    buttonLabel: STRINGS.LOAD,
  },
  TEXT: {
    type: 'TEXT',
    textLabel: STRINGS.LOOK_UP_RSS_FEED_URL,
    buttonLabel: STRINGS.SEARCH,
  },
};

const PodcastSearch = ({
  onRSSLoad,
  submit,
  onBeforeRSSLoad,
  searchHeight,
  useContext,
  searchText,
  recordAnalytics,
}) => {
  const [rssText, setRssText] = useState(searchText || '');
  const [loadingRSS, setLoadingRSS] = useState(false);
  const [searchMode, setSearchMode] = useState(RSS_INPUT_OPTIONS.URL.type);
  const [open, setOpen] = useState(false);

  const { podcastState } = useContext();

  const setPodcast = podcastState[1];

  const ref = useRef();

  useClickOutside(ref, () => setOpen(false));

  const CustomWrapper = submit ? 'form' : 'div';

  const loadRSS = async (item) => {
    setLoadingRSS(true);
    let load = true;

    const { rssUrl, imageUrl, itunesId } = item;

    if (!rssUrl) {
      onRSSLoad(null, STRINGS.RSS_FEED_NOT_FOUND);
    }

    try {
      load = await onBeforeRSSLoad(item);
    } catch (error) {
      load = false;
    }

    if (!load) {
      setLoadingRSS(false);
      return;
    }

    fetch(CORS_PROXY + rssUrl)
      .then((response) => response.json())
      .catch(() => {
        onRSSLoad(null, STRINGS.CANNOT_LOAD_THIS_URL_TRY_AGAIN);
        setLoadingRSS(false);
      })
      .then((data) => {
        setLoadingRSS(false);
        onRSSLoad(
          {
            ...data,
            ...(imageUrl && { imageUrl }),
            ...(itunesId && { itunesId }),
            ...(!data.feedUrl && { feedUrl: rssUrl }),
          },
          null
        );
      })
      .catch(() => {
        onRSSLoad(null, STRINGS.CANNOT_LOAD_THIS_URL_TRY_AGAIN);
      });
  };

  const handleRSSSearch = (actionType) => {
    setPodcast(null);

    switch (searchMode) {
      case RSS_INPUT_OPTIONS.URL.type: {
        recordAnalytics && record(RSS_DIRECT_ENTRY, { rssFeedUrl: rssText, actionType });
        loadRSS({ rssUrl: rssText });
        return;
      }
      case RSS_INPUT_OPTIONS.TEXT.type: {
        recordAnalytics && record(API_SEARCH, { searchTerm: rssText, actionType });

        setOpen(true);
        setLoadingRSS(true);

        return;
      }

      default:
        return;
    }
  };

  const handleRSSChange = (evt) => {
    const {
      target: { value },
    } = evt;

    setRssText(value);
  };

  useEffect(() => {
    setOpen(false);
    const isUrl = rssText.match(urlRegex);

    if (isUrl) setSearchMode(RSS_INPUT_OPTIONS.URL.type);
    else setSearchMode(RSS_INPUT_OPTIONS.TEXT.type);
  }, [rssText]);

  return (
    <CustomWrapper
      onSubmit={(evt) => {
        evt.preventDefault();

        handleRSSSearch('returnKey');
      }}
    >
      <Box
        ref={ref}
        sx={{ display: 'flex', alignItems: 'flex-start', position: 'relative' }}
      >
        <FormControl
          color="secondary"
          focused
          sx={{ width: '100%', mr: 2 }}
          size={'small'}
          fullWidth
        >
          <InputLabel color="secondary" htmlFor="component-outlined">
            {RSS_INPUT_OPTIONS[searchMode].textLabel}
          </InputLabel>
          <OutlinedInput
            sx={{ color: 'secondary.main' }}
            color="secondary"
            id="component-outlined"
            value={rssText}
            onChange={handleRSSChange}
            label={RSS_INPUT_OPTIONS[searchMode].textLabel}
          />
          <Box sx={{ display: 'flex', mt: 1, alignItems: 'center' }}>
            <InfoIcon fontSize="small" color="info" sx={{ mr: 1 }} />
            <Typography variant="caption">
              {STRINGS.ENTER_AN_RSS_URL_OR_SEARCH_PODCAST}
            </Typography>
          </Box>
        </FormControl>
        <div>
          <StyledInvertedButton
            sx={{ height: 39.97 }}
            disabled={rssText.length === 0 || loadingRSS || open}
            color="info"
            variant="contained"
            type={submit ? 'submit' : 'button'}
            {...(!submit && {
              onClick: () => handleRSSSearch('tapSearchButton'),
            })}
          >
            {loadingRSS ? (
              <CircularProgress color="secondary" size={15} />
            ) : (
              RSS_INPUT_OPTIONS[searchMode].buttonLabel
            )}
          </StyledInvertedButton>
        </div>

        {open && (
          <PodcastSearchResults
            searchText={rssText}
            onClose={() => setOpen(false)}
            onClickItem={(item) => {
              const { title } = item;
              loadRSS(item);
              setRssText(title);
            }}
            onLoad={() => {
              setLoadingRSS(false);
            }}
            searchHeight={searchHeight}
          />
        )}
      </Box>
    </CustomWrapper>
  );
};

PodcastSearch.propTypes = props;
PodcastSearch.defaultProps = defaultProps;

export default PodcastSearch;
