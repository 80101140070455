import React, { useEffect, useState } from 'react';

import {
  MenuItem,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  ListItemIcon,
  OutlinedInput,
} from '@mui/material';
import { uuid } from '@utils/uuid';
import { StyledInvertedCheckbox } from '@shared/styledInputs';
import { props, defaultProps } from './props';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultipleSelect = ({
  id = uuid(),
  label = '',
  onChange,
  options,
  selected,
  disabled = false,
  size = 'small',
  color = 'primary',
}) => {
  const isAllSelected = options.length > 0 && selected.length === options.length;
  const [hashOptions, setHashOptions] = useState(null);
  const [optionsArray, setOptionsArray] = useState([]);

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === 'all') {
      onChange(value.length - 1 === options.length ? [] : optionsArray);
      return;
    }

    onChange(value);
  };

  useEffect(() => {
    const { byId, allIds } = options.reduce(
      (obj, opt) => ({
        byId: { ...obj.byId, [opt.value]: opt.label },
        allIds: [...obj.allIds, opt.value],
      }),
      { byId: {}, allIds: [] }
    );

    setHashOptions(byId);
    setOptionsArray(allIds);
  }, [options]);

  return (
    <FormControl color={color} size={size} disabled={disabled} fullWidth>
      <InputLabel
        sx={{ '&.MuiInputLabel-root': { color: `${color}.main` } }}
        id={`mutiple-select-label-${id}`}
      >
        {label}
      </InputLabel>
      <Select
        size={size}
        disabled={disabled}
        labelId={`mutiple-select-label-${id}`}
        multiple
        value={selected}
        onChange={handleChange}
        renderValue={(selected) => {
          return hashOptions && selected.map((sel) => hashOptions[sel]).join(', ');
        }}
        sx={{
          '.MuiSelect-icon': { color: `${color}.light` },
          '.MuiSelect-outlined': { color: `${color}.light` },
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: `${color}.light`,
          },
        }}
        MenuProps={MenuProps}
        input={<OutlinedInput color={color} label={label} />}
      >
        <MenuItem value="all">
          <ListItemIcon>
            <StyledInvertedCheckbox
              checked={isAllSelected}
              indeterminate={selected.length > 0 && selected.length < options.length}
            />
          </ListItemIcon>
          <ListItemText primary="Select All" />
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <ListItemIcon>
              <StyledInvertedCheckbox checked={selected.indexOf(option.value) > -1} />
            </ListItemIcon>
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

MultipleSelect.propTypes = props;
MultipleSelect.defaultProps = defaultProps;

export default MultipleSelect;
