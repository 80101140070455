import React, { useEffect, useState } from 'react';
import { Typography, Box, Button, Modal, CircularProgress } from '@mui/material';
import { STRINGS } from 'constants/strings';
import { HEX_COLORS } from 'constants/stylesVariables';
import { CountriesSelect } from '@components/index';
import { props } from './props';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: `2px solid ${HEX_COLORS.black}`,
  boxShadow: 24,
  p: 4,
};

const GeoAvailabilityModal = ({ open, onClose, isLoading, onSubmit }) => {
  const [geo, setGeo] = useState([]);

  const handleSubmit = () => onSubmit(geo);
  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    setGeo([]);
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={isLoading ? null : handleClose}
      aria-labelledby="geo-modal-title"
      aria-describedby="geo-modal-description"
    >
      <Box sx={style}>
        <Typography id="geo-modal-title" variant="h6" component="h2">
          {STRINGS.CHANGE_GEO_AVAILABILITY}
        </Typography>
        <CountriesSelect
          color="secondary"
          name="countries"
          value={geo}
          onChange={setGeo}
        />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleSubmit} sx={{ mr: 1 }} variant="contained" color="error">
            {isLoading ? <CircularProgress color="secondary" /> : STRINGS.SAVE}
          </Button>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="secondary"
            disabled={isLoading}
          >
            {STRINGS.CANCEL}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

GeoAvailabilityModal.propTypes = props;

export default GeoAvailabilityModal;
