import { DynamicForm } from '@components/index';
import { FIELD_TYPES } from 'constants/dynamicFormTypes';
import { STRINGS } from 'constants/strings';
import React, { useMemo } from 'react';
import { Typography, Grid, TextField } from '@mui/material';
import { M11N_AUTO_TYPE } from 'constants/m11n';
import { getAvailableInGeos } from '@utils/getAvailableInGeos';
import { PREFERRED_SUBSCRIBE_LINK_TYPES } from 'constants/preferredSubscribeLinkTypes';
import { getActiveStatusValue } from '@utils/getActiveStatus';
import { STATUS_ACTIVE, STATUS_INACTIVE, STATUS_LABELS } from 'constants/activeStatus';
import { useAuth } from 'contexts/AuthContext';
import { PODCAST_VIEW_ACTIONS } from 'constants/actionsList';

const EditPodcast = ({
  PK,
  preferredSubscribeLink,
  preferredSubscribeLinkType,
  availableInGeos,
  isActivated,
  m11nIsEnabled,
  m11nAutoType,
  onCancel,
  onSubmit,
}) => {
  const { permissions } = useAuth();

  const canEditBasic = useMemo(
    () => !!permissions[PODCAST_VIEW_ACTIONS.EDIT_PODCAST_BASIC_INFO],
    [permissions]
  );

  const canEditGeo = useMemo(
    () => !!permissions[PODCAST_VIEW_ACTIONS.EDIT_GEO_AVAILABILITY],
    [permissions]
  );

  const canToggleStatus = useMemo(
    () => !!permissions[PODCAST_VIEW_ACTIONS.TOGGLE_ACTIVATION_PODCAST],
    [permissions]
  );

  const canToggleM11n = useMemo(
    () => !!permissions[PODCAST_VIEW_ACTIONS.TOGGLE_M11N],
    [permissions]
  );

  const canEditM11nOptions = useMemo(
    () => !!permissions[PODCAST_VIEW_ACTIONS.EDIT_M11N_OPTIONS],
    [permissions]
  );

  const fields = useMemo(() => {
    return [
      ...(canEditBasic
        ? [
            {
              type: FIELD_TYPES.TEXT,
              label: STRINGS.PREFERED_SUBSCRIBE_LINK,
              name: 'preferredSubscribeLink',
              color: 'secondary',
              md: 6,
              xs: 12,
            },
            {
              type: FIELD_TYPES.SELECT,
              label: STRINGS.SUBSCRIBE_LINK_TYPE,
              name: 'preferredSubscribeLinkType',
              items: Object.keys(PREFERRED_SUBSCRIBE_LINK_TYPES).map((_key) => ({
                value: _key,
                label: PREFERRED_SUBSCRIBE_LINK_TYPES[_key].label,
              })),
              color: 'secondary',
              md: 6,
              xs: 12,
            },
          ]
        : []),
      {
        type: FIELD_TYPES.TITLE,
        label: STRINGS.PODCAST_AVAILABILITY,
      },
      ...(canToggleStatus
        ? [
            {
              type: FIELD_TYPES.SELECT,
              label: 'Status',
              name: 'isActivated',
              items: [STATUS_ACTIVE, STATUS_INACTIVE].map((_status) => ({
                value: _status,
                label: STATUS_LABELS[_status],
              })),
              md: 6,
              color: 'secondary',
              xs: 12,
            },
          ]
        : []),

      ...(canEditGeo
        ? [
            {
              type: FIELD_TYPES.COUNTRIES_SELECT,
              name: 'availableInGeos',
              color: 'secondary',
              label: STRINGS.GEOGRAPHIC_AVAILABILITY,
              md: 12,
            },
          ]
        : []),
      ...(canToggleM11n || canEditM11nOptions
        ? [
            {
              type: FIELD_TYPES.TITLE,
              label: STRINGS.MARBYLIZATION_SETTINGS,
            },
          ]
        : []),
      ...(canToggleM11n
        ? [
            {
              type: FIELD_TYPES.SELECT,
              items: [STATUS_ACTIVE, STATUS_INACTIVE].map((_status) => ({
                value: _status,
                label: STATUS_LABELS[_status],
              })),
              label: 'Status',
              name: 'm11nIsEnabled',
              md: 6,
              color: 'secondary',
            },
          ]
        : []),
      ...(canEditM11nOptions
        ? [
            {
              type: FIELD_TYPES.SELECT,
              label: STRINGS.SCHEME,
              name: 'm11nAutoType',
              items: Object.keys(M11N_AUTO_TYPE).map((_key) => ({
                value: _key,
                label: M11N_AUTO_TYPE[_key].label,
              })),
              md: 6,
              color: 'secondary',
            },
          ]
        : []),
    ];
  }, [canEditBasic, canToggleStatus, canToggleM11n, canEditM11nOptions, canEditGeo]);

  const initialValues = useMemo(() => {
    const init = {
      ...(canEditBasic && {
        preferredSubscribeLink,
        preferredSubscribeLinkType:
          preferredSubscribeLinkType || PREFERRED_SUBSCRIBE_LINK_TYPES.web_page.key,
      }),
      ...(canEditGeo && {
        availableInGeos: getAvailableInGeos(availableInGeos),
      }),
      ...(canToggleStatus && {
        isActivated: getActiveStatusValue(isActivated),
      }),
      ...(canToggleM11n && {
        m11nIsEnabled: getActiveStatusValue(!!m11nIsEnabled),
      }),
      ...(canEditM11nOptions && { m11nAutoType }),
    };

    return init;
  }, [
    isActivated,
    availableInGeos,
    m11nIsEnabled,
    preferredSubscribeLink,
    preferredSubscribeLinkType,
    m11nAutoType,
    canEditBasic,
    canToggleStatus,
    canToggleM11n,
    canEditM11nOptions,
    canEditGeo,
  ]);

  const handleSubmit = (values) => onSubmit({ id: PK, ...values });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <Typography variant="h6">{STRINGS.RSS_INFORMATION}</Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label={STRINGS.FEED_URL}
            color="secondary"
            disabled
            name="rss-feed"
            fullWidth
            value={'https://feeds.simplecast.com/54nAGcIl'}
          />
        </Grid>
      </Grid>

      <DynamicForm
        formHeader={''}
        formHeaderVariant={'h5'}
        initialValues={initialValues}
        fields={fields}
        onCancel={onCancel}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default EditPodcast;
