import { useState } from 'react';
import PropTypes from 'prop-types';
import { uuid } from '@utils/uuid';
import { Button, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { NestedMenuItem } from 'mui-nested-menu';
import { HEX_COLORS } from 'constants/stylesVariables';

const NestedMenu = ({ list }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleBtnClick = (evt) => setAnchorEl(evt.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const renderMenu = (listItems) => {
    let items = [];

    // TODO: Change these onClicks when the query that retrieves the data is being implemented
    items = listItems.map((item) => {
      if (Array.isArray(item?.items) && item?.items?.length > 0) {
        return (
          <NestedMenuItem
            key={uuid()}
            label={item.label}
            parentMenuOpen={open}
            ContainerProps={{ onClick: () => item.callback(open, item.label) }}
          >
            {renderMenu(item.items)}
          </NestedMenuItem>
        );
      }

      return (
        <MenuItem key={uuid()} onClick={item.callback}>
          {item.label}
        </MenuItem>
      );
    });

    return items;
  };

  return (
    <>
      <Button
        id="nested-menu-btn"
        aria-control={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleBtnClick}
        color="secondary"
        variant="outlined"
        sx={{ border: `1px solid ${HEX_COLORS.celticBlue}` }}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Selected Entity
      </Button>
      <Menu
        id="nested-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'nested-button',
        }}
      >
        {renderMenu(list)}
      </Menu>
    </>
  );
};

NestedMenu.propTypes = {
  list: PropTypes.array,
};

NestedMenu.defaultProps = {
  list: [],
};

export default NestedMenu;
