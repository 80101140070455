import { useState, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthState } from '@aws-amplify/ui-components';
import { TextField } from '@mui/material';
import { MessageIcon } from '@components/index';
import {
  UnauthenticatedContainer,
  UnauthenticatedFormContainer,
} from '@components/containers';
import { useAuth } from 'contexts/AuthContext';
import { confirmForgotPasswordRequest } from '@graphql/mutations/accounts';
import { requestAPIGraphQL } from '@services/appSyncAPI';
import useQuery from 'hooks/useQuery';
import useNotifications from 'hooks/useNotifications';
import { STRINGS } from 'constants/strings';
import { MAIN_ROUTES } from 'constants/routing';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [requestCode, setRequestCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const { authState, login, loading: loadingAuth } = useAuth();
  const { id } = useParams();
  const history = useHistory();
  const query = useQuery();
  const { showErrorMessages } = useNotifications();

  const initiateData = useCallback(() => {
    const code = query?.get('code');

    setRequestCode(code);
  }, []);

  useEffect(() => {
    initiateData();
  }, [initiateData]);

  const toggleLoading = () => setLoading((prevLoading) => !prevLoading);

  const handleLogin = (username, password) => {
    login(username, password).catch((err) => {
      toast.error(
        <MessageIcon text={err.message} icon="none" typographyVariant="body1" />
      );
    });
  };

  useEffect(() => {
    if (authState === AuthState.SignedIn) {
      history.push(MAIN_ROUTES.APP);
    }
  }, [authState, history]);

  const handleSubmit = () => {
    toggleLoading();

    if (requestCode) {
      requestAPIGraphQL(
        confirmForgotPasswordRequest,
        {
          newPassword,
          confirmPassword: confirmedPassword,
          userSub: id,
          verificationCode: requestCode,
        },
        { authMode: 'API_KEY' }
      )
        .then(({ data }) => {
          if (data?.actionResponse?.email) {
            toast.success('Your password has been successfully reset.');

            handleLogin(data?.actionResponse?.email, newPassword);
          }
        })
        .catch(({ errors }) => {
          showErrorMessages(
            errors,
            'There was an error trying to reset your password, please try again.'
          );

          toggleLoading();
        });
    }
  };

  return (
    <UnauthenticatedContainer
      redirectBtnLabel={STRINGS.SIGN_IN}
      redirectBtnRoute={MAIN_ROUTES.LOGIN}
    >
      <UnauthenticatedFormContainer
        title="Enter New Password"
        onSubmit={handleSubmit}
        submitBtnLabel="Update"
        submitBtnColor="error"
        submitBtnWidth="100%"
        loading={loading || loadingAuth}
      >
        <TextField
          value={newPassword}
          onChange={(evt) => setNewPassword(evt?.target?.value)}
          variant="outlined"
          margin="normal"
          placeholder="New Password"
          label="New Password"
          type="password"
          fullWidth
          required
          focused
          sx={{ '.MuiOutlinedInput-input': { color: 'black' } }}
        />
        <TextField
          value={confirmedPassword}
          onChange={(evt) => setConfirmedPassword(evt?.target?.value)}
          variant="outlined"
          margin="normal"
          placeholder="Confirm new password"
          label="Confirm new password"
          type="password"
          fullWidth
          required
          focused
          sx={{ '.MuiOutlinedInput-input': { color: 'black' } }}
        />
      </UnauthenticatedFormContainer>
    </UnauthenticatedContainer>
  );
};

export default ResetPassword;
