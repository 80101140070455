import React, { useState, useEffect, useCallback } from 'react';

import { replaceLiterals } from '@utils/replaceLiterals';
import { STRINGS } from 'constants/strings';
import { useAddYourPodcast } from 'contexts/AddYourPodcastContext';

import { Typography, Box, Button, CircularProgress } from '@mui/material';
import { PodcastArtTitle } from '@components/index';
import useCountDown from 'react-countdown-hook';
import { millisToMinutesAndSeconds } from '@utils/time';
import { toast } from 'react-toastify';
import {
  TAP_PREVIOUS_FROM_VERIFY_CODE,
  TAP_RESEND_CODE,
  TAP_VERIFY,
} from 'constants/analytics';

import usePodcastEmail from 'hooks/usePodcastEmail';
import { StyledInvertedButton, StyledInvertedTextField } from '@shared/styledInputs';
import { pageNames } from 'constants/ipsso';
import useLoadModalSubpage from 'hooks/useLoadModalSubpage';
import useRecordAnalyticsPodcast from 'hooks/useRecordAnalyticsPodcast';

const initialTime = 60 * 5000;
const interval = 1000;

const VerifyCode = ({ renderBackBtn, onNextClick }) => {
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [timeLeft, { start }] = useCountDown(initialTime, interval);

  const {
    podcastState: [podcast],
    verifyPodcastCode,
    requestVerificationCode,
  } = useAddYourPodcast();

  const podcastEmail = usePodcastEmail(podcast);
  const { recordAnalyticsPodcast } = useRecordAnalyticsPodcast();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const restart = useCallback(() => start(60 * 5000), []);

  const handleVerifyCode = async (evt) => {
    try {
      evt.preventDefault();

      toast.dismiss();
      setLoading(true);

      recordAnalyticsPodcast(TAP_VERIFY);

      const verifyPPayload = {
        verificationCode: code.replace('-', ''),
      };

      const verifyResponse = await verifyPodcastCode(verifyPPayload);

      if (!verifyResponse) {
        setLoading(false);
        toast.error(STRINGS.AN_ERROR_OCCURED_ON_SERVER__TRY_AGAIN);

        return;
      }

      setLoading(false);
      onNextClick();
    } catch (err) {
      setLoading(false);
      const [{ message }] = err.errors;
      toast.error(message);
    }
  };

  const handleResendCode = async () => {
    try {
      setLoading(true);

      recordAnalyticsPodcast(TAP_RESEND_CODE, { podcastEmail });

      const requestPVPayload = {
        podcastEmail,
      };

      await requestVerificationCode(requestPVPayload);
      restart();

      setLoading(false);
      toast.success(STRINGS.CODE_HAS_BEEN_SENT);
    } catch (e) {
      setLoading(false);

      const [{ message }] = e.errors;
      toast.error(message);
    }
  };

  const renderLoadingOrComp = useCallback(
    (renderComp) => {
      return loading ? <CircularProgress color="secondary" /> : renderComp();
    },
    [loading]
  );

  useEffect(() => {
    start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLoadModalSubpage(pageNames.VERIFY_CODE);

  return (
    <form onSubmit={handleVerifyCode}>
      <PodcastArtTitle podcast={podcast} />
      <Typography sx={{ mt: 2 }} variant="h6">
        {STRINGS.CHECK_YOUR_EMAIL}
      </Typography>
      <Typography sx={{ mb: 1 }} variant="subtitle1">
        {replaceLiterals(
          STRINGS.WE_SENT_A_6_DIGIT_CODE_TO_THE_EMAIL_ADDRESS_ASSOCIATED_WITH_YOUR_RSS_FEED_ENTER_THAT_CODE_HERE_NOTE_THE_CODE_WILL_EXPIRE_IN,
          {
            email: podcastEmail,
          }
        )}{' '}
        {millisToMinutesAndSeconds(timeLeft)}.
      </Typography>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 2,
          color: 'secondary.main',
        }}
      >
        <StyledInvertedTextField
          value={code}
          label="Code"
          size="small"
          onChange={(evt) => setCode(evt.target.value)}
          color="secondary"
          focused
          type="password"
          sx={{ mr: 1 }}
          inputProps={{ minLength: 6, maxLength: 6 }}
        />

        {renderLoadingOrComp(() => (
          <StyledInvertedButton
            variant="contained"
            sx={{ ml: 1 }}
            type="submit"
            color="error"
          >
            {STRINGS.VERIFY}
          </StyledInvertedButton>
        ))}
      </Box>

      <Button
        color="info"
        disabled={loading}
        type="button"
        onClick={handleResendCode}
        sx={{ mb: 2 }}
      >
        {STRINGS.RESEND_CODE}
      </Button>

      <Box sx={{ mt: 2 }}>{renderBackBtn(TAP_PREVIOUS_FROM_VERIFY_CODE)}</Box>
    </form>
  );
};

export default VerifyCode;
