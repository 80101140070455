/* eslint-disable no-console */
import { STAGE } from 'constants/secrets';

export const log = (...props) => {
  if (STAGE === 'prod') return;

  console.log(...props);
};

export const tableLog = (...props) => {
  console.table(...props);
};

export const errorLog = (...props) => {
  console.error(...props);
};
