import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import { MAIN_ROUTES } from 'constants/routing';
import { record } from '@utils/analytics';
import { TAP_MARBYL_LOGO } from 'constants/analytics';

const LogoIconContainer = ({ children, isRecording = false }) => {
  const { authed } = useAuth();
  const history = useHistory();

  const onClickLogo = useCallback(() => {
    const route = authed ? MAIN_ROUTES.MPC : MAIN_ROUTES.HOME;
    if (isRecording) record(TAP_MARBYL_LOGO);

    history.push(route);
  }, [history, isRecording, authed]);

  return (
    <IconButton
      onClick={onClickLogo}
      sx={{
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }}
    >
      {children}
    </IconButton>
  );
};

LogoIconContainer.propTypes = {
  children: PropTypes.node.isRequired,
  isRecording: PropTypes.bool,
};

LogoIconContainer.defaultProps = {
  isRecording: false,
};

export default LogoIconContainer;
