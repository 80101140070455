export const getTranscriptSelectedTextInfo = (mainText, selectedText) => {
  const positionStart = mainText.indexOf(selectedText);
  const positionEnd = mainText.indexOf(selectedText) + selectedText.length - 1;
  const prevText =
    positionStart > 0 ? mainText.substring(positionStart - 300, positionStart) : '';
  const lastText =
    positionEnd > 0 ? `${mainText.substring(positionEnd + 1, positionEnd + 301)}...` : '';

  return {
    positionStart,
    positionEnd,
    prevText,
    lastText,
  };
};
