export const listPodcastsForPodcaster = /* GraphQL */ `
  query listPodcastsByUserId($podcasterId: String!, $limit: Int!, $nextToken: String) {
    podcastsList: MPCListShowsByUserIdV2(
      podcasterId: $podcasterId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        PK
        title
        description
        rssFeedLink
        categories
        isActivated
        showArtSmall
        m11nIsEnabled
        m11nAutoType
        feedIngestionState
        categoriesWithSubs {
          name
          subs {
            name
          }
        }
        email
        updatedAt
        isDeleted: deleted
      }
      nextToken
    }
  }
`;

export const getPodcasterById = /* GraphQL */ `
  query getPodcasterById($podcasterId: String!) {
    getPodcasterById(podcasterId: $podcasterId) {
      name
      owningCompanyName
      owners {
        name
        isHostCoHost
        ownerIsAccountUser
        phoneCode
        phoneNumber
        id
        email
      }
      phoneCode
      phoneNumber
      updatedAt
      optedIntoTospp
      isActivated
      description
      createdAt
      contentAgreementType
      availableInGeos
      SK
      PK
    }
  }
`;
