import React, { useCallback, useMemo } from 'react';
import { Button, Box } from '@mui/material';
import { STRINGS } from 'constants/strings';
import { record } from '@utils/analytics';
import { TAP_EDIT_PODCAST } from 'constants/analytics';
import EditIcon from '@mui/icons-material/Edit';
import { MetaInfo } from '@components/index';
import { StyledHeaderSection } from '@shared/styledGeneral';
import { dateToMMDoYYYY } from '@utils/dateFormat';
import { convertGeoArrayToText } from '@utils/getAvailableInGeos';
import { getFeedIngestionStatus, getPodcastAutoType } from '@utils/m11n';
import { LAST_INGESTION_RESULTS } from 'constants/m11n';
import { categoriesWithSubsToText } from '@utils/categories';
import { useAuth } from 'contexts/AuthContext';
import { PODCAST_VIEW_ACTIONS } from 'constants/actionsList';

const PodcastDetail = ({
  PK,
  rssFeedLink: rssFeed,
  title,
  subtitle,
  description,
  availableInGeos: geoAvailability,
  author,
  owner,
  infoLink,
  imageUrl,
  language,
  podcastType: type,
  copyright,
  categoriesWithSubs,
  explicit,
  isActivated: status,
  isActivatedUpdated: statusDate,
  m11nIsEnabled,
  m11nAutoType,
  m11nIsEnabledUpdated,
  feedIngestionState,
  lastIngestionDate,
  lastIngestionResult,
  lastIngestionEpisodes,
  onClickEdit = () => null,
}) => {
  const { permissions } = useAuth();

  const metaPodcastInfo = useMemo(() => {
    return [
      { label: STRINGS.TITLE, value: title, md: 3, xs: 12 },
      { label: STRINGS.SUBTITLE, value: subtitle, md: 3, xs: 12 },
      {
        label: STRINGS.INFO_LINK,
        value: infoLink,
        md: 3,
        xs: 12,
        renderValue: (val) => (
          <a href={val} target="_blank" rel="noreferrer">
            Link
          </a>
        ),
      },
      {
        label: STRINGS.IMAGE_URL,
        value: imageUrl,
        md: 3,
        xs: 12,
        renderValue: (val) => (
          <a href={val} target="_blank" rel="noreferrer">
            Link
          </a>
        ),
      },

      { label: STRINGS.AUTHOR, value: author?.name || author?.email, md: 3, xs: 12 },
      { label: STRINGS.OWNER, value: owner?.name, md: 3, xs: 12 },
      { label: STRINGS.LANGUAGE, value: language, md: 3, xs: 12 },
      { label: STRINGS.TYPE, value: type, md: 3, xs: 12 },

      {
        label: STRINGS.COMPLETE,
        value: title,
        renderValue: (val) => (val ? 'Yes' : 'No'),
        md: 3,
        xs: 12,
      },
      { label: STRINGS.COPYRIGHT, value: copyright, md: 3, xs: 12 },
      {
        label: STRINGS.CATEGORIES,
        value: categoriesWithSubsToText(categoriesWithSubs),
        md: 3,
        xs: 12,
      },
      {
        label: STRINGS.EXPLICIT,
        value: explicit,
        renderValue: (val) => (val ? 'Yes' : 'No'),
        md: 3,
        xs: 12,
      },
      { label: STRINGS.DESCRIPTION, value: description, md: 12, xs: 12 },
    ];
  }, [
    title,
    subtitle,
    imageUrl,
    author,
    owner,
    categoriesWithSubs,
    copyright,
    description,
    explicit,
    language,
    type,
    infoLink,
  ]);

  const metaPodcastAvailability = useMemo(() => {
    return [
      {
        label: STRINGS.PODCAST_IS_ACTIVATED,
        value: status,
        renderValue: (val) => (val ? 'Yes' : 'No'),
        md: 4,
        xs: 12,
      },
      {
        label: STRINGS.DATE_UPDATED,
        value: statusDate,
        md: 4,
        xs: 12,
        renderValue: (val) => dateToMMDoYYYY(val),
      },
      {
        label: STRINGS.GEOGRAPHIC_AVAILABILITY,
        value: geoAvailability,
        md: 3,
        xs: 12,
        renderValue: (val) => convertGeoArrayToText(val),
      },
    ];
  }, [geoAvailability, status, statusDate]);

  const metaMarbylizationSettings = useMemo(() => {
    return [
      {
        label: STRINGS.ENABLED,
        value: m11nIsEnabled,
        renderValue: (val) => (val ? 'Yes' : 'No'),
        md: 4,
        xs: 12,
      },
      {
        label: STRINGS.SCHEME,
        value: getPodcastAutoType(m11nAutoType).label,
        md: 4,
        xs: 12,
      },
      {
        label: STRINGS.DATE_UPDATED,
        value: m11nIsEnabledUpdated,
        md: 3,
        xs: 12,
        renderValue: (val) => (val ? dateToMMDoYYYY(val) : ''),
      },
    ];
  }, [m11nIsEnabled, m11nAutoType, m11nIsEnabledUpdated]);

  const metaLastIngestion = useMemo(() => {
    return [
      {
        label: STRINGS.DATE,
        value: lastIngestionDate,
        md: 4,
        xs: 12,
        renderValue: (val) => (val ? dateToMMDoYYYY(val) : STRINGS.PENDING),
      },
      {
        label: STRINGS.RESULT,
        value: LAST_INGESTION_RESULTS[lastIngestionResult] || '',
        md: 4,
        xs: 12,
      },
      {
        label: STRINGS.EPISODES,
        value: lastIngestionEpisodes,
        md: 3,
        xs: 12,
      },
    ];
  }, [lastIngestionDate, lastIngestionResult, lastIngestionEpisodes]);

  const renderRssData = useCallback(() => {
    return permissions[PODCAST_VIEW_ACTIONS.RSS_SOURCED_DATA] ? (
      <>
        <Box sx={{ mt: 2, mb: 2 }}>
          <StyledHeaderSection variant="h6">
            {STRINGS.RSS_INFORMATION}:{' '}
            <a
              href={rssFeed}
              target="_blank"
              rel="noreferrer"
              style={{ fontSize: '0.8em' }}
            >
              {rssFeed}
            </a>
          </StyledHeaderSection>
        </Box>

        <MetaInfo items={metaPodcastInfo} />
      </>
    ) : null;
  }, [permissions, metaPodcastInfo, rssFeed]);

  const renderPodcastAvailabilityData = useCallback(() => {
    return permissions[PODCAST_VIEW_ACTIONS.PLATFORM_SOURCED_DATA] ? (
      <>
        <StyledHeaderSection sx={{ mt: 4 }} variant="h6">
          {STRINGS.PODCAST_AVAILABILITY}
        </StyledHeaderSection>
        <MetaInfo items={metaPodcastAvailability} />
      </>
    ) : null;
  }, [permissions, metaPodcastAvailability]);

  const renderMarbylizationSettings = useCallback(() => {
    return permissions[PODCAST_VIEW_ACTIONS.M11N_RELATED_DATA] ? (
      <>
        <StyledHeaderSection sx={{ mt: 4 }} variant="h6">
          {STRINGS.MARBYLIZATION_SETTINGS}
        </StyledHeaderSection>

        <MetaInfo items={metaMarbylizationSettings} />
      </>
    ) : null;
  }, [permissions, metaMarbylizationSettings]);

  const renderIngestionStatusData = useCallback(() => {
    return permissions[PODCAST_VIEW_ACTIONS.PROCESS_STATUS_RELATED] ? (
      <>
        <StyledHeaderSection sx={{ mt: 4 }} variant="h6">
          {STRINGS.INGESTION_STATUS}
        </StyledHeaderSection>

        <MetaInfo
          items={[
            {
              label: STRINGS.FEED_INGESTION_STATE,
              value: getFeedIngestionStatus(feedIngestionState).label,
            },
          ]}
        />
      </>
    ) : null;
  }, [permissions, feedIngestionState]);

  const renderLastIngestionData = useCallback(() => {
    return permissions[PODCAST_VIEW_ACTIONS.METRICS_HISTORICAL_RELATED] ? (
      <>
        <StyledHeaderSection sx={{ mt: 4 }} variant="h6">
          {STRINGS.LAST_INGESTION}
        </StyledHeaderSection>
        <MetaInfo items={metaLastIngestion} />
      </>
    ) : null;
  }, [permissions, metaLastIngestion]);

  const renderEditButton = useCallback(() => {
    return permissions[PODCAST_VIEW_ACTIONS.EDIT_PODCAST_BASIC_INFO] ? (
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={() => {
            record(TAP_EDIT_PODCAST, { id: PK });
            onClickEdit(PK);
          }}
          variant="outlined"
          color="secondary"
        >
          {STRINGS.EDIT}
          <EditIcon sx={{ ml: 1, fontSize: 18 }} />
        </Button>
      </Box>
    ) : null;
  }, [permissions, PK, onClickEdit]);

  return (
    <>
      {renderEditButton()}
      {renderRssData()}
      {renderPodcastAvailabilityData()}
      {renderMarbylizationSettings()}
      {renderIngestionStatusData()}
      {renderLastIngestionData()}
    </>
  );
};

export default PodcastDetail;
