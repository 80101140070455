import React, { useMemo } from 'react';
import { Button, Box, Paper } from '@mui/material';
import { STRINGS } from 'constants/strings';
import EditIcon from '@mui/icons-material/Edit';
import { MetaInfo } from '@components/index';
import { StyledHeaderSection } from '@shared/styledGeneral';
import { dateToMMDoYYYY } from '@utils/dateFormat';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { MPC_ROUTES } from 'constants/routing';

const MarbylDetail = ({ marbyl, onClickEdit }) => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const marbylInfo = useMemo(() => {
    if (!marbyl) {
      return [];
    }

    return [
      { label: STRINGS.MARBYL_TEXT, value: marbyl.text, md: 12, xs: 12 },
      {
        label: 'Creation Date',
        value: marbyl.createdAt,
        md: 6,
        xs: 12,
        renderValue: (val) => dateToMMDoYYYY(val),
      },
      {
        label: 'Source Type',
        value: marbyl.source,
        md: 6,
        xs: 12,
      },
      {
        label: STRINGS.MODEL,
        value: marbyl.modelName,
        md: 6,
        xs: 12,
      },
      {
        label: STRINGS.VERSION,
        value: marbyl.modelVersion,
        md: 6,
        xs: 12,
      },
      {
        label: STRINGS.GENRE,
        value: marbyl.genre,
        md: 6,
        xs: 12,
      },
      {
        label: STRINGS.SUB_GENRE,
        value: marbyl.subGenre,
        md: 6,
        xs: 12,
      },
    ];
  }, [marbyl]);

  const adminInfo = useMemo(() => {
    if (!marbyl) {
      return [];
    }

    return [
      {
        label: STRINGS.IS_ACTIVATED,
        value: marbyl.isActivated,
        md: 6,
        xs: 12,
        renderValue: (val) => (val ? STRINGS.ACTIVE : STRINGS.INACTIVE),
      },
      {
        label: STRINGS.EPISODE_ACTIVATION_DATE,
        value: marbyl.isActivatedUpdated
          ? dateToMMDoYYYY(marbyl.isActivatedUpdated)
          : STRINGS.PENDING,
        md: 6,
        xs: 12,
      },
    ];
  }, [marbyl]);

  return (
    <Paper sx={{ p: 2, bgcolor: 'primary.main', color: 'secondary.main' }} elevation={2}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button sx={{ mr: 2 }} onClick={onClickEdit} variant="outlined" color="secondary">
          {STRINGS.EDIT}
          <EditIcon sx={{ ml: 1, fontSize: 18 }} />
        </Button>
        <Button
          onClick={() => history.push(`${url}${MPC_ROUTES.CONTENT_REPORTS}`)}
          variant="contained"
          color="error"
        >
          {STRINGS.VIEW_CONTENT_REPORT}
        </Button>
      </Box>
      <StyledHeaderSection variant="h6">{STRINGS.MARBYL_DETAILS}</StyledHeaderSection>

      <MetaInfo items={marbylInfo} />

      <StyledHeaderSection sx={{ mt: 3 }} variant="h6">
        {STRINGS.ADMIN_INFORMATION}
      </StyledHeaderSection>

      <MetaInfo items={adminInfo} />
    </Paper>
  );
};

export default MarbylDetail;
