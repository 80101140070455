import { getPodcastsListenNotes } from '@graphql/queries/ipsso';
import { PODCAST_SEARCH_URL } from 'constants/secrets';
import { requestAPIGraphQL } from './appSyncAPI';

export const listPodcasts = async (searchTerm, offset = 0) => {
  try {
    const response = await fetch(
      `${PODCAST_SEARCH_URL}?q=${searchTerm}&offset=${offset}`,
      {
        method: 'GET',
      }
    );

    const json = await response.json();

    const { next_offset, results, total } = json;

    return {
      results: results.map((_result) => ({
        ..._result,
        title: _result.title_original,
        imageUrl: _result.image,
        rssUrl: _result.rss,
      })),
      next_offset,
      total,
    };
  } catch (e) {
    throw new Error(e);
  }
};

export const listPodcastsAppSync = async (searchTerm, offset = 0) => {
  try {
    const {
      data: {
        getPodcastsListenNotes: { next_offset, total, results },
      },
    } = await requestAPIGraphQL(getPodcastsListenNotes, { q: searchTerm, offset });
    return { next_offset, results, total };
  } catch (error) {
    throw new Error(error);
  }
};

export const listPodcastsPerPage = async (searchTerm, offset = 0, itemsPerPage = 20) => {
  const iterations = itemsPerPage / 10;

  let temp = [];
  let nextOffset = offset;
  let finalTotal = 0;

  for (let i = 1; i <= iterations; i++) {
    const { results, next_offset, total } = await listPodcastsAppSync(
      searchTerm,
      nextOffset
    );
    temp = [...temp, ...results];
    nextOffset = next_offset;
    finalTotal = total;
  }

  return { results: temp, next_offset: nextOffset, total: finalTotal };
};
