export const PREFERRED_SUBSCRIBE_LINK_TYPES = {
  web_page: {
    key: 'web_page',
    label: 'Web Page',
  },
  app: {
    key: 'app',
    label: 'App',
  },
};
