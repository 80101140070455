import { uuid } from '@utils/uuid';
import PropTypes from 'prop-types';

export const props = {
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  selected: PropTypes.array,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.oneOf(['primary', 'secondary', 'success', 'warning', 'error', 'info']),
};

export const defaultProps = {
  id: uuid(),
  label: '',
  disabled: false,
  size: 'small',
  color: 'primary',
};
