import React, { useMemo } from 'react';
import { STRINGS } from 'constants/strings';
import { DynamicForm } from '@components/index';
import { FIELD_TYPES } from 'constants/dynamicFormTypes';
import { log } from '@utils/logs';
import { getUserRoleLabel } from '@utils/roles';

const mapAccounts = (userAccounts) =>
  userAccounts.map((_account) => ({
    PK: _account.accountId,
    name: `${_account.entityName} (${getUserRoleLabel(_account.accountRole)})`,
  }));

const UserProfileEdit = ({ profile, onCancel, onSubmit }) => {
  const fields = useMemo(() => {
    const { userAccounts } = profile;

    return [
      {
        type: FIELD_TYPES.TITLE,
        label: STRINGS.PROFILE_SETTINGS,
        md: 12,
        xs: 12,
      },
      {
        type: FIELD_TYPES.TEXT,
        label: STRINGS.NAME,
        name: 'name',
        md: 6,
        color: 'secondary',
        xs: 12,
      },
      {
        type: FIELD_TYPES.TEXT,
        label: STRINGS.EMAIL,
        name: 'email',
        inputType: 'email',
        md: 6,
        color: 'secondary',
        xs: 12,
      },
      {
        type: FIELD_TYPES.PHONE_NUMBER,
        label: STRINGS.MOBILE_PHONE,
        name: 'phoneNumber',
        md: 6,
        color: 'secondary',
        xs: 12,
      },
      {
        type: FIELD_TYPES.IMAGE,
        label: '+ Add Photo',
        name: 'image',
        color: 'secondary',
        md: 12,
        xs: 12,
      },
      {
        type: FIELD_TYPES.TEXT,
        label: 'Bio',
        name: 'biography',
        color: 'secondary',
        md: 12,
        xs: 12,
        multiline: true,
        minRows: 4,
      },
      {
        type: FIELD_TYPES.SELECT_MULTIPLE,
        label: STRINGS.ASSOCIATED_ACCOUNTS,
        name: 'accounts',
        color: 'secondary',
        md: 12,
        xs: 12,
        items: mapAccounts(userAccounts),
        valueName: 'PK',
        labelName: 'name',
        disabled: true,
      },
    ];
  }, [profile]);

  const initialValues = useMemo(() => {
    const {
      user: { givenName, familyName, email, phoneNumber = '+1', avatar = '', bio = '' },
      userAccounts,
    } = profile;

    return {
      name: `${givenName} ${familyName}`,
      email,
      phoneNumber,
      image: avatar,
      biography: bio,
      accounts: userAccounts.map((_account) => _account.accountId),
    };
  }, [profile]);

  log(profile);

  const handleSubmit = (values) => {
    // record(TAP_SAVE_MARBYL, { id: marbyl.id });
    onSubmit({ ...values });
  };

  return (
    <>
      <DynamicForm
        formHeader={STRINGS.UPDATE_USER_PROFILE}
        formHeaderVariant={'h4'}
        initialValues={initialValues}
        fields={fields}
        onCancel={onCancel}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default UserProfileEdit;
