import React, { useCallback, useMemo, useRef } from 'react';
import { Typography, Button, Box } from '@mui/material';
import { STRINGS } from 'constants/strings';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { record } from '@utils/analytics';
import { TAP_EDIT_PODCASTER } from 'constants/analytics';
import EditIcon from '@mui/icons-material/Edit';
import { AgGridReact } from 'ag-grid-react';
import { StyledHeaderSection } from '@shared/styledGeneral';
import { MetaInfo } from '@components/index';
import { AGREEMENT_TYPES_LABELS } from 'constants/podcaster';
import { convertGeoArrayToText } from '@utils/getAvailableInGeos';
import { getActiveStatusValue } from '@utils/getActiveStatus';
import { STATUS_LABELS } from 'constants/activeStatus';
import { useAuth } from 'contexts/AuthContext';
import { PODCASTER_VIEW_ACTIONS } from 'constants/actionsList';

const PodcasterDetail = ({
  id,
  name,
  availableInGeos,
  owners = [],
  owningCompanyName,
  contentAgreementType,
  isActivated,
  description,
  optedIntoTospp,
  onClickEdit = () => null,
}) => {
  const handleEditPodcaster = useCallback(() => {
    onClickEdit();
    record(TAP_EDIT_PODCASTER, { id });
  }, [id, onClickEdit]);

  const { permissions } = useAuth();

  const gridRef = useRef();
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

  const renderEditButton = useCallback(() => {
    return permissions[PODCASTER_VIEW_ACTIONS.EDIT_PODCASTER_INFO] ? (
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={handleEditPodcaster} variant="outlined" color="secondary">
          {STRINGS.EDIT}
          <EditIcon sx={{ ml: 1, fontSize: 18 }} />
        </Button>
      </Box>
    ) : null;
  }, [permissions, handleEditPodcaster]);

  const podcasterDetailsInfo = useMemo(
    () => [
      {
        label: STRINGS.NAME,
        value: name,
        md: 6,
        xs: 12,
      },
      {
        label: STRINGS.GEOGRAPHIC_AVAILABILITY,
        value: availableInGeos,
        renderValue: (val) => convertGeoArrayToText(val),
        md: 6,
        xs: 12,
      },
      {
        label: STRINGS.DESCRIPTION,
        value: description,
        md: 12,
        xs: 12,
      },
    ],
    [name, availableInGeos, description]
  );

  const adminSettingsInfo = useMemo(
    () => [
      {
        label: STRINGS.STATUS,
        value: isActivated,
        md: 4,
        xs: 12,
        renderValue: (val) => STATUS_LABELS[getActiveStatusValue(val)],
      },
      {
        label: STRINGS.AGREEMENT,
        value: AGREEMENT_TYPES_LABELS[contentAgreementType] || '',
        md: 4,
        xs: 12,
      },
      {
        label: STRINGS.OPTED_IN_TO_TOS_PP,
        value: optedIntoTospp,
        md: 4,
        xs: 12,
        renderValue: (val) =>
          val ? <CheckIcon color="success" /> : <CloseIcon color="error" />,
        styles: { marginLeft: 8 },
      },
    ],
    [optedIntoTospp, isActivated, contentAgreementType]
  );

  const columnsDef = useMemo(() => {
    return [
      { field: 'name', headerName: 'Name', minWidth: 300, flex: 1 },
      { field: 'email', headerName: 'Email', width: 400 },
      { field: 'phone', headerName: 'Phone', width: 350 },
    ];
  }, []);

  return (
    <>
      {renderEditButton()}
      <StyledHeaderSection variant="h6">{STRINGS.PODCASTER_DETAILS}</StyledHeaderSection>

      <MetaInfo items={podcasterDetailsInfo} />

      <StyledHeaderSection sx={{ mb: 2, mt: 4 }} variant="h6">
        {STRINGS.OWNER_INFORMATION}
      </StyledHeaderSection>
      <div className="ag-theme-custom" style={gridStyle}>
        <AgGridReact
          ref={gridRef}
          columnDefs={columnsDef}
          rowData={owners}
          domLayout="autoHeight"
        />
      </div>

      <Box sx={{ mb: 2, mt: 2 }}>
        <Typography sx={{ fontWeight: 'bold' }} variant="subtitle1">
          {STRINGS.COMPANY_NAME}
        </Typography>
        <Typography variant="body1">{owningCompanyName}</Typography>
      </Box>

      <StyledHeaderSection sx={{ mt: 4 }} variant="h6">
        {STRINGS.ADMIN_SETTINGS}
      </StyledHeaderSection>

      <MetaInfo items={adminSettingsInfo} />
    </>
  );
};

export default PodcasterDetail;
