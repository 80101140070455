import PropTypes from 'prop-types';
import { MenuItem, /*Select,*/ Typography } from '@mui/material';
import { StyledFilterSelect } from './styles';

const FilterSelect = ({
  selectId,
  defaultValue,
  value,
  labelText,
  onChange,
  options,
}) => {
  const optionsList = options?.map((item) => (
    <MenuItem key={item.value} value={item.value}>
      {item.name}
    </MenuItem>
  ));

  const renderSelectedValue = () => {
    const name = options.find((item) => item.value === value).name;

    return <Typography variant="subtitle1">{`${labelText}: ${name}`}</Typography>;
  };

  return (
    <StyledFilterSelect
      id={selectId}
      defaultValue={defaultValue}
      value={value}
      label={labelText}
      onChange={(evt) => onChange(evt.target.value)}
      renderValue={renderSelectedValue}
    >
      {optionsList}
    </StyledFilterSelect>
  );
};

FilterSelect.propTypes = {
  selectId: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  value: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
};

FilterSelect.defaultProps = {
  defaultValue: '',
  options: [],
};

export default FilterSelect;
