import { range } from '@utils/linearInterpolation';
import { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { props } from './props';

const getMaxSize = ({ maxSize }) => maxSize;
const getMinSize = ({ minSize }) => minSize;

const StyledSpacer = styled.span.attrs((props) => ({
  style: {
    width: `${props.size}px`,
    height: `${props.size}px`,
  },
}))`
  display: block;
  max-height: ${getMaxSize}px;
  max-width: ${getMaxSize}px;
  min-height: ${getMinSize}px;
  min-width: ${getMinSize}px;
`;

const DynamicSpacer = ({ minSize, maxSize, minWidth, maxWidth }) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const calculatedSize = useMemo(
    () => range(maxWidth, minWidth, maxSize, minSize, windowSize),
    [minSize, maxSize, minWidth, maxWidth, windowSize]
  );

  useEffect(() => {
    const resizeCallback = () => setWindowSize(window.innerWidth);

    window.addEventListener('resize', resizeCallback);

    return () => {
      window.removeEventListener('resize', resizeCallback);
    };
  }, []);

  return (
    <StyledSpacer minSize={minSize} maxSize={maxSize} size={calculatedSize.toFixed(2)} />
  );
};

DynamicSpacer.propTypes = props;

export default DynamicSpacer;
