import { useAddYourPodcast } from 'contexts/AddYourPodcastContext';
import PropTypes from 'prop-types';

export const props = {
  onRSSLoad: PropTypes.func.isRequired,
  submit: PropTypes.bool,
  onBeforeRSSLoad: PropTypes.func,
  searchHeight: PropTypes.number,
  useContext: PropTypes.func,
  searchText: PropTypes.string,
  recordAnalytics: PropTypes.bool,
};

export const defaultProps = {
  submit: false,
  onBeforeRSSLoad: async () => true,
  searchHeight: 600,
  useContext: useAddYourPodcast,
  searchText: '',
  recordAnalytics: true,
};
