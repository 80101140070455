import React, { useCallback, useEffect, useRef } from 'react';
import ReactSelect from 'react-select';
import { showPromiseError } from '@utils/promiseError';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useLoadItems } from 'hooks/useLoadItems';
import { ListContainer, ListItem, colourStyles } from './styles';
import { props, defaultProps } from './props';

const MenuList = (props) => {
  return (
    <ListContainer ref={props.selectProps.rootRef}>
      <div>{props.children}</div>
      <div>
        {props.selectProps.hasNextPage && (
          <ListItem ref={props.selectProps.infiniteRef}>Loading</ListItem>
        )}
      </div>
    </ListContainer>
  );
};

const InfiniteSelect = ({
  query,
  payload,
  responseKeyName,
  onChange,
  value,
  restClient,
  labelName,
  valueName,
  getLabel,
  styles,
  ...props
}) => {
  const tokenRef = useRef(null);

  const loadItems = useCallback(async () => {
    try {
      const {
        data: { [responseKeyName]: response },
      } = await restClient(query, {
        ...payload,
        ...(tokenRef.current && { nextToken: tokenRef.current }),
      });
      if (!response) {
        throw Error('Not response');
      }

      const { items, nextToken } = response;
      tokenRef.current = nextToken;
      return { data: items, hasNextPage: !!nextToken };
    } catch (err) {
      return showPromiseError(err);
    }
  }, [query, payload, responseKeyName, restClient]);

  const { loading, items, hasNextPage, error, loadMore, clear } = useLoadItems({
    loadItems,
  });

  useEffect(() => {
    tokenRef.current = null;
    clear();
  }, [payload, clear]);

  const [infiniteRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  });

  return (
    <ReactSelect
      options={[
        ...items.map((_item) => ({
          value: _item[valueName],
          label: getLabel ? getLabel(_item) : _item[labelName],
        })),
      ]}
      styles={{ ...colourStyles, ...styles }}
      rootRef={rootRef}
      infiniteRef={infiniteRef}
      hasNextPage={hasNextPage}
      components={{ MenuList }}
      onChange={onChange}
      value={value}
      {...props}
    />
  );
};

InfiniteSelect.propTypes = props;
InfiniteSelect.defaultProps = defaultProps;

export default InfiniteSelect;
