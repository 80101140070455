export const cancelDeleteAccount = /* GraphQL */ `
  mutation cancelDeleteAccountRequest($token: String!) {
    actionResponse: cancelDeleteAccountRequest(token: $token)
  }
`;

export const confirmDeleteAccount = /* GraphQL */ `
  mutation confirmDeleteAccountRequest($token: String!) {
    actionResponse: confirmDeleteAccountRequest(token: $token)
  }
`;

export const forgotPasswordRequest = /* GraphQL */ `
  mutation sendForgotPasswordRequest($email: String!) {
    actionResponse: sendForgotPasswordRequest(email: $email)
  }
`;

export const confirmForgotPasswordRequest = /* GraphQL */ `
  mutation confirmForgotPasswordRequest(
    $newPassword: String!
    $confirmPassword: String!
    $userSub: String!
    $verificationCode: String!
  ) {
    actionResponse: confirmForgotPasswordRequest(
      newPassword: $newPassword
      confirmPassword: $confirmPassword
      userSub: $userSub
      verificationCode: $verificationCode
    ) {
      email
    }
  }
`;
