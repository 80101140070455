import React, { useState } from 'react';

import { STRINGS } from 'constants/strings';
import { useAddYourPodcast } from 'contexts/AddYourPodcastContext';

import {
  Typography,
  FormControlLabel,
  Grid,
  TextField,
  Box,
  Button,
  RadioGroup,
  FormControl,
  FormLabel,
  MenuItem,
  Divider,
} from '@mui/material';
import { ButtonLink, MultipleSelect, PodcastArtTitle } from '@components/index';
import { COUNTRIES, DIAL_CODES } from 'constants/countriesList';
import { useMemo } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ReactInputMask from 'react-input-mask';
import {
  StyledInvertedButton,
  StyledInvertedCheckbox,
  StyledInvertedRadio,
  StyledInvertedTextField,
} from '@shared/styledInputs';
import useDesktopMobile from 'hooks/useDesktopMobile';
import { TAP_PRIVACY_POLICY_LINK, TAP_TERMS_OF_USE_LINK } from 'constants/analytics';
import { pageNames } from 'constants/ipsso';
import useLoadModalSubpage from 'hooks/useLoadModalSubpage';
import { record } from '@utils/analytics';

const AVAILABILITY = {
  WORLDWIDE: 'worldwide',
  SPECIFIC: 'specific',
};

const PodcastInfo = ({ renderBackBtn, onNextClick }) => {
  const {
    podcaster: { owners, companyName },
    hosts,
    podcastState: [podcast],
    regionsState: [regions, setRegions],
    addOwner,
    setOwner,
    removeOwner,
    setCompanyName,
    toggleHost,
  } = useAddYourPodcast();
  const { renderMobileComponent, renderDesktopComponent } = useDesktopMobile();

  const [availability, setAvailability] = useState(() => {
    return regions.length === 0 ? AVAILABILITY.WORLDWIDE : AVAILABILITY.SPECIFIC;
  });

  const [terms, setTerms] = useState(false);

  const handleAddOwner = () => addOwner();

  const handleRemoveOwner = (index) => () => removeOwner(index);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    // Verify code and continue or display error message...
    onNextClick();
  };

  const handleAvailability = (evt) => {
    const {
      target: { value },
    } = evt;

    setRegions(value === AVAILABILITY.SPECIFIC ? ['US'] : []);
    setAvailability(value);
  };

  const handleOwner = (index, field) => (evt) => {
    const {
      target: { value },
    } = evt;

    setOwner(index, { field, value });
  };

  const handleHost = (id) => (evt) => {
    const {
      target: { checked },
    } = evt;

    toggleHost(id, checked);
  };

  const countriesList = useMemo(() => {
    return COUNTRIES.map((country) => ({
      label: country.Name,
      value: country.Code,
    }));
  }, []);

  useLoadModalSubpage(pageNames.PODCAST_INFO);

  return (
    <>
      <PodcastArtTitle podcast={podcast} />
      <Typography sx={{ mt: 2 }} variant="h6">
        {STRINGS.ADD_SOME_DETAILS_ABOUT_YOUR_PODCAST}
      </Typography>

      <form id="owner-form" onSubmit={handleSubmit}>
        <Box
          sx={{
            pl: 1,
            pr: 1,
          }}
        >
          <Grid sx={{ mt: 2 }} alignItems="center" container rowSpacing={2}>
            {renderDesktopComponent(() => (
              <Grid container item>
                <Grid item md={3}>
                  <Typography variant="subtitle1">{STRINGS.OWNERS}</Typography>
                </Grid>
                <Grid
                  sx={{
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  item
                  md={2}
                >
                  <Typography variant="subtitle1">
                    {STRINGS.HOST_CO_HOST_PODCAST}
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography variant="subtitle1">{STRINGS.EMAIL}</Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography variant="subtitle1">{STRINGS.MOBILE_PHONE_SMS}</Typography>
                </Grid>

                <Grid item md={1}></Grid>
              </Grid>
            ))}

            {owners.map((_owner, index) => (
              <Grid container item key={_owner.id} columnSpacing={1} rowSpacing={2}>
                <Grid item md={3} xs={12}>
                  <StyledInvertedTextField
                    color="secondary"
                    value={_owner.name}
                    label="Owner's Name"
                    size="small"
                    required={index === 0}
                    fullWidth
                    onChange={handleOwner(index, 'name')}
                    focused
                  />
                </Grid>
                <Grid sx={{ textAlign: 'center' }} item md={2} xs={12}>
                  {renderMobileComponent(() => (
                    <FormControlLabel
                      control={
                        <StyledInvertedCheckbox
                          checked={hosts[_owner.id] || false}
                          onChange={handleHost(_owner.id)}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={STRINGS.HOST_CO_HOST_PODCAST}
                    />
                  ))}

                  {renderDesktopComponent(() => (
                    <StyledInvertedCheckbox
                      checked={hosts[_owner.id] || false}
                      onChange={handleHost(_owner.id)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  ))}
                </Grid>
                <Grid item md={3} xs={12}>
                  <StyledInvertedTextField
                    value={_owner.email}
                    label="Owner's Email"
                    size="small"
                    required={index === 0}
                    fullWidth
                    onChange={handleOwner(index, 'email')}
                    color="secondary"
                    focused
                  />
                </Grid>
                <Grid item md={3} xs>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      color="secondary"
                      value={_owner.phoneCode}
                      id="outlined-select-code"
                      select
                      label="Code"
                      size="small"
                      sx={{
                        width: 115,
                        mr: 1,
                        '.MuiOutlinedInput-root': { color: 'secondary.main' },
                      }}
                      onChange={handleOwner(index, 'phoneCode')}
                      focused
                    >
                      {DIAL_CODES.map((country) => (
                        <MenuItem key={country.key} value={country.label}>
                          {country.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <ReactInputMask
                      value={_owner.phoneNumber}
                      onChange={handleOwner(index, 'phoneNumber')}
                      mask="(999) 999-9999"
                    >
                      {(inputProps) => (
                        <StyledInvertedTextField
                          {...inputProps}
                          label="Owner's Phone"
                          size="small"
                          fullWidth
                          color="secondary"
                          focused
                        />
                      )}
                    </ReactInputMask>
                  </Box>
                </Grid>

                {index !== 0 && (
                  <Grid item md={1} xs={2}>
                    <DeleteOutlineIcon
                      fontSize={'large'}
                      role="button"
                      onClick={handleRemoveOwner(index)}
                    />
                  </Grid>
                )}

                {renderMobileComponent(() => (
                  <Grid item xs={12}>
                    <Divider sx={{ borderColor: 'secondary.main', mt: 3, mb: 3 }} />
                  </Grid>
                ))}
              </Grid>
            ))}

            {owners.length < 4 && (
              <Grid item md={6}>
                <Button color="info" onClick={handleAddOwner}>
                  + Additional Owner/Co-host
                </Button>
              </Grid>
            )}

            {renderDesktopComponent(() => (
              <Grid sx={{ mt: 2 }} item md={12}>
                <Typography variant="subtitle1">
                  {`${STRINGS.OWNER_COMPANY_NAME} (${STRINGS.IF_APPLICABLE})`}
                </Typography>
              </Grid>
            ))}

            <Grid item md={3} xs={12}>
              <StyledInvertedTextField
                value={companyName}
                label="Owner's Company Name"
                size="small"
                onChange={(evt) => setCompanyName(evt.target.value)}
                fullWidth
                color="secondary"
                focused
              />
            </Grid>

            <Grid sx={{ mt: 4 }} item md={12} xs={12}>
              <FormControl component="fieldset">
                <FormLabel
                  sx={{
                    '&.MuiFormLabel-root': { color: 'secondary.main' },
                    '&.MuiFormLabel-colorSecondary': { color: 'primary.main' },
                  }}
                  component="label"
                >
                  {STRINGS.PODCAST_GEOGRAPHICAL_AVAILABILITY}
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="availability"
                  name="row-radio-buttons-group"
                  value={availability}
                  onChange={handleAvailability}
                >
                  <FormControlLabel
                    value={AVAILABILITY.WORLDWIDE}
                    control={<StyledInvertedRadio color="secondary" />}
                    label={STRINGS.WORLDWIDE}
                  />
                  <FormControlLabel
                    value={AVAILABILITY.SPECIFIC}
                    control={<StyledInvertedRadio color="secondary" />}
                    label={STRINGS.SPECIFIC_COUNTRIES}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {availability === AVAILABILITY.SPECIFIC && (
              <Grid item md={3} xs={12}>
                <MultipleSelect
                  color="secondary"
                  label={STRINGS.COUNTRIES}
                  disabled={availability === AVAILABILITY.WORLDWIDE}
                  options={countriesList}
                  selected={regions}
                  onChange={(value) => setRegions(value)}
                />
              </Grid>
            )}
          </Grid>

          <FormControlLabel
            sx={{ mt: 5 }}
            value={terms}
            onChange={(evt) => setTerms(evt.target.checked)}
            control={<StyledInvertedCheckbox required />}
            label={
              <span>
                I acknowledge the Tree Goat Media{' '}
                <ButtonLink
                  onClick={() => {
                    record(TAP_PRIVACY_POLICY_LINK);
                    window.open('/policy', '_blank');
                  }}
                >
                  Privacy Policy
                </ButtonLink>{' '}
                and agree to{' '}
                <ButtonLink
                  onClick={() => {
                    record(TAP_TERMS_OF_USE_LINK);
                    window.open('/terms', '_blank');
                  }}
                >
                  Terms of Service
                </ButtonLink>
                .
              </span>
            }
          />
        </Box>

        <Box sx={{ mt: 2 }}>
          {renderBackBtn()}

          <StyledInvertedButton
            color="error"
            disabled={!terms}
            type="submit"
            variant="contained"
          >
            {STRINGS.NEXT}
          </StyledInvertedButton>
        </Box>
      </form>
    </>
  );
};

export default PodcastInfo;
