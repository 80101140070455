import styled from '@mui/styled-engine';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { MOBILE_BREAKPOINT } from 'constants/breakpoints';

export const StyledTranscriptMainContainer = styled(Box)(
  () => `
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
  `
);

export const StyledTranscriptContentContainer = styled(Box)(
  ({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ${theme.spacing(2)};
  `
);

export const StyledTranscriptText = styled(Box)(
  () => `
    margin: 0 100px;

    @media ${MOBILE_BREAKPOINT} {
      margin: 0;
    }
  `
);

export const StyledHighlightMessage = styled(Typography)(
  ({ theme }) => `
    border: 1px solid ${theme.palette.error.main};
    border-radius: 4px;
    color: ${theme.palette.error.main};
    padding: 5px 15px;
  `
);
