import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Box, Typography, CircularProgress } from '@mui/material';
import { StyledAuthForm } from '@shared/styledAuthForm';
import { StyledContainer } from '@shared/styledLandingPages';
import { LandingPagesContainer } from '@components/containers/index';
import { useForm, Controller } from 'react-hook-form';
import { StyledTextField } from './styled';

const ConfirmPage = ({
  title,
  description,
  hideFields,
  fieldProperties,
  submitBtnText,
  secondBtnText,
  onSubmit,
  onCancel,
  loading,
  secondLoading,
}) => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      fieldValue: '',
    },
  });

  const onSubmitForm = ({ fieldValue }) => {
    if (fieldValue) {
      onSubmit(fieldValue);
    } else {
      onSubmit();
    }
  };

  const renderLoadingButton = useCallback(
    (text, btnLoading, type, onClick = () => {}) => {
      const isSubmit = type === 'submit';
      const buttonType = isSubmit ? 'submit' : 'button';
      const color = isSubmit ? 'success' : 'secondary';
      const variant = isSubmit ? 'contained' : 'text';
      const style = isSubmit ? { '&.MuiButton-root': { color: 'white' } } : { mt: 2 };

      return !btnLoading ? (
        <Button
          sx={style}
          type={buttonType}
          color={color}
          variant={variant}
          onClick={onClick}
          disabled={loading || secondLoading}
        >
          {text}
        </Button>
      ) : (
        <CircularProgress />
      );
    },
    [loading, secondLoading]
  );

  return (
    <StyledContainer>
      <LandingPagesContainer spacerSize={0} showPartners={false} imgBackground="">
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <StyledAuthForm style={{ maxWidth: 350 }} onSubmit={handleSubmit(onSubmitForm)}>
            <Typography color="secondary" sx={{ textAlign: 'center' }} variant="h6">
              {title}
            </Typography>
            {!hideFields && (
              <Grid container spacing={2}>
                <Grid md={12} sm={12} item>
                  <Controller
                    name="fieldValue"
                    control={control}
                    render={({ field }) => (
                      <StyledTextField
                        {...field}
                        color="secondary"
                        variant="standard"
                        margin="normal"
                        placeholder={fieldProperties?.placeholder}
                        label={fieldProperties?.label}
                        fullWidth
                        required={fieldProperties?.required}
                        focused
                        sx={{ '.MuiInput-input': { color: 'black' } }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
            {description && (
              <Typography
                color="secondary"
                variant="subtitle2"
                sx={{ fontWeight: '400' }}
              >
                {description}
              </Typography>
            )}

            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
              {renderLoadingButton(submitBtnText, loading, 'submit')}
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              {renderLoadingButton(secondBtnText, secondLoading, '', onCancel)}
            </Box>
          </StyledAuthForm>
        </Box>
      </LandingPagesContainer>
    </StyledContainer>
  );
};

ConfirmPage.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  hideFields: PropTypes.bool,
  fieldProperties: PropTypes.shape({
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
  }),
  submitBtnText: PropTypes.string,
  secondBtnText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  secondLoading: PropTypes.bool,
};

ConfirmPage.defaultProps = {
  description: '',
  hideFields: false,
  fieldProperties: {
    label: '',
    placeholder: '',
    required: true,
  },
  submitBtnText: 'Accept',
  secondBtnText: 'Cancel',
  onCancel: () => {},
  loading: false,
  secondLoading: false,
};

export default ConfirmPage;
