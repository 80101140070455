import { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { TextField } from '@mui/material';
import { UnauthenticatedFormContainer } from '@components/containers';
import { forgotPasswordRequest } from '@graphql/mutations/accounts';
import { requestAPIGraphQL } from '@services/appSyncAPI';
import useNotifications from 'hooks/useNotifications';
import { STRINGS } from 'constants/strings';
import { TAP_SIGN_UP_LINK } from 'constants/analytics';

const ForgotPassword = ({ changeFormType, onClose }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const { showErrorMessages } = useNotifications();

  const toggleLoading = () => setLoading((prevLoading) => !prevLoading);

  const handleSubmit = () => {
    toggleLoading();

    requestAPIGraphQL(forgotPasswordRequest, { email }, { authMode: 'API_KEY' })
      .then(({ data }) => {
        if (data.actionResponse) {
          toast.success('A message has been sent to your email address.');

          changeFormType('login');
        }
      })
      .catch(({ errors }) => {
        showErrorMessages(errors);

        toggleLoading();
      });
  };

  return (
    <UnauthenticatedFormContainer
      title="Reset Your Password"
      onSubmit={handleSubmit}
      onClose={onClose}
      submitBtnLabel="Submit"
      submitBtnColor="error"
      submitBtnWidth="100%"
      loading={loading}
      footerMainText="You will receive a link to reset your password."
      footerText={STRINGS.DONT_HAVE_ACCOUNT_YET}
      mainLinkText={STRINGS.SIGN_UP}
      mainLinkRoute={() => changeFormType('signup')}
      mainLinkRecord={TAP_SIGN_UP_LINK}
    >
      <TextField
        value={email}
        onChange={(evt) => setEmail(evt?.target?.value)}
        variant="outlined"
        margin="normal"
        placeholder="Enter your Email Address"
        label="Email Address"
        fullWidth
        required
        focused
        sx={{ '.MuiOutlinedInput-input': { color: 'black' } }}
      />
    </UnauthenticatedFormContainer>
  );
};

ForgotPassword.propTypes = {
  changeFormType: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ForgotPassword;
