import { DEFAULT_EVERYWHERE } from 'constants/countriesList';
import { STRINGS } from 'constants/strings';

export const getAvailableInGeos = (availableInGeos) => {
  if (!availableInGeos) return [];

  const [_country] = availableInGeos;

  return _country === DEFAULT_EVERYWHERE ? [] : availableInGeos;
};

export const getAvailableInGeosPayload = (availableInGeos) => {
  return availableInGeos.length === 0 ? [DEFAULT_EVERYWHERE] : availableInGeos;
};

export const convertGeoArrayToText = (availableInGeos) => {
  if (!availableInGeos) return '';

  if (availableInGeos.length === 0 || availableInGeos[0] === DEFAULT_EVERYWHERE) {
    return STRINGS.WORLDWIDE;
  }

  return availableInGeos.join(', ');
};
