import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { STRINGS } from 'constants/strings';
import useDesktopMobile from 'hooks/useDesktopMobile';
import podOneLogo from '@images/PodcastOne_Logo_mono.svg';
import TIPNetworkLogo from '@images/TIP-Network---Logo_mono.svg';
import PodglomerateLogo from '@images/Podglomerate.svg';
import TwitterLogo from '@images/twitter.svg';
import LinkedinLogo from '@images/linkedin.svg';
import Spacer from '@shared/spacer';
import {
  TAP_PRIVACY_POLICY_LINK,
  TAP_TERMS_OF_USE_LINK,
  TAP_TGM_LINK,
} from 'constants/analytics';
import { useLocation } from 'react-router-dom';
import { record } from '@utils/analytics';
import {
  StyledFooter,
  StyledPartnersWrapper,
  StyledPartnersLabel,
  StyledPartnersBox,
  StyledFooterCopyright,
  StyledSocialMediaWrapper,
  StyledSocialMediaLabel,
  StyledSocialMediaImg,
  StyledMailToA,
} from './styles';

const Footer = ({ showPartners }) => {
  const { pathname } = useLocation();
  const { renderDesktopComponent, renderMobileComponent } = useDesktopMobile();

  const handleClick = (analyticsName, route) => () => {
    record(analyticsName, { pathname });
    window.open(route, '_blank');
  };

  const renderPartners = useCallback(() => {
    if (!showPartners) return <></>;

    return (
      <>
        <StyledPartnersWrapper>
          <StyledPartnersLabel>{STRINGS.OUR_PARTNERS}</StyledPartnersLabel>
          <Spacer size={20} />

          <StyledPartnersBox>
            <img src={PodglomerateLogo} alt="InvestorsPodcast" width={148} height={59} />

            {renderDesktopComponent(() => (
              <Spacer size={60} />
            ))}
            {renderMobileComponent(() => (
              <Spacer size={40} />
            ))}
            <img src={podOneLogo} alt="PodcastOne" width={88} height={48} />
            {renderDesktopComponent(() => (
              <Spacer size={60} />
            ))}
            {renderMobileComponent(() => (
              <Spacer size={40} />
            ))}
            <img src={TIPNetworkLogo} alt="InvestorsPodcast" width={148} height={46} />
          </StyledPartnersBox>
        </StyledPartnersWrapper>

        <Spacer size={51} />
      </>
    );
  }, [showPartners]);

  const renderSocialMedia = useCallback(() => {
    return (
      <StyledSocialMediaWrapper>
        <a href={STRINGS.TWITTER_LINK} target="_blank" rel="noreferrer">
          <StyledSocialMediaImg src={TwitterLogo} alt="TwitterLogo" />
        </a>
        <a href={STRINGS.LINKEDIN_LINK} target="_blank" rel="noreferrer">
          <StyledSocialMediaImg src={LinkedinLogo} alt="LinkedinLogo" />
        </a>
        <StyledMailToA
          href={`mailto:${STRINGS.SUPPORT_EMAIL}`}
          target="_blank"
          rel="noreferrer"
        >
          <StyledSocialMediaLabel>{STRINGS.SUPPORT_EMAIL}</StyledSocialMediaLabel>
        </StyledMailToA>
      </StyledSocialMediaWrapper>
    );
  }, []);

  return (
    <StyledFooter>
      {renderPartners()}
      {renderSocialMedia()}

      <StyledFooterCopyright>
        <span>© {new Date().getFullYear()}</span>{' '}
        <span onClick={handleClick(TAP_TGM_LINK, STRINGS.TGM_LINK)} className="__link">
          Tree Goat Media, Inc.{' '}
        </span>
        <span>All rights reserved.</span>{' '}
        {renderDesktopComponent(() => (
          <span>|</span>
        ))}
        {renderMobileComponent(() => (
          <br />
        ))}{' '}
        <span
          onClick={handleClick(TAP_PRIVACY_POLICY_LINK, '/policy')}
          className="__link"
        >
          Privacy Policy
        </span>{' '}
        <span>|</span>{' '}
        <span onClick={handleClick(TAP_TERMS_OF_USE_LINK, '/terms')} className="__link">
          Terms of Use
        </span>{' '}
      </StyledFooterCopyright>
    </StyledFooter>
  );
};

Footer.propTypes = {
  showPartners: PropTypes.bool,
};

Footer.defaultProps = {
  showPartners: true,
};

export default Footer;
