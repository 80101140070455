export const createTranscriptReviewRequest = /* GraphQL */ `
  mutation createTranscriptReviewRequest(
    $comment: String
    $episodeId: String!
    $textPositionEnd: Int
    $textPositionStart: Int
    $transcriptFragment: String!
  ) {
    response: MPCCreateTranscriptReviewRequest(
      comment: $comment
      episodeId: $episodeId
      textPositionEnd: $textPositionEnd
      textPositionStart: $textPositionStart
      transcriptFragment: $transcriptFragment
    )
  }
`;

export const resolveTranscriptReviewRequest = /* GraphQL */ `
  mutation resolveTranscriptReview($transcriptReviewId: String!) {
    resolveResponse: MPCResolveReOpenTranscriptReview(
      transcriptReviewId: $transcriptReviewId
    ) {
      PK
      SK
      status
      transcriptFragment
      requesterId
      createdAt
    }
  }
`;

export const cancelTranscriptReviewRequest = /* GraphQL */ `
  mutation cancelTranscriptReview($transcriptIds: [String]) {
    cancelResponse: MPCCancelTranscriptReviewRequest(transcriptIds: $transcriptIds)
  }
`;
