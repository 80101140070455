import React, { useCallback, useMemo } from 'react';
import { Button, Box } from '@mui/material';
import { STRINGS } from 'constants/strings';
import EditIcon from '@mui/icons-material/Edit';
import { ArrowBackTitle, MetaInfo } from '@components/index';
import { dateToMMDoYYYY } from '@utils/dateFormat';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { MPC_ROUTES } from 'constants/routing';
import { MPC_USER } from 'constants/idPrefixes';
import { getUserRoleLabel } from '@utils/roles';

const AccountUserDetail = ({ user, onClickEdit = () => null }) => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const userInfo = useMemo(() => {
    if (!user) {
      return [];
    }

    return [
      { label: STRINGS.FULLNAME, value: user.name, md: 6, xs: 12 },
      { label: STRINGS.EMAIL, value: user.email, md: 6, xs: 12 },
      { label: STRINGS.INVITED_BY, value: user.invitedBy || '', md: 6, xs: 12 },
      {
        label: STRINGS.JOINED_DATE,
        value: dateToMMDoYYYY(user.joinedDate),
        md: 6,
        xs: 12,
      },
      {
        label: STRINGS.ROLE,
        value: getUserRoleLabel(user.role),
        md: 6,
        xs: 12,
      },
    ];
  }, [user]);

  const handleProfileClick = useCallback(
    () =>
      history.push(`${url}${MPC_ROUTES.PROFILE}/${user.userId.replace(MPC_USER, '')}`),
    [history, user, url]
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 4,
        }}
      >
        <ArrowBackTitle>{STRINGS.USER_ACCOUNT}</ArrowBackTitle>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            sx={{ mr: 2 }}
            onClick={onClickEdit}
            variant="outlined"
            color="secondary"
          >
            {STRINGS.EDIT}
            <EditIcon sx={{ ml: 1, fontSize: 18 }} />
          </Button>
          <Button onClick={handleProfileClick} variant="contained" color="error">
            {STRINGS.VIEW_USER_PROFILE}
          </Button>
        </Box>
      </Box>
      <MetaInfo items={userInfo} />
    </>
  );
};

export default AccountUserDetail;
