export const ACCOUNT_TYPES = {
  TGM: 'TGM',
  PODCASTER: 'PODCASTER',
  PODCAST_NETWORK: 'PODCAST_NETWORK',
};

export const USER_ACCOUNT_TYPES = [
  { PK: 'TGM', name: 'TGM' },
  { PK: 'PODCASTER', name: 'PODCASTER' },
  { PK: 'PODCAST_NETWORK', name: 'PODCAST NETWORK' },
];
