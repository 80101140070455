export const updateNetwork = /* GraphQL */ `
  mutation updateNetwork(
    $networkId: String!
    $availableInGeos: [Alpha2Codes]
    $contentAgreementType: ContentAgreementType
    $description: String
    $isActivated: Boolean
    $entity: String
    $entityType: LegalEntityType
    $podcastNetworkName: String
  ) {
    updatePodcastNetwork(
      input: {
        podcastNetworkId: $networkId
        availableInGeos: $availableInGeos
        contentAgreementType: $contentAgreementType
        description: $description
        isActivated: $isActivated
        legalEntityName: $entity
        legalEntityType: $entityType
        podcastNetworkName: $podcastNetworkName
      }
    ) {
      PK
    }
  }
`;
