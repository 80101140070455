import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  IconButton,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonLink } from '@components/index';
import { StyledAuthForm } from '@shared/styledAuthForm';
import { record } from '@utils/analytics';
import Spacer from '@shared/spacer';
import { FONT_FAMILIES } from 'constants/stylesVariables';

const UnauthenticatedFormContainer = ({
  children,
  onSubmit,
  onClose,
  titleMessage,
  title,
  submitBtnLabel,
  submitBtnColor,
  submitBtnWidth,
  loading,
  contentContainerSpacing,
  footerMainText,
  footerText,
  mainLinkText,
  mainLinkRoute,
  mainLinkRecord,
  secondaryLinkText,
  secondaryLinkRoute,
  secondaryLinkRecord,
  cancelBtnLabel,
  onCancel,
  maxWidth,
}) => {
  const hasCancelBtn = useMemo(
    () => cancelBtnLabel && onCancel,
    [cancelBtnLabel, onCancel]
  );

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    onSubmit();
  };

  const renderLoadingButton = useCallback(() => {
    return !loading ? (
      <Button
        sx={{
          '&.MuiButton-root': {
            color: 'white',
            fontFamily: FONT_FAMILIES.proximaNovaBlack,
            fontSize: '1.1rem',
            mr: hasCancelBtn ? 2 : 0,
            width: submitBtnWidth,
            backgroundColor: 'black',
            borderRadius: 5,
            textTransform: 'none',
          },
        }}
        type="submit"
        color={submitBtnColor}
        variant="contained"
      >
        {submitBtnLabel}
      </Button>
    ) : (
      <CircularProgress />
    );
  }, [loading]);

  const renderLinks = (onClick, label, text = '', recordKey = '') => {
    if (!onClick && !label) return <></>;

    const onClickRoute = () => {
      if (recordKey) record(recordKey);

      onClick();
    };

    return (
      <Typography color="primaryDark" sx={{ textAlign: 'center', mt: 2 }} variant="body1">
        {`${text} `}{' '}
        <ButtonLink isLandingPage={false} onClick={onClickRoute}>
          {label}
        </ButtonLink>
      </Typography>
    );
  };

  const renderCancelBtn = () => {
    if (!hasCancelBtn) return <></>;

    return (
      <Button
        sx={{
          '&.MuiButton-root': {
            color: 'black',
            fontFamily: FONT_FAMILIES.proximaNovaBlack,
            fontSize: '1.1rem',
            textTransform: 'none',
          },
        }}
        color={submitBtnColor}
        variant="text"
        onClick={onCancel}
      >
        {cancelBtnLabel}
      </Button>
    );
  };

  return (
    <StyledAuthForm onSubmit={handleSubmit} maxWidth={maxWidth}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <IconButton sx={{ padding: 0 }} onClick={onClose}>
          <CloseIcon sx={{ color: 'black', cursor: 'pointer' }} />
        </IconButton>
      </Box>
      <Typography
        color="primaryDark"
        variant="h5"
        align="left"
        sx={{ fontFamily: FONT_FAMILIES.proximaNovaBlack, marginBottom: 2 }}
      >
        {title}
      </Typography>
      {titleMessage ? (
        <>
          <Typography display="block" color="primaryDark" variant="string" align="left">
            {titleMessage}
          </Typography>
          <Spacer size={15} />
        </>
      ) : null}
      <Grid container spacing={contentContainerSpacing}>
        {children}
      </Grid>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          mt: 4,
        }}
      >
        {renderLoadingButton()}
        {renderCancelBtn()}
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        {footerMainText && (
          <Typography
            color="primaryDark"
            sx={{
              fontSize: '0.9rem',
              fontWeight: 600,
              mt: 4,
              mb: 2,
              textAlign: 'left',
            }}
            variant="body1"
          >
            {footerMainText}
          </Typography>
        )}
        {renderLinks(mainLinkRoute, mainLinkText, footerText, mainLinkRecord)}
        {renderLinks(secondaryLinkRoute, secondaryLinkText, '', secondaryLinkRecord)}
      </Box>
    </StyledAuthForm>
  );
};

UnauthenticatedFormContainer.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  submitBtnLabel: PropTypes.string.isRequired,
  submitBtnColor: PropTypes.string,
  submitBtnWidth: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  contentContainerSpacing: PropTypes.number,
  footerMainText: PropTypes.string,
  footerText: PropTypes.string,
  mainLinkText: PropTypes.string,
  mainLinkRoute: PropTypes.function,
  mainLinkRecord: PropTypes.string,
  secondaryLinkText: PropTypes.string,
  secondaryLinkRoute: PropTypes.function,
  secondaryLinkRecord: PropTypes.string,
  cancelBtnLabel: PropTypes.string,
  onCancel: PropTypes.func,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

UnauthenticatedFormContainer.defaultProps = {
  submitBtnColor: 'success',
  submitBtnWidth: 'auto',
  contentContainerSpacing: 0,
  footerMainText: '',
  footerText: '',
  mainLinkText: '',
  mainLinkRoute: () => {},
  mainLinkRecord: '',
  secondaryLinkText: '',
  secondaryLinkRoute: () => {},
  secondaryLinkRecord: '',
  cancelBtnLabel: '',
  onCancel: null,
  maxWidth: 400,
  width: '',
};

export default UnauthenticatedFormContainer;
