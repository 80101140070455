import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { STRINGS } from 'constants/strings';
import RevisionItemDetails from '@components/revisionItemDetails/index';
import { dateToMMDoYYYY } from '@utils/dateFormat';
import { useParams } from 'react-router-dom';
import { requestAPIGraphQL } from '@services/appSyncAPI';
import { getReportById } from '@graphql/queries/reports';
import { REPORT } from 'constants/idPrefixes';
import { showPromiseError } from '@utils/promiseError';
import { getReportContact } from '@utils/reportContact';
import { REPORT_STATUS } from 'constants/reportStatus';
import { toggleMarbylStatus } from '@graphql/mutations/marbyls';
import { updateReportStatus } from '@graphql/mutations/reports';

const ReportDetail = () => {
  const [report, setReport] = useState(null);
  const { id } = useParams();

  const reportData = useMemo(() => {
    if (!report) return [];

    return [
      {
        label: STRINGS.DATE_CREATED,
        value: dateToMMDoYYYY(report.createdAt),
        md: 3,
        xs: 12,
      },
      {
        label: STRINGS.REPORT_TYPE,
        value: report.reportReason || '',
        md: 3,
        xs: 12,
      },
      {
        label: 'Marbyl Id',
        value: report.marbylId,
        md: 3,
        xs: 12,
      },
      {
        label: STRINGS.CONTACT,
        value: getReportContact(report.email, report.phoneNumber),
        md: 3,
        xs: 12,
      },
      {
        label: STRINGS.MARBYL_TEXT,
        value: report.marbylText,
        md: 12,
        xs: 12,
      },
      {
        label: STRINGS.DETAILS,
        value: report.reportReasonDetail,
        md: 12,
        xs: 12,
      },
    ];
  }, [report]);

  const fetchReport = useCallback(
    () =>
      requestAPIGraphQL(getReportById, { reportId: `${REPORT}${id}` })
        .then(({ data: { reportRes } }) => setReport(reportRes))
        .catch(showPromiseError),
    [id]
  );

  const toggleMarbyl = useCallback(
    () =>
      requestAPIGraphQL(toggleMarbylStatus, { marbylId: report.marbylId })
        .then(() => fetchReport())
        .catch(showPromiseError),
    [report, fetchReport]
  );

  const toggleReportStatus = useCallback(
    () =>
      requestAPIGraphQL(updateReportStatus, { reportId: report.reportId })
        .then(() => fetchReport())
        .catch(showPromiseError),
    [report, fetchReport]
  );

  const reportButtonState = useMemo(() => {
    if (!report) return {};

    if (report.status === REPORT_STATUS.OPEN) {
      return {
        color: 'success',
        children: 'Resolve report',
      };
    }

    return {
      color: 'warning',
      children: 'Reopen report',
    };
  }, [report]);

  const marbylButtonState = useMemo(() => {
    if (!report) return {};

    if (report.marbylStatus) {
      return {
        color: 'error',
        children: 'Deactivate Marbyl',
      };
    }

    return {
      color: 'success',
      children: 'Activate Marbyl',
    };
  }, [report]);

  useEffect(() => Promise.all([fetchReport()]), [fetchReport]);

  return (
    <RevisionItemDetails
      title={STRINGS.CONTENT_REPORT}
      metaData={reportData}
      isLoading={!report}
      cancelBtn={marbylButtonState}
      acceptBtn={reportButtonState}
      onClickCancel={toggleMarbyl}
      onClickAccept={toggleReportStatus}
    />
  );
};

export default ReportDetail;
