import { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { createContext } from 'react';
import { Auth } from 'aws-amplify';
import { AuthState } from '@aws-amplify/ui-components';
// import { updateEndpoint } from "@utils/analytics";
import { ROLE_ACTIONS } from 'constants/userGroups';
import { requestAPIGraphQL } from '@services/appSyncAPI';
import { getUserDefaultAccount } from '@graphql/queries/mpc';

const AUTH_STATE = 'auth-state';
const AUTH_DATA = 'auth-data';

const TEMP_CREDENTIALS = 'temp-credentials';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState(localStorage.getItem(AUTH_STATE) || null);
  const [user, setUser] = useState(
    localStorage.getItem(AUTH_DATA) ? JSON.parse(localStorage.getItem(AUTH_DATA)) : null
  );

  const loading = useMemo(() => authState === AuthState.Loading, [authState]);

  const authed = useMemo(
    () => authState === AuthState.SignedIn && !!user,
    [authState, user]
  );

  const permissions = useMemo(() => {
    if (!user) return {};

    const {
      signInUserSession: {
        accessToken: {
          payload: { 'cognito:groups': _groups },
        },
      },
    } = user;

    const actions = [];

    _groups.forEach((group) => {
      const _actions = ROLE_ACTIONS[group];
      if (_actions) actions.push(..._actions);
    });

    if (!actions) return {};

    return actions.reduce((obj, val) => ({ ...obj, [val]: true }), {});
  }, [user]);

  const getUserDefaultAccountInfo = useCallback(
    (email) =>
      new Promise((resolve, reject) => {
        requestAPIGraphQL(getUserDefaultAccount, { email })
          .then(({ data: { MPCGetUserDefaultAccountInformation } }) => {
            resolve(MPCGetUserDefaultAccountInformation);
          })
          .catch((err) => reject(err));
      }),
    []
  );

  const _signIn = useCallback(
    async (username, password) => {
      let authData = await Auth.signIn(username, password);
      const userAccountInfo = await getUserDefaultAccountInfo(username);

      authData = { ...authData, userAccount: userAccountInfo };

      localStorage.setItem(AUTH_STATE, AuthState.SignedIn);
      localStorage.setItem(AUTH_DATA, JSON.stringify(authData));

      return authData;
    },
    [getUserDefaultAccountInfo]
  );

  const login = useCallback(
    async (username, password) => {
      try {
        setAuthState(AuthState.Loading);
        const authData = await _signIn(username, password);

        setAuthState(AuthState.SignedIn);
        setUser(authData);
      } catch (error) {
        if (error.code === 'UserNotConfirmedException') {
          setAuthState(AuthState.ConfirmSignUp);

          localStorage.setItem(TEMP_CREDENTIALS, JSON.stringify({ username, password }));
          return;
        }

        setAuthState(null);
        throw error;
      }
    },
    [_signIn]
  );

  const register = useCallback(
    async (username, password, firstName, lastName, phoneNumber, verification) => {
      try {
        setAuthState(AuthState.Loading);

        await Auth.signUp({
          username,
          password,
          attributes: {
            given_name: firstName,
            family_name: lastName,
            phone_number: phoneNumber,
            'custom:verification_token': verification,
          },
        });

        localStorage.setItem(TEMP_CREDENTIALS, JSON.stringify({ username, password }));

        setAuthState(AuthState.ConfirmSignUp);
      } catch (error) {
        setAuthState(null);
        throw error;
      }
    },
    []
  );

  const confirmSignUp = useCallback(
    async (code) => {
      try {
        setAuthState(AuthState.Loading);
        const tempCredentials = JSON.parse(
          localStorage.getItem(TEMP_CREDENTIALS) || null
        );

        if (!tempCredentials) throw new Error('No credentials found.');

        await Auth.confirmSignUp(tempCredentials.username, code);

        const { username, password } = tempCredentials;

        localStorage.clear(TEMP_CREDENTIALS);
        const authData = await _signIn(username, password);

        setAuthState(AuthState.SignedIn);
        setUser(authData);
      } catch (error) {
        setAuthState(null);
        throw error;
      }
    },
    [_signIn]
  );

  const logout = useCallback(
    () =>
      new Promise((resolve) => {
        Auth.signOut().then(() => {
          setAuthState(null);
          setUser(null);

          localStorage.clear(TEMP_CREDENTIALS);
          localStorage.clear(AUTH_STATE);
          localStorage.clear(AUTH_DATA);

          resolve();
        });
      }),
    []
  );

  const resendCode = useCallback(async () => {
    try {
      const tempCredentials = JSON.parse(localStorage.getItem(TEMP_CREDENTIALS) || null);

      if (!tempCredentials) throw new Error('No credentials found.');

      setAuthState(AuthState.Loading);
      const { username } = tempCredentials;
      await Auth.resendSignUp(username);

      setAuthState(null);
    } catch (error) {
      setAuthState(null);
      throw error;
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authed,
        authState,
        login,
        register,
        confirmSignUp,
        logout,
        resendCode,
        loading,
        user,
        permissions,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
