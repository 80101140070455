export const FONT_FAMILIES = {
  mallory: 'Mallory',
  malloryMP: 'Mallory MP',
  proximaNovaRegular: 'proxima_nova_regular',
  proximaNovaBlack: 'proxima_nova_black',
  proximaNovaBold: 'proxima_nova_bold',
  proximaNovaSemibold: 'proxima_nova_semibold',
};

export const HEX_COLORS = {
  black: '#000',
  white: '#FFFFFF',
  americanSilver: '#D0D0D0',
  brightYellow: '#ffa726',
  blueBerry: '#5573F6',
  blueBolt: '#03BCF4',
  buttonBlue: '#29b6f6',
  celticBlue: '#2F5CCE',
  darkJungleGreen: '#161F22',
  dimGray: '#68686e',
  electricCrimson: '#FD003D',
  lightGray: '#D2D2D2',
  malachite: '#00F453',
  mantis: '#66bb6a',
  turquoise: '#18B3C5',
  blue: '#4999D4',
  blue2: '#4666B0',
  pink: '#680CFF',
  purple: '#950CFF',
  purple2: '#9862DB',
  purple3: '#C14AD5',
  purple4: '#7535FF',
  silverSand: '#c3c3c3',
};

export const RGB_COLORS = {
  black: 'rgba(255, 255, 255, 0.8)',
};
