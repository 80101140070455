export const M11N_AUTO_TYPES = {
  DISABLED: 'disabled',
  AUTO_LATEST_AND_NEW_ONLY: 'auto_latest_and_new_only',
  AUTO_ALL: 'auto_all',
  MANUAL: 'manual',
};

export const FEED_INGESTION_STATUS = {
  pending: {
    key: 'pending',
    label: 'Pending',
    color: 'secondary',
  },
  ready: {
    key: 'ready',
    label: 'Ready',
    color: 'success',
  },
  processing: {
    key: 'processing',
    label: 'Processing',
    color: 'warning',
  },
  failing: {
    key: 'failing',
    label: 'Failing',
    color: 'error',
  },
};

export const M11N_AUTO_TYPE = {
  [M11N_AUTO_TYPES.DISABLED]: {
    label: 'Disabled',
    color: 'error',
  },
  [M11N_AUTO_TYPES.AUTO_LATEST_AND_NEW_ONLY]: {
    label: 'Auto-Latest and New Only',
    color: 'success',
  },
  [M11N_AUTO_TYPES.AUTO_ALL]: {
    label: 'Auto-All',
    color: 'success',
  },
  [M11N_AUTO_TYPES.MANUAL]: {
    label: 'Manual',
    color: 'warning',
  },
};

export const M11N_EPISODE_PROCESSING_STATUS = {
  NULL: {
    label: 'Pending',
    color: 'secondary',
  },
  QUEUED: {
    label: 'Queued',
    color: 'info',
  },
  COMPLETED: {
    label: 'Completed',
    color: 'success',
  },
  FAILED: {
    label: 'Failed',
    color: 'error',
  },
};

export const LAST_INGESTION_RESULTS = {
  success: 'Success',
  failure: 'Failure',
};
