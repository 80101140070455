import { record } from '@utils/analytics';
import { useAddYourPodcast } from 'contexts/AddYourPodcastContext';
import { useCallback } from 'react';

const useRecordAnalyticsPodcast = () => {
  const {
    podcastState: [podcast],
  } = useAddYourPodcast();

  const recordAnalyticsPodcast = useCallback(
    (event, attributes = null, metrics = null) => {
      return record(
        event,
        !!podcast
          ? {
              podcastName: podcast.title,
              podcastFeedUrl: podcast.feedUrl,
              ...attributes,
            }
          : attributes,
        metrics
      );
    },
    [podcast]
  );

  return { recordAnalyticsPodcast };
};

export default useRecordAnalyticsPodcast;
