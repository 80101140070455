import { Grid } from '@mui/material';
import styled from '@mui/styled-engine';

export const ListContainer = styled('div')(
  () => `
  max-height: 300px;
  overflow: auto;
  background-color: white;
  color: black;
`
);

export const ClearFiltersContainer = styled(Grid)(
  ({ theme }) => `
    height: auto;
    padding-bottom: 0;
    justify-content: flex-end;
    margin-bottom: 0;
    padding-top: ${theme.spacing(2)};

    @media (min-width: ${theme.breakpoints.values.lg}px) {
      height: 50%;
      padding-top: ${theme.spacing(3.5)};
    }
  `
);

export const colorStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'transparent',
  }),
  singleValue: (styles, state) => ({
    ...styles,
    color: 'black',
    opacity: state.isDisabled ? 0.5 : 1,
  }),
  placeholder: (styles) => ({ ...styles, color: 'black' }),
  valueContainer: (styles, state) => ({
    ...styles,
    opacity: state.isDisabled ? 0.5 : 1,
  }),
  indicatorsContainer: (styles, state) => ({
    ...styles,
    opacity: state.isDisabled ? 0.5 : 1,
  }),
};
