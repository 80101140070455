export const COUNTRIES = [
  {
    Name: 'United States',
    Code: 'US',
    Dial: '+1',
  },
  {
    Name: 'Afghanistan',
    Code: 'AF',
    Dial: '+93',
  },
  {
    Name: 'Aland Islands',
    Code: 'AX',
    Dial: '+358',
  },
  {
    Name: 'Albania',
    Code: 'AL',
    Dial: '+355',
  },
  {
    Name: 'Algeria',
    Code: 'DZ',
    Dial: '+213',
  },
  {
    Name: 'AmericanSamoa',
    Code: 'AS',
    Dial: '+1684',
  },
  {
    Name: 'Andorra',
    Code: 'AD',
    Dial: '+376',
  },
  {
    Name: 'Angola',
    Code: 'AO',
    Dial: '+244',
  },
  {
    Name: 'Anguilla',
    Code: 'AI',
    Dial: '+1264',
  },
  {
    Name: 'Antarctica',
    Code: 'AQ',
    Dial: '+672',
  },
  {
    Name: 'Antigua and Barbuda',
    Code: 'AG',
    Dial: '+1268',
  },
  {
    Name: 'Argentina',
    Code: 'AR',
    Dial: '+54',
  },
  {
    Name: 'Armenia',
    Code: 'AM',
    Dial: '+374',
  },
  {
    Name: 'Aruba',
    Code: 'AW',
    Dial: '+297',
  },
  {
    Name: 'Australia',
    Code: 'AU',
    Dial: '+61',
  },
  {
    Name: 'Austria',
    Code: 'AT',
    Dial: '+43',
  },
  {
    Name: 'Azerbaijan',
    Code: 'AZ',
    Dial: '+994',
  },
  {
    Name: 'Bahamas',
    Code: 'BS',
    Dial: '+1242',
  },
  {
    Name: 'Bahrain',
    Code: 'BH',
    Dial: '+973',
  },
  {
    Name: 'Bangladesh',
    Code: 'BD',
    Dial: '+880',
  },
  {
    Name: 'Barbados',
    Code: 'BB',
    Dial: '+1246',
  },
  {
    Name: 'Belarus',
    Code: 'BY',
    Dial: '+375',
  },
  {
    Name: 'Belgium',
    Code: 'BE',
    Dial: '+32',
  },
  {
    Name: 'Belize',
    Code: 'BZ',
    Dial: '+501',
  },
  {
    Name: 'Benin',
    Code: 'BJ',
    Dial: '+229',
  },
  {
    Name: 'Bermuda',
    Code: 'BM',
    Dial: '+1441',
  },
  {
    Name: 'Bhutan',
    Code: 'BT',
    Dial: '+975',
  },
  {
    Name: 'Bolivia, Plurinational State of',
    Code: 'BO',
    Dial: '+591',
  },
  {
    Name: 'Bosnia and Herzegovina',
    Code: 'BA',
    Dial: '+387',
  },
  {
    Name: 'Botswana',
    Code: 'BW',
    Dial: '+267',
  },
  {
    Name: 'Brazil',
    Code: 'BR',
    Dial: '+55',
  },
  {
    Name: 'British Indian Ocean Territory',
    Code: 'IO',
    Dial: '+246',
  },
  {
    Name: 'Brunei Darussalam',
    Code: 'BN',
    Dial: '+673',
  },
  {
    Name: 'Bulgaria',
    Code: 'BG',
    Dial: '+359',
  },
  {
    Name: 'Burkina Faso',
    Code: 'BF',
    Dial: '+226',
  },
  {
    Name: 'Burundi',
    Code: 'BI',
    Dial: '+257',
  },
  {
    Name: 'Cambodia',
    Code: 'KH',
    Dial: '+855',
  },
  {
    Name: 'Cameroon',
    Code: 'CM',
    Dial: '+237',
  },
  {
    Name: 'Cape Verde',
    Code: 'CV',
    Dial: '+238',
  },
  {
    Name: 'Cayman Islands',
    Code: 'KY',
    Dial: '+ 345',
  },
  {
    Name: 'Central African Republic',
    Code: 'CF',
    Dial: '+236',
  },
  {
    Name: 'Chad',
    Code: 'TD',
    Dial: '+235',
  },
  {
    Name: 'Chile',
    Code: 'CL',
    Dial: '+56',
  },
  {
    Name: 'China',
    Code: 'CN',
    Dial: '+86',
  },
  {
    Name: 'Christmas Island',
    Code: 'CX',
    Dial: '+61',
  },
  {
    Name: 'Cocos (Keeling) Islands',
    Code: 'CC',
    Dial: '+61',
  },
  {
    Name: 'Colombia',
    Code: 'CO',
    Dial: '+57',
  },
  {
    Name: 'Comoros',
    Code: 'KM',
    Dial: '+269',
  },
  {
    Name: 'Congo',
    Code: 'CG',
    Dial: '+242',
  },
  {
    Name: 'Congo, The Democratic Republic of the Congo',
    Code: 'CD',
    Dial: '+243',
  },
  {
    Name: 'Cook Islands',
    Code: 'CK',
    Dial: '+682',
  },
  {
    Name: 'Costa Rica',
    Code: 'CR',
    Dial: '+506',
  },
  {
    Name: "Cote d'Ivoire",
    Code: 'CI',
    Dial: '+225',
  },
  {
    Name: 'Croatia',
    Code: 'HR',
    Dial: '+385',
  },
  {
    Name: 'Cuba',
    Code: 'CU',
    Dial: '+53',
  },
  {
    Name: 'Cyprus',
    Code: 'CY',
    Dial: '+357',
  },
  {
    Name: 'Czech Republic',
    Code: 'CZ',
    Dial: '+420',
  },
  {
    Name: 'Denmark',
    Code: 'DK',
    Dial: '+45',
  },
  {
    Name: 'Djibouti',
    Code: 'DJ',
    Dial: '+253',
  },
  {
    Name: 'Dominica',
    Code: 'DM',
    Dial: '+1767',
  },
  {
    Name: 'Dominican Republic',
    Code: 'DO',
    Dial: '+1849',
  },
  {
    Name: 'Ecuador',
    Code: 'EC',
    Dial: '+593',
  },
  {
    Name: 'Egypt',
    Code: 'EG',
    Dial: '+20',
  },
  {
    Name: 'El Salvador',
    Code: 'SV',
    Dial: '+503',
  },
  {
    Name: 'Equatorial Guinea',
    Code: 'GQ',
    Dial: '+240',
  },
  {
    Name: 'Eritrea',
    Code: 'ER',
    Dial: '+291',
  },
  {
    Name: 'Estonia',
    Code: 'EE',
    Dial: '+372',
  },
  {
    Name: 'Ethiopia',
    Code: 'ET',
    Dial: '+251',
  },
  {
    Name: 'Falkland Islands (Malvinas)',
    Code: 'FK',
    Dial: '+500',
  },
  {
    Name: 'Faroe Islands',
    Code: 'FO',
    Dial: '+298',
  },
  {
    Name: 'Fiji',
    Code: 'FJ',
    Dial: '+679',
  },
  {
    Name: 'Finland',
    Code: 'FI',
    Dial: '+358',
  },
  {
    Name: 'France',
    Code: 'FR',
    Dial: '+33',
  },
  {
    Name: 'French Guiana',
    Code: 'GF',
    Dial: '+594',
  },
  {
    Name: 'French Polynesia',
    Code: 'PF',
    Dial: '+689',
  },
  {
    Name: 'Gabon',
    Code: 'GA',
    Dial: '+241',
  },
  {
    Name: 'Gambia',
    Code: 'GM',
    Dial: '+220',
  },
  {
    Name: 'Georgia',
    Code: 'GE',
    Dial: '+995',
  },
  {
    Name: 'Germany',
    Code: 'DE',
    Dial: '+49',
  },
  {
    Name: 'Ghana',
    Code: 'GH',
    Dial: '+233',
  },
  {
    Name: 'Gibraltar',
    Code: 'GI',
    Dial: '+350',
  },
  {
    Name: 'Greece',
    Code: 'GR',
    Dial: '+30',
  },
  {
    Name: 'Greenland',
    Code: 'GL',
    Dial: '+299',
  },
  {
    Name: 'Grenada',
    Code: 'GD',
    Dial: '+1473',
  },
  {
    Name: 'Guadeloupe',
    Code: 'GP',
    Dial: '+590',
  },
  {
    Name: 'Guam',
    Code: 'GU',
    Dial: '+1671',
  },
  {
    Name: 'Guatemala',
    Code: 'GT',
    Dial: '+502',
  },
  {
    Name: 'Guernsey',
    Code: 'GG',
    Dial: '+44',
  },
  {
    Name: 'Guinea',
    Code: 'GN',
    Dial: '+224',
  },
  {
    Name: 'Guinea-Bissau',
    Code: 'GW',
    Dial: '+245',
  },
  {
    Name: 'Guyana',
    Code: 'GY',
    Dial: '+595',
  },
  {
    Name: 'Haiti',
    Code: 'HT',
    Dial: '+509',
  },
  {
    Name: 'Holy See (Vatican City State)',
    Code: 'VA',
    Dial: '+379',
  },
  {
    Name: 'Honduras',
    Code: 'HN',
    Dial: '+504',
  },
  {
    Name: 'Hong Kong',
    Code: 'HK',
    Dial: '+852',
  },
  {
    Name: 'Hungary',
    Code: 'HU',
    Dial: '+36',
  },
  {
    Name: 'Iceland',
    Code: 'IS',
    Dial: '+354',
  },
  {
    Name: 'India',
    Code: 'IN',
    Dial: '+91',
  },
  {
    Name: 'Indonesia',
    Code: 'ID',
    Dial: '+62',
  },
  {
    Name: 'Iran, Islamic Republic of Persian Gulf',
    Code: 'IR',
    Dial: '+98',
  },
  {
    Name: 'Iraq',
    Code: 'IQ',
    Dial: '+964',
  },
  {
    Name: 'Ireland',
    Code: 'IE',
    Dial: '+353',
  },
  {
    Name: 'Isle of Man',
    Code: 'IM',
    Dial: '+44',
  },
  {
    Name: 'Israel',
    Code: 'IL',
    Dial: '+972',
  },
  {
    Name: 'Italy',
    Code: 'IT',
    Dial: '+39',
  },
  {
    Name: 'Jamaica',
    Code: 'JM',
    Dial: '+1876',
  },
  {
    Name: 'Japan',
    Code: 'JP',
    Dial: '+81',
  },
  {
    Name: 'Jersey',
    Code: 'JE',
    Dial: '+44',
  },
  {
    Name: 'Jordan',
    Code: 'JO',
    Dial: '+962',
  },
  {
    Name: 'Kazakhstan',
    Code: 'KZ',
    Dial: '+77',
  },
  {
    Name: 'Kenya',
    Code: 'KE',
    Dial: '+254',
  },
  {
    Name: 'Kiribati',
    Code: 'KI',
    Dial: '+686',
  },
  {
    Name: "Korea, Democratic People's Republic of Korea",
    Code: 'KP',
    Dial: '+850',
  },
  {
    Name: 'Korea, Republic of South Korea',
    Code: 'KR',
    Dial: '+82',
  },
  {
    Name: 'Kuwait',
    Code: 'KW',
    Dial: '+965',
  },
  {
    Name: 'Kyrgyzstan',
    Code: 'KG',
    Dial: '+996',
  },
  {
    Name: 'Laos',
    Code: 'LA',
    Dial: '+856',
  },
  {
    Name: 'Latvia',
    Code: 'LV',
    Dial: '+371',
  },
  {
    Name: 'Lebanon',
    Code: 'LB',
    Dial: '+961',
  },
  {
    Name: 'Lesotho',
    Code: 'LS',
    Dial: '+266',
  },
  {
    Name: 'Liberia',
    Code: 'LR',
    Dial: '+231',
  },
  {
    Name: 'Libyan Arab Jamahiriya',
    Code: 'LY',
    Dial: '+218',
  },
  {
    Name: 'Liechtenstein',
    Code: 'LI',
    Dial: '+423',
  },
  {
    Name: 'Lithuania',
    Code: 'LT',
    Dial: '+370',
  },
  {
    Name: 'Luxembourg',
    Code: 'LU',
    Dial: '+352',
  },
  {
    Name: 'Macao',
    Code: 'MO',
    Dial: '+853',
  },
  {
    Name: 'Macedonia',
    Code: 'MK',
    Dial: '+389',
  },
  {
    Name: 'Madagascar',
    Code: 'MG',
    Dial: '+261',
  },
  {
    Name: 'Malawi',
    Code: 'MW',
    Dial: '+265',
  },
  {
    Name: 'Malaysia',
    Code: 'MY',
    Dial: '+60',
  },
  {
    Name: 'Maldives',
    Code: 'MV',
    Dial: '+960',
  },
  {
    Name: 'Mali',
    Code: 'ML',
    Dial: '+223',
  },
  {
    Name: 'Malta',
    Code: 'MT',
    Dial: '+356',
  },
  {
    Name: 'Marshall Islands',
    Code: 'MH',
    Dial: '+692',
  },
  {
    Name: 'Martinique',
    Code: 'MQ',
    Dial: '+596',
  },
  {
    Name: 'Mauritania',
    Code: 'MR',
    Dial: '+222',
  },
  {
    Name: 'Mauritius',
    Code: 'MU',
    Dial: '+230',
  },
  {
    Name: 'Mayotte',
    Code: 'YT',
    Dial: '+262',
  },
  {
    Name: 'Mexico',
    Code: 'MX',
    Dial: '+52',
  },
  {
    Name: 'Micronesia, Federated States of Micronesia',
    Code: 'FM',
    Dial: '+691',
  },
  {
    Name: 'Moldova',
    Code: 'MD',
    Dial: '+373',
  },
  {
    Name: 'Monaco',
    Code: 'MC',
    Dial: '+377',
  },
  {
    Name: 'Mongolia',
    Code: 'MN',
    Dial: '+976',
  },
  {
    Name: 'Montenegro',
    Code: 'ME',
    Dial: '+382',
  },
  {
    Name: 'Montserrat',
    Code: 'MS',
    Dial: '+1664',
  },
  {
    Name: 'Morocco',
    Code: 'MA',
    Dial: '+212',
  },
  {
    Name: 'Mozambique',
    Code: 'MZ',
    Dial: '+258',
  },
  {
    Name: 'Myanmar',
    Code: 'MM',
    Dial: '+95',
  },
  {
    Name: 'Namibia',
    Code: 'NA',
    Dial: '+264',
  },
  {
    Name: 'Nauru',
    Code: 'NR',
    Dial: '+674',
  },
  {
    Name: 'Nepal',
    Code: 'NP',
    Dial: '+977',
  },
  {
    Name: 'Netherlands',
    Code: 'NL',
    Dial: '+31',
  },
  {
    Name: 'Netherlands Antilles',
    Code: 'AN',
    Dial: '+599',
  },
  {
    Name: 'New Caledonia',
    Code: 'NC',
    Dial: '+687',
  },
  {
    Name: 'New Zealand',
    Code: 'NZ',
    Dial: '+64',
  },
  {
    Name: 'Nicaragua',
    Code: 'NI',
    Dial: '+505',
  },
  {
    Name: 'Niger',
    Code: 'NE',
    Dial: '+227',
  },
  {
    Name: 'Nigeria',
    Code: 'NG',
    Dial: '+234',
  },
  {
    Name: 'Niue',
    Code: 'NU',
    Dial: '+683',
  },
  {
    Name: 'Norfolk Island',
    Code: 'NF',
    Dial: '+672',
  },
  {
    Name: 'Northern Mariana Islands',
    Code: 'MP',
    Dial: '+1670',
  },
  {
    Name: 'Norway',
    Code: 'NO',
    Dial: '+47',
  },
  {
    Name: 'Oman',
    Code: 'OM',
    Dial: '+968',
  },
  {
    Name: 'Pakistan',
    Code: 'PK',
    Dial: '+92',
  },
  {
    Name: 'Palau',
    Code: 'PW',
    Dial: '+680',
  },
  {
    Name: 'Palestinian Territory, Occupied',
    Code: 'PS',
    Dial: '+970',
  },
  {
    Name: 'Panama',
    Code: 'PA',
    Dial: '+507',
  },
  {
    Name: 'Papua New Guinea',
    Code: 'PG',
    Dial: '+675',
  },
  {
    Name: 'Paraguay',
    Code: 'PY',
    Dial: '+595',
  },
  {
    Name: 'Peru',
    Code: 'PE',
    Dial: '+51',
  },
  {
    Name: 'Philippines',
    Code: 'PH',
    Dial: '+63',
  },
  {
    Name: 'Pitcairn',
    Code: 'PN',
    Dial: '+872',
  },
  {
    Name: 'Poland',
    Code: 'PL',
    Dial: '+48',
  },
  {
    Name: 'Portugal',
    Code: 'PT',
    Dial: '+351',
  },
  {
    Name: 'Puerto Rico',
    Code: 'PR',
    Dial: '+1939',
  },
  {
    Name: 'Qatar',
    Code: 'QA',
    Dial: '+974',
  },
  {
    Name: 'Romania',
    Code: 'RO',
    Dial: '+40',
  },
  {
    Name: 'Russia',
    Code: 'RU',
    Dial: '+7',
  },
  {
    Name: 'Rwanda',
    Code: 'RW',
    Dial: '+250',
  },
  {
    Name: 'Reunion',
    Code: 'RE',
    Dial: '+262',
  },
  {
    Name: 'Saint Barthelemy',
    Code: 'BL',
    Dial: '+590',
  },
  {
    Name: 'Saint Helena, Ascension and Tristan Da Cunha',
    Code: 'SH',
    Dial: '+290',
  },
  {
    Name: 'Saint Kitts and Nevis',
    Code: 'KN',
    Dial: '+1869',
  },
  {
    Name: 'Saint Lucia',
    Code: 'LC',
    Dial: '+1758',
  },
  {
    Name: 'Saint Martin',
    Code: 'MF',
    Dial: '+590',
  },
  {
    Name: 'Saint Pierre and Miquelon',
    Code: 'PM',
    Dial: '+508',
  },
  {
    Name: 'Saint Vincent and the Grenadines',
    Code: 'VC',
    Dial: '+1784',
  },
  {
    Name: 'Samoa',
    Code: 'WS',
    Dial: '+685',
  },
  {
    Name: 'San Marino',
    Code: 'SM',
    Dial: '+378',
  },
  {
    Name: 'Sao Tome and Principe',
    Code: 'ST',
    Dial: '+239',
  },
  {
    Name: 'Saudi Arabia',
    Code: 'SA',
    Dial: '+966',
  },
  {
    Name: 'Senegal',
    Code: 'SN',
    Dial: '+221',
  },
  {
    Name: 'Serbia',
    Code: 'RS',
    Dial: '+381',
  },
  {
    Name: 'Seychelles',
    Code: 'SC',
    Dial: '+248',
  },
  {
    Name: 'Sierra Leone',
    Code: 'SL',
    Dial: '+232',
  },
  {
    Name: 'Singapore',
    Code: 'SG',
    Dial: '+65',
  },
  {
    Name: 'Slovakia',
    Code: 'SK',
    Dial: '+421',
  },
  {
    Name: 'Slovenia',
    Code: 'SI',
    Dial: '+386',
  },
  {
    Name: 'Solomon Islands',
    Code: 'SB',
    Dial: '+677',
  },
  {
    Name: 'Somalia',
    Code: 'SO',
    Dial: '+252',
  },
  {
    Name: 'South Africa',
    Code: 'ZA',
    Dial: '+27',
  },
  {
    Name: 'South Sudan',
    Code: 'SS',
    Dial: '+211',
  },
  {
    Name: 'South Georgia and the South Sandwich Islands',
    Code: 'GS',
    Dial: '+500',
  },
  {
    Name: 'Spain',
    Code: 'ES',
    Dial: '+34',
  },
  {
    Name: 'Sri Lanka',
    Code: 'LK',
    Dial: '+94',
  },
  {
    Name: 'Sudan',
    Code: 'SD',
    Dial: '+249',
  },
  {
    Name: 'Suriname',
    Code: 'SR',
    Dial: '+597',
  },
  {
    Name: 'Svalbard and Jan Mayen',
    Code: 'SJ',
    Dial: '+47',
  },
  {
    Name: 'Swaziland',
    Code: 'SZ',
    Dial: '+268',
  },
  {
    Name: 'Sweden',
    Code: 'SE',
    Dial: '+46',
  },
  {
    Name: 'Switzerland',
    Code: 'CH',
    Dial: '+41',
  },
  {
    Name: 'Syrian Arab Republic',
    Code: 'SY',
    Dial: '+963',
  },
  {
    Name: 'Taiwan',
    Code: 'TW',
    Dial: '+886',
  },
  {
    Name: 'Tajikistan',
    Code: 'TJ',
    Dial: '+992',
  },
  {
    Name: 'Tanzania, United Republic of Tanzania',
    Code: 'TZ',
    Dial: '+255',
  },
  {
    Name: 'Thailand',
    Code: 'TH',
    Dial: '+66',
  },
  {
    Name: 'Timor-Leste',
    Code: 'TL',
    Dial: '+670',
  },
  {
    Name: 'Togo',
    Code: 'TG',
    Dial: '+228',
  },
  {
    Name: 'Tokelau',
    Code: 'TK',
    Dial: '+690',
  },
  {
    Name: 'Tonga',
    Code: 'TO',
    Dial: '+676',
  },
  {
    Name: 'Trinidad and Tobago',
    Code: 'TT',
    Dial: '+1868',
  },
  {
    Name: 'Tunisia',
    Code: 'TN',
    Dial: '+216',
  },
  {
    Name: 'Turkey',
    Code: 'TR',
    Dial: '+90',
  },
  {
    Name: 'Turkmenistan',
    Code: 'TM',
    Dial: '+993',
  },
  {
    Name: 'Turks and Caicos Islands',
    Code: 'TC',
    Dial: '+1649',
  },
  {
    Name: 'Tuvalu',
    Code: 'TV',
    Dial: '+688',
  },
  {
    Name: 'Uganda',
    Code: 'UG',
    Dial: '+256',
  },
  {
    Name: 'Ukraine',
    Code: 'UA',
    Dial: '+380',
  },
  {
    Name: 'United Arab Emirates',
    Code: 'AE',
    Dial: '+971',
  },
  {
    Name: 'United Kingdom',
    Code: 'GB',
    Dial: '+44',
  },
  {
    Name: 'Uruguay',
    Code: 'UY',
    Dial: '+598',
  },
  {
    Name: 'Uzbekistan',
    Code: 'UZ',
    Dial: '+998',
  },
  {
    Name: 'Vanuatu',
    Code: 'VU',
    Dial: '+678',
  },
  {
    Name: 'Venezuela, Bolivarian Republic of Venezuela',
    Code: 'VE',
    Dial: '+58',
  },
  {
    Name: 'Vietnam',
    Code: 'VN',
    Dial: '+84',
  },
  {
    Name: 'Virgin Islands, British',
    Code: 'VG',
    Dial: '+1284',
  },
  {
    Name: 'Virgin Islands, U.S.',
    Code: 'VI',
    Dial: '+1340',
  },
  {
    Name: 'Wallis and Futuna',
    Code: 'WF',
    Dial: '+681',
  },
  {
    Name: 'Yemen',
    Code: 'YE',
    Dial: '+967',
  },
  {
    Name: 'Zambia',
    Code: 'ZM',
    Dial: '+260',
  },
  {
    Name: 'Zimbabwe',
    Code: 'ZW',
    Dial: '+263',
  },
];

export const COUNTRIES_BY_CODE = {
  AF: {
    Name: 'Afghanistan',
    Code: 'AF',
    Dial: '+93',
  },
  AX: {
    Name: 'Aland Islands',
    Code: 'AX',
    Dial: '+358',
  },
  AL: {
    Name: 'Albania',
    Code: 'AL',
    Dial: '+355',
  },
  DZ: {
    Name: 'Algeria',
    Code: 'DZ',
    Dial: '+213',
  },
  AS: {
    Name: 'AmericanSamoa',
    Code: 'AS',
    Dial: '+1684',
  },
  AD: {
    Name: 'Andorra',
    Code: 'AD',
    Dial: '+376',
  },
  AO: {
    Name: 'Angola',
    Code: 'AO',
    Dial: '+244',
  },
  AI: {
    Name: 'Anguilla',
    Code: 'AI',
    Dial: '+1264',
  },
  AQ: {
    Name: 'Antarctica',
    Code: 'AQ',
    Dial: '+672',
  },
  AG: {
    Name: 'Antigua and Barbuda',
    Code: 'AG',
    Dial: '+1268',
  },
  AR: {
    Name: 'Argentina',
    Code: 'AR',
    Dial: '+54',
  },
  AM: {
    Name: 'Armenia',
    Code: 'AM',
    Dial: '+374',
  },
  AW: {
    Name: 'Aruba',
    Code: 'AW',
    Dial: '+297',
  },
  AU: {
    Name: 'Australia',
    Code: 'AU',
    Dial: '+61',
  },
  AT: {
    Name: 'Austria',
    Code: 'AT',
    Dial: '+43',
  },
  AZ: {
    Name: 'Azerbaijan',
    Code: 'AZ',
    Dial: '+994',
  },
  BS: {
    Name: 'Bahamas',
    Code: 'BS',
    Dial: '+1242',
  },
  BH: {
    Name: 'Bahrain',
    Code: 'BH',
    Dial: '+973',
  },
  BD: {
    Name: 'Bangladesh',
    Code: 'BD',
    Dial: '+880',
  },
  BB: {
    Name: 'Barbados',
    Code: 'BB',
    Dial: '+1246',
  },
  BY: {
    Name: 'Belarus',
    Code: 'BY',
    Dial: '+375',
  },
  BE: {
    Name: 'Belgium',
    Code: 'BE',
    Dial: '+32',
  },
  BZ: {
    Name: 'Belize',
    Code: 'BZ',
    Dial: '+501',
  },
  BJ: {
    Name: 'Benin',
    Code: 'BJ',
    Dial: '+229',
  },
  BM: {
    Name: 'Bermuda',
    Code: 'BM',
    Dial: '+1441',
  },
  BT: {
    Name: 'Bhutan',
    Code: 'BT',
    Dial: '+975',
  },
  BO: {
    Name: 'Bolivia, Plurinational State of',
    Code: 'BO',
    Dial: '+591',
  },
  BA: {
    Name: 'Bosnia and Herzegovina',
    Code: 'BA',
    Dial: '+387',
  },
  BW: {
    Name: 'Botswana',
    Code: 'BW',
    Dial: '+267',
  },
  BR: {
    Name: 'Brazil',
    Code: 'BR',
    Dial: '+55',
  },
  IO: {
    Name: 'British Indian Ocean Territory',
    Code: 'IO',
    Dial: '+246',
  },
  BN: {
    Name: 'Brunei Darussalam',
    Code: 'BN',
    Dial: '+673',
  },
  BG: {
    Name: 'Bulgaria',
    Code: 'BG',
    Dial: '+359',
  },
  BF: {
    Name: 'Burkina Faso',
    Code: 'BF',
    Dial: '+226',
  },
  BI: {
    Name: 'Burundi',
    Code: 'BI',
    Dial: '+257',
  },
  KH: {
    Name: 'Cambodia',
    Code: 'KH',
    Dial: '+855',
  },
  CM: {
    Name: 'Cameroon',
    Code: 'CM',
    Dial: '+237',
  },
  CA: {
    Name: 'Canada',
    Code: 'CA',
    Dial: '+1',
  },
  CV: {
    Name: 'Cape Verde',
    Code: 'CV',
    Dial: '+238',
  },
  KY: {
    Name: 'Cayman Islands',
    Code: 'KY',
    Dial: '+ 345',
  },
  CF: {
    Name: 'Central African Republic',
    Code: 'CF',
    Dial: '+236',
  },
  TD: {
    Name: 'Chad',
    Code: 'TD',
    Dial: '+235',
  },
  CL: {
    Name: 'Chile',
    Code: 'CL',
    Dial: '+56',
  },
  CN: {
    Name: 'China',
    Code: 'CN',
    Dial: '+86',
  },
  CX: {
    Name: 'Christmas Island',
    Code: 'CX',
    Dial: '+61',
  },
  CC: {
    Name: 'Cocos (Keeling) Islands',
    Code: 'CC',
    Dial: '+61',
  },
  CO: {
    Name: 'Colombia',
    Code: 'CO',
    Dial: '+57',
  },
  KM: {
    Name: 'Comoros',
    Code: 'KM',
    Dial: '+269',
  },
  CG: {
    Name: 'Congo',
    Code: 'CG',
    Dial: '+242',
  },
  CD: {
    Name: 'Congo, The Democratic Republic of the Congo',
    Code: 'CD',
    Dial: '+243',
  },
  CK: {
    Name: 'Cook Islands',
    Code: 'CK',
    Dial: '+682',
  },
  CR: {
    Name: 'Costa Rica',
    Code: 'CR',
    Dial: '+506',
  },
  CI: {
    Name: "Cote d'Ivoire",
    Code: 'CI',
    Dial: '+225',
  },
  HR: {
    Name: 'Croatia',
    Code: 'HR',
    Dial: '+385',
  },
  CU: {
    Name: 'Cuba',
    Code: 'CU',
    Dial: '+53',
  },
  CY: {
    Name: 'Cyprus',
    Code: 'CY',
    Dial: '+357',
  },
  CZ: {
    Name: 'Czech Republic',
    Code: 'CZ',
    Dial: '+420',
  },
  DK: {
    Name: 'Denmark',
    Code: 'DK',
    Dial: '+45',
  },
  DJ: {
    Name: 'Djibouti',
    Code: 'DJ',
    Dial: '+253',
  },
  DM: {
    Name: 'Dominica',
    Code: 'DM',
    Dial: '+1767',
  },
  DO: {
    Name: 'Dominican Republic',
    Code: 'DO',
    Dial: '+1849',
  },
  EC: {
    Name: 'Ecuador',
    Code: 'EC',
    Dial: '+593',
  },
  EG: {
    Name: 'Egypt',
    Code: 'EG',
    Dial: '+20',
  },
  SV: {
    Name: 'El Salvador',
    Code: 'SV',
    Dial: '+503',
  },
  GQ: {
    Name: 'Equatorial Guinea',
    Code: 'GQ',
    Dial: '+240',
  },
  ER: {
    Name: 'Eritrea',
    Code: 'ER',
    Dial: '+291',
  },
  EE: {
    Name: 'Estonia',
    Code: 'EE',
    Dial: '+372',
  },
  ET: {
    Name: 'Ethiopia',
    Code: 'ET',
    Dial: '+251',
  },
  FK: {
    Name: 'Falkland Islands (Malvinas)',
    Code: 'FK',
    Dial: '+500',
  },
  FO: {
    Name: 'Faroe Islands',
    Code: 'FO',
    Dial: '+298',
  },
  FJ: {
    Name: 'Fiji',
    Code: 'FJ',
    Dial: '+679',
  },
  FI: {
    Name: 'Finland',
    Code: 'FI',
    Dial: '+358',
  },
  FR: {
    Name: 'France',
    Code: 'FR',
    Dial: '+33',
  },
  GF: {
    Name: 'French Guiana',
    Code: 'GF',
    Dial: '+594',
  },
  PF: {
    Name: 'French Polynesia',
    Code: 'PF',
    Dial: '+689',
  },
  GA: {
    Name: 'Gabon',
    Code: 'GA',
    Dial: '+241',
  },
  GM: {
    Name: 'Gambia',
    Code: 'GM',
    Dial: '+220',
  },
  GE: {
    Name: 'Georgia',
    Code: 'GE',
    Dial: '+995',
  },
  DE: {
    Name: 'Germany',
    Code: 'DE',
    Dial: '+49',
  },
  GH: {
    Name: 'Ghana',
    Code: 'GH',
    Dial: '+233',
  },
  GI: {
    Name: 'Gibraltar',
    Code: 'GI',
    Dial: '+350',
  },
  GR: {
    Name: 'Greece',
    Code: 'GR',
    Dial: '+30',
  },
  GL: {
    Name: 'Greenland',
    Code: 'GL',
    Dial: '+299',
  },
  GD: {
    Name: 'Grenada',
    Code: 'GD',
    Dial: '+1473',
  },
  GP: {
    Name: 'Guadeloupe',
    Code: 'GP',
    Dial: '+590',
  },
  GU: {
    Name: 'Guam',
    Code: 'GU',
    Dial: '+1671',
  },
  GT: {
    Name: 'Guatemala',
    Code: 'GT',
    Dial: '+502',
  },
  GG: {
    Name: 'Guernsey',
    Code: 'GG',
    Dial: '+44',
  },
  GN: {
    Name: 'Guinea',
    Code: 'GN',
    Dial: '+224',
  },
  GW: {
    Name: 'Guinea-Bissau',
    Code: 'GW',
    Dial: '+245',
  },
  GY: {
    Name: 'Guyana',
    Code: 'GY',
    Dial: '+595',
  },
  HT: {
    Name: 'Haiti',
    Code: 'HT',
    Dial: '+509',
  },
  VA: {
    Name: 'Holy See (Vatican City State)',
    Code: 'VA',
    Dial: '+379',
  },
  HN: {
    Name: 'Honduras',
    Code: 'HN',
    Dial: '+504',
  },
  HK: {
    Name: 'Hong Kong',
    Code: 'HK',
    Dial: '+852',
  },
  HU: {
    Name: 'Hungary',
    Code: 'HU',
    Dial: '+36',
  },
  IS: {
    Name: 'Iceland',
    Code: 'IS',
    Dial: '+354',
  },
  IN: {
    Name: 'India',
    Code: 'IN',
    Dial: '+91',
  },
  ID: {
    Name: 'Indonesia',
    Code: 'ID',
    Dial: '+62',
  },
  IR: {
    Name: 'Iran, Islamic Republic of Persian Gulf',
    Code: 'IR',
    Dial: '+98',
  },
  IQ: {
    Name: 'Iraq',
    Code: 'IQ',
    Dial: '+964',
  },
  IE: {
    Name: 'Ireland',
    Code: 'IE',
    Dial: '+353',
  },
  IM: {
    Name: 'Isle of Man',
    Code: 'IM',
    Dial: '+44',
  },
  IL: {
    Name: 'Israel',
    Code: 'IL',
    Dial: '+972',
  },
  IT: {
    Name: 'Italy',
    Code: 'IT',
    Dial: '+39',
  },
  JM: {
    Name: 'Jamaica',
    Code: 'JM',
    Dial: '+1876',
  },
  JP: {
    Name: 'Japan',
    Code: 'JP',
    Dial: '+81',
  },
  JE: {
    Name: 'Jersey',
    Code: 'JE',
    Dial: '+44',
  },
  JO: {
    Name: 'Jordan',
    Code: 'JO',
    Dial: '+962',
  },
  KZ: {
    Name: 'Kazakhstan',
    Code: 'KZ',
    Dial: '+77',
  },
  KE: {
    Name: 'Kenya',
    Code: 'KE',
    Dial: '+254',
  },
  KI: {
    Name: 'Kiribati',
    Code: 'KI',
    Dial: '+686',
  },
  KP: {
    Name: "Korea, Democratic People's Republic of Korea",
    Code: 'KP',
    Dial: '+850',
  },
  KR: {
    Name: 'Korea, Republic of South Korea',
    Code: 'KR',
    Dial: '+82',
  },
  KW: {
    Name: 'Kuwait',
    Code: 'KW',
    Dial: '+965',
  },
  KG: {
    Name: 'Kyrgyzstan',
    Code: 'KG',
    Dial: '+996',
  },
  LA: {
    Name: 'Laos',
    Code: 'LA',
    Dial: '+856',
  },
  LV: {
    Name: 'Latvia',
    Code: 'LV',
    Dial: '+371',
  },
  LB: {
    Name: 'Lebanon',
    Code: 'LB',
    Dial: '+961',
  },
  LS: {
    Name: 'Lesotho',
    Code: 'LS',
    Dial: '+266',
  },
  LR: {
    Name: 'Liberia',
    Code: 'LR',
    Dial: '+231',
  },
  LY: {
    Name: 'Libyan Arab Jamahiriya',
    Code: 'LY',
    Dial: '+218',
  },
  LI: {
    Name: 'Liechtenstein',
    Code: 'LI',
    Dial: '+423',
  },
  LT: {
    Name: 'Lithuania',
    Code: 'LT',
    Dial: '+370',
  },
  LU: {
    Name: 'Luxembourg',
    Code: 'LU',
    Dial: '+352',
  },
  MO: {
    Name: 'Macao',
    Code: 'MO',
    Dial: '+853',
  },
  MK: {
    Name: 'Macedonia',
    Code: 'MK',
    Dial: '+389',
  },
  MG: {
    Name: 'Madagascar',
    Code: 'MG',
    Dial: '+261',
  },
  MW: {
    Name: 'Malawi',
    Code: 'MW',
    Dial: '+265',
  },
  MY: {
    Name: 'Malaysia',
    Code: 'MY',
    Dial: '+60',
  },
  MV: {
    Name: 'Maldives',
    Code: 'MV',
    Dial: '+960',
  },
  ML: {
    Name: 'Mali',
    Code: 'ML',
    Dial: '+223',
  },
  MT: {
    Name: 'Malta',
    Code: 'MT',
    Dial: '+356',
  },
  MH: {
    Name: 'Marshall Islands',
    Code: 'MH',
    Dial: '+692',
  },
  MQ: {
    Name: 'Martinique',
    Code: 'MQ',
    Dial: '+596',
  },
  MR: {
    Name: 'Mauritania',
    Code: 'MR',
    Dial: '+222',
  },
  MU: {
    Name: 'Mauritius',
    Code: 'MU',
    Dial: '+230',
  },
  YT: {
    Name: 'Mayotte',
    Code: 'YT',
    Dial: '+262',
  },
  MX: {
    Name: 'Mexico',
    Code: 'MX',
    Dial: '+52',
  },
  FM: {
    Name: 'Micronesia, Federated States of Micronesia',
    Code: 'FM',
    Dial: '+691',
  },
  MD: {
    Name: 'Moldova',
    Code: 'MD',
    Dial: '+373',
  },
  MC: {
    Name: 'Monaco',
    Code: 'MC',
    Dial: '+377',
  },
  MN: {
    Name: 'Mongolia',
    Code: 'MN',
    Dial: '+976',
  },
  ME: {
    Name: 'Montenegro',
    Code: 'ME',
    Dial: '+382',
  },
  MS: {
    Name: 'Montserrat',
    Code: 'MS',
    Dial: '+1664',
  },
  MA: {
    Name: 'Morocco',
    Code: 'MA',
    Dial: '+212',
  },
  MZ: {
    Name: 'Mozambique',
    Code: 'MZ',
    Dial: '+258',
  },
  MM: {
    Name: 'Myanmar',
    Code: 'MM',
    Dial: '+95',
  },
  NA: {
    Name: 'Namibia',
    Code: 'NA',
    Dial: '+264',
  },
  NR: {
    Name: 'Nauru',
    Code: 'NR',
    Dial: '+674',
  },
  NP: {
    Name: 'Nepal',
    Code: 'NP',
    Dial: '+977',
  },
  NL: {
    Name: 'Netherlands',
    Code: 'NL',
    Dial: '+31',
  },
  AN: {
    Name: 'Netherlands Antilles',
    Code: 'AN',
    Dial: '+599',
  },
  NC: {
    Name: 'New Caledonia',
    Code: 'NC',
    Dial: '+687',
  },
  NZ: {
    Name: 'New Zealand',
    Code: 'NZ',
    Dial: '+64',
  },
  NI: {
    Name: 'Nicaragua',
    Code: 'NI',
    Dial: '+505',
  },
  NE: {
    Name: 'Niger',
    Code: 'NE',
    Dial: '+227',
  },
  NG: {
    Name: 'Nigeria',
    Code: 'NG',
    Dial: '+234',
  },
  NU: {
    Name: 'Niue',
    Code: 'NU',
    Dial: '+683',
  },
  NF: {
    Name: 'Norfolk Island',
    Code: 'NF',
    Dial: '+672',
  },
  MP: {
    Name: 'Northern Mariana Islands',
    Code: 'MP',
    Dial: '+1670',
  },
  NO: {
    Name: 'Norway',
    Code: 'NO',
    Dial: '+47',
  },
  OM: {
    Name: 'Oman',
    Code: 'OM',
    Dial: '+968',
  },
  PK: {
    Name: 'Pakistan',
    Code: 'PK',
    Dial: '+92',
  },
  PW: {
    Name: 'Palau',
    Code: 'PW',
    Dial: '+680',
  },
  PS: {
    Name: 'Palestinian Territory, Occupied',
    Code: 'PS',
    Dial: '+970',
  },
  PA: {
    Name: 'Panama',
    Code: 'PA',
    Dial: '+507',
  },
  PG: {
    Name: 'Papua New Guinea',
    Code: 'PG',
    Dial: '+675',
  },
  PY: {
    Name: 'Paraguay',
    Code: 'PY',
    Dial: '+595',
  },
  PE: {
    Name: 'Peru',
    Code: 'PE',
    Dial: '+51',
  },
  PH: {
    Name: 'Philippines',
    Code: 'PH',
    Dial: '+63',
  },
  PN: {
    Name: 'Pitcairn',
    Code: 'PN',
    Dial: '+872',
  },
  PL: {
    Name: 'Poland',
    Code: 'PL',
    Dial: '+48',
  },
  PT: {
    Name: 'Portugal',
    Code: 'PT',
    Dial: '+351',
  },
  PR: {
    Name: 'Puerto Rico',
    Code: 'PR',
    Dial: '+1939',
  },
  QA: {
    Name: 'Qatar',
    Code: 'QA',
    Dial: '+974',
  },
  RO: {
    Name: 'Romania',
    Code: 'RO',
    Dial: '+40',
  },
  RU: {
    Name: 'Russia',
    Code: 'RU',
    Dial: '+7',
  },
  RW: {
    Name: 'Rwanda',
    Code: 'RW',
    Dial: '+250',
  },
  RE: {
    Name: 'Reunion',
    Code: 'RE',
    Dial: '+262',
  },
  BL: {
    Name: 'Saint Barthelemy',
    Code: 'BL',
    Dial: '+590',
  },
  SH: {
    Name: 'Saint Helena, Ascension and Tristan Da Cunha',
    Code: 'SH',
    Dial: '+290',
  },
  KN: {
    Name: 'Saint Kitts and Nevis',
    Code: 'KN',
    Dial: '+1869',
  },
  LC: {
    Name: 'Saint Lucia',
    Code: 'LC',
    Dial: '+1758',
  },
  MF: {
    Name: 'Saint Martin',
    Code: 'MF',
    Dial: '+590',
  },
  PM: {
    Name: 'Saint Pierre and Miquelon',
    Code: 'PM',
    Dial: '+508',
  },
  VC: {
    Name: 'Saint Vincent and the Grenadines',
    Code: 'VC',
    Dial: '+1784',
  },
  WS: {
    Name: 'Samoa',
    Code: 'WS',
    Dial: '+685',
  },
  SM: {
    Name: 'San Marino',
    Code: 'SM',
    Dial: '+378',
  },
  ST: {
    Name: 'Sao Tome and Principe',
    Code: 'ST',
    Dial: '+239',
  },
  SA: {
    Name: 'Saudi Arabia',
    Code: 'SA',
    Dial: '+966',
  },
  SN: {
    Name: 'Senegal',
    Code: 'SN',
    Dial: '+221',
  },
  RS: {
    Name: 'Serbia',
    Code: 'RS',
    Dial: '+381',
  },
  SC: {
    Name: 'Seychelles',
    Code: 'SC',
    Dial: '+248',
  },
  SL: {
    Name: 'Sierra Leone',
    Code: 'SL',
    Dial: '+232',
  },
  SG: {
    Name: 'Singapore',
    Code: 'SG',
    Dial: '+65',
  },
  SK: {
    Name: 'Slovakia',
    Code: 'SK',
    Dial: '+421',
  },
  SI: {
    Name: 'Slovenia',
    Code: 'SI',
    Dial: '+386',
  },
  SB: {
    Name: 'Solomon Islands',
    Code: 'SB',
    Dial: '+677',
  },
  SO: {
    Name: 'Somalia',
    Code: 'SO',
    Dial: '+252',
  },
  ZA: {
    Name: 'South Africa',
    Code: 'ZA',
    Dial: '+27',
  },
  SS: {
    Name: 'South Sudan',
    Code: 'SS',
    Dial: '+211',
  },
  GS: {
    Name: 'South Georgia and the South Sandwich Islands',
    Code: 'GS',
    Dial: '+500',
  },
  ES: {
    Name: 'Spain',
    Code: 'ES',
    Dial: '+34',
  },
  LK: {
    Name: 'Sri Lanka',
    Code: 'LK',
    Dial: '+94',
  },
  SD: {
    Name: 'Sudan',
    Code: 'SD',
    Dial: '+249',
  },
  SR: {
    Name: 'Suriname',
    Code: 'SR',
    Dial: '+597',
  },
  SJ: {
    Name: 'Svalbard and Jan Mayen',
    Code: 'SJ',
    Dial: '+47',
  },
  SZ: {
    Name: 'Swaziland',
    Code: 'SZ',
    Dial: '+268',
  },
  SE: {
    Name: 'Sweden',
    Code: 'SE',
    Dial: '+46',
  },
  CH: {
    Name: 'Switzerland',
    Code: 'CH',
    Dial: '+41',
  },
  SY: {
    Name: 'Syrian Arab Republic',
    Code: 'SY',
    Dial: '+963',
  },
  TW: {
    Name: 'Taiwan',
    Code: 'TW',
    Dial: '+886',
  },
  TJ: {
    Name: 'Tajikistan',
    Code: 'TJ',
    Dial: '+992',
  },
  TZ: {
    Name: 'Tanzania, United Republic of Tanzania',
    Code: 'TZ',
    Dial: '+255',
  },
  TH: {
    Name: 'Thailand',
    Code: 'TH',
    Dial: '+66',
  },
  TL: {
    Name: 'Timor-Leste',
    Code: 'TL',
    Dial: '+670',
  },
  TG: {
    Name: 'Togo',
    Code: 'TG',
    Dial: '+228',
  },
  TK: {
    Name: 'Tokelau',
    Code: 'TK',
    Dial: '+690',
  },
  TO: {
    Name: 'Tonga',
    Code: 'TO',
    Dial: '+676',
  },
  TT: {
    Name: 'Trinidad and Tobago',
    Code: 'TT',
    Dial: '+1868',
  },
  TN: {
    Name: 'Tunisia',
    Code: 'TN',
    Dial: '+216',
  },
  TR: {
    Name: 'Turkey',
    Code: 'TR',
    Dial: '+90',
  },
  TM: {
    Name: 'Turkmenistan',
    Code: 'TM',
    Dial: '+993',
  },
  TC: {
    Name: 'Turks and Caicos Islands',
    Code: 'TC',
    Dial: '+1649',
  },
  TV: {
    Name: 'Tuvalu',
    Code: 'TV',
    Dial: '+688',
  },
  UG: {
    Name: 'Uganda',
    Code: 'UG',
    Dial: '+256',
  },
  UA: {
    Name: 'Ukraine',
    Code: 'UA',
    Dial: '+380',
  },
  AE: {
    Name: 'United Arab Emirates',
    Code: 'AE',
    Dial: '+971',
  },
  GB: {
    Name: 'United Kingdom',
    Code: 'GB',
    Dial: '+44',
  },
  US: {
    Name: 'United States',
    Code: 'US',
    Dial: '+1',
  },
  UY: {
    Name: 'Uruguay',
    Code: 'UY',
    Dial: '+598',
  },
  UZ: {
    Name: 'Uzbekistan',
    Code: 'UZ',
    Dial: '+998',
  },
  VU: {
    Name: 'Vanuatu',
    Code: 'VU',
    Dial: '+678',
  },
  VE: {
    Name: 'Venezuela, Bolivarian Republic of Venezuela',
    Code: 'VE',
    Dial: '+58',
  },
  VN: {
    Name: 'Vietnam',
    Code: 'VN',
    Dial: '+84',
  },
  VG: {
    Name: 'Virgin Islands, British',
    Code: 'VG',
    Dial: '+1284',
  },
  VI: {
    Name: 'Virgin Islands, U.S.',
    Code: 'VI',
    Dial: '+1340',
  },
  WF: {
    Name: 'Wallis and Futuna',
    Code: 'WF',
    Dial: '+681',
  },
  YE: {
    Name: 'Yemen',
    Code: 'YE',
    Dial: '+967',
  },
  ZM: {
    Name: 'Zambia',
    Code: 'ZM',
    Dial: '+260',
  },
  ZW: {
    Name: 'Zimbabwe',
    Code: 'ZW',
    Dial: '+263',
  },
};

export const DIAL_CODES = [
  {
    key: '0',
    label: '+1',
    code: 'US',
  },
  {
    key: '1',
    label: '+7',
    code: 'RU',
  },
  {
    key: '2',
    label: '+20',
    code: 'EG',
  },
  {
    key: '3',
    label: '+27',
    code: 'ZA',
  },
  {
    key: '4',
    label: '+30',
    code: 'GR',
  },
  {
    key: '5',
    label: '+31',
    code: 'NL',
  },
  {
    key: '6',
    label: '+32',
    code: 'BE',
  },
  {
    key: '7',
    label: '+33',
    code: 'FR',
  },
  {
    key: '8',
    label: '+34',
    code: 'ES',
  },
  {
    key: '9',
    label: '+36',
    code: 'HU',
  },
  {
    key: '10',
    label: '+39',
    code: 'IT',
  },
  {
    key: '11',
    label: '+40',
    code: 'RO',
  },
  {
    key: '12',
    label: '+41',
    code: 'CH',
  },
  {
    key: '13',
    label: '+43',
    code: 'AT',
  },
  {
    key: '14',
    label: '+44',
    code: 'GB',
  },
  {
    key: '15',
    label: '+45',
    code: 'DK',
  },
  {
    key: '16',
    label: '+46',
    code: 'SE',
  },
  {
    key: '17',
    label: '+47',
    code: 'NO',
  },
  {
    key: '18',
    label: '+48',
    code: 'PL',
  },
  {
    key: '19',
    label: '+49',
    code: 'DE',
  },
  {
    key: '20',
    label: '+51',
    code: 'PE',
  },
  {
    key: '21',
    label: '+52',
    code: 'MX',
  },
  {
    key: '22',
    label: '+53',
    code: 'CU',
  },
  {
    key: '23',
    label: '+54',
    code: 'AR',
  },
  {
    key: '24',
    label: '+55',
    code: 'BR',
  },
  {
    key: '25',
    label: '+56',
    code: 'CL',
  },
  {
    key: '26',
    label: '+57',
    code: 'CO',
  },
  {
    key: '27',
    label: '+58',
    code: 'VE',
  },
  {
    key: '28',
    label: '+60',
    code: 'MY',
  },
  {
    key: '29',
    label: '+61',
    code: 'CX',
  },
  {
    key: '30',
    label: '+62',
    code: 'ID',
  },
  {
    key: '31',
    label: '+63',
    code: 'PH',
  },
  {
    key: '32',
    label: '+64',
    code: 'NZ',
  },
  {
    key: '33',
    label: '+65',
    code: 'SG',
  },
  {
    key: '34',
    label: '+66',
    code: 'TH',
  },
  {
    key: '35',
    label: '+81',
    code: 'JP',
  },
  {
    key: '36',
    label: '+82',
    code: 'KR',
  },
  {
    key: '37',
    label: '+84',
    code: 'VN',
  },
  {
    key: '38',
    label: '+86',
    code: 'CN',
  },
  {
    key: '39',
    label: '+90',
    code: 'TR',
  },
  {
    key: '40',
    label: '+91',
    code: 'IN',
  },
  {
    key: '41',
    label: '+92',
    code: 'PK',
  },
  {
    key: '42',
    label: '+93',
    code: 'AF',
  },
  {
    key: '43',
    label: '+94',
    code: 'LK',
  },
  {
    key: '44',
    label: '+95',
    code: 'MM',
  },
  {
    key: '45',
    label: '+98',
    code: 'IR',
  },
  {
    key: '46',
    label: '+212',
    code: 'MA',
  },
  {
    key: '47',
    label: '+213',
    code: 'DZ',
  },
  {
    key: '48',
    label: '+216',
    code: 'TN',
  },
  {
    key: '49',
    label: '+218',
    code: 'LY',
  },
  {
    key: '50',
    label: '+220',
    code: 'GM',
  },
  {
    key: '51',
    label: '+221',
    code: 'SN',
  },
  {
    key: '52',
    label: '+222',
    code: 'MR',
  },
  {
    key: '53',
    label: '+223',
    code: 'ML',
  },
  {
    key: '54',
    label: '+224',
    code: 'GN',
  },
  {
    key: '55',
    label: '+225',
    code: 'CI',
  },
  {
    key: '56',
    label: '+226',
    code: 'BF',
  },
  {
    key: '57',
    label: '+227',
    code: 'NE',
  },
  {
    key: '58',
    label: '+228',
    code: 'TG',
  },
  {
    key: '59',
    label: '+229',
    code: 'BJ',
  },
  {
    key: '60',
    label: '+230',
    code: 'MU',
  },
  {
    key: '61',
    label: '+231',
    code: 'LR',
  },
  {
    key: '62',
    label: '+232',
    code: 'SL',
  },
  {
    key: '63',
    label: '+233',
    code: 'GH',
  },
  {
    key: '64',
    label: '+234',
    code: 'NG',
  },
  {
    key: '65',
    label: '+235',
    code: 'TD',
  },
  {
    key: '66',
    label: '+236',
    code: 'CF',
  },
  {
    key: '67',
    label: '+237',
    code: 'CM',
  },
  {
    key: '68',
    label: '+238',
    code: 'CV',
  },
  {
    key: '69',
    label: '+239',
    code: 'ST',
  },
  {
    key: '70',
    label: '+240',
    code: 'GQ',
  },
  {
    key: '71',
    label: '+241',
    code: 'GA',
  },
  {
    key: '72',
    label: '+242',
    code: 'CG',
  },
  {
    key: '73',
    label: '+243',
    code: 'CD',
  },
  {
    key: '74',
    label: '+244',
    code: 'AO',
  },
  {
    key: '75',
    label: '+245',
    code: 'GW',
  },
  {
    key: '76',
    label: '+246',
    code: 'IO',
  },
  {
    key: '77',
    label: '+248',
    code: 'SC',
  },
  {
    key: '78',
    label: '+249',
    code: 'SD',
  },
  {
    key: '79',
    label: '+250',
    code: 'RW',
  },
  {
    key: '80',
    label: '+251',
    code: 'ET',
  },
  {
    key: '81',
    label: '+252',
    code: 'SO',
  },
  {
    key: '82',
    label: '+253',
    code: 'DJ',
  },
  {
    key: '83',
    label: '+254',
    code: 'KE',
  },
  {
    key: '84',
    label: '+255',
    code: 'TZ',
  },
  {
    key: '85',
    label: '+256',
    code: 'UG',
  },
  {
    key: '86',
    label: '+257',
    code: 'BI',
  },
  {
    key: '87',
    label: '+258',
    code: 'MZ',
  },
  {
    key: '88',
    label: '+260',
    code: 'ZM',
  },
  {
    key: '89',
    label: '+261',
    code: 'MG',
  },
  {
    key: '90',
    label: '+262',
    code: 'YT',
  },
  {
    key: '91',
    label: '+263',
    code: 'ZW',
  },
  {
    key: '92',
    label: '+264',
    code: 'NA',
  },
  {
    key: '93',
    label: '+265',
    code: 'MW',
  },
  {
    key: '94',
    label: '+266',
    code: 'LS',
  },
  {
    key: '95',
    label: '+267',
    code: 'BW',
  },
  {
    key: '96',
    label: '+268',
    code: 'SZ',
  },
  {
    key: '97',
    label: '+269',
    code: 'KM',
  },
  {
    key: '98',
    label: '+290',
    code: 'SH',
  },
  {
    key: '99',
    label: '+291',
    code: 'ER',
  },
  {
    key: '100',
    label: '+297',
    code: 'AW',
  },
  {
    key: '101',
    label: '+298',
    code: 'FO',
  },
  {
    key: '102',
    label: '+299',
    code: 'GL',
  },
  {
    key: '103',
    label: '+345',
    code: 'KY',
  },
  {
    key: '104',
    label: '+350',
    code: 'GI',
  },
  {
    key: '105',
    label: '+351',
    code: 'PT',
  },
  {
    key: '106',
    label: '+352',
    code: 'LU',
  },
  {
    key: '107',
    label: '+353',
    code: 'IE',
  },
  {
    key: '108',
    label: '+354',
    code: 'IS',
  },
  {
    key: '109',
    label: '+355',
    code: 'AL',
  },
  {
    key: '110',
    label: '+356',
    code: 'MT',
  },
  {
    key: '111',
    label: '+357',
    code: 'CY',
  },
  {
    key: '112',
    label: '+358',
    code: 'FI',
  },
  {
    key: '113',
    label: '+359',
    code: 'BG',
  },
  {
    key: '114',
    label: '+370',
    code: 'LT',
  },
  {
    key: '115',
    label: '+371',
    code: 'LV',
  },
  {
    key: '116',
    label: '+372',
    code: 'EE',
  },
  {
    key: '117',
    label: '+373',
    code: 'MD',
  },
  {
    key: '118',
    label: '+374',
    code: 'AM',
  },
  {
    key: '119',
    label: '+375',
    code: 'BY',
  },
  {
    key: '120',
    label: '+376',
    code: 'AD',
  },
  {
    key: '121',
    label: '+377',
    code: 'MC',
  },
  {
    key: '122',
    label: '+378',
    code: 'SM',
  },
  {
    key: '123',
    label: '+379',
    code: 'VA',
  },
  {
    key: '124',
    label: '+380',
    code: 'UA',
  },
  {
    key: '125',
    label: '+381',
    code: 'RS',
  },
  {
    key: '126',
    label: '+382',
    code: 'ME',
  },
  {
    key: '127',
    label: '+385',
    code: 'HR',
  },
  {
    key: '128',
    label: '+386',
    code: 'SI',
  },
  {
    key: '129',
    label: '+387',
    code: 'BA',
  },
  {
    key: '130',
    label: '+389',
    code: 'MK',
  },
  {
    key: '131',
    label: '+420',
    code: 'CZ',
  },
  {
    key: '132',
    label: '+421',
    code: 'SK',
  },
  {
    key: '133',
    label: '+423',
    code: 'LI',
  },
  {
    key: '134',
    label: '+500',
    code: 'FK',
  },
  {
    key: '135',
    label: '+501',
    code: 'BZ',
  },
  {
    key: '136',
    label: '+502',
    code: 'GT',
  },
  {
    key: '137',
    label: '+503',
    code: 'SV',
  },
  {
    key: '138',
    label: '+504',
    code: 'HN',
  },
  {
    key: '139',
    label: '+505',
    code: 'NI',
  },
  {
    key: '140',
    label: '+506',
    code: 'CR',
  },
  {
    key: '141',
    label: '+507',
    code: 'PA',
  },
  {
    key: '142',
    label: '+508',
    code: 'PM',
  },
  {
    key: '143',
    label: '+509',
    code: 'HT',
  },
  {
    key: '144',
    label: '+537',
    code: '',
  },
  {
    key: '145',
    label: '+590',
    code: 'BL',
  },
  {
    key: '146',
    label: '+591',
    code: 'BO',
  },
  {
    key: '147',
    label: '+593',
    code: 'EC',
  },
  {
    key: '148',
    label: '+594',
    code: 'GF',
  },
  {
    key: '149',
    label: '+595',
    code: 'PY',
  },
  {
    key: '150',
    label: '+596',
    code: 'MQ',
  },
  {
    key: '151',
    label: '+597',
    code: 'SR',
  },
  {
    key: '152',
    label: '+598',
    code: 'UY',
  },
  {
    key: '153',
    label: '+599',
    code: 'CW',
  },
  {
    key: '154',
    label: '+670',
    code: 'TL',
  },
  {
    key: '155',
    label: '+672',
    code: 'AQ',
  },
  {
    key: '156',
    label: '+673',
    code: 'BN',
  },
  {
    key: '157',
    label: '+674',
    code: 'NR',
  },
  {
    key: '158',
    label: '+675',
    code: 'PG',
  },
  {
    key: '159',
    label: '+676',
    code: 'TO',
  },
  {
    key: '160',
    label: '+677',
    code: 'SB',
  },
  {
    key: '161',
    label: '+678',
    code: 'VU',
  },
  {
    key: '162',
    label: '+679',
    code: 'FJ',
  },
  {
    key: '163',
    label: '+680',
    code: 'PW',
  },
  {
    key: '164',
    label: '+681',
    code: 'WF',
  },
  {
    key: '165',
    label: '+682',
    code: 'CK',
  },
  {
    key: '166',
    label: '+683',
    code: 'NU',
  },
  {
    key: '167',
    label: '+685',
    code: 'WS',
  },
  {
    key: '168',
    label: '+686',
    code: 'KI',
  },
  {
    key: '169',
    label: '+687',
    code: 'NC',
  },
  {
    key: '170',
    label: '+688',
    code: 'TV',
  },
  {
    key: '171',
    label: '+689',
    code: 'PF',
  },
  {
    key: '172',
    label: '+690',
    code: 'TK',
  },
  {
    key: '173',
    label: '+691',
    code: 'FM',
  },
  {
    key: '174',
    label: '+692',
    code: 'MH',
  },
  {
    key: '175',
    label: '+850',
    code: 'KP',
  },
  {
    key: '176',
    label: '+852',
    code: 'HK',
  },
  {
    key: '177',
    label: '+853',
    code: 'MO',
  },
  {
    key: '178',
    label: '+855',
    code: 'KH',
  },
  {
    key: '179',
    label: '+856',
    code: 'LA',
  },
  {
    key: '180',
    label: '+872',
    code: '',
  },
  {
    key: '181',
    label: '+880',
    code: 'BD',
  },
  {
    key: '182',
    label: '+886',
    code: 'TW',
  },
  {
    key: '183',
    label: '+960',
    code: 'MV',
  },
  {
    key: '184',
    label: '+961',
    code: 'LB',
  },
  {
    key: '185',
    label: '+962',
    code: 'JO',
  },
  {
    key: '186',
    label: '+963',
    code: 'SY',
  },
  {
    key: '187',
    label: '+964',
    code: 'IQ',
  },
  {
    key: '188',
    label: '+965',
    code: 'KW',
  },
  {
    key: '189',
    label: '+966',
    code: 'SA',
  },
  {
    key: '190',
    label: '+967',
    code: 'YE',
  },
  {
    key: '191',
    label: '+968',
    code: 'OM',
  },
  {
    key: '192',
    label: '+970',
    code: 'PS',
  },
  {
    key: '193',
    label: '+971',
    code: 'AE',
  },
  {
    key: '194',
    label: '+972',
    code: 'IL',
  },
  {
    key: '195',
    label: '+973',
    code: 'BH',
  },
  {
    key: '196',
    label: '+974',
    code: 'QA',
  },
  {
    key: '197',
    label: '+975',
    code: 'BT',
  },
  {
    key: '198',
    label: '+976',
    code: 'MN',
  },
  {
    key: '199',
    label: '+977',
    code: 'NP',
  },
  {
    key: '200',
    label: '+992',
    code: 'TJ',
  },
  {
    key: '201',
    label: '+993',
    code: 'TM',
  },
  {
    key: '202',
    label: '+994',
    code: 'AZ',
  },
  {
    key: '203',
    label: '+995',
    code: 'GE',
  },
  {
    key: '204',
    label: '+996',
    code: 'KG',
  },
  {
    key: '205',
    label: '+998',
    code: 'UZ',
  },
];

export const DEFAULT_EVERYWHERE = 'Everywhere';
export const DEFAULT_NOWHERE = 'Nowhere';
