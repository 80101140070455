import PropTypes from 'prop-types';
import { Box, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const SkeletonLoading = ({ height, isFullHeight, isDarkTheme }) => {
  const theme = useTheme();
  const skeletonHeight = isFullHeight ? '100%' : height;

  return (
    <Box
      sx={{
        ...(isFullHeight && { display: 'flex', height: '100%' }),
      }}
    >
      <Skeleton
        variant="rectangular"
        height={skeletonHeight}
        width="100%"
        sx={{ ...(isDarkTheme && { bgcolor: theme.palette.secondary.main }) }}
      />
    </Box>
  );
};

SkeletonLoading.defaultProps = {
  height: 450,
  isFullHeight: false,
  isDarkTheme: false,
};

SkeletonLoading.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isFullHeight: PropTypes.bool,
  isDarkTheme: PropTypes.bool,
};

export default SkeletonLoading;
