import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { STRINGS } from 'constants/strings';
// import { replaceLiterals } from '@utils/replaceLiterals';
// import { useAddYourPodcast } from 'contexts/AddYourPodcastContext';
import useLoadModalSubpage from 'hooks/useLoadModalSubpage';
import { pageNames } from 'constants/ipsso';
import useRecordAnalyticsPodcast from 'hooks/useRecordAnalyticsPodcast';
import { TAP_DONE_FROM_WELCOME_TO_MARBYL } from 'constants/analytics';
import { useHistory } from 'react-router-dom';
import { MAIN_ROUTES } from 'constants/routing';
const APP_STORE_URL = 'https://apps.apple.com/us/app/marbyl/id1606442385';

const WelcomeMessage = ({ onClickDone }) => {
  // const {
  //   podcastState: [podcast],
  // } = useAddYourPodcast();

  const history = useHistory();

  const { recordAnalyticsPodcast } = useRecordAnalyticsPodcast();

  const handleClickDone = () => {
    recordAnalyticsPodcast(TAP_DONE_FROM_WELCOME_TO_MARBYL);
    onClickDone();

    history.push(MAIN_ROUTES.APP);
  };

  useLoadModalSubpage(pageNames.WELCOME_MESSAGE);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        {STRINGS.YOURE_NOW_A_MARBYL_PARTNER}
      </Typography>

      <Typography sx={{ mb: 2, whiteSpace: 'pre-wrap' }} variant="subtitle1">
        Please be sure to check both your inbox and spam folders for messages from{' '}
        <a href="mailto:support@marbyl.com">support@marbyl.com</a>. You will be among the
        first to know when we are ready to onboard our new partners!
        <br />
        In the meantime, if you haven’t already, feel free to download the{' '}
        <a href={APP_STORE_URL} target="_blank" rel="noreferrer">
          Marbyl App
        </a>
        , explore how it works, and send us any feedback.
        <br />
        Thank you for your interest!
      </Typography>

      <Button
        variant="contained"
        color="success"
        onClick={handleClickDone}
        sx={{ mt: 2 }}
      >
        {STRINGS.DONE}
      </Button>
    </Box>
  );
};

export default WelcomeMessage;
