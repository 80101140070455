import React, { useCallback, useState } from 'react';
import { Typography, Box } from '@mui/material';
import { PodcastArtTitle, PodcastSearch } from '@components/index';
import { STRINGS } from 'constants/strings';
import { useAddYourPodcast } from 'contexts/AddYourPodcastContext';
import { toast } from 'react-toastify';
import { StyledInvertedButton } from '@shared/styledInputs';
import {
  TAP_CANCEL_FROM_FEED_CONFIRMATION,
  TAP_CANCEL_FROM_FEED_LOOKUP,
  TAP_NEXT_FROM_FEED_CONFIRMATION,
} from 'constants/analytics';
import { pageNames } from 'constants/ipsso';
import useLoadModalSubpage from 'hooks/useLoadModalSubpage';

const FeedConfirmation = ({ renderBackBtn, onNextClick, height }) => {
  const {
    podcastState: [podcast, setPodcast],
    checkRSSExists,
  } = useAddYourPodcast();

  const [searchHeight, setSearchHeight] = useState(0);

  const headerRef = useCallback(
    (ref) => {
      if (!ref) return;

      setSearchHeight(height - ref.clientHeight - 80);
    },
    [height]
  );

  const handleRSSLoad = (data, err) => {
    if (err) {
      toast.error(err);

      return;
    }

    setPodcast(data);
  };

  const renderNextButton = useCallback(() => {
    if (!podcast) return null;

    return (
      <StyledInvertedButton
        onClick={() => onNextClick(TAP_NEXT_FROM_FEED_CONFIRMATION)}
        variant="contained"
        color="error"
      >
        {STRINGS.NEXT}
      </StyledInvertedButton>
    );
  }, [podcast, onNextClick]);

  const renderLanguageWarning = useCallback(() => {
    if (!podcast) return null;

    const { language = 'en' } = podcast;

    return (
      !language.includes('en') && (
        <Typography color="info.main" sx={{ mt: 3 }} variant="subtitle1">
          {
            STRINGS.THERE_MAY_BE_A_DELAY_UNTIL_WE_SUPPORT_YOUR_LANGUAGE_WE_CURRENTLY_ONLY_SUPPORT_PODCASTS_IN_ENGLISH_BUT_PLAN_TO_SUPPORT_MORE_LANGUAGES_IN_THE_FUTURE
          }
        </Typography>
      )
    );
  }, [podcast]);

  const handleRSSValidation = useCallback(
    async (item) => {
      toast.dismiss();
      const { rssUrl } = item;

      try {
        const rssFeedExists = await checkRSSExists(rssUrl);

        if (rssFeedExists) {
          toast.error(
            STRINGS.THIS_PODCAST_HAS_ALREADY_BEEN_CLAIMED_PLEASE_CONTACT_OWNER_OF_PODCAST_FOR_DETAILS
          );
          return false;
        }

        return true;
      } catch (error) {
        toast.error(STRINGS.AN_ERROR_OCCURED_ON_SERVER__TRY_AGAIN);
        return false;
      }
    },
    [checkRSSExists]
  );

  useLoadModalSubpage(pageNames.FEED_CONFIRMATION);

  return (
    <>
      <Box ref={headerRef}>
        <Typography variant="h6">{STRINGS.ADD_YOUR_PODCAST_RSS}</Typography>
        <Typography sx={{ mb: 3 }} variant="subtitle1">
          {' '}
        </Typography>
        <PodcastSearch
          submit
          onRSSLoad={handleRSSLoad}
          onBeforeRSSLoad={handleRSSValidation}
          searchHeight={searchHeight}
        />
      </Box>

      {podcast && <PodcastArtTitle podcast={podcast} />}

      {renderLanguageWarning()}

      <Box sx={{ mt: 2 }}>
        {renderBackBtn(
          !podcast ? TAP_CANCEL_FROM_FEED_LOOKUP : TAP_CANCEL_FROM_FEED_CONFIRMATION
        )}
        {renderNextButton()}
      </Box>
    </>
  );
};

export default FeedConfirmation;
