import React, { useState } from 'react';

import { replaceLiterals } from '@utils/replaceLiterals';
import { STRINGS } from 'constants/strings';
import { useAddYourPodcast } from 'contexts/AddYourPodcastContext';

import { Typography, Box, CircularProgress } from '@mui/material';
import { PodcastArtTitle } from '@components/index';
import { requestAPIGraphQL } from '@services/appSyncAPI';
import { requestPodcastVerification } from '@graphql/mutations/ipsso';

import { toast } from 'react-toastify';
import usePodcastEmail from 'hooks/usePodcastEmail';
import { STAGE } from 'constants/secrets';
import { useTheme } from '@mui/material/styles';
import { StyledInvertedButton } from '@shared/styledInputs';
import { TAP_PREVIOUS_FROM_SEND_CODE, TAP_SEND_THE_CODE } from 'constants/analytics';
import { pageNames } from 'constants/ipsso';
import useLoadModalSubpage from 'hooks/useLoadModalSubpage';
import useRecordAnalyticsPodcast from 'hooks/useRecordAnalyticsPodcast';

const SendCode = ({ renderBackBtn, onNextClick }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const {
    podcastState: [podcast],
  } = useAddYourPodcast();

  const podcastEmail = usePodcastEmail(podcast);
  const { recordAnalyticsPodcast } = useRecordAnalyticsPodcast();

  const onSendCode = async () => {
    try {
      toast.dismiss();
      setLoading(true);

      recordAnalyticsPodcast(TAP_SEND_THE_CODE, { podcastEmail });

      const requestPVPayload = {
        podcastEmail,
      };

      await requestAPIGraphQL(requestPodcastVerification, {
        input: requestPVPayload,
      });

      setLoading(false);
      onNextClick();
    } catch (e) {
      setLoading(false);

      const [{ message }] = e.errors;
      toast.error(message);
    }
  };

  useLoadModalSubpage(pageNames.SEND_CODE);

  return (
    <>
      <PodcastArtTitle podcast={podcast} />
      <Typography sx={{ mt: 2 }} variant="h6">
        {STRINGS.VERIFY_THIS_PODCAST_IS_YOURS}
      </Typography>
      <Typography sx={{ mb: 2, mt: 2 }} variant="subtitle1">
        {STAGE === 'prod' ? (
          replaceLiterals(
            STRINGS.TO_VERIFY_THAT_YOU_OWN_THE_PODCAST_WE_WILL_SEND_A_6_DIGIT_CODE_TO_THE_EMAIL_ADDRESS_ASSOCIATED_WITH_YOUR_RSS_FEED,
            { email: podcastEmail }
          )
        ) : (
          <>
            {STRINGS.THIS_EMAIL_WOULD_BE_SENT_TO}{' '}
            <span
              style={{
                color: theme.palette.error.main,
                textDecoration: 'underline',
              }}
            >
              {podcast?.itunes?.owner?.email}
            </span>
            {'. '}
            {STRINGS.FOR_TESTING_PURPOSES_WE_WILL_INSTEAD_SEND_THE_EMAIL_TO}{' '}
            <span
              style={{
                color: theme.palette.error.main,
                textDecoration: 'underline',
              }}
            >
              {podcastEmail}
            </span>
            {'.'}
          </>
        )}
      </Typography>

      <Typography variant="subtitle1">
        {
          STRINGS.TO_UPDATE_THE_EMAIL_ASSOCIATED_WITH_YOUR_RSS_FEED_CONTACT_YOUR_RSS_HOST_OR_OWNER
        }
      </Typography>

      <Box sx={{ mt: 2 }}>
        {renderBackBtn(TAP_PREVIOUS_FROM_SEND_CODE)}

        <StyledInvertedButton
          onClick={onSendCode}
          disabled={loading}
          variant="contained"
          color="error"
        >
          {loading ? (
            <CircularProgress size={15} color="secondary" />
          ) : (
            STRINGS.SEND_THE_CODE
          )}
        </StyledInvertedButton>
      </Box>
    </>
  );
};

export default SendCode;
