import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { PAGE_EXIT, PAGE_LOAD } from 'constants/analytics';
import { record } from '@utils/analytics';

const usePageLoadExitAnalytics = () => {
  const { pathname } = useLocation();
  const initialTime = useRef(new Date().getTime());

  useEffect(() => {
    record(PAGE_LOAD, { pathname });
    const initTime = initialTime.current;

    return () => {
      const exitTime = new Date().getTime();
      const seconds = new Date(exitTime - initTime).getSeconds();
      initialTime.current = exitTime;

      record(PAGE_EXIT, { pathname }, { timeSpentOnView: seconds });
    };
  }, [pathname]);
};

export default usePageLoadExitAnalytics;
