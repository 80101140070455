import React, { forwardRef } from 'react';
import { StyledFieldTitle } from './styled';
import { Typography, Grid, Box } from '@mui/material';
import { props } from './props';

const MetaInfo = forwardRef(({ items }, ref) => (
  <Grid ref={ref} sx={{ wordBreak: 'break-word' }} container spacing={2}>
    {items.map((item, i) => (
      <Grid key={i} item md={item.md || 6} sm={item.sm || 12} xs={item.xs || 12}>
        <StyledFieldTitle variant="subtitle1">{item.label}</StyledFieldTitle>
        {item.renderValue ? (
          <Box {...(item.styles && { sx: item.styles })}>
            {item.renderValue(item.value)}
          </Box>
        ) : (
          <Typography {...(item.styles && { sx: item.styles })} variant="body1">
            {item.value}
          </Typography>
        )}
      </Grid>
    ))}
  </Grid>
));

MetaInfo.propTypes = props;

export default MetaInfo;
