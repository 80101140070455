import { Topbar } from '@components/index';
import { StyledFullHeight, StyledInnerContainer } from '@shared/styledContainers';
import React, { useEffect } from 'react';
import Spacer from '@shared/spacer';

const BetaPage = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = '';
    script.src = 'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=Y3DEFU';

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <StyledFullHeight>
      <StyledInnerContainer>
        <Topbar />
        <Spacer size={50} />
        <div className="klaviyo-form-YANGuV"></div>
      </StyledInnerContainer>
    </StyledFullHeight>
  );
};

export default BetaPage;
