import React, { useMemo } from 'react';
import { Button, Box } from '@mui/material';
import { STRINGS } from 'constants/strings';
import EditIcon from '@mui/icons-material/Edit';
import { StyledHeaderSection } from '@shared/styledGeneral';
import { MetaInfo } from '@components/index';
import { AGREEMENT_TYPES_LABELS } from 'constants/podcaster';
import { convertGeoArrayToText } from '@utils/getAvailableInGeos';
import { getActiveStatusValue } from '@utils/getActiveStatus';
import { STATUS_LABELS } from 'constants/activeStatus';
import { LEGAL_ENTITY_TYPES_LABEL } from 'constants/legalEntityTypes';

const NetworkDetail = ({
  name,
  availableInGeos,
  description,
  entity,
  entityType,
  isActivated,
  contentAgreementType,
  onClickEdit = () => null,
}) => {
  const handleEdit = () => {
    onClickEdit();
  };

  const networkDetails = useMemo(
    () => [
      {
        label: STRINGS.NAME,
        value: name,
        md: 6,
        xs: 12,
      },
      {
        label: STRINGS.GEOGRAPHIC_AVAILABILITY,
        value: availableInGeos,
        renderValue: (val) => convertGeoArrayToText(val),
        md: 6,
        xs: 12,
      },
      {
        label: STRINGS.DESCRIPTION,
        value: description,
        md: 12,
        xs: 12,
      },
    ],
    [name, availableInGeos, description]
  );

  const legalInfoCols = useMemo(
    () => [
      {
        label: 'Entity Name',
        value: entity,
        md: 6,
        xs: 12,
      },
      {
        label: 'Entity Type',
        value: entityType ? LEGAL_ENTITY_TYPES_LABEL[entityType] : STRINGS.LLC,
        md: 6,
        xs: 12,
      },
    ],
    [entity, entityType]
  );

  const adminSettingsInfo = useMemo(
    () => [
      {
        label: STRINGS.STATUS,
        value: isActivated,
        md: 4,
        xs: 12,
        renderValue: (val) => STATUS_LABELS[getActiveStatusValue(val)],
      },
      {
        label: STRINGS.AGREEMENT,
        value: AGREEMENT_TYPES_LABELS[contentAgreementType] || '',
        md: 4,
        xs: 12,
      },
    ],
    [isActivated, contentAgreementType]
  );

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={handleEdit} variant="outlined" color="secondary">
          {STRINGS.EDIT}
          <EditIcon sx={{ ml: 1, fontSize: 18 }} />
        </Button>
      </Box>

      <StyledHeaderSection variant="h6">{STRINGS.NETWORK_DETAILS}</StyledHeaderSection>

      <MetaInfo items={networkDetails} />

      <StyledHeaderSection sx={{ mt: 4 }} variant="h6">
        {STRINGS.LEGAL_INFORMATION}
      </StyledHeaderSection>

      <MetaInfo items={legalInfoCols} />

      <StyledHeaderSection sx={{ mt: 4 }} variant="h6">
        {STRINGS.ADMIN_SETTINGS}
      </StyledHeaderSection>

      <MetaInfo items={adminSettingsInfo} />
    </>
  );
};

export default NetworkDetail;
