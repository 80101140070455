import React from 'react';
import { useTheme } from '@mui/material/styles/';
import { props, defaultProps } from './entitiesIconsProps';

const NetworkIcon = ({ width, height }) => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
      width={width}
      height={height}
    >
      <path
        d="M208 144V80C208 53.49 229.5 32 256 32H320C346.5 32 368 53.49 368 80V144C368 170.5 346.5 192 320 192H304V240H464C490.5 240 512 261.5 512 288V320H528C554.5 320 576 341.5 576 368V432C576 458.5 554.5 480 528 480H464C437.5 480 416 458.5 416 432V368C416 341.5 437.5 320 464 320H480V288C480 279.2 472.8 272 464 272H304V320H320C346.5 320 368 341.5 368 368V432C368 458.5 346.5 480 320 480H256C229.5 480 208 458.5 208 432V368C208 341.5 229.5 320 256 320H272V272H112C103.2 272 96 279.2 96 288V320H112C138.5 320 160 341.5 160 368V432C160 458.5 138.5 480 112 480H48C21.49 480 0 458.5 0 432V368C0 341.5 21.49 320 48 320H64V288C64 261.5 85.49 240 112 240H272V192H256C229.5 192 208 170.5 208 144zM320 64H256C247.2 64 240 71.16 240 80V144C240 152.8 247.2 160 256 160H320C328.8 160 336 152.8 336 144V80C336 71.16 328.8 64 320 64zM32 368V432C32 440.8 39.16 448 48 448H112C120.8 448 128 440.8 128 432V368C128 359.2 120.8 352 112 352H48C39.16 352 32 359.2 32 368zM240 368V432C240 440.8 247.2 448 256 448H320C328.8 448 336 440.8 336 432V368C336 359.2 328.8 352 320 352H256C247.2 352 240 359.2 240 368zM448 368V432C448 440.8 455.2 448 464 448H528C536.8 448 544 440.8 544 432V368C544 359.2 536.8 352 528 352H464C455.2 352 448 359.2 448 368z"
        fill={theme.palette.secondary.main}
      />
    </svg>
  );
};

NetworkIcon.propTypes = props;

NetworkIcon.defaultProps = defaultProps;

export default NetworkIcon;
