import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch, useParams } from 'react-router';
import {
  MarbylContainer,
  MPCMarbylReports,
  MPCReportDetail,
} from '@components/containers/index';

import { CommonSwitch } from './common';
import { MPC_ROUTES } from 'constants/routing';

const MarbylReportsRoutes = ({ id }) => {
  const { url } = useRouteMatch();

  const routes = useMemo(
    () => [
      {
        exact: true,
        path: url,
        childComponent: <MPCMarbylReports id={id} />,
      },
      {
        path: `${url}${MPC_ROUTES.ID}`,
        childComponent: <MPCReportDetail />,
      },
    ],
    [id, url]
  );

  return <CommonSwitch routes={routes} />;
};

const MarbylRoutes = () => {
  const { url } = useRouteMatch();
  const { id } = useParams();

  const routes = useMemo(
    () => [
      {
        exact: true,
        path: url,
        childComponent: <MarbylContainer id={id} />,
      },
      {
        path: `${url}${MPC_ROUTES.CONTENT_REPORTS}`,
        childComponent: <MarbylReportsRoutes id={id} />,
      },
    ],
    [id, url]
  );

  return <CommonSwitch routes={routes} />;
};

MarbylReportsRoutes.propTypes = {
  id: PropTypes.string,
};

MarbylReportsRoutes.defaultProps = {
  id: '',
};

export default MarbylRoutes;
