import PropTypes from 'prop-types';

export const props = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  renderOptions: PropTypes.func,
  renderExpandedContent: PropTypes.func,
  headerType: PropTypes.string,
  podcastShowArt: PropTypes.string,
};

export const defaultProps = {
  headerType: '',
  podcastShowArt: '',
  renderOptions: () => null,
};
