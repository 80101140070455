import PropTypes from 'prop-types';

export const props = {
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  minWidthFontSize: PropTypes.number,
  maxWidthFontSize: PropTypes.number,
  minWidthLineHeight: PropTypes.number,
  maxWidthLineHeight: PropTypes.number,
  container: PropTypes.oneOf(['parent', 'self']),
  fontSizeUnits: PropTypes.oneOf(['em', 'rem', 'px']),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export const defaultProps = {
  minWidth: 320,
  maxWidth: 960,
  minWidthFontSize: 1,
  maxWidthFontSize: 1.8,
  minWidthLineHeight: 1.33,
  maxWidthLineHeight: 1.25,
  container: 'parent',
  fontSizeUnits: 'em',
};
