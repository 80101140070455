import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { StyledFullHeight, StyledInnerContainer } from '@shared/styledContainers';
import { Topbar } from '@components/index';
import { useEffect } from 'react';
import { useState } from 'react';
import { requestAPIGraphQL } from '@services/appSyncAPI';
import { getPrivacyPolicy } from '@graphql/queries/main';
import Spacer from '@shared/spacer';

const PrivacyPolicyPage = () => {
  const [html, setHtml] = useState('');

  useEffect(() => {
    const init = () => {
      requestAPIGraphQL(getPrivacyPolicy, null, { authMode: 'API_KEY' }).then(
        ({ data: { getPrivacyPolicy: url } }) => {
          fetch(url)
            .then((response) => response.text())
            .then((htmlRes) => setHtml(htmlRes));
        }
      );
    };

    init();
  }, []);

  return (
    <StyledFullHeight isDarkTheme={false}>
      <StyledInnerContainer>
        <Topbar isDarkTheme={false} />
        <Spacer size={50} />
        <Box
          sx={{
            maxWidth: 780,
            margin: '0 auto',
            ...(!html && {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
            }),
          }}
        >
          {!!html ? (
            <div dangerouslySetInnerHTML={{ __html: html }} />
          ) : (
            <CircularProgress color="info" />
          )}
        </Box>
      </StyledInnerContainer>
    </StyledFullHeight>
  );
};

export default PrivacyPolicyPage;
