import React, { useCallback, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { STRINGS } from 'constants/strings';
import { replaceLiterals } from '@utils/replaceLiterals';
import { useAddYourPodcast } from 'contexts/AddYourPodcastContext';
import useLoadModalSubpage from 'hooks/useLoadModalSubpage';
import { pageNames } from 'constants/ipsso';
import useRecordAnalyticsPodcast from 'hooks/useRecordAnalyticsPodcast';
import { PODCASTER_ONBOARDED, PODCAST_ONBOARDED } from 'constants/analytics';
import { requestAPIGraphQL } from '@services/appSyncAPI';
import { getIPSSONotification } from '@graphql/queries/ipsso';
import useAccountEmail from 'hooks/useAccountEmail';
import usePodcastRSSEmail from 'hooks/usePodcastRSSEmail';
import { STAGE } from 'constants/secrets';

const WelcomeMessage = ({ handleReset, isClaim }) => {
  const {
    podcastState: [podcast],
    podcaster: { owners, companyName },
  } = useAddYourPodcast();

  const accountEmail = useAccountEmail();
  const podcastRSSEmail = usePodcastRSSEmail(podcast);
  const { recordAnalyticsPodcast } = useRecordAnalyticsPodcast();

  const callOnboardNotification = useCallback(
    (type = 'Podcast_onboarded') => {
      if (STAGE !== 'prod') {
        return new Promise((resolve) => resolve(null));
      }

      let podcasterName = '';

      owners.forEach((_owner, index) => {
        podcasterName += `${_owner.name}${index !== owners.length - 1 ? ', ' : ''}`;
      });

      if (companyName) {
        podcasterName += ` | ${companyName}`;
      }

      const payload = {
        emailPodcaster: accountEmail,
        emailRSS: podcastRSSEmail,
        notificationSource: type,
        podcasterName,
        podcastName: podcast.title,
        RSSurl: podcast.feedUrl,
      };

      return requestAPIGraphQL(getIPSSONotification, payload);
    },
    [accountEmail, podcastRSSEmail, owners, companyName, podcast]
  );

  useEffect(() => {
    recordAnalyticsPodcast(PODCAST_ONBOARDED);
    callOnboardNotification('Podcast_onboarded').then();

    if (isClaim) {
      recordAnalyticsPodcast(PODCASTER_ONBOARDED);
      callOnboardNotification('Podcaster_onboarded').then();
    }
  }, [isClaim, recordAnalyticsPodcast, callOnboardNotification]);

  useLoadModalSubpage(pageNames.WELCOME_MESSAGE);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        {STRINGS.YOURE_NOW_A_MARBYL_PARTNER}
      </Typography>

      <Typography sx={{ mb: 2, whiteSpace: 'pre-wrap' }} variant="subtitle1">
        {replaceLiterals(
          STRINGS.WELL_REACH_OUT_WHEN_PODCAST_IS_AVAILABLE_IN_MARBYL_PLEASE_BE_SURE_TO_CHECK_BOTH_YOUR_INBOX_AND_SPAM_FOLDERS_FOR_MESSAGES_FROM_SUPPORT__ONCE_YOUR_PODCAST_IS_LIVE_IN_MARBYL_WELL_SEND_YOU_AN_INVITE_TO_ACCESS_OUR_PRIVATE_BETA_IF_YOU_HAVE_ANY_QUESTIONS_SEND_US_AN_EMAIL_AT_SUPPORT_MAIL,
          {
            podcastName: podcast.title,
          }
        )}
      </Typography>

      <Button variant="contained" color="success" onClick={handleReset} sx={{ mt: 2 }}>
        {STRINGS.DONE}
      </Button>
    </Box>
  );
};

export default WelcomeMessage;
