import { useMemo } from 'react';
import FiltersTable from '@components/FiltersTable/index';
import {
  getEpisodesSelectList,
  getPodcastersByNetworkId,
  getPodcastsByPodcasterId,
  getSubnetworkByNetworkId,
  getPodcastsByNetworkId,
} from '@graphql/queries/reports';
import { getAllTranscriptReviewRequests } from '@graphql/queries/transcripts';
import { format } from '@utils/dateFormat';
import { TRANSCRIPT_REVIEW_ITEM } from 'constants/idPrefixes';
import { STRINGS } from 'constants/strings';
import { REVISION_STATUS } from 'constants/revisionTypes';

const TranscriptReviews = () => {
  const filtersQuery = {
    main: {
      query: getAllTranscriptReviewRequests,
      responseKeyName: 'listAllTranscriptReviewRequests',
    },
    network: {
      query: getSubnetworkByNetworkId,
      responseKeyName: 'networksList',
    },
    podcaster: {
      query: getPodcastersByNetworkId,
      responseKeyName: 'podcastersList',
    },
    podcast: {
      query: getPodcastsByPodcasterId,
      secondary: getPodcastsByNetworkId,
      responseKeyName: 'response',
    },
    episode: {
      query: getEpisodesSelectList,
      responseKeyName: 'episodesList',
    },
  };

  const columnDefs = useMemo(
    () => [
      {
        field: 'reportId',
        hide: true,
      },
      {
        field: 'createdAt',
        headerName: 'Date Created',
        cellRenderer: ({ value }) => (value ? format(value) : ''),
        minWidth: 100,
        maxWidth: 150,
      },
      {
        field: 'comment',
        headerName: 'Comment',
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'transcriptFragment',
        headerName: 'Text for Review',
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 100,
      },
    ],
    []
  );

  return (
    <FiltersTable
      title={STRINGS.REPORTED_TRANSCRIPTION_ERRORS}
      columnDefs={columnDefs}
      filtersQuery={filtersQuery}
      entityInfo={{
        idName: 'PK',
        replacedString: TRANSCRIPT_REVIEW_ITEM,
        entity: 'Transcript Review Details',
      }}
      itemType={REVISION_STATUS.TRANSCRIPT_REVIEW_ITEM}
    />
  );
};

export default TranscriptReviews;
