import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { history } from './utils/history';

import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { createHookedOnReducer } from 'hooked-on-redux';
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { ToasterContainer } from '@components/index';
import { BASENAME } from 'constants/routing';
import AuthProvider from 'contexts/AuthContext';
import ToasterProvider from 'contexts/ToasterContext';
import MPCContextProvider from 'contexts/MPCContext';
import 'react-toastify/dist/ReactToastify.min.css';
import DepsProvider from 'contexts/DepsContext';
import { listPodcastsPerPage } from '@services/listenNotesAPI';
import { theme } from '@theme/theme';
import { requestAPIGraphQL } from '@services/appSyncAPI';

const reducer = createHookedOnReducer({}, 'HOOKED_ON_REDUX', {
  // eslint-disable-next-line no-unused-vars
  'HOOKED_ON_REDUX/LOGOUT': (state, action) => {
    return {};
  },
});
const store = createStore(reducer, {});

ReactDOM.render(
  <Router history={history} basename={BASENAME}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToasterProvider>
          <ToasterContainer />
          <AuthProvider>
            <DepsProvider
              httpClient={requestAPIGraphQL}
              podcastsSearchAPI={listPodcastsPerPage}
            >
              <MPCContextProvider>
                <App />
              </MPCContextProvider>
            </DepsProvider>
          </AuthProvider>
        </ToasterProvider>
      </ThemeProvider>
    </Provider>
  </Router>,
  document.getElementById('root')
);
