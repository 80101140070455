import { createTheme } from '@mui/material/styles';
import { TYPOGRAPHY } from 'theme/typography';
import { BREAKPOINTS } from 'theme/breakpoints';
import { /*PALETTE, */ LIGHT_PALETTE } from 'theme/palette';
import { HEX_COLORS } from 'constants/stylesVariables';

export const theme = createTheme({
  typography: {
    fontFamily: 'Mallory',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        ${TYPOGRAPHY}

        input:-webkit-autofill{
          -webkit-text-fill-color: white !important;
        } 

        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus, 
        input:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px ${HEX_COLORS.darkJungleGreen} inset !important;
        }
      `,
    },
  },
  breakpoints: {
    values: {
      ...BREAKPOINTS,
    },
  },
  palette: {
    // ...PALETTE,
    ...LIGHT_PALETTE,
  },
});
