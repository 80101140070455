import { Box, Button, Modal as ModalUI, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { STRINGS } from 'constants/strings';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  p: 4,
  outline: 'none',
};

const Modal = ({
  open,
  onClose,
  title,
  subtitle,
  children,
  acceptBtnLabel,
  cancelBtnLabel,
  onAccept,
  loading,
  disableAcceptBtn,
  containerWidth,
  borderRadius,
}) => {
  const theme = useTheme();

  return (
    <ModalUI open={open} onClose={onClose}>
      <Box
        sx={{
          ...style,
          width: containerWidth,
          borderRadius,
          bgcolor: theme.palette.main.main,
        }}
      >
        {title && <Typography variant="h5">{title}</Typography>}
        {subtitle && (
          <Typography variant="h6" color="text.secondary">
            {subtitle}
          </Typography>
        )}
        <Box sx={{ marginTop: 1, marginBottom: '50px' }}>{children}</Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={onClose}
            variant="outlined"
            color="secondary"
            disabled={loading}
          >
            {cancelBtnLabel}
          </Button>
          <Button
            sx={{ ml: 2 }}
            onClick={onAccept}
            variant="contained"
            color="error"
            disabled={loading || disableAcceptBtn}
          >
            {acceptBtnLabel}
          </Button>
        </Box>
      </Box>
    </ModalUI>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  acceptBtnLabel: PropTypes.string,
  cancelBtnLabel: PropTypes.string,
  onAccept: PropTypes.func,
  loading: PropTypes.bool,
  disableAcceptBtn: PropTypes.bool,
  containerWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderRadius: PropTypes.string,
};

Modal.defaultProps = {
  title: '',
  subtitle: '',
  acceptBtnLabel: 'Ok',
  cancelBtnLabel: STRINGS.CANCEL,
  onAccept: () => {},
  loading: false,
  disableAcceptBtn: false,
  containerWidth: 600,
  borderRadius: 0,
};

export default Modal;
