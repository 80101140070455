import React, { useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import styled from '@mui/styled-engine';
import { LogoutButton, SkeletonLoading, Topbar } from '@components/index';
import { StyledFullHeight, StyledInnerContainer } from '@shared/styledContainers';
import { STRINGS } from 'constants/strings';
import { AddYourPodcastModal } from '@components/containers/index';
import { useAuth } from 'contexts/AuthContext';
import AddYourPodcastContextProvider from 'contexts/AddYourPodcastContext';
import { useCallback } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { COUNTRIES_BY_CODE } from 'constants/countriesList';
import { useEffect } from 'react';
import { requestAPIGraphQL } from '@services/appSyncAPI';
import { listPodcastsForPodcaster } from '@graphql/queries/podcasters';
import { useHistory } from 'react-router-dom';
import { PARTNERS_ROUTES } from 'constants/routing';
import Spacer from '@shared/spacer';
import { TAP_ADD_PODCAST } from 'constants/analytics';
import { record } from '@utils/analytics';

const StyledTypography = styled(Typography)(
  () => `
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
);

const IndyPodcastsPage = () => {
  const { logout, user } = useAuth();
  const [open, setOpen] = useState(false);
  const [podcasts, setPodcasts] = useState([]);
  const history = useHistory();

  const fetchPodcasts = useCallback(() => {
    requestAPIGraphQL(listPodcastsForPodcaster)
      .then(
        ({
          data: {
            listPodcastsByUserId: { items },
          },
        }) => {
          const _podcasts = items.filter((item) => !item.SK.includes('PODCASTER#'));

          setPodcasts(_podcasts);
        }
      )
      .catch(() => {
        history.push(PARTNERS_ROUTES.CLAIM);
      });
  }, [history]);

  const handleClose = () => {
    fetchPodcasts();
    setOpen(false);
  };
  const handleOpen = () => {
    record(TAP_ADD_PODCAST);
    setOpen(true);
  };

  const renderAddPodcastBox = useCallback(() => {
    return (
      <Box
        onClick={handleOpen}
        sx={{
          border: 2,
          borderRadius: 4,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          flexDirection: 'column',
          transition: 'background-color 0.1s',
          '&:hover': {
            backgroundColor: 'primaryDark.main',
          },
          borderColor: 'secondaryDark.main',
          minHeight: 270,
          height: '100%',
          width: '100%',
        }}
      >
        <AddIcon
          sx={{
            fontSize: 45,
            color: 'secondaryDark.main',
          }}
        />

        <Typography variant="h6" color="secondaryDark.main">
          {STRINGS.ADD_PODCAST}
        </Typography>
      </Box>
    );
  }, []);

  const renderPodcastsList = useCallback(() => {
    return (
      <Box>
        <Box
          sx={{
            mt: 2,
            maxWidth: '40%',
            border: 2,
            borderRadius: 4,
            p: 1,
            color: 'secondaryDark.main',
          }}
        >
          <Typography variant="h6">
            Owner: {user.attributes?.given_name} {user.attributes?.family_name}
          </Typography>
        </Box>

        <Spacer size={50} />

        <Grid container spacing={2}>
          {podcasts.map((podcast) => {
            const {
              imageUrl: image,
              availableInGeos = [],
              categories = [],
              SK,
            } = podcast;

            return (
              <Grid key={SK} item lg={4} md={4} sm={6} xs={12}>
                <Box
                  sx={{
                    p: 2,
                    border: 2,
                    borderRadius: 4,
                    borderColor: 'secondaryDark.main',
                    width: '100%',
                    minHeight: 270,
                    height: 'auto',
                  }}
                >
                  <img
                    src={image}
                    style={{ width: 150, height: 150 }}
                    alt="Podcast art"
                  />
                  <Box sx={{ color: 'secondaryDark.main' }}>
                    <StyledTypography variant="h6">{podcast.title}</StyledTypography>
                    <StyledTypography sx={{ mt: 1 }} variant="subtitle2">
                      {availableInGeos
                        ? availableInGeos.length === 0
                          ? STRINGS.WORLDWIDE
                          : availableInGeos
                              .map((region) => COUNTRIES_BY_CODE[region].Name)
                              .join(', ')
                        : ''}
                    </StyledTypography>

                    <StyledTypography
                      sx={{ ...(!categories && { visibility: 'hidden' }) }}
                      variant="subtitle2"
                    >
                      {categories ? categories.join(', ') : 'NA'}
                    </StyledTypography>
                  </Box>
                </Box>
              </Grid>
            );
          })}
          <Grid item lg={4} md={4} sm={6} xs={12}>
            {renderAddPodcastBox()}
          </Grid>
        </Grid>
      </Box>
    );
  }, [podcasts, renderAddPodcastBox, user]);

  useEffect(() => {
    fetchPodcasts();
  }, [fetchPodcasts]);

  if (Array.isArray(podcasts) && podcasts.length === 0) {
    return <SkeletonLoading isFullHeight isDarkTheme />;
  }

  return (
    <>
      <StyledFullHeight>
        <StyledInnerContainer>
          <Topbar
            renderDesktopItems={() => {
              return <LogoutButton onLogout={logout} />;
            }}
          />
          <Spacer size={50} />
          {renderPodcastsList()}
        </StyledInnerContainer>
      </StyledFullHeight>

      <AddYourPodcastContextProvider>
        <AddYourPodcastModal
          open={open}
          onClose={handleClose}
          redirect={false}
          isOnboarding={podcasts.length === 0}
        />
      </AddYourPodcastContextProvider>
    </>
  );
};

export default IndyPodcastsPage;
