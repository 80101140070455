import { useState, useCallback } from 'react';

export function useLoadItems({ loadItems }) {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [error, setError] = useState('');

  const loadMore = useCallback(async () => {
    setLoading(true);
    try {
      const { data, hasNextPage: newHasNextPage } = await loadItems();
      setItems((current) => [...current, ...data]);
      setHasNextPage(newHasNextPage);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [loadItems]);

  const clear = useCallback(() => {
    setLoading(false);
    setItems([]);
    setHasNextPage(true);
    setError('');
  }, []);

  return { loading, items, hasNextPage, error, loadMore, clear };
}
