import styled from '@mui/styled-engine';
import Button from '@mui/material/Button';

export const StyledFloatRightButton = styled(Button)(
  ({ theme }) => `
  min-width: 200px;
  position: absolute;
  top: ${theme.spacing(2)};
  right: ${theme.spacing(3)};
`
);
