import PropTypes from 'prop-types';

export const props = {
  disabled: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    })
  ),
  onClickAction: PropTypes.func.isRequired,
};

export const defaultProps = {
  disabled: false,
};
