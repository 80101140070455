import PropTypes from 'prop-types';
import LogoIcon from './logoIcon';
import WhiteLogoIcon from './logoWhiteIcon';

const MainMarbylIcon = (props) => {
  const renderIcon = () => {
    if (props.isDarkTheme) return <WhiteLogoIcon {...props} />;

    return <LogoIcon {...props} />;
  };
  return <div>{renderIcon()}</div>;
};

MainMarbylIcon.propTypes = {
  isDarkTheme: PropTypes.bool,
  isRecording: PropTypes.bool,
  width: PropTypes.number,
};

MainMarbylIcon.defaultProps = {
  isDarkTheme: true,
  isRecording: false,
  width: 90,
};

export default MainMarbylIcon;
