/* eslint-disable no-console */

export const NEWEST_OLDEST = [
  {
    value: 'NEWEST_OLDEST',
    name: 'Newest-Oldest',
  },
  {
    value: 'OLDEST_NEWEST',
    name: 'Oldest-Newest',
  },
];

export const SINCE = [
  {
    value: 'TODAY',
    name: 'Today',
  },
  {
    value: 'YESTERDAY',
    name: 'Yesterday',
  },
  {
    value: 'LAST7D',
    name: 'Last 7 days',
  },
  {
    value: 'LAST28D',
    name: 'Last 28 days',
  },
  {
    value: 'LAST90D',
    name: 'Last 90 days',
  },
  {
    value: 'ALLTIME',
    name: 'All time',
  },
];

export const MADE_BY = [
  {
    value: 'EVERYONE',
    name: 'Everyone',
  },
  {
    value: 'MARBYL_AI',
    name: 'Marbyl-AI',
  },
];

export const NETWORK_ITEMS_LIST = [
  {
    label: 'Network 1',
    id: 'subnetwork1',
    callback: (evt, item) => console.log(evt, item),
  },
  {
    label: 'The Investors Podcast Network',
    id: 'investors-podcast',
    callback: (evt, item) => console.log('clicked', evt, item),
    items: [
      {
        label: 'Investor 1',
        id: 'investor-1',
        callback: (evt, item) => console.log(evt, item),
      },
      {
        label: 'Investor 2',
        id: 'investor-2',
        callback: (evt, item) => console.log(evt, item),
      },
    ],
  },
  {
    label: 'Podcast One',
    id: 'podcast-one',
    callback: (evt, item) => console.log(evt, item),
    items: [
      {
        label: 'Podcaster 1',
        id: 'podcaster-1',
        callback: (evt, item) => console.log(evt, item),
      },
      {
        label: 'Podcaster 2',
        id: 'podcaster-2',
        callback: (evt, item) => console.log(evt, item),
      },
    ],
  },
];
