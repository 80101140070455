import {
  FEED_INGESTION_STATUS,
  M11N_AUTO_TYPE,
  M11N_EPISODE_PROCESSING_STATUS,
} from 'constants/m11n';

export const getFeedIngestionStatus = (status) => {
  return FEED_INGESTION_STATUS[status] || FEED_INGESTION_STATUS.pending;
};

export const getPodcastAutoType = (type) => {
  return M11N_AUTO_TYPE[type] || M11N_AUTO_TYPE.disabled;
};

export const getEpisodeProcessingStatus = (type) => {
  return M11N_EPISODE_PROCESSING_STATUS[type] || M11N_EPISODE_PROCESSING_STATUS.NULL;
};
