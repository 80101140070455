import { CancelConfirmDeleteAccount } from '@components/index';
import { cancelDeleteAccount } from '@graphql/mutations/accounts';

const CancelDeleteAccountPage = () => {
  return (
    <CancelConfirmDeleteAccount
      deleteMutation={cancelDeleteAccount}
      textMessage="You have cancelled the Marbyl account deletion process."
    />
  );
};

export default CancelDeleteAccountPage;
