import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';

import { PODCAST_NETWORK_VIEW_ACTIONS } from 'constants/actionsList';
import { ROUTES } from 'constants/routing';
import { STRINGS } from 'constants/strings';
import { DRAWER_WIDTH } from 'constants/navigation';

import {
  StyledListItem,
  LinkBehaviour,
  StyledListItemIcon,
  StyledVersions,
} from './layout.styled';
import { APP_VERSION } from 'constants/secrets';
import { useAuth } from 'contexts/AuthContext';
import { useMPC } from 'contexts/MPCContext';

const Sidebar = ({ open, onClose, version }) => {
  const { permissions } = useAuth();
  const { getIsSuperUser } = useMPC();
  const theme = useTheme();

  const getRoutesList = () => {
    const isSuperUser = getIsSuperUser();
    const hasReportedContentAccess =
      permissions[PODCAST_NETWORK_VIEW_ACTIONS?.GO_TO_CONTENT_REPORTS_PAGE] &&
      isSuperUser;
    let updatedRoutes = [...ROUTES];

    if (!getIsSuperUser()) {
      updatedRoutes = updatedRoutes.filter(
        (updatedRoute) => updatedRoute.label === STRINGS.DASHBOARD
      );
    } else {
      if (!hasReportedContentAccess)
        updatedRoutes = updatedRoutes.filter(
          (updatedRoute) => updatedRoute.label !== STRINGS.REPORTED_CONTENT
        );

      if (!isSuperUser)
        updatedRoutes = updatedRoutes.filter(
          (updateRoute) => updateRoute.label !== STRINGS.TRANSCRIPT_REVIEWS
        );
    }

    return updatedRoutes.map((item, index) => (
      <StyledListItem
        activeClassName="active"
        to={item.to}
        component={LinkBehaviour}
        button
        key={index}
      >
        <StyledListItemIcon sx={{ color: 'secondary.main' }}>
          {item.icon}
        </StyledListItemIcon>
        <ListItemText primary={item.label} />
      </StyledListItem>
    ));
  };

  return (
    <Drawer
      sx={{
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          color: 'secondary.main',
          display: 'flex',
          justifyContent: 'space-between',
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
          backgroundColor: theme.palette.main.main,
          backgroundImage: 'none',
          pt: 10,
        },
      }}
      variant="persistent"
      open={open}
      onClose={onClose}
      anchor="left"
    >
      <List>{getRoutesList()}</List>
      <Toolbar
        sx={{
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          marginBottom: 1,
        }}
      >
        <StyledVersions>{`${APP_VERSION}/1.${version}`}</StyledVersions>
      </Toolbar>
    </Drawer>
  );
};

export default Sidebar;
