import { STRINGS } from './strings';

export const ROLES = [
  { PK: 'podcasterAccountOwner', name: 'Podcaster Account Owner' },
  { PK: 'podcasterAdministrator', name: 'Podcaster Administrator' },
  { PK: 'podcasterUser', name: 'Podcaster User' },

  { PK: 'podcastNetworkAccountOwner', name: 'Podcast Network Account Owner' },
  { PK: 'podcastNetworkAdministrator', name: 'Podcast Network Administrator' },
  { PK: 'podcastNetworkUser', name: 'Podcast Network User' },

  { PK: 'treeGoatAdministrator', name: 'Tree Goat Administrator' },
  { PK: 'treeGoatSuperuser', name: 'Tree Goat Superuser' },
  { PK: 'treeGoatUser', name: 'Tree Goat User' },
  { PK: 'userAccountOwner', name: 'User Account Owner' },
];

export const USER_ROLES = {
  PODCASTER_ACCOUNT_OWNER: 'podcasterAccountOwner',
  PODCASTER_ADMIN: 'podcasterAdministrator',
  PODCASTER_USER: 'podcasterUser',
  TGM_ADMIN: 'treeGoatAdministrator',
  TGM_SUPERUSER: 'treeGoatSuperuser',
  TGM_USER: 'treeGoatUser',
  PODCAST_NETWORK_ACCOUNT_OWNER: 'podcastNetworkAccountOwner',
  PODCAST_NETWORK_ADMIN: 'podcastNetworkAdministrator',
  PODCAST_NETWORK_USER: 'podcastNetworkUser',
  USER_ACCOUNT_OWNER: 'userAccountOwner',
};

export const ROLES_LABELS = {
  [USER_ROLES.TGM_ADMIN]: STRINGS.TREE_GOAT_ADMIN,
  [USER_ROLES.TGM_SUPERUSER]: STRINGS.TREE_GOAT_SUPER,
  [USER_ROLES.TGM_USER]: STRINGS.TREE_GOAT_USER,
  [USER_ROLES.PODCASTER_ACCOUNT_OWNER]: STRINGS.PODCASTER_ACCOUNT_OWNER,
  [USER_ROLES.PODCASTER_ADMIN]: STRINGS.PODCASTER_ADMIN,
  [USER_ROLES.PODCASTER_USER]: STRINGS.PODCASTER_USER,
  [USER_ROLES.PODCAST_NETWORK_ACCOUNT_OWNER]: STRINGS.PODCAST_NETWORK_ACCOUNT_OWNER,
  [USER_ROLES.PODCAST_NETWORK_ADMIN]: STRINGS.PODCAST_NETWORK_ADMIN,
  [USER_ROLES.PODCAST_NETWORK_USER]: STRINGS.PODCAST_NETWORK_USER,
};
