import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const MessageIcon = ({ icon, text, typographyVariant }) => {
  const renderIcon = () => {
    const iconList = {
      info: <InfoIcon fontSize="small" color="success" sx={{ mr: 1 }} />,
      check: <CheckCircleIcon fontSize="small" color="info" sx={{ mr: 1 }} />,
      none: null,
    };

    return iconList[icon];
  };

  const renderText = () => {
    if (typeof text === 'string') return text;

    return text.map((item, index) => (
      <div key={`item-${index}`}>
        {item}
        {index !== text.length - 1 && <br />}
      </div>
    ));
  };

  return (
    <Box sx={{ display: 'flex', mt: 1, alignItems: 'center' }}>
      {renderIcon()}
      <Typography variant={typographyVariant}>{renderText()}</Typography>
    </Box>
  );
};

MessageIcon.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
    .isRequired,
  icon: PropTypes.oneOf(['info', 'check', 'none']),
  typographyVariant: PropTypes.string,
};

MessageIcon.defaultProps = {
  icon: 'info',
  typographyVariant: 'caption',
};

export default MessageIcon;
