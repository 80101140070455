import React, { useMemo } from 'react';
import { Paper } from '@mui/material';
import { STRINGS } from 'constants/strings';
import { DynamicForm } from '@components/index';
import { dateToMMDoYYYY } from '@utils/dateFormat';
import { FIELD_TYPES } from 'constants/dynamicFormTypes';
import { record } from '@utils/analytics';
import { TAP_SAVE_MARBYL } from 'constants/analytics';

const EditMarbyl = ({ marbyl, onCancel, onSubmit }) => {
  const fields = useMemo(() => {
    return [
      {
        type: FIELD_TYPES.TEXT,
        label: STRINGS.MARBYL_TEXT,
        name: 'text',
        required: true,
        color: 'secondary',
        md: 12,
        xs: 12,
        multiline: true,
        maxRows: 2,
        disabled: true,
      },
      {
        type: FIELD_TYPES.TEXT,
        label: STRINGS.MARBYL_CREATION_DATE,
        name: 'createdAt',
        required: true,
        color: 'secondary',
        md: 6,
        xs: 12,
        disabled: true,
      },
      {
        type: FIELD_TYPES.TEXT,
        label: STRINGS.MARBYL_SOURCE_TYPE,
        name: 'source',
        required: true,
        color: 'secondary',
        md: 6,
        xs: 12,
        disabled: true,
      },
      {
        type: FIELD_TYPES.TEXT,
        label: STRINGS.MARBYLIZATION_MODEL_USED,
        name: 'modelName',
        required: true,
        color: 'secondary',
        md: 6,
        xs: 12,
        disabled: true,
      },
      {
        type: FIELD_TYPES.TEXT,
        label: STRINGS.MARBYLIZATION_MODEL_VERSION,
        name: 'modelVersion',
        required: true,
        color: 'secondary',
        md: 6,
        xs: 12,
        disabled: true,
      },
      {
        type: FIELD_TYPES.TEXT,
        label: STRINGS.GENRE,
        name: 'genre',
        required: true,
        color: 'secondary',
        md: 6,
        xs: 12,
        disabled: true,
      },
      {
        type: FIELD_TYPES.TEXT,
        label: STRINGS.SUB_GENRE,
        name: 'subgenre',
        required: true,
        color: 'secondary',
        md: 6,
        xs: 12,
        disabled: true,
      },
      {
        type: FIELD_TYPES.CHECKBOX,
        label: STRINGS.IS_ACTIVATED,
        name: 'isActivated',
        color: 'secondary',
        md: 6,
        xs: 12,
      },
      {
        type: FIELD_TYPES.TEXT,
        label: STRINGS.EPISODE_ACTIVATION_DATE,
        name: 'isActivatedUpdated',
        color: 'secondary',
        md: 6,
        xs: 12,
        disabled: true,
      },
    ];
  }, []);

  const initialValues = useMemo(() => {
    const {
      text,
      createdAt,
      source,
      modelName,
      modelVersion,
      genre,
      subgenre,
      isActivated,
      isActivatedUpdated,
    } = marbyl;

    return {
      text,
      createdAt: dateToMMDoYYYY(createdAt),
      source,
      modelName,
      modelVersion,
      genre,
      subgenre,
      isActivated,
      isActivatedUpdated: dateToMMDoYYYY(isActivatedUpdated),
    };
  }, [marbyl]);

  const handleSubmit = (values) => {
    record(TAP_SAVE_MARBYL, { id: marbyl.id });
    onSubmit({ ...values, PK: marbyl.PK });
  };

  return (
    <Paper sx={{ p: 2, bgcolor: 'primary.main', color: 'secondary.main' }} elevation={2}>
      <DynamicForm
        formHeader={STRINGS.EDIT_MARBYL}
        formHeaderVariant={'h6'}
        initialValues={initialValues}
        fields={fields}
        onCancel={onCancel}
        onSubmit={handleSubmit}
      />
    </Paper>
  );
};

export default EditMarbyl;
