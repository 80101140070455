import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Layout } from '@components/index';
import MPCPage from '@pages/MPCPage/MPCPage';
import { MAIN_ROUTES, PARTNERS_ROUTES } from 'constants/routing';
import { useAuth } from 'contexts/AuthContext';
import { useMPC } from 'contexts/MPCContext';
import { PODCAST_NETWORK_VIEW_ACTIONS } from 'constants/actionsList';
import { Switch, Redirect, Route } from 'react-router';
import { useHistory } from 'react-router-dom';
import UsersRoutes from './UsersRoutes_v2';
import MPCReportsRoutes from './MPCReportsRoutes';
import TranscriptionErrorsRoutes from './TranscriptionErrorsRoutes';
import { MarbylChartView } from '@components/containers';

const MPCPageWrapper = (...props) => <MPCPage {...props} />;

const DashboardRoutes = () => {
  const history = useHistory();
  const { permissions } = useAuth();
  const { getIsSuperUser, selectedAccount } = useMPC();
  const hasReportedContentAccess =
    permissions[PODCAST_NETWORK_VIEW_ACTIONS?.GO_TO_CONTENT_REPORTS_PAGE] &&
    getIsSuperUser();

  useEffect(() => {
    if (!isEmpty(selectedAccount) && selectedAccount?.entityAccountType !== 'TGM') {
      history.push(PARTNERS_ROUTES.PODCASTS);
    }
  }, [history, selectedAccount]);

  return (
    <Switch>
      <Redirect exact from={MAIN_ROUTES.APP} to={MAIN_ROUTES.MPC} />
      <Layout>
        <Route path={MAIN_ROUTES.MPC}>
          <MPCPageWrapper />
        </Route>
        <Route path={MAIN_ROUTES.MARBYL_CHART_VIEW}>
          <MarbylChartView />
        </Route>
        {getIsSuperUser() && (
          <>
            <Route path={MAIN_ROUTES.USERS}>
              <UsersRoutes />
            </Route>
            {hasReportedContentAccess && (
              <Route path={MAIN_ROUTES.REPORTED_CONTENT}>
                <MPCReportsRoutes />
              </Route>
            )}
            <Route path={MAIN_ROUTES.TRANSCRIPTION_ERRORS}>
              <TranscriptionErrorsRoutes />
            </Route>
          </>
        )}
      </Layout>
    </Switch>
  );
};

export default DashboardRoutes;
