import { FIELD_TYPES } from 'constants/dynamicFormTypes';
import PropTypes from 'prop-types';

export const propTypes = {
  formHeader: PropTypes.string,
  formHeaderVariant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  fields: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        type: PropTypes.oneOf([FIELD_TYPES.TITLE]),
        label: PropTypes.string.isRequired,
        variant: PropTypes.string,
      }),
      PropTypes.shape({
        type: PropTypes.oneOf([FIELD_TYPES.TEXT]),
        label: PropTypes.string.isRequired,
        value: PropTypes.string,
        name: PropTypes.string.isRequired,
        required: PropTypes.bool,
        multiline: PropTypes.bool,
        rows: PropTypes.number,
        inputType: PropTypes.string,
        minRows: PropTypes.number,
        maxRows: PropTypes.number,
      }),
      PropTypes.shape({
        type: PropTypes.oneOf([FIELD_TYPES.SELECT]),
        label: PropTypes.string.isRequired,
        value: PropTypes.string,
        name: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(
          PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
              label: PropTypes.string.isRequired,
              value: PropTypes.string.isRequired,
            }),
          ])
        ),
        required: PropTypes.bool,
        disabled: PropTypes.bool,
      }),
      PropTypes.shape({
        type: PropTypes.oneOf([FIELD_TYPES.SELECT_MULTIPLE]),
        label: PropTypes.string.isRequired,
        value: PropTypes.array,
        name: PropTypes.string.isRequired,
        items: PropTypes.array,
        valueName: PropTypes.string.isRequired,
        labelName: PropTypes.string.isRequired,
        required: PropTypes.bool,
      }),
      PropTypes.shape({
        type: PropTypes.oneOf([FIELD_TYPES.IMAGE]),
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        color: PropTypes.string,
      }),
      PropTypes.shape({
        type: PropTypes.oneOf([FIELD_TYPES.CHECKBOX_SELECT]),
        checkbox: PropTypes.shape({
          label: PropTypes.string.isRequired,
          initialValue: PropTypes.bool,
        }),
        checkedPlaceholder: PropTypes.string.isRequired,
        helperText: PropTypes.string.isRequired,
        items: PropTypes.array,
        label: PropTypes.string.isRequired,
        labelName: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        required: PropTypes.bool,
        uncheckedPlaceholder: PropTypes.string.isRequired,
        value: PropTypes.array,
        valueName: PropTypes.string.isRequired,
      }),
      PropTypes.shape({
        type: PropTypes.oneOf([FIELD_TYPES.PODCAST_SEARCH]),
        rssState: PropTypes.array.isRequired,
        onRSSLoad: PropTypes.func.isRequired,
      }),
      PropTypes.shape({
        type: PropTypes.oneOf([FIELD_TYPES.LIST]),
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        headerColumns: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
          })
        ),
        inputs: PropTypes.arrayOf(
          PropTypes.shape({
            required: PropTypes.bool,
            name: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            defaultValue: PropTypes.any.isRequired,
            label: PropTypes.string,
          })
        ),
        addButtonLabel: PropTypes.string,
      }),
      PropTypes.shape({
        type: PropTypes.oneOf([FIELD_TYPES.COUNTRIES_SELECT]),
        name: PropTypes.string.isRequired,
        color: PropTypes.string,
        label: PropTypes.string,
      }),
      PropTypes.shape({
        type: PropTypes.oneOf([FIELD_TYPES.CHECKBOX]),
        name: PropTypes.string.isRequired,
        color: PropTypes.string,
        label: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
      }),
      PropTypes.shape({
        type: PropTypes.oneOf([FIELD_TYPES.PHONE_NUMBER]),
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        color: PropTypes.string,
        disabled: PropTypes.bool,
      }),
    ])
  ),
  onSubmit: PropTypes.func.isRequired,
  validations: PropTypes.object,
  initialValues: PropTypes.object,
  onCancel: PropTypes.func,
};
