import React, { useMemo } from 'react';
import { Button, Box } from '@mui/material';
import { STRINGS } from 'constants/strings';
import EditIcon from '@mui/icons-material/Edit';
import { ArrowBackTitle, EntitiesDataGrid, MetaInfo } from '@components/index';
import profileImg from '@images/profile.png';
import { getUserRoleLabel } from '@utils/roles';

const UserProfileDetail = ({ profile, onClickEdit = () => null }) => {
  const columnDefs = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Account Name',
        flex: 1,
      },
      {
        field: 'role',
        headerName: 'Role',
        flex: 1,
        cellRenderer: ({ value }) => getUserRoleLabel(value),
      },
    ],
    []
  );

  const userInfo = useMemo(() => {
    if (!profile) {
      return [];
    }

    const {
      user: { name, email, phoneNumber, avatar, bio },
      userAccounts,
    } = profile;

    return [
      {
        label: STRINGS.FULLNAME,
        value: name,
        md: 6,
        xs: 12,
      },
      { label: STRINGS.EMAIL, value: email, md: 6, xs: 12 },
      { label: 'Mobile Phone', value: phoneNumber, md: 6, xs: 12 },
      {
        label: '',
        renderValue: () => {
          return (
            <Box
              sx={{
                width: 75,
                height: 75,
                borderRadius: 2,
                overflow: 'hidden',
                border: 1,
                borderColor: 'primary.main',
              }}
            >
              <img style={{ width: '100%' }} src={avatar || profileImg} alt="Profile" />
            </Box>
          );
        },
        md: 6,
        xs: 12,
      },
      {
        label: STRINGS.BIOGRAPHY,
        value: bio || '',
        md: 12,
        xs: 12,
      },
      {
        label: STRINGS.ASSOCIATED_ACCOUNTS,
        md: 12,
        xs: 12,
        renderValue: () => {
          return (
            <EntitiesDataGrid
              rowData={userAccounts.map(({ entityName, accountRole }) => ({
                name: entityName,
                role: accountRole,
              }))}
              topHeight={600}
              columnDefs={columnDefs}
            />
          );
        },
      },
    ];
  }, [profile, columnDefs]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 4,
        }}
      >
        <ArrowBackTitle>{STRINGS.USER_PROFILE}</ArrowBackTitle>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            sx={{ mr: 2 }}
            onClick={onClickEdit}
            variant="outlined"
            color="secondary"
          >
            {STRINGS.EDIT}
            <EditIcon sx={{ ml: 1, fontSize: 18 }} />
          </Button>
        </Box>
      </Box>
      <MetaInfo items={userInfo} />
    </>
  );
};

export default UserProfileDetail;
