import React, { useMemo } from 'react';
import { useRouteMatch, useParams } from 'react-router';
import { EpisodeContainer, TranscriptContainer } from '@components/containers/index';

import { CommonSwitch } from './common';
import MarbylRoutes from './MarbylRoutes';
import { MPC_ROUTES } from 'constants/routing';

const EpisodesRoutes = () => {
  const { url } = useRouteMatch();
  const { id } = useParams();

  const routes = useMemo(
    () => [
      {
        exact: true,
        path: url,
        childComponent: <EpisodeContainer id={id} />,
      },
      {
        path: `${url}${MPC_ROUTES.MARBYLS}/:id`,
        childComponent: <MarbylRoutes />,
      },
      {
        path: `${url}${MPC_ROUTES.TRANSCRIPT_VIEW}`,
        childComponent: <TranscriptContainer episodeId={id} />,
      },
    ],
    [id, url]
  );

  return <CommonSwitch routes={routes} />;
};

export default EpisodesRoutes;
