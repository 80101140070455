import React from 'react';
import PropTypes from 'prop-types';
import { ListContainer } from './styles';

const MenuList = ({ children }) => (
  <ListContainer>
    <div>{children}</div>
  </ListContainer>
);

MenuList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MenuList;
