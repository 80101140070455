import React, { useState /*useCallback*/ } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { STRINGS } from 'constants/strings';
import { FONT_FAMILIES } from 'constants/stylesVariables';
import {
  StyledTitle,
  StyledBlackButton,
  StyledClaimBtnContainer,
} from './PartnersLandingPage.styles';

import { StyledContainer } from '@shared/styledLandingPages';
import { UnauthenticatedForms } from '@components/index';
import { LandingPagesContainer } from '@components/containers/index';

import { useAuth } from 'contexts/AuthContext';
import { MAIN_ROUTES } from 'constants/routing';
import {
  //   TAP_CLAIM_PODCAST,
  //   TAP_LOGIN,
  TAP_MY_ACCOUNT,
  // TAP_SIGN_UP_CONFIRMATION,
} from 'constants/analytics';

import { record } from '@utils/analytics';
// import { SIGN_IN_REDIRECT_KEY, SIGN_UP_REDIRECT_KEY } from 'constants/localStorageKeys';
import PartnersBackground from '@images/partners-background.svg';

const PartnersLandingPage = () => {
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();
  const { authed } = useAuth();

  // const handleClaimPodcastClick = useCallback(() => {
  //   if (authed) {
  //     record(TAP_MY_ACCOUNT);
  //     history.push(`${MAIN_ROUTES.APP}`);
  //     return;
  //   }

  //   record(TAP_CLAIM_PODCAST);
  //   localStorage.setItem(SIGN_UP_REDIRECT_KEY, PARTNERS_ROUTES.CLAIM);
  //   history.push(`${MAIN_ROUTES.REGISTER}`);
  // }, [authed, history]);

  const toggleModal = () => setOpenModal((prevOpen) => !prevOpen);

  const handleClaimBtn = () => {
    if (authed) {
      record(TAP_MY_ACCOUNT);
      history.push(MAIN_ROUTES.APP);
      return;
    }

    toggleModal();
  };

  return (
    <StyledContainer>
      <LandingPagesContainer
        spacerSize={0}
        showPartners={false}
        imgBackground={PartnersBackground}
        imgBackgroundDimensions={{
          height: 'auto', // In mobile it should be auto or 80%
          width: 'auto',
        }}
        desktopTop="28px"
        mobileTop="220px"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
        >
          <StyledTitle>{STRINGS.SPEAK_AND_THEY_SHALL_FIND}</StyledTitle>
          <Typography
            sx={{
              textAlign: 'center',
              color: '#FFF',
              fontSize: '22px',
              fontWeight: 500,
              fontFamily: FONT_FAMILIES.proximaNovaBlack,
              lineHeight: '21.92px',
            }}
            variant="body1"
          >
            {STRINGS.YOUR_VOICE_MATTERS}
          </Typography>
          <StyledClaimBtnContainer>
            <StyledBlackButton onClick={handleClaimBtn}>
              {authed ? STRINGS.MY_ACCOUNT : STRINGS.CLAIM_YOUR_PODCAST}
            </StyledBlackButton>
          </StyledClaimBtnContainer>
        </Box>
      </LandingPagesContainer>

      {openModal && <UnauthenticatedForms isOpen={openModal} onClose={toggleModal} />}
    </StyledContainer>
  );
};

export default PartnersLandingPage;
