import React, { useMemo } from 'react';
import { useRouteMatch, useParams } from 'react-router';
import { PodcastContainer } from '@components/containers/index';
import EpisodesRoutes from '@routes/EpisodesRoutes';
import { CommonSwitch } from './common';
import { MPC_ROUTES } from 'constants/routing';

const PodcastRoutes = () => {
  const { url } = useRouteMatch();
  const { id } = useParams();

  const routes = useMemo(
    () => [
      {
        exact: true,
        path: url,
        childComponent: <PodcastContainer id={id} />,
      },
      {
        path: `${url}${MPC_ROUTES.EPISODES}/:id`,
        childComponent: <EpisodesRoutes />,
      },
    ],
    [url, id]
  );

  return (
    <>
      <CommonSwitch routes={routes} />
    </>
  );
};

export default PodcastRoutes;
