import { STRINGS } from 'constants/strings';

export const getEntityType = (type) => {
  const validateType = (valueType) =>
    type === valueType || type?.toLowerCase()?.includes(valueType?.toLowerCase());

  const possibleValues = [
    {
      flag: validateType(STRINGS.NETWORK),
      value: STRINGS.NETWORK,
    },
    {
      flag: validateType(STRINGS.PODCASTER),
      value: STRINGS.PODCASTER,
    },
    {
      flag: validateType(STRINGS.PODCAST) || type?.toLowerCase() === 'show',
      value: STRINGS.PODCAST,
    },
    {
      flag: validateType(STRINGS.EPISODE),
      value: STRINGS.EPISODE,
    },
  ];

  return possibleValues.find((possibleValue) => possibleValue.flag)?.value || '';
};
