import React, { useCallback } from 'react';
import {
  TextField,
  Typography,
  Button,
  Checkbox,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from '@mui/material';
import { Controller, useFieldArray } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { props } from './props';

const ARRAY_FIELD_TYPES = {
  TEXT: 'text',
  EMAIL: 'email',
  PHONE: 'phone',
  CHECKBOX: 'checkbox',
  HIDDEN: 'hidden',
};

const FieldArrayInput = ({
  control,
  name,
  headerColumns,
  inputs,
  label,
  addButtonLabel,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const renderField = useCallback(
    (type, input, fieldIndex) => {
      switch (type) {
        case ARRAY_FIELD_TYPES.TEXT:
          return (
            <Controller
              name={`${name}.${fieldIndex}.${input.name}`}
              control={control}
              render={({ field }) => (
                <TextField
                  multiline
                  maxRows={4}
                  required={!!input.required}
                  type={'text'}
                  fullWidth
                  color="secondary"
                  label={input.label}
                  {...field}
                />
              )}
            />
          );

        case ARRAY_FIELD_TYPES.EMAIL:
          return (
            <Controller
              name={`${name}.${fieldIndex}.${input.name}`}
              control={control}
              render={({ field }) => (
                <TextField
                  required={!!input.required}
                  type={'email'}
                  fullWidth
                  color="secondary"
                  label={input.label}
                  {...field}
                />
              )}
            />
          );

        case ARRAY_FIELD_TYPES.CHECKBOX:
          return (
            <Controller
              name={`${name}.${fieldIndex}.${input.name}`}
              control={control}
              render={({ field }) => <Checkbox color="secondary" {...field} />}
            />
          );

        case ARRAY_FIELD_TYPES.PHONE:
          return (
            <Controller
              name={`${name}.${fieldIndex}.${input.name}`}
              control={control}
              render={({ field }) => (
                <ReactInputMask {...field} mask="+1 (999) 999-9999">
                  {(inputProps) => (
                    <TextField
                      {...inputProps}
                      name={`${name}.${fieldIndex}.${input.name}`}
                      required={!!input.required}
                      type={'text'}
                      fullWidth
                      color="secondary"
                      label={input.label}
                    />
                  )}
                </ReactInputMask>
              )}
            />
          );

        case ARRAY_FIELD_TYPES.HIDDEN:
          return (
            <Controller
              name={`${name}.${fieldIndex}.${input.name}`}
              control={control}
              render={({ field }) => <input type="hidden" value={field.value} />}
            />
          );

        default:
          return null;
      }
    },
    [control, name]
  );

  return (
    <>
      <Typography sx={{ mb: 2 }} variant="h6">
        {label}
      </Typography>
      <TableContainer sx={{ width: '100%', mb: 2 }} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {headerColumns.map((col, index) => (
                <TableCell key={index}>{col.name}</TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((item, index) => {
              return (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  key={item.id}
                >
                  {inputs.map((input) => {
                    return (
                      <TableCell key={input.name} component="th" scope="row">
                        <Controller
                          name={`${name}.${index}.id`}
                          control={control}
                          render={({ field }) => <input {...field} type="hidden" />}
                        />
                        {renderField(input.type, input, index)}
                      </TableCell>
                    );
                  })}

                  <TableCell onClick={() => remove(index)} component="th" scope="row">
                    <DeleteIcon role="button" color="error" />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Box sx={{ pt: 1, pb: 1, pl: 1 }}>
          <Button
            color="info"
            onClick={() => {
              const newObj = inputs.reduce(
                (obj, _input) => ({
                  ...obj,
                  [_input.name]: _input.defaultValue,
                }),
                {}
              );

              append(newObj);
            }}
          >
            <AddIcon />
            {addButtonLabel && <Typography variant="body2">{addButtonLabel}</Typography>}
          </Button>
        </Box>
      </TableContainer>
    </>
  );
};

FieldArrayInput.propTypes = props;

export default FieldArrayInput;
