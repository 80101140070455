import { useEffect } from 'react';
import { LOAD_MODAL_SUBPAGE } from 'constants/analytics';
import { record } from '@utils/analytics';
import { useAddYourPodcast } from 'contexts/AddYourPodcastContext';

const useLoadModalSubpage = (pageName) => {
  const {
    podcastState: [podcast],
  } = useAddYourPodcast();

  useEffect(() => {
    record(LOAD_MODAL_SUBPAGE, {
      pageName,
      ...(podcast && {
        podcastRssFeedURL: podcast.feedUrl,
        podcastTitle: podcast.title,
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageName]);
};

export default useLoadModalSubpage;
