import { Box } from '@mui/material';
import styled from '@mui/styled-engine';
import { DESKTOP_BREAKPOINT } from 'constants/breakpoints';
import { HEX_COLORS, FONT_FAMILIES, RGB_COLORS } from 'constants/stylesVariables';

export const StyledLoginButton = styled('button')(
  () => `
  background-color: transparent;
  border: 0;
  color: ${HEX_COLORS.white};
  cursor: pointer;
  font-family: ${FONT_FAMILIES.mallory};
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.1em;
  transition: opacity 0.2s;
  line-height: 21px;
  &:hover {
    opacity: 0.75;
  }
`
);

export const StyledTitle = styled('h2')(
  ({ theme }) => `
    color: ${HEX_COLORS.white};
    font-family: ${FONT_FAMILIES.proximaNovaBlack};
    font-size: 40px;
    font-weight: 900;
    line-height: 48.72px;
    margin: 0;
    margin-bottom: 470px;
    margin-top: 112px;
    text-align: center;
    white-space: pre-wrap;

    @media (max-width: ${theme.breakpoints.values.sm}px) {
      font-size: 36px;
      line-height: 39px;
      margin-bottom: 200px;
      margin-top: 112px;
    }
  `
);

export const StyledSubtitle = styled('span')(
  () => `
  color: ${HEX_COLORS.white};
  font-size: 24px;
  font-weight: normal;
  line-height: 32px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  white-space: pre-wrap;
  @media only screen and ${DESKTOP_BREAKPOINT} {
    margin-left: 0;
    margin-right: 0;
  }
`
);

export const StyledCardTitle = styled('h2')(
  ({ fontSize, lineHeight }) => `
  color: ${HEX_COLORS.white};
  font-weight: bold;
  text-align: center;
  font-size: ${fontSize};
  line-height: ${lineHeight};
  white-space: pre-wrap;
  @media only screen and ${DESKTOP_BREAKPOINT} {
    text-align: initial;
  }
`
);

export const StyledCardDescription = styled('p')(
  ({ fontSize, lineHeight }) => `
  color: ${RGB_COLORS.black};
  font-weight: normal;
  font-size: ${fontSize};
  line-height: ${lineHeight};
  text-align: center;
  white-space: pre-wrap;
  @media only screen and ${DESKTOP_BREAKPOINT} {
    text-align: initial;
  }
`
);

export const StyledClaimBtnContainer = styled(Box)(
  () => `
    max-width: 260px;
    margin: 0 auto;
    margin-bottom: 91px;
    margin-top: 28px;
  `
);

export const StyledBlackButton = styled('button')(
  () => `
  background-color: ${HEX_COLORS.white};
  border-radius: 26px;
  border: 0;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.2);
  color: ${HEX_COLORS.purple4};
  cursor: pointer;
  font-family: ${FONT_FAMILIES.proximaNovaBlack};
  font-size: 20px;
  font-weight: 800;
  line-height: 20px;
  padding: 16px 40px;
  text-transform: lowercase;
  transition: opacity 0.2s;
  z-index: 2;
  &:hover {
    opacity: 0.75;
  }
`
);

export const StyledInnerContainer = styled(Box)(
  () => `
  display: flex;
  flex-direction: column;
  height: inherit;
  margin: 0 auto;
  min-width: 320px;
`
);
