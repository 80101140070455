import React, { useMemo } from 'react';
import { useRouteMatch, useParams } from 'react-router';

import { NetworkContainer } from '@components/containers/index';

import PodcasterRoutes from '@routes/PodcasterRoutes';
import { CommonSwitch } from './common';
import { MPC_ROUTES } from 'constants/routing';
import PodcastRoutes from './PodcastRoutes';

const NetworkRoutes = () => {
  const { url } = useRouteMatch();
  const { id = 'tgm' } = useParams();

  const routes = useMemo(
    () => [
      {
        exact: true,
        path: url,
        childComponent: <NetworkContainer id={id} />,
      },
      {
        path: `${url}${MPC_ROUTES.NETWORKS}/:id`,
        childComponent: <NetworkRoutes />,
      },
      {
        path: `${url}${MPC_ROUTES.PODCAST}/:id`,
        childComponent: <PodcastRoutes />,
      },
      {
        path: `${url}${MPC_ROUTES.PODCASTER}/:id`,
        childComponent: <PodcasterRoutes />,
      },
    ],
    [url, id]
  );

  return (
    <>
      <CommonSwitch routes={routes} />
    </>
  );
};

export default NetworkRoutes;
