import { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import TranscriptText from '@components/transcriptText/index';
import RevisionItemDetails from '@components/revisionItemDetails/index';
import { requestAPIGraphQL } from '@services/appSyncAPI';
import { useDeps } from 'contexts/DepsContext';
import { getTranscriptDetails } from '@graphql/queries/transcripts';
import {
  resolveTranscriptReviewRequest,
  cancelTranscriptReviewRequest,
} from '@graphql/mutations/transcriptions';
import { format } from '@utils/dateFormat';
import { getTranscriptSelectedTextInfo } from '@utils/transcripts';
import { TRANSCRIPT_REVIEW_ITEM } from 'constants/idPrefixes';
import { STRINGS } from 'constants/strings';

const TranscriptDetails = () => {
  const [loading, setLoading] = useState(false);
  const [loadingCancelTranscript, setLoadingCancelTranscript] = useState(false);
  const [loadingResolveTranscript, setLoadingResolveTranscript] = useState(false);
  const [details, setDetails] = useState({
    createdAt: '',
    createdBy: '',
    comment: '',
    transcriptFragment: '',
    prevText: '',
    lastText: '',
    status: '',
  });
  const isOpenStatus = details?.status === 'OPEN';
  const { id } = useParams();
  const { httpClient } = useDeps();

  const fetchTranscriptDetails = useCallback(() => {
    if (id) {
      setLoading(true);

      requestAPIGraphQL(getTranscriptDetails, {
        transcriptId: `${TRANSCRIPT_REVIEW_ITEM}${id}`,
      })
        .then(({ data }) => {
          if (data?.transcriptDetails) {
            const {
              TranscriptReview: transcriptReview,
              TranscriptText: transcript,
              User: transcriptUser,
            } = data?.transcriptDetails;
            const { prevText, lastText } = getTranscriptSelectedTextInfo(
              transcript,
              transcriptReview?.transcriptFragment
            );

            setDetails({
              createdAt: format(transcriptReview?.createdAt),
              createdBy: transcriptUser?.name,
              comment: transcriptReview?.comment,
              transcriptFragment: transcriptReview?.transcriptFragment,
              status: transcriptReview?.status,
              prevText,
              lastText,
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    fetchTranscriptDetails();
  }, [fetchTranscriptDetails]);

  const cancelTranscript = () => {
    setLoadingCancelTranscript(true);

    httpClient(cancelTranscriptReviewRequest, {
      transcriptIds: [`${TRANSCRIPT_REVIEW_ITEM}${id}`],
    })
      .then(({ data }) => {
        if (data?.cancelResponse) {
          toast.success('The transcript review has been cancelled.');

          fetchTranscriptDetails();
        }
      })
      .catch(() => {
        toast.error(
          'There was an error trying to cancel the transcript review, please try again.'
        );
      })
      .finally(() => {
        setLoadingCancelTranscript(false);
      });
  };

  const resolveTranscript = () => {
    setLoadingResolveTranscript(true);

    httpClient(resolveTranscriptReviewRequest, {
      transcriptReviewId: `${TRANSCRIPT_REVIEW_ITEM}${id}`,
    })
      .then(({ data }) => {
        if (data?.resolveResponse) {
          const successMessage = isOpenStatus ? 'successfully resolved' : 'reopened';
          toast.success(`The transcript review has been ${successMessage}.`);

          fetchTranscriptDetails();
        }
      })
      .catch(() => {
        const errorMessage = isOpenStatus ? 'resolve' : 'reopen';

        toast.error(
          `There was an error trying to ${errorMessage} the transcript review, please try again.`
        );
      })
      .finally(() => {
        setLoadingResolveTranscript(false);
      });
  };

  const transcriptionData = useMemo(() => {
    if (!details) return [];

    return [
      {
        label: STRINGS.DATE_CREATED,
        value: details.createdAt,
        md: 3,
        xs: 12,
      },
      {
        label: 'Created By',
        value: details.createdBy,
        md: 3,
        xs: 12,
      },
      {
        label: 'Comment',
        value: details.comment,
        md: 6,
        xs: 12,
      },
    ];
  }, [details]);

  const getBtnChildren = (label, btnColor, loading) => {
    return loading ? <CircularProgress size={15} color={btnColor} /> : `${label} Report`;
  };

  const getCancelBtnState = useMemo(() => {
    const children = getBtnChildren('Cancel', 'error', loadingCancelTranscript);

    return {
      color: 'error',
      children,
      disabled: !isOpenStatus || loadingCancelTranscript,
      minWidth: 119,
    };
  }, [details]);

  const getResolveReopenBtnState = useMemo(() => {
    const btnLabel = isOpenStatus ? 'Resolve' : 'Reopen';
    const btnColor = isOpenStatus ? 'success' : 'warning';
    const children = getBtnChildren(btnLabel, btnColor, loadingResolveTranscript);

    return {
      color: btnColor,
      children,
      disabled: loadingResolveTranscript,
      minWidth: 119,
    };
  });

  return (
    <RevisionItemDetails
      title="Transcript Review"
      metaData={transcriptionData}
      isLoading={loading}
      cancelBtn={getCancelBtnState}
      acceptBtn={getResolveReopenBtnState}
      onClickCancel={cancelTranscript}
      onClickAccept={resolveTranscript}
      lastContainerDirection={!isOpenStatus}
      hideCancelBtn={!isOpenStatus}
    >
      <TranscriptText
        prevText={details?.prevText}
        selectedText={details?.transcriptFragment}
        lastText={details?.lastText}
      />
    </RevisionItemDetails>
  );
};

export default TranscriptDetails;
