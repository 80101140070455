export const rssFeedExists = /* GraphQL */ `
  query rssFeedExists($feedUrl: AWSURL!) {
    rssFeedExists(rssFeedLink: $feedUrl)
  }
`;

export const getPodcastsListenNotes = /* GraphQL */ `
  query getPodcastsListenNotes($q: String!, $offset: Int) {
    getPodcastsListenNotes(q: $q, offset: $offset) {
      next_offset
      total
      results {
        imageUrl
        rssUrl
        title
        itunesId
        email
      }
    }
  }
`;

export const getIPSSONotification = /* GraphQL */ `
  query getIPSSONotification(
    $emailPodcaster: String
    $emailRSS: String
    $notificationSource: NotificationType
    $podcasterName: String
    $podcastName: String
    $RSSurl: String
  ) {
    getIPSSONotification(
      EmailAddressPodcasterAccount: $emailPodcaster
      EmailAddressRSS: $emailRSS
      NotificationSource: $notificationSource
      PodcasterName: $podcasterName
      PodcastName: $podcastName
      RSSurl: $RSSurl
    ) {
      IPSSONotificationMessage
    }
  }
`;
