import React, { useRef, useMemo, useCallback } from 'react';
import { EntitiesDataGrid, HierarchyHeader } from '@components/index';
import { dateToMMDoYYYY } from '@utils/dateFormat';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { listContentReports } from '@graphql/queries/reports';
import useInfiniteRow from 'hooks/useInfiniteRow';
import { DEFAULT_LIMIT } from 'constants/appSync';
import { MARBYL_PREFIX, REPORT } from 'constants/idPrefixes';
import { getReportContact } from '@utils/reportContact';
import { STRINGS } from 'constants/strings';
import { useEntityNames } from 'contexts/EntitiesNamesContext';

const PAGE_BLOCK = DEFAULT_LIMIT;

const MarbylReports = ({ id }) => {
  const gridRef = useRef();
  const history = useHistory();
  const { url } = useRouteMatch();
  const setEntity = useEntityNames()[1];

  const { getDataSource } = useInfiniteRow({
    query: listContentReports,
    payload: {
      filterBy: 'marbyl',
      itemId: `${MARBYL_PREFIX}${id}`,
      limit: PAGE_BLOCK,
    },
    responseKeyName: 'contentReportsRes',
  });

  const setDataSource = useCallback(
    () => gridRef.current.api.setDatasource(getDataSource()),
    [getDataSource]
  );

  const getRowId = useCallback(function (params) {
    return params.data.reportId;
  }, []);

  const columnDefs = useMemo(
    () => [
      {
        field: 'reportId',
        hide: true,
      },
      {
        field: 'createdAt',
        headerName: 'Date Created',
        cellRenderer: ({ value }) => dateToMMDoYYYY(value),
        minWidth: 100,
        maxWidth: 150,
      },
      {
        field: 'email',
        headerName: 'Contact',
        cellRenderer: ({ data }) => {
          if (!data) return '';

          const { email, phoneNumber } = data;
          return getReportContact(email, phoneNumber);
        },
        minWidth: 300,
      },
      {
        field: 'reportReason',
        headerName: 'Report Type',
        width: 150,
      },
      {
        field: 'reportReasonDetail',
        headerName: 'Description',
        minWidth: 300,
      },
      {
        field: 'marbylId',
        headerName: 'MarbylId',
        width: 130,
      },
      {
        field: 'marbylText',
        headerName: 'Marbyl Text',
        minWidth: 300,
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 100,
      },
    ],
    []
  );

  const handleRowClick = ({ data }) => {
    const _id = data.reportId.replace(REPORT, '');
    setEntity(_id, STRINGS.REPORT_DETAIL);
    history.push(`${url}/${_id}`);
  };

  return (
    <>
      <HierarchyHeader title={STRINGS.MARBYL_CONTENT_REPORTS} />

      <EntitiesDataGrid
        ref={gridRef}
        columnDefs={columnDefs}
        onCellClicked={handleRowClick}
        rowModelType={'infinite'}
        onGridReady={setDataSource}
        cacheBlockSize={PAGE_BLOCK}
        getRowId={getRowId}
      />
    </>
  );
};

export default MarbylReports;
