import PropTypes from 'prop-types';
import {
  Box,
  Card as CardMUI,
  CardActions,
  CardContent,
  Chip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import RedoIcon from '@mui/icons-material/Redo';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { StyledMarbylText } from './styles';
import { HEX_COLORS } from 'constants/stylesVariables';

const Card = ({
  marbylText,
  marbylViews,
  marbylShares,
  creatorUserName,
  showName,
  episodeName,
  marbylStartTime,
}) => {
  const theme = useTheme();
  const userName = creatorUserName === 'ai' ? 'Marbyl-AI' : creatorUserName;

  const limitCharacters = (text) => {
    if (text.length > 25) return `${text.substring(0, 24)}...`;

    return text;
  };

  const getStartTime = () => {
    const splittedData = marbylStartTime.split(':');
    const formattedData = `${splittedData[1]}m${splittedData[2].substring(
      0,
      splittedData[2].indexOf('.')
    )}s`;

    return formattedData;
  };

  return (
    <CardMUI>
      <CardContent
        sx={{
          height: 200,
          minHeight: 200,
          maxHeight: 200,
          padding: theme.spacing(2),
          paddingBottom: 0,
        }}
      >
        <StyledMarbylText variant="h6">{marbylText}</StyledMarbylText>
        <Typography sx={{ color: HEX_COLORS.silverSand }}>
          {`${limitCharacters(showName)} : ${limitCharacters(
            episodeName
          )} : ${getStartTime()}`}
        </Typography>
      </CardContent>
      <CardActions
        disableSpacing
        sx={{ padding: theme.spacing(2), paddingTop: theme.spacing(1) }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>{marbylViews}</Typography>
          <VisibilityIcon sx={{ height: '0.7em', width: '0.7em' }} />
        </Box>
        <Box
          sx={{ display: 'flex', alignItems: 'center', margin: theme.spacing(0, 1.5) }}
        >
          <Typography>{marbylShares}</Typography>
          <RedoIcon sx={{ height: '0.7em', width: '0.7em' }} />
        </Box>
        <Chip label={userName} />
      </CardActions>
    </CardMUI>
  );
};

Card.propTypes = {
  marbylText: PropTypes.string.isRequired,
  marbylViews: PropTypes.number.isRequired,
  marbylShares: PropTypes.number.isRequired,
  creatorUserName: PropTypes.string.isRequired,
};

export default Card;
