export const getEpisodeById = /* GraphQL */ `
  query getEpisodeById($episodeId: String!) {
    getMPCEpisodeById(episodeId: $episodeId) {
      PK
      title
      subtitle
      summary
      guid
      enclosure
      infoLink
      imageUrl
      author {
        name
        email
      }
      creator
      pubDate
      episode
      season
      episodeType
      explicit
      duration
      block
      description
      isActivated
      isActivatedUpdated
      availableInGeos
      m11nIsEnabled
      m11nIsEnabledUpdated
      m11nProcessingState
      lastM11nModelUsed
      lastM11nDate
      lastM11nResult
      lastM11nModelVersion
      lastM11nMarbylsCreated
      show {
        m11nAutoType
      }
    }
  }
`;

export const listMarbylsByEpisode = /* GraphQL */ `
  query listMarbylsByEpisode($episodeId: String!, $limit: Int, $nextToken: String) {
    listMarbylsByEpisodeId(episodeId: $episodeId, limit: $limit, nextToken: $nextToken) {
      items {
        PK
        source
        text
        modelName
        isActivated
      }
      nextToken
    }
  }
`;

export const getEpisodeTitle = /* GraphQL */ `
  query getTranscriptTitle($episodeId: String!) {
    getMPCEpisodeById(episodeId: $episodeId) {
      title
    }
  }
`;
