import { range } from '@utils/linearInterpolation';
import { useEffect, useMemo, useState } from 'react';
import { props, defaultProps } from './props';

const FluidFont = ({
  minFontSize,
  maxFontSize,
  minLineHeight,
  maxLineHeight,
  minViewportWidth,
  maxViewportWidth,
  fontSizeUnit,
  children,
}) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const fontSize = useMemo(
    () =>
      `${Math.abs(
        range(maxViewportWidth, minViewportWidth, maxFontSize, minFontSize, windowSize)
      ).toFixed(2)}${fontSizeUnit}`,
    [
      maxFontSize,
      minFontSize,
      maxViewportWidth,
      minViewportWidth,
      windowSize,
      fontSizeUnit,
    ]
  );

  const lineHeight = useMemo(
    () =>
      `${Math.abs(
        range(
          maxViewportWidth,
          minViewportWidth,
          maxLineHeight,
          minLineHeight,
          windowSize
        )
      ).toFixed(2)}em`,
    [maxLineHeight, minLineHeight, maxViewportWidth, minViewportWidth, windowSize]
  );

  useEffect(() => {
    const resizeCallback = () => setWindowSize(window.innerWidth);

    window.addEventListener('resize', resizeCallback);

    return () => {
      window.removeEventListener('resize', resizeCallback);
    };
  }, []);

  return children({
    styles: {
      fontSize,
      lineHeight,
    },
  });
};

FluidFont.propTypes = props;
FluidFont.defaultProps = defaultProps;

export default FluidFont;
