import muiStyled from '@mui/styled-engine';
import { Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DESKTOP_BREAKPOINT, MOBILE_BREAKPOINT } from 'constants/breakpoints';

export const StyledExpandedBox = muiStyled(Box)(
  ({ theme }) => `
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar { 
    display: none
  }

  &.open {
    max-height: 1500px;
    overflow: auto;
    transition: max-height 0.25s ease-in;
    margin-bottom: ${theme.spacing(4)};
    padding-right: ${theme.spacing(1)};
  }
`
);

export const StyledExpandedContainer = muiStyled('div')(
  ({ theme }) => `
  position: absolute; 
  bottom: 5px; 
  left: 0; 
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: ${theme.spacing(2)};
`
);

export const StyledExpandedIcon = muiStyled(ExpandMoreIcon)(
  () => `

  &.open {
    transform: rotate(180deg);
  }
`
);

export const StyledTopContainer = muiStyled(Box)(
  ({ theme }) => `
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: ${theme.spacing(3)};

    @media ${DESKTOP_BREAKPOINT} {
      align-items: center;
      flex-direction: row;
    }
  `
);

export const StyledTitleContainer = muiStyled('div')(
  () => `
    display: flex;
    align-items: flex-start;
    flex: 1;
    flex-direction: column;
    
    @media ${DESKTOP_BREAKPOINT} {
      flex-direction: row;
    }
  `
);

export const StyledTitle = muiStyled(Typography)(
  () => `
    flex: 1;
    margin-left: 0.33em;

    @media ${MOBILE_BREAKPOINT} {
      margin-left: 0; 
    }
  `
);

export const StyledDescription = muiStyled(Typography)(
  ({ theme }) => `
    margin-top: ${theme.spacing(2)};

    @media ${MOBILE_BREAKPOINT} {
      margin: ${theme.spacing(1)}; 
    }
  `
);

export const StyledOptionsContainer = muiStyled('div')(
  ({ theme }) => `

  @media ${MOBILE_BREAKPOINT} {
    margin-top: ${theme.spacing(1)}; 
  }
  `
);
