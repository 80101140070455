import { STRINGS } from './strings';

export const LEGAL_ENTITY_TYPES = {
  LLC: 'llc',
  SOLE_PROPRIETORSHIP: 'sole_proprietorship',
  PARTNERSHIP: 'partnership',
  BUSINESSS_CORPORATION: 'business_corporation',
};

const { LLC, SOLE_PROPRIETORSHIP, PARTNERSHIP, BUSINESSS_CORPORATION } =
  LEGAL_ENTITY_TYPES;

export const LEGAL_ENTITY_TYPES_LABEL = {
  [LLC]: STRINGS.LLC,
  [SOLE_PROPRIETORSHIP]: STRINGS.SOLE_PROPRIETORSHIP,
  [PARTNERSHIP]: STRINGS.PARTNERSHIP,
  [BUSINESSS_CORPORATION]: STRINGS.BUSINESSS_CORPORATION,
};
