import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import { STRINGS } from 'constants/strings';
import { StyledAuthForm } from '@shared/styledAuthForm';
import Spacer from '@shared/spacer';

const AccountConfirmationForm = ({ onSubmit }) => {
  const handleSubmit = (evt) => {
    evt.preventDefault();
    onSubmit();
  };

  return (
    <StyledAuthForm maxWidth={600} onSubmit={handleSubmit}>
      <Typography color="primary" sx={{ textAlign: 'center' }} variant="h6">
        {STRINGS.ACCOUNT_CONFIRMATION}
      </Typography>

      <Spacer size={16} />

      <Typography color="primary" variant="body1">
        {STRINGS.YOU_HAVE_BEEN_INVITED_TO_JOIN_THE_ACCOUNT_CLICK_CONFIRM_TO_JOIN}
      </Typography>

      <Spacer size={16} />

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          sx={{ '&.MuiButton-root': { color: 'white' } }}
          variant="contained"
          color="success"
          type="submit"
        >
          {STRINGS.CONFIRM}
        </Button>
      </Box>
    </StyledAuthForm>
  );
};

export default AccountConfirmationForm;
