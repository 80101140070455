import { FONT_FAMILIES } from 'constants/stylesVariables';
import MalloryThin from '@fonts/Mallory/mallory_compact_thin.ttf';
import MalloryThinItalic from '@fonts/Mallory/mallory_compact_thin_italic.ttf';
import MalloryXLight from '@fonts/Mallory/mallory_compact_x_light.ttf';
import MalloryXLightItalic from '@fonts/Mallory/mallory_compact_x_light_italic.ttf';
import MalloryLight from '@fonts/Mallory/mallory_compact_light.ttf';
import MalloryLightItalic from '@fonts/Mallory/mallory_compact_light_italic.ttf';
import MalloryBook from '@fonts/Mallory/mallory_compact_book.ttf';
import MalloryBookItalic from '@fonts/Mallory/mallory_compact_book_italic.ttf';
import MalloryMedium from '@fonts/Mallory/mallory_compact_medium.ttf';
import MalloryMediumItalic from '@fonts/Mallory/mallory_compact_medium_italic.ttf';
import MalloryBold from '@fonts/Mallory/mallory_compact_bold.ttf';
import MalloryBoldItalic from '@fonts/Mallory/mallory_compact_bold_italic.ttf';
import MalloryBlack from '@fonts/Mallory/mallory_compact_black.ttf';
import MalloryBlackItalic from '@fonts/Mallory/mallory_compact_black_italic.ttf';
import MalloryUltra from '@fonts/Mallory/mallory_compact_ultra.ttf';
import MalloryUltraItalic from '@fonts/Mallory/mallory_compact_ultra_italic.ttf';

import MalloryMPLight from '@fonts/MalloryMP/mallory_mp_compact_light.ttf';
import MalloryMPLightItalic from '@fonts/MalloryMP/mallory_mp_compact_light_italic.ttf';
import MalloryMPBook from '@fonts/MalloryMP/mallory_mp_compact_book.ttf';
import MalloryMPBookItalic from '@fonts/MalloryMP/mallory_mp_compact_book_italic.ttf';
import MalloryMPMedium from '@fonts/MalloryMP/mallory_mp_compact_medium.ttf';
import MalloryMPMediumItalic from '@fonts/MalloryMP/mallory_mp_compact_medium_italic.ttf';
import MalloryMPBold from '@fonts/MalloryMP/mallory_mp_compact_bold.ttf';
import MalloryMPBoldItalic from '@fonts/MalloryMP/mallory_mp_compact_bold_italic.ttf';
import MalloryMPBlack from '@fonts/MalloryMP/mallory_mp_compact_black.ttf';
import MalloryMPBlackItalic from '@fonts/MalloryMP/mallory_mp_compact_black_italic.ttf';

import ProximaNovaBlack from '@fonts/ProximaNova/proxima_nova_black.ttf';
import ProximaNovaBold from '@fonts/ProximaNova/proxima_nova_bold.ttf';
import ProximaNovaRegular from '@fonts/ProximaNova/proxima_nova_regular.ttf';
import ProximaNovaSemibold from '@fonts/ProximaNova/proxima_nova_semibold.ttf';

export const TYPOGRAPHY = `
  /* Mallory Thin */
  @font-face {
    font-family: "${FONT_FAMILIES.mallory}";
    src: url(${MalloryThin}) format("woff");
    font-style: normal;
    font-weight: 200;
  }

  @font-face {
    font-family: "${FONT_FAMILIES.mallory}";
    src: url(${MalloryThinItalic}) format("woff");
    font-style: italic;
    font-weight: 200;
  }

  /* Mallory Extra Lite */
  @font-face {
    font-family: "${FONT_FAMILIES.mallory}";
    src: url(${MalloryXLight}) format("woff");
    font-style: normal;
    font-weight: 250;
  }

  @font-face {
    font-family: "${FONT_FAMILIES.mallory}";
    src: url(${MalloryXLightItalic}) format("woff");
    font-style: italic;
    font-weight: 250;
  }

  /* Mallory Lite */
  @font-face {
    font-family: "${FONT_FAMILIES.mallory}";
    src: url(${MalloryLight}) format("woff");
    font-style: normal;
    font-weight: 300;
  }

  @font-face {
    font-family: "${FONT_FAMILIES.mallory}";
    src: url(${MalloryLightItalic}) format("woff");
    font-style: italic;
    font-weight: 300;
  }

  /* Mallory Book */
  @font-face {
    font-family: "${FONT_FAMILIES.mallory}";
    src: url(${MalloryBook}) format("woff");
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: "${FONT_FAMILIES.mallory}";
    src: url(${MalloryBookItalic}) format("woff");
    font-style: italic;
    font-weight: 400;
  }

  /* Mallory Medium */
  @font-face {
    font-family: "${FONT_FAMILIES.mallory}";
    src: url(${MalloryMedium}) format("woff");
    font-style: normal;
    font-weight: 500;
  }

  @font-face {
    font-family: "${FONT_FAMILIES.mallory}";
    src: url(${MalloryMediumItalic}) format("woff");
    font-style: italic;
    font-weight: 500;
  }

  /* Mallory Bold */
  @font-face {
    font-family: "${FONT_FAMILIES.mallory}";
    src: url(${MalloryBold}) format("woff");
    font-style: normal;
    font-weight: 600;
  }

  @font-face {
    font-family: "${FONT_FAMILIES.mallory}";
    src: url(${MalloryBoldItalic}) format("woff");
    font-style: italic;
    font-weight: 600;
  }

  /* Mallory Black */
  @font-face {
    font-family: "${FONT_FAMILIES.mallory}";
    src: url(${MalloryBlack}) format("woff");
    font-style: normal;
    font-weight: 700;
  }

  @font-face {
    font-family: "${FONT_FAMILIES.mallory}";
    src: url(${MalloryBlackItalic}) format("woff");
    font-style: italic;
    font-weight: 700;
  }

  /* Mallory Ultra */
  @font-face {
    font-family: "${FONT_FAMILIES.mallory}";
    src: url(${MalloryUltra}) format("woff");
    font-style: normal;
    font-weight: 900;
  }

  @font-face {
    font-family: "${FONT_FAMILIES.mallory}";
    src: url(${MalloryUltraItalic}) format("woff");
    font-style: italic;
    font-weight: 900;
  }

  /* Mallory MP Lite */
  @font-face {
    font-family: "${FONT_FAMILIES.malloryMP}";
    src: url(${MalloryMPLight}) format("woff");
    font-style: normal;
    font-weight: 300;
  }

  @font-face {
    font-family: "${FONT_FAMILIES.malloryMP}";
    src: url(${MalloryMPLightItalic}) format("woff");
    font-style: italic;
    font-weight: 300;
  }

  /* Mallory MP Book */
  @font-face {
    font-family: "${FONT_FAMILIES.malloryMP}";
    src: url(${MalloryMPBook}) format("woff");
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: "${FONT_FAMILIES.malloryMP}";
    src: url(${MalloryMPBookItalic}) format("woff");
    font-style: italic;
    font-weight: 400;
  }

  /* Mallory MP Medium */
  @font-face {
    font-family: "${FONT_FAMILIES.malloryMP}";
    src: url(${MalloryMPMedium}) format("woff");
    font-style: normal;
    font-weight: 500;
  }

  @font-face {
    font-family: "${FONT_FAMILIES.malloryMP}";
    src: url(${MalloryMPMediumItalic}) format("woff");
    font-style: italic;
    font-weight: 500;
  }

  /* Mallory MP Bold */
  @font-face {
    font-family: "${FONT_FAMILIES.malloryMP}";
    src: url(${MalloryMPBold}) format("woff");
    font-style: normal;
    font-weight: 600;
  }

  @font-face {
    font-family: "${FONT_FAMILIES.malloryMP}";
    src: url(${MalloryMPBoldItalic}) format("woff");
    font-style: italic;
    font-weight: 600;
  }

  /* Mallory MP Black */
  @font-face {
    font-family: "${FONT_FAMILIES.malloryMP}";
    src: url(${MalloryMPBlack}) format("woff");
    font-style: normal;
    font-weight: 700;
  }

  @font-face {
    font-family: "${FONT_FAMILIES.malloryMP}";
    src: url(${MalloryMPBlackItalic}) format("woff");
    font-style: italic;
    font-weight: 700;
  }

  /* Proxima Nova Regular */
  @font-face {
    font-family: "${FONT_FAMILIES.proximaNovaRegular}";
    src: url(${ProximaNovaRegular}) format("truetype");
    font-style: normal;
    font-weight: 600;
  }

  /* Proxima Nova Semibold */
  @font-face {
    font-family: "${FONT_FAMILIES.proximaNovaSemibold}";
    src: url(${ProximaNovaSemibold}) format("truetype");
    font-style: normal;
    font-weight: 700;
  }

  /* Proxima Nova Bold */
  @font-face {
    font-family: "${FONT_FAMILIES.proximaNovaBold}";
    src: url(${ProximaNovaBold}) format("truetype");
    font-style: normal;
    font-weight: 800;
  }

  /* Proxima Nova Black */
  @font-face {
    font-family: "${FONT_FAMILIES.proximaNovaBlack}";
    src: url(${ProximaNovaBlack}) format("truetype");
    font-style: normal;
    font-weight: 900;
  }
`;
