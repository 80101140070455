import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Box, Typography } from '@mui/material';
import {
  EntitiesDataGrid,
  HierarchyHeader,
  InfiniteSelect,
  SimpleDropdown,
} from '@components/index';
import { STRINGS } from 'constants/strings';
import { useHistory, useRouteMatch } from 'react-router';
import { record } from '@utils/analytics';
import { TAP_CREATE_USER, TAP_USER_ITEM } from 'constants/analytics';
import { MPC_ROUTES } from 'constants/routing';
import useInfiniteRow from 'hooks/useInfiniteRow';
import { DEFAULT_LIMIT } from 'constants/appSync';
import { getAllEntities, listUsersByAccountId } from '@graphql/queries/users';
import { ACCOUNT } from 'constants/idPrefixes';
import { useMPC } from 'contexts/MPCContext';
import { useHookedOnState } from 'hooked-on-redux';
import AddIcon from '@mui/icons-material/Add';

const USERS_BLOCK = DEFAULT_LIMIT;
const DROPDOWN_LIMIT = 100;

const usersActions = {
  REMOVE_USER_FROM_ACCOUNT: {
    key: 'removeUserFromAccount',
    label: 'Remove user from Account',
  },
  LEAVE_ACCOUNT: {
    key: 'leaveAccount',
    label: 'Leave Account',
  },
};

const UsersPage = () => {
  const gridRef = useRef();
  const selAccountRef = useRef(null);
  const { url } = useRouteMatch();
  const history = useHistory();
  const [selUsers, setSelUsers] = useState([]);
  const { selectedAccount, getIsSuperUser } = useMPC();
  const [selAccount, setSelAccount] = useHookedOnState('app.selAccount', {
    value: selectedAccount?.entityAccountId,
    label: selectedAccount?.entityName,
  });

  const handleAccount = useCallback(
    (newVal) => {
      selAccountRef.current = selAccount;
      setSelAccount(newVal);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSelAccount]
  );

  const accountId = useMemo(() => {
    if (!selAccount) return selectedAccount?.entityAccountId;

    return selAccount.value;
  }, [selectedAccount, selAccount]);

  const { getDataSource } = useInfiniteRow({
    query: listUsersByAccountId,
    payload: { limit: USERS_BLOCK, accountId },
    responseKeyName: 'listUsersByAccountId',
  });

  const handleCreateUser = () => {
    record(TAP_CREATE_USER);
    history.push(`${url}${MPC_ROUTES.ADD}`);
  };

  const handleClickUser = useCallback(
    ({ data }) => {
      const _accountId = data.accountId.replace(ACCOUNT, '');
      record(TAP_USER_ITEM, { id: _accountId });

      history.push(`${url}/${_accountId}`);
    },
    [history, url]
  );

  const handleAction = useCallback((action) => {
    switch (action) {
      case usersActions.LEAVE_ACCOUNT.key: {
        break;
      }

      case usersActions.REMOVE_USER_FROM_ACCOUNT.key: {
        break;
      }

      default: {
        break;
      }
    }

    alert(STRINGS.THIS_IS_IN_PROGRESS);
  }, []);

  const handleSelectUsers = useCallback((evt) => {
    setSelUsers(evt.api.getSelectedRows());
  }, []);

  const columnDefs = useMemo(() => {
    return [
      {
        field: '',
        checkboxSelection: true,
        width: 60,
        cellStyle: { display: 'flex', alignItems: 'center' },
      },
      {
        field: 'name',
        headerName: 'Name',
        minWidth: 300,
        flex: 1,
      },
      {
        field: 'email',
        headerName: 'Email',
        minWidth: 300,
        flex: 1,
      },
      {
        field: 'role',
        headerName: 'Role',
        minWidth: 300,
        flex: 1,
      },
    ];
  }, []);

  const setDataSource = useCallback(() => {
    if (!gridRef?.current?.api?.setDatasource) return;

    gridRef.current.api.setDatasource(getDataSource());
  }, [getDataSource]);

  const getRowId = useCallback(function (params) {
    return params.data.accountId;
  }, []);

  const getLabel = useCallback((item) => {
    return item.name || item.podcastNetworkName || item.title;
  }, []);

  const accountsPayload = useMemo(() => {
    return { limit: DROPDOWN_LIMIT };
  }, []);

  const selectAccount = useCallback(() => {
    if (JSON.stringify(selAccount) === JSON.stringify(selAccountRef.current)) {
      setDataSource();
    }
  }, [selAccount, setDataSource]);

  const renderAccountsDropdown = useCallback(() => {
    if (!getIsSuperUser()) {
      return null;
    }

    return (
      <Box>
        <Typography variant="subtitle2" sx={{ pb: 0.5 }}>
          Change Account
        </Typography>
        <InfiniteSelect
          styles={{ width: '100%' }}
          placeholder="Select Account"
          query={getAllEntities}
          payload={accountsPayload}
          responseKeyName="getAllEntities"
          value={selAccount}
          onChange={handleAccount}
          valueName="accountId"
          getLabel={getLabel}
        />
      </Box>
    );
  }, [accountsPayload, getLabel, selAccount, selectedAccount, handleAccount]);

  useEffect(() => {
    selectAccount();
  }, [selectAccount]);

  return (
    <>
      <HierarchyHeader
        title={`${selectedAccount?.entityName} | ${STRINGS.USER_MANAGEMENT}`}
        description={''}
        renderOptions={() => (
          <Button onClick={handleCreateUser} variant="outlined" color="secondary">
            <AddIcon fontSize="small" sx={{ mr: 0.5 }} />
            {STRINGS.CREATE_USER}
          </Button>
        )}
      />

      <Box
        sx={{
          display: 'flex',
          mt: 2,
          justifyContent: 'space-between',
          alignItems: 'end',
        }}
      >
        {renderAccountsDropdown()}
        <SimpleDropdown
          disabled={selUsers.length === 0}
          actions={Object.values(usersActions)}
          onClickAction={handleAction}
        />
      </Box>

      <EntitiesDataGrid
        ref={gridRef}
        columnDefs={columnDefs}
        rowModelType={'infinite'}
        onGridReady={setDataSource}
        cacheBlockSize={USERS_BLOCK}
        getRowId={getRowId}
        onSelectionChanged={handleSelectUsers}
        onCellClicked={handleClickUser}
      />
    </>
  );
};

export default UsersPage;
