import PropTypes from 'prop-types';

export const props = {
  title: PropTypes.string,
  renderSlash: PropTypes.bool,
};

export const defaultProps = {
  renderSlash: true,
};
