import React, { useState, useCallback, useRef } from 'react';
import { Box, Button, Modal, Fade } from '@mui/material';
import { STRINGS } from 'constants/strings';
import { Stepper } from '@components/index';
import { useMemo } from 'react';
import { useAddYourPodcast } from 'contexts/AddYourPodcastContext';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import Spacer from '@shared/spacer';
import useDesktopMobile from 'hooks/useDesktopMobile';
import poweredByLogo from '@images/powered-by-listen-notes-white.png';
import {
  PODCASTER_ONBOARDED,
  PODCAST_ONBOARDED,
  TAP_X_CLOSE_ADD_PODCAST_MODAL,
} from 'constants/analytics';
import { HEX_COLORS } from 'constants/stylesVariables';
import {
  FeedConfirmation,
  ReviewSubmit,
  SendCode,
  VerifyCode,
  WelcomeMessage,
} from '../index';
import { record } from '@utils/analytics';
import useRecordAnalyticsPodcast from 'hooks/useRecordAnalyticsPodcast';
import PropTypes from 'prop-types';
import { STAGE } from 'constants/secrets';
import { requestAPIGraphQL } from '@services/appSyncAPI';
import { getIPSSONotification } from '@graphql/queries/ipsso';
import useAccountEmail from 'hooks/useAccountEmail';
import usePodcastRSSEmail from 'hooks/usePodcastRSSEmail';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  bgcolor: 'primary.main',
  border: `2px solid ${HEX_COLORS.black}`,
  boxShadow: 24,
  pl: 2,
  pr: 2,
  pt: 2,
  pb: 2,
  height: '100vh',
  color: 'secondary.main',
  '&:focus-visible': {
    outline: 'none',
  },
};

const VERIFY_RSS_STEP = 'Verify RSS';
const REVIEW_SUBMIT_STEP = 'Review & Submit';

const STEPS_DIRECTIONS = { l: 'l', r: 'r' };

const AddPodcastModal = ({ open, onClose, stages, onFinish }) => {
  const { renderMobileComponent } = useDesktopMobile();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [modalHeight, setModalHeight] = useState(0);
  const [finish, setFinish] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const {
    resetDefaultState,
    podcastState: [podcast],
    podcaster: { owners, companyName },
    isOnboarding,
  } = useAddYourPodcast();

  const accountEmail = useAccountEmail();
  const podcastRSSEmail = usePodcastRSSEmail(podcast);

  const modalRef = useRef(null);
  const headerRef = useRef(null);

  const { recordAnalyticsPodcast } = useRecordAnalyticsPodcast();

  const callOnboardNotification = useCallback(
    (type = 'Podcast_onboarded') => {
      if (STAGE !== 'prod') {
        return new Promise((resolve) => resolve(null));
      }

      let podcasterName = '';

      owners.forEach((_owner, index) => {
        podcasterName += `${_owner.name}${index !== owners.length - 1 ? ', ' : ''}`;
      });

      if (companyName) {
        podcasterName += ` | ${companyName}`;
      }

      const payload = {
        emailPodcaster: accountEmail,
        emailRSS: podcastRSSEmail,
        notificationSource: type,
        podcasterName,
        podcastName: podcast.title,
        RSSurl: podcast.feedUrl,
      };

      return requestAPIGraphQL(getIPSSONotification, payload);
    },
    [accountEmail, podcastRSSEmail, owners, companyName, podcast]
  );

  const contentHeight = useMemo(
    () => (modalHeight > headerHeight ? modalHeight - headerHeight - 32 : modalHeight),
    [modalHeight, headerHeight]
  );

  const { steps, hashStepsComponents } = useMemo(() => {
    const onSubmitPodcast = (response) => {
      onFinish(response);
      setFinish(true);
    };

    const { headers, stagesObj } = stages.reduce(
      (obj, val, index) => {
        const { component: Component } = val;

        return {
          headers: [...obj.headers, val.breadcrumbHeaderTitle],
          stagesObj: {
            ...obj.stagesObj,
            [index + 3]: (props) => <Component {...props} />,
          },
        };
      },
      { headers: [], stagesObj: {} }
    );

    const _steps = [VERIFY_RSS_STEP, ...headers, REVIEW_SUBMIT_STEP];

    let hashStepsComponents = {
      0: (props) => <FeedConfirmation {...props} />,
      1: (props) => <SendCode {...props} />,
      2: (props) => <VerifyCode {...props} />,
      ...stagesObj,
      [_steps.length + 1]: (props) => (
        <ReviewSubmit
          {...props}
          isOnboarding={isOnboarding}
          onSubmitPodcast={onSubmitPodcast}
        />
      ),
    };

    return {
      steps: [VERIFY_RSS_STEP, ...headers, REVIEW_SUBMIT_STEP],
      hashStepsComponents,
    };
  }, [stages, onFinish, isOnboarding]);

  const modalCallback = useCallback((ref) => {
    if (!ref) return;

    modalRef.current = ref;
    setModalHeight(ref.clientHeight);
  }, []);

  const headerCallback = useCallback((ref) => {
    if (!ref) return;

    headerRef.current = ref;
    setHeaderHeight(ref.clientHeight);
  }, []);

  /* Breadcrumb should remain the same
  for the first three steps for verifying RSS */
  const breadcrumbActiveStep = useMemo(() => {
    if (activeStep < 3) return 0;

    return activeStep - 2;
  }, [activeStep]);

  const handleReset = useCallback(() => {
    setFinish(false);
    setActiveStep(0);
    onClose();
    resetDefaultState();
  }, [onClose, resetDefaultState]);

  const handleClose = useCallback(() => {
    handleReset();
  }, [handleReset]);

  /*
  Receives event from components for recording
  after step changes
  */
  const onNextBackClick = useCallback(
    (direction, event) => {
      event && recordAnalyticsPodcast(event);

      if (direction === STEPS_DIRECTIONS.l) {
        if (activeStep === 0) {
          handleClose();
          return;
        }

        setActiveStep((prev) => prev - 1);

        return;
      }

      if (direction === STEPS_DIRECTIONS.r) {
        if (breadcrumbActiveStep === steps.length - 1) {
          return;
        }

        setActiveStep((prev) => prev + 1);
      }
    },
    [activeStep, breadcrumbActiveStep, handleClose, recordAnalyticsPodcast, steps]
  );

  /* Component for back button */
  const renderBackBtn = useCallback(
    (event) => {
      return (
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => {
            onNextBackClick(STEPS_DIRECTIONS.l, event);
          }}
          sx={{ mr: 2, padding: '6px 16px' }}
        >
          {activeStep === 0 ? STRINGS.CANCEL : STRINGS.PREVIOUS}
        </Button>
      );
    },
    [activeStep, onNextBackClick]
  );

  const renderCurrentView = useCallback(
    (height) => {
      if (finish && isOnboarding) {
        return <WelcomeMessage onClickDone={handleReset} />;
      }

      const onNextClick = (event) => onNextBackClick(STEPS_DIRECTIONS.r, event);
      const renderFn = hashStepsComponents[activeStep];

      if (!renderFn) {
        return null;
      }

      return renderFn({ renderBackBtn, onNextClick, height });
    },
    [
      activeStep,
      renderBackBtn,
      onNextBackClick,
      finish,
      hashStepsComponents,
      isOnboarding,
      handleReset,
    ]
  );

  const renderStepper = useCallback(() => {
    const _steps = stages.map(({ breadcrumbHeaderTitle }) => breadcrumbHeaderTitle);

    return (
      !finish && (
        <Stepper
          steps={[VERIFY_RSS_STEP, ..._steps, REVIEW_SUBMIT_STEP]}
          activeStep={breadcrumbActiveStep}
        />
      )
    );
  }, [finish, breadcrumbActiveStep, stages]);

  const renderPoweredByLogo = useCallback(() => {
    if (activeStep !== 0) {
      return null;
    }

    return (
      <img
        style={{ position: 'absolute', bottom: 0, left: 0, width: 200 }}
        src={poweredByLogo}
        alt="Powered by Listen Notes"
      />
    );
  }, [activeStep]);

  const renderModalContent = useCallback(() => {
    return (
      open && (
        <>
          <Box ref={headerCallback}>
            <CloseIcon
              onClick={() => {
                record(TAP_X_CLOSE_ADD_PODCAST_MODAL);
                if (finish) {
                  handleReset();
                  return;
                }

                handleClose();
              }}
              fontSize="large"
              sx={{ position: 'absolute', top: 8, left: 8, cursor: 'pointer' }}
            />
            {renderMobileComponent(() => (
              <Spacer size={32} />
            ))}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {renderStepper()}
            </Box>
          </Box>

          <Box
            sx={{
              overflow: 'auto',
              height: `${contentHeight}px`,
            }}
          >
            {renderCurrentView(contentHeight)}
          </Box>
        </>
      )
    );
  }, [
    open,
    finish,
    handleClose,
    handleReset,
    renderStepper,
    renderCurrentView,
    renderMobileComponent,
    headerCallback,
    contentHeight,
  ]);

  useEffect(() => {
    toast.dismiss();
  }, [activeStep, open]);

  useEffect(() => {
    if (finish) {
      recordAnalyticsPodcast(PODCAST_ONBOARDED);
      callOnboardNotification('Podcast_onboarded').then();

      if (isOnboarding) {
        recordAnalyticsPodcast(PODCASTER_ONBOARDED);
        callOnboardNotification('Podcaster_onboarded').then();
      } else {
        handleReset();
      }
    }
  }, [
    finish,
    isOnboarding,
    callOnboardNotification,
    recordAnalyticsPodcast,
    handleReset,
  ]);

  useEffect(() => {
    const resizeCallback = () => {
      if (!modalRef.current || !headerRef.current) {
        return;
      }
      setModalHeight(modalRef.current.clientHeight);
      setHeaderHeight(headerRef.current.clientHeight);
    };

    window.addEventListener('resize', resizeCallback);

    return () => {
      window.removeEventListener('resize', resizeCallback);
    };
  }, []);

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={open}>
        <Box ref={modalCallback} sx={style}>
          {renderPoweredByLogo()}
          <Box
            sx={{
              maxWidth: 1400,
              width: '100%',
              margin: '0 auto',
            }}
          >
            {renderModalContent()}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

AddPodcastModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func,
  stages: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.func,
      breadcrumbHeaderTitle: PropTypes.string.isRequired,
    })
  ).isRequired,
  showWelcomeScreen: PropTypes.bool,
};

AddPodcastModal.defaultProps = {
  onFinish: () => null,
};

export default AddPodcastModal;
