import { Switch, Route, Redirect } from 'react-router';
import Amplify from 'aws-amplify';
import { useMPC } from 'contexts/MPCContext';
import awsconfig from './aws-exports.json';
import './App.css';
import { MAIN_ROUTES } from 'constants/routing';
import LandingPage from '@pages/LandingPage/LandingPage';
import DashboardRoutes from '@routes/DashboardRoutes';
import ResetPassword from '@pages/ResetPassword/ResetPassword';
import ConfirmSignUpPage from '@pages/ConfirmSignUpPage/ConfirmSignUpPage';
import PartnersRoutes from '@routes/PartnersRoutes';
import PrivacyPolicyPage from '@pages/PrivacyPolicyPage/PrivacyPolicyPage';
import TermsOfServicePage from '@pages/TermsOfServicePage/TermsOfServicePage';
import NotFoundPage from '@pages/NotFoundPage/NotFoundPage';
import OpenSourceAttributionsPage from '@pages/OpenSourceAttributionsPage/OpenSourceAttributionsPage';
import usePageLoadExitAnalytics from 'hooks/usePageLoadExitAnalytics';
import BetaPage from '@pages/BetaPage/BetaPage';

import 'ag-grid-community/dist/styles/ag-grid.css';
import './styles/ag-theme-custom.css';

import PrivateRoute from '@components/privateRoute/index';
import AccountConfirmationPage from '@pages/AccountConfirmationPage/AccountConfirmationPage';
import CancelDeleteAccountPage from '@pages/CancelDeleteAccountPage/CancelDeleteAccountPage';
import ConfirmDeleteAccountPage from '@pages/ConfirmDeleteAccountPage/ConfirmDeleteAccountPage';
import ConfirmAccountUser from '@pages/ConfirmAccountUser';

Amplify.configure({ ...awsconfig });

const App = () => {
  const { selectedAccount } = useMPC();

  usePageLoadExitAnalytics();

  if (!selectedAccount) return <></>;

  return (
    <>
      <Switch>
        <Route exact path={MAIN_ROUTES.LANDING}>
          <LandingPage />
        </Route>
        <Route path={MAIN_ROUTES.PARTNERS}>
          <PartnersRoutes />
        </Route>
        <Route path={MAIN_ROUTES.POLICY}>
          <PrivacyPolicyPage />
        </Route>
        <Route path={MAIN_ROUTES.TERMS}>
          <TermsOfServicePage />
        </Route>
        <Route path={MAIN_ROUTES.OPEN_SOURCE_ATTRIBUTIONS}>
          <OpenSourceAttributionsPage />
        </Route>
        <PrivateRoute path={MAIN_ROUTES.APP} component={DashboardRoutes} />
        <Redirect from={MAIN_ROUTES.LOGIN} to={MAIN_ROUTES.LANDING} />
        <Route path={MAIN_ROUTES.RESET_PASSWORD}>
          <ResetPassword />
        </Route>
        <PrivateRoute
          path={MAIN_ROUTES.ACCOUNT_CONFIRMATION}
          component={AccountConfirmationPage}
        />
        <PrivateRoute
          path={MAIN_ROUTES.CONFIRM_ACCOUNT_USER}
          component={ConfirmAccountUser}
        />
        <Route path={MAIN_ROUTES.CONFIRM_SIGNUP}>
          <ConfirmSignUpPage />
        </Route>
        <Route path={MAIN_ROUTES.CANCEL_ACCOUNT_DELETION}>
          <CancelDeleteAccountPage />
        </Route>
        <Route path={MAIN_ROUTES.CONFIRM_ACCOUNT_DELETION}>
          <ConfirmDeleteAccountPage />
        </Route>
        <Route path={MAIN_ROUTES.BETA}>
          <BetaPage />
        </Route>
        <Route component={NotFoundPage} />
      </Switch>
    </>
  );
};

export default App;
