import PropTypes from 'prop-types';

export const props = {
  minSize: PropTypes.number.isRequired,
  maxSize: PropTypes.number.isRequired,
  minWidth: PropTypes.number.isRequired,
  maxWidth: PropTypes.number.isRequired,
};

export const defaultProps = {};
