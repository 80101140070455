export const BREAKPOINTS = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1200,
  xl: 1536,
  mobile: 576,
  tablet: 768,
  desktop: 1024,
};
