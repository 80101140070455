import { SkeletonLoading } from '@components/index';
import React, { useCallback, useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import UserProfileDetail from './detail';
import UserProfileEdit from './edit';
import { useParams } from 'react-router-dom';
import { useDeps } from 'contexts/DepsContext';
import { getUserProfile } from '@graphql/queries/users';
import { MPC_USER } from 'constants/idPrefixes';
import { updateProfile } from '@graphql/mutations/users';
import { dataImageRegex, urlRegex } from '@utils/regex';
import { toast } from 'react-toastify';
import { STRINGS } from 'constants/strings';
import { showPromiseError } from '@utils/promiseError';
import { cleanPhoneNumber } from '@utils/phone';

const ProfilePage = () => {
  const [profile, setProfile] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const { userId } = useParams();
  const { httpClient } = useDeps();

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const loadUser = useCallback(
    () =>
      httpClient(getUserProfile, { userId: `${MPC_USER}${userId}` })
        .then(({ data: { getUserProfileResponse } }) =>
          setProfile(getUserProfileResponse)
        )
        .catch(),
    [httpClient, userId]
  );

  const handleSubmit = async (values) => {
    try {
      const { name, phoneNumber, image, email, biography } = values;

      const [givenName = '', lastName = ''] = name.split(' ');

      const payload = {
        givenName: givenName,
        lastName: lastName,
        phoneNumber: phoneNumber.length === 1 ? '' : `+${cleanPhoneNumber(phoneNumber)}`,
        userId: `${MPC_USER}${userId}`,
        ...(image &&
          !urlRegex.test(image) && {
            image: image.replace(dataImageRegex, ''),
          }),
        email,
        bio: biography,
      };

      await httpClient(updateProfile, { ...payload });

      toast.success(STRINGS.PROFILE_UPDATED);

      loadUser();
      toggleEditMode();
    } catch (error) {
      showPromiseError(error);
    }
  };

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  if (!profile) {
    return <SkeletonLoading />;
  }

  return (
    <Paper sx={{ p: 2, bgcolor: 'primary.main', color: 'secondary.main' }} elevation={2}>
      {editMode ? (
        <UserProfileEdit
          profile={profile}
          onCancel={toggleEditMode}
          onSubmit={handleSubmit}
        />
      ) : (
        <UserProfileDetail profile={profile} onClickEdit={toggleEditMode} />
      )}
    </Paper>
  );
};

export default ProfilePage;
