import React from 'react';

import {
  Button,
  Typography,
  Box,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  MenuItem,
} from '@mui/material';
import { CountriesSelect, PodcastArtTitle } from '@components/index';

import useLoadModalSubpage from 'hooks/useLoadModalSubpage';

import { pageNames } from 'constants/ipsso';
import { STRINGS } from 'constants/strings';
import { useAddYourPodcast } from 'contexts/AddYourPodcastContext';
import { TAP_NEXT_TO_CONFIRM_PODCAST_INFO } from 'constants/analytics';
import { useMediaQuery } from 'react-responsive';
import useDesktopMobile from 'hooks/useDesktopMobile';
import { DESKTOP_BREAKPOINT } from 'constants/breakpoints';
import { DIAL_CODES } from 'constants/countriesList';
import ReactInputMask from 'react-input-mask';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Spacer from '@shared/spacer';

const PodcastOwners = ({ renderBackBtn, onNextClick }) => {
  const {
    podcaster: { owners },
    hosts,
    podcastState: [podcast],
    regionsState: [regions, setRegions],
    toggleHost,
    setOwner,
    removeOwner,
    addOwner,
  } = useAddYourPodcast();

  const isDesktopOrLaptop = useMediaQuery({
    query: DESKTOP_BREAKPOINT,
  });
  const { renderMobileComponent, renderDesktopComponent } = useDesktopMobile();

  const handleSubmit = (evt) => {
    evt.preventDefault();
    onNextClick(TAP_NEXT_TO_CONFIRM_PODCAST_INFO);
  };

  const handleHost = (id) => (evt) => {
    const {
      target: { checked },
    } = evt;

    toggleHost(id, checked);
  };

  const handleOwner = (index, field) => (evt) => {
    const {
      target: { value },
    } = evt;

    setOwner(index, { field, value });
  };

  const handleRemoveOwner = (index) => () => removeOwner(index);
  const handleAddOwner = () => addOwner();

  useLoadModalSubpage(pageNames.PODCAST_INFO);

  return (
    <>
      <PodcastArtTitle podcast={podcast} />
      <Typography sx={{ mt: 2 }} variant="h6">
        {STRINGS.ADD_SOME_DETAILS_ABOUT_YOUR_PODCAST}
      </Typography>

      <form id="owner-form" onSubmit={handleSubmit}>
        <Box
          sx={{
            pl: 1,
            pr: 1,
          }}
        >
          <Grid sx={{ mt: 2 }} alignItems="center" container rowSpacing={2}>
            {renderDesktopComponent(() => (
              <Grid container item>
                <Grid item md={3}>
                  <Typography variant="subtitle1">{STRINGS.OWNERS}</Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography
                    sx={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}
                    variant="subtitle1"
                  >
                    {STRINGS.HOST_CO_HOST_PODCAST}
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography variant="subtitle1">{STRINGS.EMAIL}</Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography variant="subtitle1">{STRINGS.MOBILE_PHONE_SMS}</Typography>
                </Grid>

                <Grid item md={1}></Grid>
              </Grid>
            ))}

            {owners.map((_owner, index) => (
              <Grid container item key={_owner.id} columnSpacing={1} rowSpacing={2}>
                <Grid item md={3} xs={12}>
                  <TextField
                    color="secondary"
                    value={_owner.name}
                    label="Owner's Name"
                    size="small"
                    fullWidth
                    onChange={handleOwner(index, 'name')}
                    focused
                  />
                </Grid>
                <Grid
                  sx={{ ...(isDesktopOrLaptop && { textAlign: 'center' }) }}
                  item
                  md={2}
                  xs={12}
                >
                  {renderMobileComponent(() => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          checked={hosts[_owner.id] || false}
                          onChange={handleHost(_owner.id)}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={STRINGS.HOST_CO_HOST_PODCAST}
                    />
                  ))}

                  {renderDesktopComponent(() => (
                    <Checkbox
                      color="secondary"
                      checked={hosts[_owner.id] || false}
                      onChange={handleHost(_owner.id)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  ))}
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    value={_owner.email}
                    label="Owner's Email"
                    size="small"
                    fullWidth
                    onChange={handleOwner(index, 'email')}
                    color="secondary"
                    focused
                  />
                </Grid>
                <Grid item md={3} xs>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      color="secondary"
                      value={_owner.phoneCode}
                      id="outlined-select-code"
                      select
                      label="Code"
                      size="small"
                      sx={{
                        width: 115,
                        mr: 1,
                        '.MuiOutlinedInput-root': { color: 'secondary.main' },
                      }}
                      onChange={handleOwner(index, 'phoneCode')}
                      focused
                    >
                      {DIAL_CODES.map((country) => (
                        <MenuItem key={country.key} value={country.label}>
                          {country.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <ReactInputMask
                      value={_owner.phoneNumber}
                      onChange={handleOwner(index, 'phoneNumber')}
                      mask="(999) 999-9999"
                    >
                      {(inputProps) => (
                        <TextField
                          {...inputProps}
                          label="Owner's Phone"
                          size="small"
                          fullWidth
                          color="secondary"
                          focused
                        />
                      )}
                    </ReactInputMask>
                  </Box>
                </Grid>

                {_owner.isNew && (
                  <Grid item md={1} xs={2}>
                    <DeleteOutlineIcon
                      fontSize={'large'}
                      role="button"
                      onClick={handleRemoveOwner(index)}
                    />
                  </Grid>
                )}

                {renderMobileComponent(() => (
                  <Grid item xs={12}>
                    <Spacer size={10} />
                  </Grid>
                ))}
              </Grid>
            ))}

            <Grid item md={6}>
              <Button color="info" onClick={handleAddOwner}>
                + Additional Owner/Co-host
              </Button>
            </Grid>
          </Grid>

          <Box sx={{ mt: 4 }}>
            <CountriesSelect
              color="secondary"
              name="countries"
              value={regions}
              onChange={(_value) => setRegions(_value)}
            />
          </Box>
        </Box>

        <Box sx={{ mt: 2 }}>
          {renderBackBtn()}

          <Button color="error" type="submit" variant="contained">
            {STRINGS.NEXT}
          </Button>
        </Box>
      </form>
    </>
  );
};

export default PodcastOwners;
