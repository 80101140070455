import styled from '@mui/styled-engine';
import { DESKTOP_BREAKPOINT } from 'constants/breakpoints';
import { HEX_COLORS, FONT_FAMILIES } from 'constants/stylesVariables';
import { Box } from '@mui/material';

export const StyledContainer = styled(Box)(
  () => `
  background-color: ${HEX_COLORS.black};
  overflow: auto;
  display: flex;
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar { display: none; }
`
);

export const StyledInnerContainer = styled(Box)(
  () => `
  display: flex;
  flex-direction: column;
  height: inherit;
  margin: 0 auto;
  // max-width: 1440px;
  min-width: 320px;
  padding-left: 10px;
  padding-right: 10px;

  @media only screen and ${DESKTOP_BREAKPOINT} {
    padding-left: 20px;
    padding-right: 20px;
  }
`
);

export const StyledTopbar = styled(Box)(
  ({ theme }) => `
  padding: 40px 10px 0;
  display: flex;
  justify-content: space-between;

  @media only screen and ${DESKTOP_BREAKPOINT} {
    padding-top: ${theme.spacing(6)};
    padding-left: ${theme.spacing(16.25)};
    padding-right: ${theme.spacing(15)};
  }
`
);

export const StyledTitle = styled('h1')(
  () => `
  color: white;
  font-weight: 600;
  font-weight: 700;
  margin: 0;
  text-align: center;
  white-space: pre-wrap;
`
);

export const StyledInfoBox = styled('article')(
  ({ bg }) => `
  align-items: center;
  background: ${bg};
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 50px 0px;
  @media only screen and ${DESKTOP_BREAKPOINT} {
    padding: 80px 40px;
    flex-direction: row;
    align-items: initial;
  }
`
);

export const StyledPhoneImage = styled('img')(
  ({ width }) => `
  width: ${width}px;
`
);

export const StyledLinkText = styled('p')(
  () => `
  font-size: 25px;
  font-weight: normal;
  line-height: 36px;
  text-align: center;
  white-space: pre-wrap;
  cursor: pointer;
  color: ${HEX_COLORS.electricCrimson};
  &:hover {
    text-decoration: underline;
  }
`
);

export const StyledSubtitle = styled('span')(
  () => `
  color: ${HEX_COLORS.white};
  font-size: 24px;
  font-weight: 350;
  line-height: 32px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  white-space: pre-wrap;

  @media only screen and ${DESKTOP_BREAKPOINT} {
    margin-left: 0;
    margin-right: 0;
  }
`
);

export const StyledPlayerImg = styled('img')(
  () => `
  margin: 0 auto;
  max-width: 910px;
  width: 100%;
`
);

export const StyledPlayerHeader = styled('h4')(
  ({ theme }) => `
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  text-align: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  margin: 0;
  margin-bottom: ${theme.spacing(1)};
  color: ${HEX_COLORS.blueBolt};
`
);

export const StyledTopbarLink = styled('button')(
  () => `
  background-color: transparent;
  border: 0;
  color: ${HEX_COLORS.white};
  cursor: pointer;
  font-family: ${FONT_FAMILIES.mallory};
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.1em;
  transition: opacity 0.2s;
  line-height: 21.33px;

  &:hover {
    opacity: 0.75;
  }
`
);
