import PropTypes from 'prop-types';

export const props = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  headerColumns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ),
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      required: PropTypes.bool,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      defaultValue: PropTypes.any.isRequired,
      label: PropTypes.string,
    })
  ),
  label: PropTypes.string.isRequired,
  addButtonLabel: PropTypes.string,
};

export const defaultProps = {};
