import { ACCOUNT, NETWORK, PODCASTER } from 'constants/idPrefixes';
import { MPC_ROUTES } from 'constants/routing';
import { NETWORK_VIEW_GROUPS } from 'constants/userGroups';

export const getRouteToRedirect = (url, defaultAccountInfo) => {
  let to = '';
  let name = '';

  if (!!defaultAccountInfo) {
    const { userDefaultAccountRole: _group } = defaultAccountInfo;

    if (Object.prototype.hasOwnProperty.call(NETWORK_VIEW_GROUPS, _group)) {
      let { entityId: networkId, entityName: networkName } = defaultAccountInfo;

      networkId = networkId?.replace(ACCOUNT, '').replace(NETWORK, '');
      to = `${url}${MPC_ROUTES.NETWORKS}/${networkId}`;
      name = networkName;

      return { to, name };
    }

    let { entityId: podcasterId, entityName: podcasterName } = defaultAccountInfo;

    podcasterId = podcasterId?.replace(PODCASTER, '');
    to = `${url}${MPC_ROUTES.PODCASTER}/${podcasterId}`;
    name = podcasterName;
  }

  return { to, name };
};
