export const FIELD_TYPES = {
  TEXT: 'text',
  SELECT: 'select',
  SELECT_MULTIPLE: 'select-multiple',
  PODCAST_SEARCH: 'podcast-search',
  CHECKBOX: 'checkbox',
  CHECKBOX_SELECT: 'checkbox-select',
  IMAGE: 'image',
  TITLE: 'title',
  LIST: 'list',
  COUNTRIES_SELECT: 'countries-select',
  PHONE_NUMBER: 'phone-number',
};
