import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Typography, Box, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router';
import { cloneDeep } from 'lodash';
import pluralize from 'pluralize';
import {
  EntitiesDataGrid,
  GeoAvailabilityModal,
  HierarchyHeader,
  SimpleDropdown,
  SkeletonLoading,
} from '@components/index';
import { EntitiesIcon } from '@components/icons/index';
import { useEntityNames } from 'contexts/EntitiesNamesContext';
import { useAuth } from 'contexts/AuthContext';
import useInfiniteRow from 'hooks/useInfiniteRow';
import { getPodcastById, listEpisodesForPodcastTable } from '@graphql/queries/podcasts';
import {
  activateDeactivateEpisodes,
  changeEpisodesGeoAvailability,
  deleteEpisodes,
  enableDisableEpisodesMarbylization,
  loadRSSFeed,
  updatePodcast,
} from '@graphql/mutations/podcasts';
import { requestAPIGraphQL } from '@services/appSyncAPI';
import { STRINGS } from 'constants/strings';
import { HEX_COLORS } from 'constants/stylesVariables';
import { EPISODE_PREFIX, PODCAST_PREFIX } from 'constants/idPrefixes';
import { TAP_EPISODE_ITEM, TAP_MARBYLIZE_EPISODES } from 'constants/analytics';
import TOAST_OPTIONS from 'constants/toastOptions';
import { STATUS_LABELS } from 'constants/activeStatus';
import { DEFAULT_LIMIT } from 'constants/appSync';
import { M11N_AUTO_TYPES } from 'constants/m11n';
import { PODCAST_VIEW_ACTIONS } from 'constants/actionsList';
import { MPC_ROUTES } from 'constants/routing';
import { dateToMMDoYYYY } from '@utils/dateFormat';
import { getEntityType } from '@utils/getEntityType';
import { getAvailableInGeosPayload } from '@utils/getAvailableInGeos';
import { getActiveStatusValue, getIsActive } from '@utils/getActiveStatus';
import { replaceLiterals } from '@utils/replaceLiterals';
import MySwal from '@utils/swalWrapper';
import { record } from '@utils/analytics';
import { errorLog } from '@utils/logs';
import EditPodcast from './edit';
import PodcastDetail from './detail';

const EPISODES_ACTIONS = {
  ENABLE_MARBYLIZATION: {
    key: 'enable_marbylization',
    label: 'Enable Marbylization',
  },
  DISABLE_MARBYLIZATION: {
    key: 'disable_marbylization',
    label: 'Disable Marbylization',
  },
  CHANGE_GEO_AVAILABILITY: {
    key: 'change_geo',
    label: STRINGS.CHANGE_GEO_AVAILABILITY,
  },
  ACTIVATE: {
    key: 'activate_episodes',
    label: 'Activate Episodes',
  },
  DEACTIVATE: {
    key: 'deactivate_episodes',
    label: 'Deactivate Episodes',
  },
  DELETE: {
    key: 'delete_episodes',
    label: 'Delete Episodes',
  },
};

const EPISODES_BLOCK = DEFAULT_LIMIT;

const getRowStyle = ({ data }) => {
  if (!data) return;

  const { isDeleted } = data;

  if (isDeleted) {
    return { color: 'gray', cursor: 'normal' };
  }
};

const PodcastContainer = ({ id }) => {
  const gridRef = useRef();
  const history = useHistory();
  const { pathname } = useLocation();
  const [podcast, setPodcast] = useState();
  const [selEpisodes, setSelEpisodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openGeoModal, setOpenGeoModal] = useState(false);
  const setEntity = useEntityNames()[1];
  const { permissions } = useAuth();

  const [editMode, setEditMode] = useState(false);

  const { getDataSource } = useInfiniteRow({
    query: listEpisodesForPodcastTable,
    payload: {
      podcastId: `${PODCAST_PREFIX}${id}`,
      limit: EPISODES_BLOCK,
    },
    responseKeyName: 'listEpisodesByPodcastId',
  });

  const columnDefs = useMemo(
    () => [
      {
        field: '',
        checkboxSelection: ({ data }) => {
          if (!data) return false;

          const { isDeleted } = data;

          if (isDeleted) return false;

          return true;
        },
        headerName: '',
        width: 60,
        cellStyle: { display: 'flex', alignItems: 'center' },
      },
      {
        field: '',
        cellRenderer: ({ data }) => {
          const entity = getEntityType(data?.PK);

          return <EntitiesIcon centerIcon isBold type={entity} height={24} width={24} />;
        },
        width: 60,
      },
      {
        field: 'isDeleted',
        hide: true,
      },
      {
        field: 'title',
        headerName: 'Title',
        minWidth: 300,
        flex: 1,
      },
      {
        field: 'pubDate',
        headerName: 'Pub Date',
        width: 200,
        cellRenderer: ({ value }) =>
          value ? dateToMMDoYYYY(value) : dateToMMDoYYYY(new Date().toISOString()),
      },
      {
        field: 'isActivated',
        headerName: 'Status',
        cellRenderer: ({ value, data }) => {
          const { isDeleted } = data || { isDeleted: false };

          if (isDeleted || value === null) {
            return STRINGS.DELETED;
          }

          const status = getActiveStatusValue(value);

          return STATUS_LABELS[status];
        },
        width: 100,
      },
      {
        field: 'duration',
        headerName: 'Duration',
        width: 130,
      },
      {
        headerName: 'Marbylization',
        children: [
          {
            field: 'm11nIsEnabled',
            headerName: 'Enabled',
            cellRenderer: (params) => {
              const { value } = params;

              if (value) {
                return <CheckIcon color="success" />;
              }

              return <CloseIcon color="error" />;
            },
            width: 120,
            cellStyle: {
              display: 'flex',
              alignItems: 'center',
              borderLeft: `1px solid ${HEX_COLORS.dimGray}`,
            },
          },
          {
            field: 'lastM11nDate',
            headerName: 'Date',
            width: 100,
            cellRenderer: ({ value }) =>
              value ? dateToMMDoYYYY(value) : STRINGS.PENDING,
          },
          {
            field: 'lastM11nResult',
            headerName: 'Result',
            width: 150,
          },
          {
            field: 'lastM11nMarbylsCreated',
            headerName: 'Marbyls Created',
            width: 150,
            cellRenderer: ({ value }) => value ?? 0,
          },
        ],
      },
    ],
    []
  );

  const episodesActions = useMemo(() => {
    const podcastsLength = selEpisodes.length;
    if (podcastsLength === 0) {
      return [];
    }

    let tempActions = cloneDeep(EPISODES_ACTIONS);
    let canMarbylize = true;
    let canChangeStatus = true;
    let canDelete = true;

    if (!(PODCAST_VIEW_ACTIONS.TOGGLE_M11N_EPISODES in permissions)) {
      canMarbylize = false;
      delete tempActions.DISABLE_MARBYLIZATION;
      delete tempActions.ENABLE_MARBYLIZATION;
    }

    if (!(PODCAST_VIEW_ACTIONS.UPDATE_EPISODES_AVAILABILITY in permissions)) {
      delete tempActions.CHANGE_GEO_AVAILABILITY;
    }

    if (!(PODCAST_VIEW_ACTIONS.TOGGLE_EPISODES in permissions)) {
      canChangeStatus = false;
      delete tempActions.ACTIVATE;
      delete tempActions.DEACTIVATE;
    }

    if (!(PODCAST_VIEW_ACTIONS.DELETE_EPISODES in permissions)) {
      canDelete = false;
      delete tempActions.DELETE;
    }

    if (canMarbylize) {
      const { m11nAutoType } = podcast;

      if (m11nAutoType === M11N_AUTO_TYPES.DISABLED) {
        tempActions.ENABLE_MARBYLIZATION.disabled = true;
        tempActions.DISABLE_MARBYLIZATION.disabled = true;
      }
    }

    if (canChangeStatus) {
      const activatedLength = selEpisodes.reduce(
        (accumulator, current) => (accumulator += current.isActivated),
        0
      );

      if (podcastsLength === 1) {
        tempActions.ACTIVATE.label = pluralize.singular(tempActions.ACTIVATE.label);
        tempActions.DEACTIVATE.label = pluralize.singular(tempActions.DEACTIVATE.label);

        activatedLength
          ? (tempActions.ACTIVATE.disabled = true)
          : (tempActions.DEACTIVATE.disabled = true);
      } else {
        if (activatedLength === podcastsLength) {
          tempActions.ACTIVATE.disabled = true;
        }

        if (activatedLength === 0) {
          tempActions.DEACTIVATE.disabled = true;
        }
      }
    }

    if (canDelete) {
      const deleted = selEpisodes.reduce(
        (accumulator, current) => (accumulator += current.isDeleted),
        0
      );

      if (deleted) {
        tempActions.DELETE.disabled = true;
      }

      if (podcastsLength === 1) {
        tempActions.DELETE.label = pluralize.singular(tempActions.DELETE.label);
      }
    }

    return Object.values(tempActions);
  }, [selEpisodes, podcast, permissions]);

  const setEpisodesDataSource = useCallback(
    () => gridRef.current.api.setDatasource(getDataSource(true)),
    [getDataSource]
  );

  const getRowId = useCallback(function (params) {
    return params.data.PK;
  }, []);

  const loadPodcast = useCallback(
    () =>
      requestAPIGraphQL(getPodcastById, { podcastId: `${PODCAST_PREFIX}${id}` })
        .then(({ data: { getPodcastById } }) => {
          setPodcast(getPodcastById);
        })
        .catch(),
    [id]
  );

  const handleRowClick = ({ PK, isDeleted, title }) => {
    if (isDeleted || selEpisodes.length) return;

    const _id = PK.replace(`${EPISODE_PREFIX}`, '');
    record(TAP_EPISODE_ITEM, { id: PK });
    setEntity(_id, title);
    history.push(`${pathname}${MPC_ROUTES.EPISODES}/${_id}`);
  };

  const handleCloseGeoModal = () => {
    setOpenGeoModal(false);
  };

  const onSubmitGeoAvailability = (episodesGeo) => {
    const ids = selEpisodes.map(({ PK }) => PK);

    const payload = {
      episodeIds: ids,
      availableInGeos: getAvailableInGeosPayload(episodesGeo),
    };
    const message = replaceLiterals(STRINGS.EPISODES_CHANGE_GEO_AVAILABILITY, {
      count: ids.length,
    });

    setLoading(true);
    requestAPIGraphQL(changeEpisodesGeoAvailability, {
      ...payload,
    })
      .then(() => {
        handleCloseGeoModal();
        gridRef.current.api.deselectAll();
        toast.success(message, TOAST_OPTIONS);
      })
      .catch(() => {
        toast.error(STRINGS.AN_ERROR_OCCURED_ON_SERVER__TRY_AGAIN, TOAST_OPTIONS);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClickAction = (action) => {
    const { ids, hash } = selEpisodes.reduce(
      (obj, pod) => ({
        ids: [...obj.ids, pod.PK],
        hash: {
          ...obj.hash,
          [pod.PK]: pod.title,
        },
      }),
      { ids: [], hash: [] }
    );

    let query = null;
    let message = '';
    let payload = {
      episodeIds: ids,
    };
    let confirmationMessage = '';

    const actionCaller = (actionPromise, successMessage) =>
      new Promise((resolve, reject) => {
        setLoading(true);

        return actionPromise
          .then(() => {
            toast.success(successMessage, TOAST_OPTIONS);
            const { episodeIds } = payload;

            switch (action) {
              case EPISODES_ACTIONS.ENABLE_MARBYLIZATION.key:
              case EPISODES_ACTIONS.DISABLE_MARBYLIZATION.key: {
                const { isEnabled } = payload;
                episodeIds.forEach((_episodeId) => {
                  const rowNode = gridRef.current.api.getRowNode(_episodeId);
                  rowNode.setDataValue('m11nIsEnabled', isEnabled);
                });
                break;
              }

              case EPISODES_ACTIONS.ACTIVATE.key:
              case EPISODES_ACTIONS.DEACTIVATE.key: {
                const { isActivated } = payload;
                episodeIds.forEach((_episodeId) => {
                  const rowNode = gridRef.current.api.getRowNode(_episodeId);
                  rowNode.setDataValue('isActivated', isActivated);
                });
                break;
              }

              case EPISODES_ACTIONS.DELETE.key: {
                episodeIds.forEach((_episodeId) => {
                  const rowNode = gridRef.current.api.getRowNode(_episodeId);
                  rowNode.setDataValue('isDeleted', true);
                  rowNode.setDataValue('isActivated', null);
                });

                break;
              }

              default:
                break;
            }

            resolve();
          })
          .catch((err) => {
            errorLog(err);
            toast.error(STRINGS.AN_ERROR_OCCURED_ON_SERVER__TRY_AGAIN, TOAST_OPTIONS);
            reject();
          })
          .finally(() => {
            gridRef.current.api.deselectAll();
            setLoading(false);
          });
      });

    const getMessage = (singleMessage, multipleMessage) => {
      return ids.length === 1
        ? replaceLiterals(singleMessage, {
            episodeName: hash[ids[0]],
          })
        : replaceLiterals(multipleMessage, {
            count: ids.length,
          });
    };

    switch (action) {
      case EPISODES_ACTIONS.ENABLE_MARBYLIZATION.key: {
        payload.isEnabled = true;
        query = enableDisableEpisodesMarbylization;
        message = getMessage(
          STRINGS.EPISODE_NAME_MARBYLIZATION_ENABLED,
          STRINGS.EPISODES_MARBYLIZATION_ENABLED
        );

        record(TAP_MARBYLIZE_EPISODES, {
          episodeIds: JSON.stringify(payload.episodeIds),
          isEnabled: true,
        });

        break;
      }

      case EPISODES_ACTIONS.DISABLE_MARBYLIZATION.key: {
        payload.isEnabled = false;
        query = enableDisableEpisodesMarbylization;
        message = getMessage(
          STRINGS.EPISODE_NAME_MARBYLIZATION_DISABLE,
          STRINGS.EPISODES_MARBYLIZATION_DISABLED
        );

        record(TAP_MARBYLIZE_EPISODES, {
          episodeIds: JSON.stringify(payload.episodeIds),
          isEnabled: false,
        });

        break;
      }

      case EPISODES_ACTIONS.ACTIVATE.key: {
        payload.isActivated = true;
        query = activateDeactivateEpisodes;
        message = getMessage(STRINGS.EPISODE_NAME_ACTIVATED, STRINGS.EPISODES_ACTIVATED);
        break;
      }

      case EPISODES_ACTIONS.DEACTIVATE.key: {
        payload.isActivated = false;
        query = activateDeactivateEpisodes;
        message = getMessage(
          STRINGS.EPISODE_NAME_DEACTIVATED,
          STRINGS.EPISODES_DEACTIVATED
        );
        break;
      }

      case EPISODES_ACTIONS.DELETE.key: {
        query = deleteEpisodes;
        message = getMessage(STRINGS.EPISODE_NAME_DELETED, STRINGS.EPISODES_DELETED);
        confirmationMessage = getMessage(
          STRINGS.ARE_YOU_SURE_DO_YOU_WANT_TO_DELETE_EPISODE_NAME,
          STRINGS.ARE_YOU_SURE_DO_YOU_WANT_TO_DELETE_SELECTED_EPISODES
        );

        break;
      }

      case EPISODES_ACTIONS.CHANGE_GEO_AVAILABILITY.key: {
        setOpenGeoModal(true);
        return;
      }

      default:
        break;
    }

    if (confirmationMessage) {
      MySwal.fire({
        html: <Typography variant="body1">{confirmationMessage}</Typography>,
        showCancelButton: true,
        confirmButtonText: STRINGS.CONFIRM,
        denyButtonText: STRINGS.CANCEL,
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          actionCaller(requestAPIGraphQL(query, payload), message);
        }
      });

      return;
    }

    actionCaller(requestAPIGraphQL(query, payload), message);
  };

  const renderReloadRSSButton = useCallback(() => {
    const handleClick = () => {
      const res = window.confirm(STRINGS.DO_YOU_WANT_TO_RELOAD_RSS_FEED);

      if (res) {
        requestAPIGraphQL(loadRSSFeed, { podcastId: `${PODCAST_PREFIX}${id}` })
          .then(() => {
            toast.success(STRINGS.PODCAST_IS_BEEN_UPDATED, TOAST_OPTIONS);
            setEditMode(false);
          })
          .catch(() => {
            toast.error(STRINGS.AN_ERROR_OCCURED_ON_SERVER__TRY_AGAIN, TOAST_OPTIONS);
          });
      }
    };

    return (
      <Button onClick={handleClick} variant="contained" color="error">
        {STRINGS.RELOAD_RSS_DATA}
      </Button>
    );
  }, [id]);

  const renderExpanded = useCallback(() => {
    const handleSubmit = (values) => {
      const payload = {
        podcastId: values.id,
        ...(permissions[PODCAST_VIEW_ACTIONS.EDIT_PODCAST_BASIC_INFO] && {
          preferredSubscribeLink: values.preferredSubscribeLink,
          preferredSubscribeLinkType: values.preferredSubscribeLinkType,
        }),
        ...(permissions[PODCAST_VIEW_ACTIONS.EDIT_GEO_AVAILABILITY] && {
          availableInGeos: getAvailableInGeosPayload(values.availableInGeos),
        }),
        ...(permissions[PODCAST_VIEW_ACTIONS.TOGGLE_ACTIVATION_PODCAST] && {
          isActivated: getIsActive(values.isActivated),
        }),
        ...(permissions[PODCAST_VIEW_ACTIONS.TOGGLE_M11N] && {
          m11nIsEnabled: getIsActive(values.m11nIsEnabled),
        }),
        ...(permissions[PODCAST_VIEW_ACTIONS.EDIT_M11N_OPTIONS] && {
          m11nAutoType: values.m11nAutoType,
        }),
      };

      requestAPIGraphQL(updatePodcast, { ...payload })
        .then(() => {
          toast.success(STRINGS.PODCAST_UPDATED, TOAST_OPTIONS);
          setEditMode(false);
          loadPodcast();
        })
        .catch((err) => {
          errorLog(err);
          toast.error(STRINGS.AN_ERROR_OCCURED_ON_SERVER__TRY_AGAIN, TOAST_OPTIONS);
        });
    };

    if (editMode) {
      return (
        <EditPodcast
          onCancel={() => setEditMode(false)}
          onSubmit={handleSubmit}
          {...podcast}
        />
      );
    }

    return <PodcastDetail onClickEdit={() => setEditMode(true)} {...podcast} />;
  }, [editMode, podcast, permissions, loadPodcast]);

  useEffect(() => {
    Promise.all([loadPodcast()]);
  }, [loadPodcast]);

  if (!podcast) return <SkeletonLoading />;

  return (
    <>
      <HierarchyHeader
        title={podcast.title}
        headerType={STRINGS.PODCAST}
        {...(editMode &&
          permissions[PODCAST_VIEW_ACTIONS.RELOAD_RSS] && {
            renderOptions: renderReloadRSSButton,
          })}
        {...(permissions[PODCAST_VIEW_ACTIONS.VIEW_PODCAST_DETAILS] && {
          renderExpandedContent: renderExpanded,
        })}
        podcastShowArt={podcast.showArtSmall}
      />

      <Box sx={{ display: 'flex', mt: 2, justifyContent: 'space-between' }}>
        <Typography variant="h4">{STRINGS.EPISODES}</Typography>
        <SimpleDropdown
          disabled={selEpisodes.length === 0 || loading}
          actions={episodesActions}
          onClickAction={onClickAction}
        />
      </Box>

      <EntitiesDataGrid
        ref={gridRef}
        columnDefs={columnDefs}
        onCellClicked={({ data }) => handleRowClick(data)}
        onSelectionChanged={(evt) => {
          setSelEpisodes(evt.api.getSelectedRows());
        }}
        rowModelType={'infinite'}
        onGridReady={setEpisodesDataSource}
        cacheBlockSize={EPISODES_BLOCK}
        getRowId={getRowId}
        getRowStyle={getRowStyle}
      />

      <GeoAvailabilityModal
        open={openGeoModal}
        onClose={handleCloseGeoModal}
        isLoading={loading}
        onSubmit={onSubmitGeoAvailability}
      />
    </>
  );
};

export default PodcastContainer;
