import { useState } from 'react';
import PropTypes from 'prop-types';
import { ControlledMenu, MenuItem, useMenuState } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';

const ContextMenu = ({ children, menuItems, onRightClick }) => {
  const [menuProps, toggleMenu] = useMenuState();
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });

  const renderedMenuItems = menuItems.map((menuItem, index) => (
    <MenuItem
      key={`context-menu-item-${index}`}
      onClick={menuItem.onClick}
      disabled={menuItem.isDisabled}
    >
      {menuItem.text}
    </MenuItem>
  ));

  return (
    <div
      onContextMenu={(evt) => {
        evt.preventDefault();

        if (typeof onRightClick === 'function') onRightClick();

        setAnchorPoint({ x: evt.clientX, y: evt.clientY });
        toggleMenu(true);
      }}
    >
      {children}
      <ControlledMenu
        {...menuProps}
        anchorPoint={anchorPoint}
        onClose={() => toggleMenu(false)}
      >
        {renderedMenuItems}
      </ControlledMenu>
    </div>
  );
};

ContextMenu.propTypes = {
  children: PropTypes.node.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      isDisabled: PropTypes.bool.isRequired,
    })
  ).isRequired,
  onRightClick: PropTypes.func,
};

ContextMenu.defaultProps = {
  onRightClick: null,
};

export default ContextMenu;
