import React, { useCallback } from 'react';
import { Box } from '@mui/material';

import { StyledFullHeight, StyledInnerContainer } from '@shared/styledContainers';
import { DynamicSpacer, Topbar } from '@components/index';
import { BREAKPOINT_SIZE, MAX_SCREEN_SIZE } from 'constants/breakpoints';
import { AccountConfirmation } from '@components/containers/index';

// TODO: (RS) Check this component, if this is not  used, then delete it

const AccountConfirmationPage = () => {
  const handleSubmit = useCallback(() => {}, []);

  return (
    <StyledFullHeight>
      <StyledInnerContainer>
        <Topbar />

        <DynamicSpacer
          maxWidth={MAX_SCREEN_SIZE}
          minWidth={BREAKPOINT_SIZE}
          minSize={50}
          maxSize={100}
        />
        <Box>
          <AccountConfirmation onSubmit={handleSubmit} />
        </Box>
      </StyledInnerContainer>
    </StyledFullHeight>
  );
};

export default AccountConfirmationPage;
