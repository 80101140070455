// What is Marbyl page: /
export const TAP_JOIN_WAITLIST_DESKTOP = 'tapJoinWaitlistDesktop';
export const TAP_JOIN_WAITLIST_MOBILE = 'tapJoinWaitlistMobile';
export const TAP_JOINING_MARBY_LINK = 'tapJoiningMarbylLink';

// Partners page - /partners
export const TAP_CLAIM_PODCAST = 'tapClaimPodcast';
export const TAP_LOGIN = 'tapLogin';
export const TAP_MY_ACCOUNT = 'tapMyAccount';

// Login
export const TAP_SIGN_UP_BUTTON = 'tapSignUpButton';
export const TAP_SIGN_IN = 'tapSignIn';
export const TAP_SIGN_UP_LINK = 'tapSignUpLink';

// SignUp
export const TAP_SIGN_IN_BUTTON = 'tapSignInButton';
export const TAP_SIGN_UP_CONFIRMATION = 'tapSignUpConfirmationButton';
export const TAP_SIGN_IN_LINK = 'tapSignInLink';

// ConfirmSignUp
export const TAP_CONFIRM_CODE = 'tapConfirmCode';
export const USER_ACCOUNT_CREATED = 'userAccountCreated';

// Claim Podcast / IPSSO podcasts
export const TAP_ADD_YOUR_PODCAST = 'tapAddYourPodcast';
export const TAP_ADD_PODCAST = 'tapAddPodcast';

// IPSSO Flow
export const RSS_DIRECT_ENTRY = 'rssDirectEntry';
export const API_SEARCH = 'apiSearch';
export const TAP_CANCEL_FROM_FEED_LOOKUP = 'tapCancelFromFeedLookup';
export const TAP_X_CLOSE_ADD_PODCAST_MODAL = 'tapXToCloseAddPodcastModal';
export const LOAD_MODAL_SUBPAGE = 'loadModalSubpage';
export const TAP_CANCEL_FROM_FEED_CONFIRMATION = 'tapCancelFromFeedConfirmation';
export const TAP_NEXT_FROM_FEED_CONFIRMATION = 'tapNextFromFeedConfirmation';

export const TAP_PREVIOUS_FROM_SEND_CODE = 'tapPreviousFromSendCode';
export const TAP_SEND_THE_CODE = 'tapSendTheCode';

export const TAP_VERIFY = 'tapVerify';
export const TAP_RESEND_CODE = 'tapResendCode';
export const TAP_PREVIOUS_FROM_VERIFY_CODE = 'tapPreviousFromVerifyCode';

export const TAP_NEXT_TO_CONFIRM_PODCAST_INFO = 'tapNextToConfirmPodcastInfo';

export const TAP_PREVIOUS_FROM_REVIEW_AND_SUBMIT = 'tapPreviousFromReviewAndSubmit';
export const TAP_SUBMIT_FROM_REVIEW_AND_SUBMIT = 'tapSubmitFromReviewAndSubmit';

export const TAP_DONE_FROM_WELCOME_TO_MARBYL = 'tapDoneFromWelcomeToMarbyl';

export const PODCAST_ONBOARDED = 'podcastOnboarded';
export const PODCASTER_ONBOARDED = 'podcasterOnboarded';

// Layout-Sidebar
export const TAP_MARBYL_LOGO = 'tapMarbylLogo';
export const TAP_HOME_IN_SIDEBAR = 'tapHomeInSidebar';
export const TAP_PODCASTS_IN_SIDEBAR = 'tapPodcastsInSidebar';
export const TAP_LOGOUT_IN_SIDEBAR = 'tapLogoutInSidebar';

// Marbyl Production Center
export const TAP_USER_MANAGEMENT = 'tapUserManagement';
export const TAP_EDIT_NETWORK = 'tapEditNetwork';
export const TAP_SHOW_NETWORK_DETAILS = 'tapShowNetworkDetails';
export const TAP_HIDE_NETWORK_DETAILS = 'tapHideNetworkDetails';
export const TAP_ADD_NETWORK = 'tapAddNetwork';
export const TAP_ADD_PODCASTER = 'tapAddPodcaster';
export const TAP_NETWORK_ENTITY = 'tapNetworkEntity';
export const TAP_PODCASTER_ENTITY = 'tapPodcasterEntity';

export const TAP_SAVE_NETWORK = 'tapSaveNetwork';
export const TAP_CREATE_USER = 'tapCreateUser';
export const TAP_USER_ITEM = 'tapUserItem';
export const TAP_SAVE_USER = 'tapSaveUser';
export const TAP_SAVE_PODCASTER = 'tapSavePodcaster';
export const TAP_EDIT_PODCASTER = 'tapEditPodcaster';
export const TAP_SHOW_PODCASTER_DETAILS = 'tapShowPodcasterDetails';
export const TAP_HIDE_PODCASTER_DETAILS = 'tapHidePodcasterDetails';
export const TAP_PODCAST_ITEM = 'tapPodcastItem';

export const TAP_EDIT_PODCAST = 'tapEditPodcast';
export const TAP_SHOW_PODCAST_DETAILS = 'tapShowPodcastDetails';
export const TAP_HIDE_PODCAST_DETAILS = 'tapHidePodcastDetails';
export const TAP_MARBYLIZE_EPISODES = 'tapMarbylizeEpisodes';
export const TAP_EPISODE_ITEM = 'tapEpisodeItem';

export const UPDATE_CURATION_STATUS_FILTER = 'updateCurationStatusFilter';
export const UPDATE_MODERATION_STATUS_FILTER = 'updateModerationStatusFilter';
export const TAP_MARBYL_ITEM = 'tapMarbylItem';
export const TAP_SAVE_MARBYL = 'tapSaveMarbyl';

// COMMON EVENTS
export const PAGE_LOAD = 'pageLoad';
export const TAP_PRIVACY_POLICY_LINK = 'tapPrivacyPolicyLink';
export const TAP_TERMS_OF_USE_LINK = 'tapTermsOfUseLink';
export const TAP_TGM_LINK = 'tapTGMLink';
export const PAGE_EXIT = 'pageExit';
export const TAP_LOGOUT = 'tapLogout';
