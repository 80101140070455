import styled from '@mui/styled-engine';

export const ListContainer = styled('div')(
  () => `
  max-height: 300px;
  overflow: auto;
  background-color: white;
  color: black;
`
);

export const ListItem = styled('div')(
  () => `
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
);

export const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'transparent',
  }),
  singleValue: (styles, state) => ({
    ...styles,
    color: 'black',
    opacity: state.isDisabled ? 0.5 : 1,
  }),
  placeholder: (styles) => ({ ...styles, color: 'black' }),
  valueContainer: (styles, state) => ({
    ...styles,
    opacity: state.isDisabled ? 0.5 : 1,
  }),
  indicatorsContainer: (styles, state) => ({
    ...styles,
    opacity: state.isDisabled ? 0.5 : 1,
  }),
};
