import { STAGE } from 'constants/secrets';
import { useMemo } from 'react';

const usePodcastRSSEmail = (podcast) => {
  const podcastEmail = useMemo(
    () => (!STAGE || STAGE !== 'prod' ? '' : podcast?.itunes?.owner?.email || ''),
    [podcast]
  );
  return podcastEmail;
};

export default usePodcastRSSEmail;
