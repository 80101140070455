import { Analytics } from 'aws-amplify';
import { STAGE } from 'constants/secrets';
import { errorLog, tableLog } from './logs';

// export const updateEndpoint = (email, userId) => {
//   try {
//     const userKey = `USER#${userId}`;
//     return Analytics.updateEndpoint({
//       address: email,
//       channelType: "EMAIL",
//       OptOut: "NONE",
//       userId: userKey,
//       userAttributes: {
//         userKey: [userKey],
//       },
//     });
//   } catch (error) {
//     errorLog(error);
//     return new Promise((resolve, reject) => resolve(null));
//   }
// };

export const record = (name, attributes = null, metrics = null) => {
  try {
    if (STAGE === 'dev') {
      tableLog({ name, attributes, metrics });
    }

    return Analytics.record({
      name,
      ...(attributes && { attributes }),
      ...(metrics && { metrics }),
    });
  } catch (error) {
    errorLog(error);
    return null;
  }
};
