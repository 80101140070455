import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Button, Box } from '@mui/material';
import { useHistory, useRouteMatch } from 'react-router';
import { EntitiesDataGrid, HierarchyHeader, SkeletonLoading } from '@components/index';
import { EntitiesIcon } from '@components/icons/index';
import { STRINGS } from 'constants/strings';
import { useEntityNames } from 'contexts/EntitiesNamesContext';
import { record } from '@utils/analytics';
import { getEntityType } from '@utils/getEntityType';
import {
  TAP_ADD_NETWORK,
  TAP_ADD_PODCASTER,
  TAP_EDIT_NETWORK,
  TAP_NETWORK_ENTITY,
  TAP_PODCASTER_ENTITY,
} from 'constants/analytics';
import { PODCASTER, NETWORK, NETWORK_ENTITY, PODCAST_PREFIX } from 'constants/idPrefixes';
import {
  getNetworkById,
  listChildrenByPodcastNetworkId,
} from '@graphql/queries/networks';
import { requestAPIGraphQL } from '@services/appSyncAPI';
import useInfiniteRow from 'hooks/useInfiniteRow';
import { DEFAULT_LIMIT } from 'constants/appSync';
import {
  convertGeoArrayToText,
  getAvailableInGeosPayload,
} from '@utils/getAvailableInGeos';
import { getIsActive } from '@utils/getActiveStatus';
import { updateNetwork } from '@graphql/mutations/networks';
import { showPromiseError } from '@utils/promiseError';
import { toast } from 'react-toastify';
import TOAST_OPTIONS from 'constants/toastOptions';
import { MPC_ROUTES } from 'constants/routing';
import EditNetwork from './edit';
import NetworkDetail from './detail';

const NETWORK_CHILDREN_BLOCK = DEFAULT_LIMIT;

const NetworkContainer = ({ id }) => {
  const gridRef = useRef();
  const history = useHistory();
  const { url } = useRouteMatch();
  const [network, setNetwork] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const setEntity = useEntityNames()[1];

  const { getDataSource } = useInfiniteRow({
    query: listChildrenByPodcastNetworkId,
    payload: {
      networkdId: `${NETWORK}${id}`,
      limit: NETWORK_CHILDREN_BLOCK,
    },
    responseKeyName: 'listChildrenByPodcastNetworkId',
  });

  const handleAddNetwork = () => {
    window.alert(STRINGS.THIS_IS_IN_PROGRESS);
    record(TAP_ADD_NETWORK);
  };

  const handleAddPodcaster = () => {
    window.alert(STRINGS.THIS_IS_IN_PROGRESS);
    record(TAP_ADD_PODCASTER);
  };

  const handleEntityClick = ({ data: { PK, ...data } }) => {
    if (PK.includes(PODCASTER)) {
      const _id = PK.replace(PODCASTER, '');
      record(TAP_PODCASTER_ENTITY, { id: _id });
      setEntity(_id, data.name);

      history.push(`${url}${MPC_ROUTES.PODCASTER}/${_id}`);

      return;
    }
    if (PK.includes(NETWORK_ENTITY)) {
      const _id = PK.replace(NETWORK_ENTITY, '');
      record(TAP_NETWORK_ENTITY, { id: _id });
      setEntity(_id, data.podcastNetworkName);

      history.push(`${url}${MPC_ROUTES.NETWORKS}/${_id}`);

      return;
    }

    const _id = PK.replace(PODCAST_PREFIX, '');
    record(TAP_NETWORK_ENTITY, { id: _id });
    setEntity(_id, data.title);

    history.push(`${url}${MPC_ROUTES.PODCAST}/${_id}`);
  };

  const columnDefs = useMemo(
    () => [
      {
        width: 60,
        cellRenderer: ({ data }) => {
          const entity = getEntityType(data?.itemType);

          return (
            <EntitiesIcon
              centerIcon
              defaultImageDimensions
              isBold
              type={entity}
              podcastShowArt={data?.showArtSmall}
              height={24}
              width={24}
            />
          );
        },
      },
      {
        field: 'name',
        headerName: 'Name',
        minWidth: 400,
        flex: 1,
        cellRenderer: ({ data }) => {
          if (!data) return '';

          const { name, podcastNetworkName, title } = data;

          return name || podcastNetworkName || title;
        },
      },
      {
        field: 'availableInGeos',
        headerName: STRINGS.GEOGRAPHIC_AVAILABILITY,
        width: 250,
        cellRenderer: ({ value }) => convertGeoArrayToText(value),
      },
    ],
    []
  );

  const setDataSource = useCallback(
    () => gridRef.current.api.setDatasource(getDataSource()),
    [getDataSource]
  );

  const getRowId = useCallback(function (params) {
    return params.data.PK;
  }, []);

  const fetchNetwork = useCallback(() => {
    requestAPIGraphQL(getNetworkById, {
      networkId: `${NETWORK_ENTITY}${id}`,
    }).then(({ data: { getPodcastNetworkById } }) => {
      setNetwork(getPodcastNetworkById);
    });
  }, [id]);

  const handleSubmitNetwork = useCallback(
    (values) => {
      const {
        PK,
        name,
        description,
        availableInGeos,
        contentAgreementType,
        isActivated,
        entity,
        entityType,
      } = values;

      const payload = {
        networkId: PK,
        availableInGeos: getAvailableInGeosPayload(availableInGeos),
        contentAgreementType,
        description,
        isActivated: getIsActive(isActivated),
        entity,
        entityType,
        podcastNetworkName: name,
      };

      requestAPIGraphQL(updateNetwork, payload)
        .then(() => {
          setEditMode(false);
          setEntity(PK.replace(NETWORK_ENTITY, ''), name);
          fetchNetwork();
          toast.success(STRINGS.NETWORK_UPDATED, TOAST_OPTIONS);
        })
        .catch(showPromiseError);
    },
    [fetchNetwork, setEntity]
  );

  const renderExpanded = useCallback(() => {
    if (editMode) {
      record(TAP_EDIT_NETWORK, { id: network.PK });

      return (
        <EditNetwork
          {...network}
          onCancel={() => setEditMode(false)}
          onSubmit={handleSubmitNetwork}
        />
      );
    }

    return <NetworkDetail onClickEdit={() => setEditMode(true)} {...network} />;
  }, [editMode, network, handleSubmitNetwork]);

  useEffect(() => {
    fetchNetwork();
  }, [fetchNetwork]);

  useEffect(() => {
    if (network) {
      setEntity(id, network.name);
    }
  }, [network, id, setEntity]);

  if (!network) {
    return <SkeletonLoading />;
  }

  return (
    <>
      <HierarchyHeader
        title={network.name}
        renderExpandedContent={renderExpanded}
        headerType={STRINGS.NETWORK}
      />

      <Box sx={{ display: 'flex', mt: 2 }}>
        <Button
          sx={{ mr: 1 }}
          onClick={handleAddNetwork}
          variant="outlined"
          color="secondary"
        >
          {STRINGS.ADD_NETWORK}
        </Button>
        <Button onClick={handleAddPodcaster} variant="outlined" color="secondary">
          {STRINGS.ADD_PODCASTER}
        </Button>
      </Box>

      <EntitiesDataGrid
        ref={gridRef}
        columnDefs={columnDefs}
        onCellClicked={handleEntityClick}
        rowModelType={'infinite'}
        onGridReady={setDataSource}
        cacheBlockSize={NETWORK_CHILDREN_BLOCK}
        getRowId={getRowId}
      />
    </>
  );
};

export default NetworkContainer;
