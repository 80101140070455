export const categoriesWithSubsToText = (categoriesWithSubs) => {
  if (!categoriesWithSubs) return '';

  let result = [];

  for (let i = 0; i < categoriesWithSubs.length; i++) {
    const { name, subs } = categoriesWithSubs[i];

    if (subs) {
      for (let j = 0; j < subs.length; j++) {
        result.push(`${name} / ${subs[j].name}`);
      }
    } else result.push(name);
  }

  return result.join(', ');
};
