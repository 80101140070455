import styled from '@mui/styled-engine';

export const ListContainer = styled('div')(
  () => `
  max-height: 300px;
  overflow: auto;
  background-color: white;
  color: black;
`
);

const getValueByDisabledStatus = (isDisabled) => {
  return isDisabled ? 0.5 : 1;
};

export const colorStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'transparent',
  }),
  singleValue: (styles, state) => ({
    ...styles,
    color: 'black',
    opacity: getValueByDisabledStatus(state.isDisabled),
  }),
  placeholder: (styles) => ({ ...styles, color: 'black' }),
  valueContainer: (styles, state) => ({
    ...styles,
    opacity: getValueByDisabledStatus(state.isDisabled),
  }),
  indicatorsContainer: (styles, state) => ({
    ...styles,
    opacity: getValueByDisabledStatus(state.isDisabled),
  }),
};
