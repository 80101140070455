import { DESKTOP_BREAKPOINT, MOBILE_BREAKPOINT } from 'constants/breakpoints';
import { useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';

const useDesktopMobile = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: DESKTOP_BREAKPOINT,
  });

  const isTabletMobile = useMediaQuery({
    query: MOBILE_BREAKPOINT,
  });

  const renderDesktopComponent = useCallback(
    (renderComponent) => {
      return isDesktopOrLaptop && renderComponent();
    },
    [isDesktopOrLaptop]
  );

  const renderMobileComponent = useCallback(
    (renderComponent) => {
      return isTabletMobile && renderComponent();
    },
    [isTabletMobile]
  );

  return {
    isDesktopOrLaptop,
    isTabletMobile,
    renderDesktopComponent,
    renderMobileComponent,
  };
};

export default useDesktopMobile;
