import { REVISION_STATUS } from 'constants/revisionTypes';

export const NETWORK = 'PODCAST_NETWORK#';
export const MPC_USER = 'MPC_USER#';
export const PODCASTER = 'PODCASTER#';
export const PODCAST_PREFIX = 'SHOW#';
export const EPISODE_PREFIX = 'EPISODE#';
export const MARBYL_PREFIX = 'MARBYL#';
export const NETWORK_ENTITY = 'PODCAST_NETWORK#';
export const REPORT = 'REPORT#';
export const ACCOUNT = 'ACCOUNT#';
export const TRANSCRIPT_REVIEW_ITEM = `${REVISION_STATUS.TRANSCRIPT_REVIEW_ITEM}#`;
