export const getPodcasterName = (owners, companyName) => {
  let podcasterName =
    owners.reduce(
      (prev, actual, index) =>
        (prev += actual.name + (index < owners.length - 1 ? ', ' : '')),
      ''
    ) + (companyName ? ` | ${companyName}` : '');

  return podcasterName;
};
