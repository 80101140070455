import React, { useMemo, useState } from 'react';
import { STRINGS } from 'constants/strings';
import {
  FormControlLabel,
  Box,
  RadioGroup,
  FormControl,
  FormLabel,
  Radio,
} from '@mui/material';
import { MultipleSelect } from '@components/index';
import { COUNTRIES } from 'constants/countriesList';
import { props, defaultProps } from './props';

const AVAILABILITY = {
  WORLDWIDE: 'worldwide',
  SPECIFIC: 'specific',
};

const CountriesSelect = ({ color, name, value, onChange, label }) => {
  const [availability, setAvailability] = useState(() => {
    return value.length === 0 ? AVAILABILITY.WORLDWIDE : AVAILABILITY.SPECIFIC;
  });

  const handleAvailability = (evt) => {
    const {
      target: { value },
    } = evt;

    onChange(value === AVAILABILITY.SPECIFIC ? ['US'] : []);
    setAvailability(value);
  };

  const countriesList = useMemo(() => {
    return COUNTRIES.map((country) => ({
      label: country.Name,
      value: country.Code,
    }));
  }, []);

  return (
    <>
      <FormControl component="fieldset">
        <FormLabel color={color} component="label">
          {label || STRINGS.PODCAST_GEOGRAPHICAL_AVAILABILITY}
        </FormLabel>
        <RadioGroup
          row
          aria-label="availability"
          name={name}
          value={availability}
          onChange={handleAvailability}
        >
          <FormControlLabel
            value={AVAILABILITY.WORLDWIDE}
            control={<Radio color={color} />}
            label={STRINGS.WORLDWIDE}
          />
          <FormControlLabel
            value={AVAILABILITY.SPECIFIC}
            control={<Radio color={color} />}
            label={STRINGS.SPECIFIC_COUNTRIES}
          />
        </RadioGroup>
      </FormControl>

      {availability === AVAILABILITY.SPECIFIC && (
        <Box sx={{ mb: 2 }}>
          <MultipleSelect
            size="normal"
            color={color}
            label={STRINGS.COUNTRIES}
            disabled={value === AVAILABILITY.WORLDWIDE}
            options={countriesList}
            selected={value}
            onChange={(value) => onChange(value)}
          />
        </Box>
      )}
    </>
  );
};

CountriesSelect.propTypes = props;
CountriesSelect.defaultProps = defaultProps;

export default CountriesSelect;
