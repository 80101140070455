import { LIGHT_PALETTE } from '@theme/palette';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const _swal = Swal.mixin({
  confirmButtonColor: LIGHT_PALETTE.error.main,
  cancelButtonColor: LIGHT_PALETTE.primary.main,
});

const MySwal = withReactContent(_swal);

export default MySwal;
