import React, { useCallback } from 'react';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { defaultProps, props } from './props';

function InfiniteLoaderWrapper({
  hasNextPage,
  isNextPageLoading,
  items,
  loadNextPage,
  renderComponent,
  renderLoading,
  width,
  height,
  itemSize,
  style,
  count = 0,
}) {
  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = count || (hasNextPage ? items.length + 1 : items.length);

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = useCallback(
    (index) => !hasNextPage || index < items.length,
    [items, hasNextPage]
  );

  // Render an item or a loading indicator.
  const Item = useCallback(
    ({ index, style }) => {
      if (!isItemLoaded(index))
        return renderLoading ? renderLoading(style) : <div style={style}>Loading...</div>;

      return renderComponent(items[index], style, index);
    },
    [renderLoading, renderComponent, items, isItemLoaded]
  );

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
    >
      {({ onItemsRendered, ref }) => (
        <List
          style={style}
          height={height}
          itemCount={itemCount}
          itemSize={itemSize}
          onItemsRendered={onItemsRendered}
          ref={ref}
          width={width}
        >
          {Item}
        </List>
      )}
    </InfiniteLoader>
  );
}

InfiniteLoaderWrapper.propTypes = props;
InfiniteLoaderWrapper.defaultProps = defaultProps;

export default InfiniteLoaderWrapper;
