import styled from '@mui/styled-engine';
import { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';

export const LinkBehaviour = forwardRef((props, ref) => (
  <RouterLink ref={ref} to="/" {...props} />
));

export const StyledTopMenuItem = styled(LinkBehaviour)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.secondary.dark,
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  width: 200,
  '&:hover': {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
  },
  '&:focus': {
    color: theme.palette.secondary.light,
  },
  '&.active': {
    color: theme.palette.secondary.light,
  },
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.secondary.main,
  },
  '&:focus': {
    outline: 'none',
    color: theme.palette.secondary.light,
  },
  '&.active': {
    backgroundColor: theme.palette.primary.light,
  },
  textDecoration: 'none',
}));

export const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.secondary.main,
  minWidth: 40,
}));

export const StyledVersions = styled('div')(
  () => `
  display: inline-block;
  font-size: 0.6em;
  line-height: 2em;
  opacity: 0.5;
  vertical-align: bottom;
`
);
