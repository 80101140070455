export const requestPodcastVerification = /* GraphQL */ `
  mutation requestPodcastVerification($input: RequestPodcastVerificationInput!) {
    requestPodcastVerification(input: $input)
  }
`;

export const verifyPodcast = /* GraphQL */ `
  mutation verifyPodcast($input: VerifyPodcastInput!) {
    verifyPodcast(input: $input)
  }
`;

export const submitAddPodcastFlow = /* GraphQL */ `
  mutation submitAddPodcastFlow(
    $podcasterId: String!
    $podcastInput: CreatePodcastInput!
    $podcasterInput: UpdatePodcasterInput!
  ) {
    createPodcast: MPCCreateShowV2(podcasterId: $podcasterId, input: $podcastInput) {
      PK
    }
    updatePodcaster(input: $podcasterInput) {
      PK
    }
  }
`;
