import React, { useCallback } from 'react';
import { Typography, Box } from '@mui/material';
import { PodcastArtTitle, PodcastSearch } from '@components/index';
import { STRINGS } from 'constants/strings';
import { useAddYourPodcast } from 'contexts/AddYourPodcastContext';
import { toast } from 'react-toastify';
import { requestAPIGraphQL } from '@services/appSyncAPI';
import { rssFeedExists } from '@graphql/queries/ipsso';
import { StyledInvertedButton } from '@shared/styledInputs';
import {
  TAP_CANCEL_FROM_FEED_CONFIRMATION,
  TAP_CANCEL_FROM_FEED_LOOKUP,
} from 'constants/analytics';
import { pageNames } from 'constants/ipsso';
import useLoadModalSubpage from 'hooks/useLoadModalSubpage';

const FeedConfirmation = ({ renderBackBtn, onNextClick }) => {
  const {
    podcastState: [podcast, setPodcast],
  } = useAddYourPodcast();

  const handleRSSLoad = (data, err) => {
    if (err) {
      toast.error(err);

      return;
    }

    setPodcast(data);
  };

  const renderNextButton = useCallback(() => {
    if (!podcast) return null;

    return (
      <StyledInvertedButton onClick={onNextClick} variant="contained" color="error">
        {STRINGS.NEXT}
      </StyledInvertedButton>
    );
  }, [podcast, onNextClick]);

  const renderLanguageWarning = useCallback(() => {
    if (!podcast) return null;

    const { language = [] } = podcast;

    return (
      !language.includes('en') && (
        <Typography color="info.main" sx={{ mt: 3 }} variant="subtitle1">
          {
            STRINGS.THERE_MAY_BE_A_DELAY_UNTIL_WE_SUPPORT_YOUR_LANGUAGE_WE_CURRENTLY_ONLY_SUPPORT_PODCASTS_IN_ENGLISH_BUT_PLAN_TO_SUPPORT_MORE_LANGUAGES_IN_THE_FUTURE
          }
        </Typography>
      )
    );
  }, [podcast]);

  const handleRSSValidation = useCallback((item) => {
    toast.dismiss();
    const { rssUrl } = item;
    return requestAPIGraphQL(rssFeedExists, { feedUrl: rssUrl })
      .then(({ data: { RSSFeedExists } }) => {
        if (RSSFeedExists) {
          toast.error(
            STRINGS.THIS_PODCAST_HAS_ALREADY_BEEN_CLAIMED_PLEASE_CONTACT_OWNER_OF_PODCAST_FOR_DETAILS
          );
          return false;
        }

        return true;
      })
      .catch(() => {
        toast.error(STRINGS.AN_ERROR_OCCURED_ON_SERVER__TRY_AGAIN);
        return false;
      });
  }, []);

  useLoadModalSubpage(pageNames.FEED_CONFIRMATION);

  return (
    <>
      <Typography variant="h6">{STRINGS.ADD_YOUR_PODCAST_RSS}</Typography>
      <Typography sx={{ mb: 3 }} variant="subtitle1">
        {
          STRINGS.ONCE_YOU_ADD_YOUR_PODCAST_WELL_TRANSCRIBE_IT_AND_PULL_OUT_GREAT_MOMENTS_FROM_EACH_EPISODE_WE_CALL_THESE_MARBYLS_AND_WELL_CONTINUE_TO_CREATE_NEW_MARBYLS_FROM_EACH_NEW_EPISODE_YOU_RELEASE
        }
      </Typography>
      <PodcastSearch
        submit
        onRSSLoad={handleRSSLoad}
        onBeforeRSSLoad={handleRSSValidation}
      />

      {podcast && <PodcastArtTitle podcast={podcast} />}

      {renderLanguageWarning()}

      <Box sx={{ mt: 2 }}>
        {renderBackBtn(
          !podcast ? TAP_CANCEL_FROM_FEED_LOOKUP : TAP_CANCEL_FROM_FEED_CONFIRMATION
        )}
        {renderNextButton()}
      </Box>
    </>
  );
};

export default FeedConfirmation;
