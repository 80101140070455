export const AGREEMENT_TYPES = {
  MARBYL_TOS: 'marbyl_tos',
  BESPOKE: 'bespoke',
  FAIR_USE: 'fair_use',
};

const { MARBYL_TOS, BESPOKE, FAIR_USE } = AGREEMENT_TYPES;

export const AGREEMENT_TYPES_LABELS = {
  [MARBYL_TOS]: 'Marbyl Terms of Service',
  [BESPOKE]: 'Bespoke',
  [FAIR_USE]: 'Fair Use',
};
