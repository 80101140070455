import { requestAPIGraphQL } from '@services/appSyncAPI';
import PropTypes from 'prop-types';

export const props = {
  // GraphQL Query
  query: PropTypes.string.isRequired,
  // GraphQL payload
  payload: PropTypes.object,
  // Graphql Query response attribute name
  responseKeyName: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  defaultValue: PropTypes.object,
  // React select props...
  restClient: PropTypes.func,
  labelName: PropTypes.string,
  valueName: PropTypes.string,
  getLabel: PropTypes.func,
  styles: PropTypes.object,
};

export const defaultProps = {
  responseKeyName: 'response',
  onChange: () => null,
  value: '',
  payload: {},
  restClient: requestAPIGraphQL,
  labelName: 'name',
  valueName: 'PK',
  getLabel: null,
  styles: {},
};
