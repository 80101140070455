import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Paper } from '@mui/material';
import MetaInfo from '@components/metaInfo/index';
import SkeletonLoading from '@components/skeletonLoading/index';
import { StyledHeaderSection } from '@shared/styledGeneral';
import { STRINGS } from 'constants/strings';

const RevisionItemDetails = ({
  title,
  metaData,
  isLoading,
  cancelBtn,
  acceptBtn,
  onClickCancel,
  onClickAccept,
  children,
  hideAcceptBtn,
  hideCancelBtn,
}) => {
  if (isLoading) return <SkeletonLoading />;

  return (
    <Paper sx={{ p: 2, bgcolor: 'primary.main', color: 'secondary.main' }} elevation={2}>
      <StyledHeaderSection variant="h6">{title}</StyledHeaderSection>
      <MetaInfo items={metaData} />
      {children}
      <StyledHeaderSection sx={{ mt: 4, mb: 2 }} variant="h6">
        {STRINGS.ADMIN_SETTINGS}
      </StyledHeaderSection>
      <Box sx={{ display: 'flex' }}>
        {!hideCancelBtn && (
          <Button
            sx={{ mr: 2 }}
            onClick={onClickCancel}
            variant="outlined"
            {...cancelBtn}
          />
        )}
        {!hideAcceptBtn && (
          <Button onClick={onClickAccept} variant="outlined" {...acceptBtn} />
        )}
      </Box>
    </Paper>
  );
};

RevisionItemDetails.propTypes = {
  title: PropTypes.string.isRequired,
  metaData: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  cancelBtn: PropTypes.shape({
    color: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  }),
  acceptBtn: PropTypes.shape({
    color: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  }),
  onClickCancel: PropTypes.func,
  onClickAccept: PropTypes.func,
  hideCancelBtn: PropTypes.bool,
  hideAcceptBtn: PropTypes.bool,
  children: PropTypes.node,
};

RevisionItemDetails.defaultProps = {
  isLoading: false,
  onClickCancel: () => {},
  onClickAccept: () => {},
  children: <Fragment></Fragment>,
  hideAcceptBtn: false,
  hideCancelBtn: false,
};

export default RevisionItemDetails;
