import React from 'react';
import PropTypes from 'prop-types';
import { StyledButtonLink } from './styled';

const ButtonLink = ({ onClick, children, isLandingPage, variant, isUnderline }) => {
  return (
    <StyledButtonLink
      role="link"
      onClick={onClick}
      isLandingPage={isLandingPage}
      isUnderline={isUnderline}
      variant={variant}
    >
      {children}
    </StyledButtonLink>
  );
};

ButtonLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isLandingPage: PropTypes.bool,
  variant: PropTypes.string,
  isUnderline: PropTypes.bool,
};

ButtonLink.defaultProps = {
  isLandingPage: false,
  variant: '',
  isUnderline: false,
};

export default ButtonLink;
