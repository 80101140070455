import React, { useState, useCallback } from 'react';
import { Box, Modal, Typography } from '@mui/material';
import { STRINGS } from 'constants/strings';
import {
  StyledTitle,
  StyledTextContainer,
  StyledContainer,
  StyledDownloadStoreImgContainer,
} from './LandingPage.styles';
import DownloadAppStore from '@images/app-store-badge.svg';
import DownloadPlayStore from '@images/google-play-logo.svg';
import HeadHeadphones from '@images/head-headphones.svg';
import { ButtonLink } from '@components/index';
import { LandingPagesContainer } from '@components/containers/index';

import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';
import { TAP_JOINING_MARBY_LINK } from 'constants/analytics';
import { HEX_COLORS, FONT_FAMILIES } from 'constants/stylesVariables';
import { record } from '@utils/analytics';
import Spacer from '@shared/spacer';

const style = {
  bgcolor: 'background.paper',
  border: `2px solid ${HEX_COLORS.black}`,
  borderRadius: 5,
  boxShadow: 24,
  left: '50%',
  maxHeight: '90vh',
  p: 4,
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: '98%',
  maxWidth: 730,
};

const APP_STORE_URL = 'https://apps.apple.com/us/app/marbyl/id1606442385';
const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.marbyl&pli=1';

const LandingPage = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const onClickLearnMore = useCallback(() => {
    record(TAP_JOINING_MARBY_LINK);
    history.push('/partners');
  }, [history]);

  const handleClose = () => setOpen(false);

  return (
    <StyledContainer>
      <LandingPagesContainer
        spacerSize={0}
        imgBackground={HeadHeadphones}
        imgBackgroundDimensions={{
          height: '705px',
          width: '552px',
        }}
        desktopTop="108px"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <StyledTextContainer>
            <StyledTitle marginTop={387} mobileMarginTop={275}>
              {STRINGS.FIND_PODCASTS_MOMENTS}
            </StyledTitle>
          </StyledTextContainer>
          <StyledDownloadStoreImgContainer>
            <a href={APP_STORE_URL} target="_blank" rel="noreferrer">
              <img src={DownloadAppStore} alt="DownloadAppStore" />
            </a>
            <Spacer size={21.21} />
            <a href={PLAY_STORE_URL} target="_blank" rel="noreferrer">
              <img src={DownloadPlayStore} alt="DownloadPlayStore" />
            </a>
          </StyledDownloadStoreImgContainer>
          <Typography
            sx={{
              textAlign: 'center',
              mb: 10,
              color: '#FFF',
              fontSize: '22px',
              fontWeight: 500,
              fontFamily: FONT_FAMILIES.proximaNovaRegular,
              lineHeight: '22px',
            }}
            variant="body1"
          >
            {`${STRINGS.ARE_YOU_A_PODCASTER} `}{' '}
            <ButtonLink isLandingPage isUnderline onClick={onClickLearnMore}>
              Learn more
            </ButtonLink>
          </Typography>
        </Box>
      </LandingPagesContainer>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseIcon onClick={handleClose} />
          <Box sx={{ overflow: 'auto', maxHeight: '50vh' }}>
            <iframe
              title="Google Form"
              src="https://docs.google.com/forms/d/e/1FAIpQLSfKjH5pZ-3tsAW3sby5ECdR_SUPI-6x7nATvSxRjU4LLSh5eQ/viewform?embedded=true"
              width="100%"
              height="859"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
            >
              Loading…
            </iframe>
          </Box>
        </Box>
      </Modal>
    </StyledContainer>
  );
};

export default LandingPage;
