export function replaceLiterals(string, values) {
  let newString = '';

  for (let i = 0; i < string.length; i++) {
    const char = string[i];

    if (char !== '{') {
      newString += char;
      continue;
    }

    const nextIndex = i + 1;
    const temp = string.substring(nextIndex);
    const nextSpaceIndex = temp.indexOf('}');
    let stringToReplace = temp.substring(0, nextSpaceIndex);

    newString += values[stringToReplace];
    i += stringToReplace.length + 1;
  }

  return newString;
}
