import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router';

import { CommonSwitch } from './common';
import { MPC_ROUTES } from 'constants/routing';
import UsersPage from '@pages/UsersPage/UsersPage';
import AddUserPage from '@pages/UsersPage/Add/AddUserPage';
import UserDetailPage from '@pages/UsersPage/Detail/UserDetailPage';
import ProfilePage from '@pages/UsersPage/Profile/ProfilePage';

const UsersRoutes = () => {
  const { url } = useRouteMatch();

  const routes = useMemo(
    () => [
      {
        exact: true,
        path: url,
        childComponent: <UsersPage />,
      },
      {
        path: `${url}${MPC_ROUTES.ADD}`,
        childComponent: <AddUserPage />,
      },
      {
        exact: true,
        path: `${url}/:id`,
        childComponent: <UserDetailPage />,
      },
      {
        path: `${url}/:id${MPC_ROUTES.PROFILE}/:userId`,
        childComponent: <ProfilePage />,
      },
    ],
    [url]
  );

  return (
    <>
      <CommonSwitch routes={routes} />
    </>
  );
};

export default UsersRoutes;
