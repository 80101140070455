import styled from '@mui/styled-engine';
import { TextField } from '@mui/material';

export const StyledEmailTextField = styled(TextField)(
  ({ theme: { palette } }) => `        
    .__label {
        color: ${palette.primary.main};
    }

    .__input {
        -webkit-text-fill-color: ${palette.primary.main};
        opacity: 1;

        &:after {
          transform: scaleX(1);
        }
    }
  `
);
