export const getUserDefaultAccount = /* GraphQL */ `
  query getUserDefaultAccount($email: String!) {
    MPCGetUserDefaultAccountInformation(email: $email) {
      entityAccountId
      entityAccountType
      entityId
      entityName
      userDefaultAccountId
      userDefaultAccountRole
      userId
    }
  }
`;

export const checkPayAccess = /* GraphQL */ `
  query checkPayAccess($accountId: String!) {
    MPCCheckPayAccess(accountId: $accountId)
  }
`;
