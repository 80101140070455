import { Auth, API } from 'aws-amplify';

const requestToken = async () => {
  const currentSession = await Auth.currentSession();
  return currentSession.accessToken.jwtToken;
};

export const requestAPIGraphQL = async (
  query,
  input = null,
  options = { authMode: 'AMAZON_COGNITO_USER_POOLS' }
) => {
  const authMode = options.authMode;

  let authToken = '';
  if (authMode === 'AMAZON_COGNITO_USER_POOLS') {
    authToken = await requestToken();
  }

  const response = await API.graphql({
    query,
    variables: input,
    authMode,
    ...(authToken && { authToken }),
  });
  return response;
};
