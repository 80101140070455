import { Box } from '@mui/material';
import styled from '@mui/styled-engine';
import { DESKTOP_BREAKPOINT } from 'constants/breakpoints';

export const StyledTopbar = styled(Box)(
  ({ theme }) => `
  padding: 40px 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and ${DESKTOP_BREAKPOINT} {
    padding-top: ${theme.spacing(6)};
    padding-left: ${theme.spacing(16.25)};
    padding-right: ${theme.spacing(15)};
  }
`
);
