import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { Modal, TranscriptText } from '@components/index';
import { useDeps } from 'contexts/DepsContext';
import { createTranscriptReviewRequest } from '@graphql/mutations/transcriptions';
import { STRINGS } from 'constants/strings';

const ReviewModal = ({
  episodeId,
  episodeTitle,
  isOpen,
  onClose,
  selectedText,
  prevText,
  lastText,
  textPositionStart,
  textPositionEnd,
}) => {
  const [comment, setComment] = useState('');
  const [isSendingReview, setIsSendingReview] = useState(false);
  const { httpClient } = useDeps();

  const onCommentChange = ({ target }) => {
    setComment(target?.value);
  };

  const onClickReview = useCallback(() => {
    setIsSendingReview(true);

    httpClient(createTranscriptReviewRequest, {
      comment,
      episodeId,
      transcriptFragment: selectedText,
      textPositionStart,
      textPositionEnd,
    })
      .then(({ data }) => {
        if (data?.response) {
          setComment('');

          toast.success('The Transcript Review has been successfully sent.');

          onClose();
        }
      })
      .catch(() => {
        toast.error(
          'There was an error trying to send the Transcript Review, please try again.'
        );
      })
      .finally(() => {
        setIsSendingReview(false);
      });
  }, [comment, episodeId, selectedText, textPositionStart, textPositionEnd]);

  if (isOpen) {
    return (
      <Modal
        open={isOpen}
        onClose={onClose}
        acceptBtnLabel={STRINGS.SUBMIT}
        onAccept={onClickReview}
        containerWidth="75%"
        borderRadius="8px"
        loading={isSendingReview}
        title={`Submit transcript review for: ${episodeTitle}`}
        subtitle="You selected the text shown in white for review, please add any descriptive information in the field below."
      >
        <Box>
          <TranscriptText
            prevText={prevText}
            selectedText={selectedText}
            lastText={lastText}
          />
          <TextField
            color="secondary"
            value={comment}
            label="Comment"
            size="small"
            fullWidth
            multiline
            rows={4}
            onChange={onCommentChange}
          />
        </Box>
      </Modal>
    );
  }

  return <></>;
};

ReviewModal.propTypes = {
  episodeId: PropTypes.string.isRequired,
  episodeTitle: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedText: PropTypes.string.isRequired,
  textPositionStart: PropTypes.number.isRequired,
  textPositionEnd: PropTypes.number.isRequired,
  prevText: PropTypes.string.isRequired,
  lastText: PropTypes.string.isRequired,
};

export default ReviewModal;
