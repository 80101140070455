import {
  EPISODE_VIEW_ACTIONS,
  PODCASTER_VIEW_ACTIONS,
  PODCAST_NETWORK_VIEW_ACTIONS,
  PODCAST_VIEW_ACTIONS,
} from './actionsList';
import { USER_ROLES } from './userRoles';

const {
  PODCASTER_DETAILS,
  EDIT_PODCASTER_INFO,
  EDIT_OWNER_INFO,
  EDIT_PODCASTER_GEO_AVAILABILITY,
  VIEW_PODCASTS,
  ADD_PODCAST,
  ACTIVATE_PODCASTS,
  DEACTIVATE_PODCASTS,
  GO_TO_PODCAST_PAGE,
  GO_TO_USER_MANAGEMENT_PAGE,
} = PODCASTER_VIEW_ACTIONS;

export const ROLE_ACTIONS = {
  default: [],
  [USER_ROLES.PODCASTER_ACCOUNT_OWNER]: [
    PODCASTER_DETAILS,
    EDIT_PODCASTER_INFO,
    EDIT_OWNER_INFO,
    EDIT_PODCASTER_GEO_AVAILABILITY,
    VIEW_PODCASTS,
    ADD_PODCAST,
    ACTIVATE_PODCASTS,
    DEACTIVATE_PODCASTS,
    GO_TO_PODCAST_PAGE,
    GO_TO_USER_MANAGEMENT_PAGE,
    PODCAST_VIEW_ACTIONS.VIEW_PODCAST_DETAILS,
    PODCAST_VIEW_ACTIONS.RSS_SOURCED_DATA,
    PODCAST_VIEW_ACTIONS.M11N_RELATED_DATA,
    PODCAST_VIEW_ACTIONS.PROCESS_STATUS_RELATED,
    PODCAST_VIEW_ACTIONS.METRICS_HISTORICAL_RELATED,
    PODCAST_VIEW_ACTIONS.EDIT_PODCAST_BASIC_INFO,
    PODCAST_VIEW_ACTIONS.EDIT_GEO_AVAILABILITY,
    PODCAST_VIEW_ACTIONS.TOGGLE_ACTIVATION_PODCAST,
    PODCAST_VIEW_ACTIONS.RELOAD_RSS,
    PODCAST_VIEW_ACTIONS.UPDATE_EPISODES_AVAILABILITY,
    PODCAST_VIEW_ACTIONS.TOGGLE_EPISODES,
    EPISODE_VIEW_ACTIONS.VIEW_EPISODE_DETAILS,
    EPISODE_VIEW_ACTIONS.VIEW_RSS_SOURCED_DATA,
    EPISODE_VIEW_ACTIONS.VIEW_M11N_DATA,
    EPISODE_VIEW_ACTIONS.VIEW_PROCESS_STATUS_DATA,
    EPISODE_VIEW_ACTIONS.VIEW_METRICS_HISTORICAL_DATA,
    EPISODE_VIEW_ACTIONS.DEACTIVATE_REACTIVATE_EPISODE,
    EPISODE_VIEW_ACTIONS.LIST_MARBYLS,
    EPISODE_VIEW_ACTIONS.TOGGLE_MARBYLS_STATUS,
  ],
  [USER_ROLES.TGM_SUPERUSER]: [
    ...Object.values(PODCAST_NETWORK_VIEW_ACTIONS),
    ...Object.values(PODCASTER_VIEW_ACTIONS),
    ...Object.values(PODCAST_VIEW_ACTIONS),
    ...Object.values(EPISODE_VIEW_ACTIONS),
  ],
};

export const NETWORK_VIEW_GROUPS = {
  [USER_ROLES.PODCASTER_ADMIN]: {},
  [USER_ROLES.TGM_ADMIN]: {},
  [USER_ROLES.TGM_SUPERUSER]: {},
  [USER_ROLES.TGM_USER]: {},
  [USER_ROLES.PODCAST_NETWORK_ACCOUNT_OWNER]: {},
  [USER_ROLES.PODCAST_NETWORK_ADMIN]: {},
  [USER_ROLES.PODCAST_NETWORK_USER]: {},
};

export const PODCASTER_VIEW_GROUPS = {
  [USER_ROLES.PODCASTER_ACCOUNT_OWNER]: {},
};
