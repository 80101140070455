import styled from '@mui/styled-engine';
import { Button, Radio, Checkbox, TextField, TableCell } from '@mui/material';

export const StyledInvertedButton = styled(Button)(
  ({ theme: { palette }, color }) => `

  &.Mui-disabled {
    background-color: ${palette[color].dark};
    color: ${palette.secondary.dark};
  }
`
);

export const StyledInvertedRadio = styled(Radio)(
  ({ theme: { palette } }) => `
  &.MuiRadio-root {
    color: ${palette.secondary.main};
  }
  &.MuiRadio-colorPrimary: {
    color: ${palette.secondary.main};
  }

  &.MuiRadio-colorSecondary: {
    color: ${palette.primary.main};
  }
`
);

export const StyledInvertedCheckbox = styled(Checkbox)(
  ({ theme: { palette } }) => `
  &.MuiCheckbox-colorPrimary { color: ${palette.secondary.main}; }
`
);

export const StyledInvertedTextField = styled(TextField)(
  ({ theme: { palette } }) => `
  &.MuiTextField-root input { color: ${palette.secondary.main}; }
`
);

export const StyledInvertedTableCell = styled(TableCell)(
  ({ theme: { palette } }) => `
  color: ${palette.secondary.main};
`
);
