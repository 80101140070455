import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { DynamicSpacer, Topbar } from '@components/index';
import { StyledFullHeight, StyledInnerContainer } from '@shared/styledContainers';

import { BREAKPOINT_SIZE, MAX_SCREEN_SIZE } from 'constants/breakpoints';
import { record } from '@utils/analytics';

const UnauthenticatedContainer = ({
  children,
  redirectBtnLabel,
  redirectBtnRoute,
  recordText,
}) => {
  const history = useHistory();

  return (
    <StyledFullHeight>
      <StyledInnerContainer>
        <Topbar
          renderDesktopItems={() => (
            <Button
              onClick={() => {
                if (recordText) record(recordText);

                history.push(redirectBtnRoute);
              }}
              color="secondary"
              variant="outlined"
            >
              {redirectBtnLabel}
            </Button>
          )}
        />

        <DynamicSpacer
          maxWidth={MAX_SCREEN_SIZE}
          minWidth={BREAKPOINT_SIZE}
          minSize={50}
          maxSize={100}
        />

        <Box>{children}</Box>
      </StyledInnerContainer>
    </StyledFullHeight>
  );
};

UnauthenticatedContainer.propTypes = {
  children: PropTypes.node.isRequired,
  redirectBtnLabel: PropTypes.string.isRequired,
  redirectBtnRoute: PropTypes.string.isRequired,
  recordText: PropTypes.string,
};

UnauthenticatedContainer.defaultProps = {
  recordText: '',
};

export default UnauthenticatedContainer;
