import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import MenuList from './MenuList';
import { colorStyles } from './styles';

const Select = ({ disabled, inheritStyles, onSelect, optionsList, selectedValue }) => (
  <ReactSelect
    disabled={disabled}
    styles={{ ...colorStyles, ...inheritStyles }}
    options={optionsList}
    value={selectedValue}
    components={{ MenuList }}
    onChange={onSelect}
  />
);

Select.propTypes = {
  disabled: PropTypes.bool,
  inheritStyles: PropTypes.object,
  optionsList: PropTypes.array,
  selectedValue: PropTypes.any,
  onSelect: PropTypes.func.isRequired,
};

Select.defaultProps = {
  disabled: false,
  inheritStyles: {},
  optionsList: [],
  selectedValue: '',
};

export default Select;
