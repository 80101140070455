export const sendInvitationUserCreation = /* GraphQL */ `
  mutation sendInvitationUserCreation(
    $accountRole: String!
    $invitedBy: String!
    $parentAccountId: String!
    $userId: String
    $userEmail: String
    $userFirstName: String
    $userLastName: String
  ) {
    MPCSendInvitationUserCreation(
      accountRole: $accountRole
      invitedBy: $invitedBy
      parentAccountId: $parentAccountId
      userId: $userId
      userEmail: $userEmail
      userFirstName: $userFirstName
      userLastName: $userLastName
    )
  }
`;

export const updateAccountUser = /* GraphQL */ `
  mutation updateAccountUser(
    $accountId: String!
    $accountRole: String
    $isActivated: Boolean
  ) {
    response: MPCUpdateAccount(
      input: {
        accountId: $accountId
        accountRole: $accountRole
        isActivated: $isActivated
      }
    ) {
      accountId
      accountType
      email
      invitedBy
      isAccountOwner
      joinedDate
      name
      role
      userId
      isActivated
    }
  }
`;

export const updateProfile = /* GraphQL */ `
  mutation updateProfile(
    $lastName: String
    $givenName: String
    $phoneNumber: String
    $userId: String!
    $image: String
    $email: String
    $bio: String
  ) {
    MPCUpdateProfile(
      input: {
        lastName: $lastName
        givenName: $givenName
        phoneNumber: $phoneNumber
        userId: $userId
        encodedImage: $image
        email: $email
        bio: $bio
      }
    )
  }
`;

export const addUserToAccount = /* GraphQL */ `
  mutation addUserToAccount($token: String!) {
    MPCAddUserToAccount(token: $token)
  }
`;

export const rejectInvitation = /* GraphQL */ `
  mutation rejectInvitation($token: String!) {
    MPCRejectInvitation(token: $token)
  }
`;
