import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { props, defaultProps } from './props';

const SimpleDropdown = ({ disabled, actions, onClickAction }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openActions = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActionClick = (action) => () => {
    handleClose();
    onClickAction(action);
  };

  return (
    <>
      <Button
        id="actions-button"
        aria-controls={openActions ? 'actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openActions ? 'true' : undefined}
        disabled={disabled}
        onClick={handleClick}
        variant="outlined"
        color="secondary"
        endIcon={<KeyboardArrowDownIcon />}
      >
        {'Actions'}
      </Button>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        open={openActions}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'actions-button',
        }}
      >
        {actions.map(({ key, label, disabled = false }) => (
          <MenuItem disabled={disabled} key={key} onClick={handleActionClick(key)}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

SimpleDropdown.propTypes = props;
SimpleDropdown.defaultProps = defaultProps;

export default SimpleDropdown;
