import { useState, useEffect, useMemo } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import Card from './Card';
import NestedMenu from './NestedMenu';
import FilterSelect from './FilterSelect';
import {
  StyledMainContainer,
  StyledFiltersContainer,
  StyledScrollContainer,
  StyledAllItemsText,
} from './styles';
import { requestAPIGraphQL } from '@services/appSyncAPI';
import useNotifications from 'hooks/useNotifications';
import { uuid } from '@utils/uuid';
import { listMarbylsByEntityId } from '@graphql/queries/dashboard';
import { NEWEST_OLDEST, MADE_BY, SINCE, NETWORK_ITEMS_LIST } from './data';

const MarbylChartView = () => {
  // const [entity, setEntity] = useState('');
  const [marbylsList, setMartbylsList] = useState([]);
  const [totalMarbylsCount, setTotalMarbylsCount] = useState(0);
  const [nextToken, setNextToken] = useState('0');
  const [sortNewestOldest, setSortNewestOldest] = useState(NEWEST_OLDEST[0].value);
  const [since, setSince] = useState(SINCE[5].value);
  const [madeBy, setMadeBy] = useState(MADE_BY[0].value);
  const [hasMoreRecords, setHasMoreRecords] = useState(true);
  const { showErrorMessages } = useNotifications();
  const theme = useTheme();
  const scrollContainerId = useMemo(() => `ScrollContainer-${uuid()}`, []);

  const fetchData = () => {
    requestAPIGraphQL(listMarbylsByEntityId, {
      entityId: 'PODCAST_NETWORK#podglomerate', // TODO: Change this later
      limit: 20,
      dateFilterPreference: since,
      sortPreference: sortNewestOldest,
      madeBy,
      nextToken: nextToken,
    })
      .then(({ data }) => {
        const {
          items,
          totalResults,
          nextToken: nextTokenFromRequest,
        } = data?.MPCListMarbylsByEntityId;

        setTotalMarbylsCount(totalResults);
        setNextToken(nextTokenFromRequest);
        setMartbylsList((prevMarbylsList) => [...prevMarbylsList, ...items]);
        setHasMoreRecords(!!nextTokenFromRequest);
      })
      .catch((ex) => {
        showErrorMessages(ex.errors, 'There was an error trying to retrieve the Marbyls');
      });
  };

  useEffect(() => {
    if (nextToken === '0') fetchData();
  }, [nextToken]);

  const onSaveClick = () => {
    setNextToken('0');
    setMartbylsList([]);
    setHasMoreRecords(true);
  };

  const renderList = () =>
    marbylsList.map((marbylItem) => (
      <Card
        key={uuid()}
        marbylText={marbylItem?.marbylText || 'marbylText'}
        marbylViews={marbylItem?.viewersCount ?? 12}
        marbylShares={marbylItem?.sharesCount ?? 11}
        creatorUserName={marbylItem?.userName || 'userName'}
        showName={marbylItem?.showTitle || 'showTitle'}
        episodeName={marbylItem?.episodeTitle || 'episodeTitle'}
        marbylStartTime={marbylItem?.marbylStartTime || '0:00:00.000'}
      />
    ));

  return (
    <StyledMainContainer>
      <StyledFiltersContainer>
        <Typography variant="h5" sx={{ marginRight: theme.spacing(1) }}>
          Dashboard
        </Typography>
        <NestedMenu list={NETWORK_ITEMS_LIST} />
      </StyledFiltersContainer>
      <StyledFiltersContainer>
        <FilterSelect
          selectId="newestOldestId"
          value={sortNewestOldest}
          labelText="Sort"
          onChange={setSortNewestOldest}
          options={NEWEST_OLDEST}
        />
        <FilterSelect
          selectId="sinceId"
          value={since}
          labelText="Since"
          onChange={setSince}
          options={SINCE}
        />
        <FilterSelect
          selectId="madeById"
          value={madeBy}
          labelText="Made by"
          onChange={setMadeBy}
          options={MADE_BY}
        />
        <Button
          variant="text"
          sx={{ color: theme.palette.blue.main }}
          onClick={onSaveClick}
        >
          Save
        </Button>
      </StyledFiltersContainer>
      <Box sx={{ display: 'flex', marginBottom: theme.spacing(2) }}>
        <Typography variant="h6">
          Marbyls <span style={{ fontWeight: 300 }}>({totalMarbylsCount})</span>
        </Typography>
      </Box>
      <StyledScrollContainer id={scrollContainerId}>
        <InfiniteScroll
          scrollThreshold="200px"
          className="List"
          dataLength={marbylsList.length}
          scrollableTarget={scrollContainerId}
          next={fetchData}
          hasMore={hasMoreRecords}
          endMessage={
            <StyledAllItemsText>
              <b>You have seen it all!</b>
            </StyledAllItemsText>
          }
        >
          {renderList()}
        </InfiniteScroll>
      </StyledScrollContainer>
    </StyledMainContainer>
  );
};

export default MarbylChartView;
