import PropTypes from 'prop-types';

export const props = {
  podcast: PropTypes.shape({
    imageUrl: PropTypes.string,
    itunes: PropTypes.shape({
      image: PropTypes.string,
    }),
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    title: PropTypes.string.isRequired,
  }),
};

export const defaultProps = {};
