import React, { useMemo } from 'react';
import { STRINGS } from 'constants/strings';
import { DynamicForm } from '@components/index';
import { dateToMMDoYYYY } from '@utils/dateFormat';
import { FIELD_TYPES } from 'constants/dynamicFormTypes';
import PropTypes from 'prop-types';
import { getUserRoleLabel } from '@utils/roles';

const AccountUserEdit = ({ user, roles, onCancel, onSubmit }) => {
  const fields = useMemo(() => {
    return [
      {
        type: FIELD_TYPES.TITLE,
        label: 'Account Settings',
        md: 12,
        xs: 12,
      },
      {
        type: FIELD_TYPES.SELECT,
        label: 'Account Role',
        name: 'role',
        items: roles.map((_role) => ({
          label: getUserRoleLabel(_role),
          value: _role,
        })),
        md: 6,
        color: 'secondary',
        xs: 12,
      },
      {
        type: FIELD_TYPES.SELECT,
        label: 'Is Account Owner?',
        name: 'isAccountOwner',
        items: ['Yes', 'No'],
        md: 6,
        color: 'secondary',
        xs: 12,
        disabled: true,
      },
      {
        type: FIELD_TYPES.TITLE,
        label: 'Marbyl Admin Settings',
        md: 12,
        xs: 12,
      },
      {
        type: FIELD_TYPES.TEXT,
        label: 'Invited By',
        name: 'invitedBy',
        md: 6,
        color: 'secondary',
        xs: 12,
        disabled: true,
      },
      {
        type: FIELD_TYPES.TEXT,
        label: 'Joined Date',
        name: 'joinedDate',
        md: 6,
        color: 'secondary',
        xs: 12,
        disabled: true,
      },
      {
        type: FIELD_TYPES.CHECKBOX,
        label: STRINGS.IS_ACTIVATED,
        name: 'isActivated',
        color: 'secondary',
        md: 6,
        xs: 12,
      },
    ];
  }, [roles]);

  const initialValues = useMemo(() => {
    return {
      role: user.role,
      isAccountOwner: user.isAccountOwner ? STRINGS.YES : STRINGS.NO,
      invitedBy: user.invitedBy || '',
      joinedDate: dateToMMDoYYYY(user.joinedDate),
      isActivated: !!user.isActivated,
    };
  }, [user]);

  const handleSubmit = (values) => {
    onSubmit({
      accountId: user.accountId,
      role: values.role,
      isActivated: values.isActivated,
    });
  };

  return (
    <DynamicForm
      formHeader={STRINGS.UPDATE_ACCOUNT_USER}
      formHeaderVariant={'h4'}
      initialValues={initialValues}
      fields={fields}
      onCancel={onCancel}
      onSubmit={handleSubmit}
    />
  );
};

AccountUserEdit.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string.isRequired,
    isAccountOwner: PropTypes.bool.isRequired,
    invitedBy: PropTypes.string,
    joinedDate: PropTypes.string.isRequired,
    isActivated: PropTypes.bool.isRequired,
  }).isRequired,
  roles: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AccountUserEdit;
