import { useMemo } from 'react';
import { useRouteMatch } from 'react-router';

import EntitiesNamesProvider from 'contexts/EntitiesNamesContext';
import { MPCTranscriptReviews, MPCTranscriptDetails } from '@components/containers/index';
import { CommonSwitch } from './common';
import { MPC_ROUTES } from 'constants/routing';

const TranscriptionErrorsRoutes = () => {
  const { url } = useRouteMatch();

  const routes = useMemo(() => [
    {
      exact: true,
      path: url,
      childComponent: <MPCTranscriptReviews />,
    },
    {
      path: `${url}${MPC_ROUTES.ID}`,
      childComponent: <MPCTranscriptDetails />,
    },
  ]);

  return (
    <EntitiesNamesProvider>
      <CommonSwitch routes={routes} />
    </EntitiesNamesProvider>
  );
};

export default TranscriptionErrorsRoutes;
