import { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

const ToasterContext = createContext();

export const useToaster = () => {
  return useContext(ToasterContext);
};

const ToasterProvider = ({ children }) => {
  const [toasterWidth, setToasterWidth] = useState(320);

  const resetWidth = () => setToasterWidth(320);

  const setWidth = (width) => setToasterWidth(width);

  return (
    <ToasterContext.Provider
      value={{
        toasterWidth,
        resetWidth,
        setWidth,
      }}
    >
      {children}
    </ToasterContext.Provider>
  );
};

ToasterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ToasterProvider;
