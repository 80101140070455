import React, { useState } from 'react';
import { TextField, Grid } from '@mui/material';
import { UnauthenticatedFormContainer } from '@components/containers';
import { STRINGS } from 'constants/strings';
import { TAP_SIGN_IN, TAP_SIGN_UP_LINK } from 'constants/analytics';
import { record } from '@utils/analytics';

const SignIn = ({ onLogin, loading, changeFormType, onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmail = (evt) => setEmail(evt.target.value);
  const handlePassword = (evt) => setPassword(evt.target.value);

  const handleSubmit = async () => {
    record(TAP_SIGN_IN, { email });
    onLogin(email, password);
  };

  return (
    <UnauthenticatedFormContainer
      title="Sign in to your account"
      onSubmit={handleSubmit}
      onClose={onClose}
      submitBtnLabel={STRINGS.SIGN_IN}
      loading={loading}
      footerText={STRINGS.DONT_HAVE_ACCOUNT_YET}
      mainLinkText={STRINGS.SIGN_UP}
      mainLinkRoute={() => changeFormType('signup')}
      mainLinkRecord={TAP_SIGN_UP_LINK}
      secondaryLinkText="Forgot Password?"
      secondaryLinkRoute={() => changeFormType('forgot-password')}
    >
      <>
        <Grid md={12} xs={12} item>
          <TextField
            fullWidth
            name="email"
            label="Email Address"
            variant="standard"
            type="email"
            margin="normal"
            placeholder="Enter your email address"
            onChange={handleEmail}
            value={email}
            required
            focused
            sx={{ '.MuiInput-input': { color: 'black' } }}
          />
        </Grid>

        <Grid md={12} xs={12} item>
          <TextField
            fullWidth
            name="password"
            label="Password"
            variant="standard"
            type="password"
            margin="normal"
            placeholder="Enter your password"
            onChange={handlePassword}
            value={password}
            required
            focused
            sx={{ '.MuiInput-input': { color: 'black' } }}
          />
        </Grid>
      </>
    </UnauthenticatedFormContainer>
  );
};

export default SignIn;
