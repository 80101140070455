import { useCallback, useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import { IconButton, MenuItem, Menu, Box, Typography } from '@mui/material/index';
import SwitchAccountsModal from '@components/partials/layout/SwitchAccountsModal';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircleOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import MainMarbylIcon from '@components/icons/mainMarbylIcon';

import { StyledAppBar } from './styled';
import { useAuth } from 'contexts/AuthContext';
import { useHookedOnState } from 'hooked-on-redux/index';
import useDesktopMobile from 'hooks/useDesktopMobile';
import { STRINGS } from 'constants/strings';

const Navbar = ({ onSignOut, onClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    user: { attributes },
  } = useAuth();
  const callLogout = useHookedOnState('LOGOUT')[1];
  const { renderMobileComponent, renderDesktopComponent, isDesktopOrLaptop } =
    useDesktopMobile();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderLabelIcon = (label, ariaLabel, icon, onClick) => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        mb: 0.5,
      }}
      onClick={onClick}
      color="secondary"
      aria-label={ariaLabel}
    >
      <Box mr={2} mt={1}>
        {icon}
      </Box>
      <Typography color="secondary.main">{label}</Typography>
    </Box>
  );

  const handleSignOut = useCallback(() => {
    callLogout();
    onSignOut();
  }, [onSignOut, callLogout]);

  return (
    <StyledAppBar position="fixed">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={onClick}
          edge="start"
          sx={{ ...(!isDesktopOrLaptop && { ml: 1 }) }}
        >
          <MenuIcon fontSize="large" />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            paddingRight: 2.4,
            ...(isDesktopOrLaptop && { borderRight: '1px solid rgba(0, 0, 0, 0.12)' }),
          }}
        >
          <MainMarbylIcon isDarkTheme={false} isRecording={true} />
        </Box>
        <Box ml="auto">
          <Box display="flex" alignItems="center">
            {renderDesktopComponent(() => (
              <h5>{attributes.given_name}</h5>
            ))}

            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircleIcon fontSize="large" />
            </IconButton>
          </Box>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {renderMobileComponent(() => (
              <MenuItem onClick={handleClose} sx={{ backgroundColor: 'grey.200' }}>
                {renderLabelIcon(
                  attributes.given_name,
                  'account',
                  <AccountCircleIcon />,
                  null
                )}
              </MenuItem>
            ))}
            <MenuItem onClick={handleClose}>
              <SwitchAccountsModal />
            </MenuItem>
            <MenuItem onClick={handleClose}>
              {renderLabelIcon(STRINGS.LOGOUT, 'logout', <LogoutIcon />, handleSignOut)}
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </StyledAppBar>
  );
};
export default Navbar;
