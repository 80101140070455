import React from 'react';
import { Box, Typography } from '@mui/material';
import { StyledFullHeight, StyledInnerContainer } from '@shared/styledContainers';

import { Topbar } from '@components/index';

const NotFoundPage = () => {
  return (
    <StyledFullHeight>
      <StyledInnerContainer>
        <Topbar />
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h1" color="primary">
            404
          </Typography>
          <Typography variant="h3" color="primary">
            Page not found
          </Typography>
        </Box>
      </StyledInnerContainer>
    </StyledFullHeight>
  );
};

export default NotFoundPage;
