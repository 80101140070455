export const getSubnetworkByNetworkId = /* GraphQL */ `
  query getSubnetworkByNetworkId(
    $networkId: String!
    $limit: Int
    $nextToken: String
    $itemType: MPClistReportTypes
  ) {
    networksList: MPCGetSubNetworksByNetworkId(
      networkId: $networkId
      limit: $limit
      nextToken: $nextToken
      itemType: $itemType
    ) {
      items {
        PK
        name: podcastNetworkName
      }
      nextToken
    }
  }
`;

export const getPodcastersByNetworkId = /* GraphQL */ `
  query getPodcastersByNetworkId(
    $networkId: String!
    $limit: Int
    $nextToken: String
    $itemType: MPClistReportTypes
  ) {
    podcastersList: MPCGetPodcastersByNetworkId(
      networkId: $networkId
      limit: $limit
      nextToken: $nextToken
      itemType: $itemType
    ) {
      items {
        PK
        name
      }
      nextToken
    }
  }
`;

export const getPodcastsByNetworkId = /* GraphQL */ `
  query getPodcastsByNetworkId(
    $networkId: String!
    $limit: Int
    $nextToken: String
    $itemType: MPClistReportTypes
  ) {
    response: MPCGetShowsByNetworkId(
      networkId: $networkId
      limit: $limit
      nextToken: $nextToken
      itemType: $itemType
    ) {
      items {
        PK
        name: title
      }
      nextToken
    }
  }
`;

export const getPodcastsByPodcasterId = /* GraphQL */ `
  query getPodcastsByPodcasterId(
    $podcasterId: String!
    $limit: Int
    $nextToken: String
    $itemType: MPClistReportTypes
  ) {
    response: MPCListShowsByUserIdRevision(
      statusFilter: ACTIVE
      podcasterId: $podcasterId
      limit: $limit
      nextToken: $nextToken
      itemType: $itemType
    ) {
      items {
        PK
        name: title
      }
      nextToken
    }
  }
`;

export const getEpisodesSelectList = /* GraphQL */ `
  query listEpisodesByPodcastId(
    $showId: String!
    $limit: Int
    $nextToken: String
    $itemType: MPClistReportTypes
  ) {
    episodesList: MPCListEpisodesByShowIdRevision(
      showId: $showId
      limit: $limit
      nextToken: $nextToken
      itemType: $itemType
    ) {
      items {
        PK
        name: title
      }
      nextToken
    }
  }
`;

export const getMarbylsSelectList = /* GraphQL */ `
  query getMarbylsSelectList($episodeId: String!, $limit: Int, $nextToken: String) {
    listMarbylsByEpisodeId: MPCListMarbylsByEpisodeIdContentReport(
      episodeId: $episodeId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        PK
        name: PK
      }
      nextToken
    }
  }
`;

export const listContentReports = /* GraphQL */ `
  query listContentReports(
    $filterBy: ContentReportFilters
    $itemId: String!
    $limit: Int
    $nextToken: String
    $status: String
  ) {
    contentReportsRes: MPCGetContentReports(
      itemId: $itemId
      filterBy: $filterBy
      limit: $limit
      nextToken: $nextToken
      status: $status
    ) {
      items: reports {
        reportId
        createdAt
        email
        phoneNumber
        reportReason
        reportReasonDetail
        marbylId
        marbylText
        status
      }
      nextToken
    }
  }
`;

export const getReportById = /* GraphQL */ `
  query getReportById($reportId: String!) {
    reportRes: MPCGetContentReportById(reportId: $reportId) {
      reportId
      createdAt
      reportReason
      marbylId
      email
      phoneNumber
      marbylText
      reportReasonDetail
      status
      marbylStatus
    }
  }
`;
