import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import EntitiesNamesProvider from 'contexts/EntitiesNamesContext';
import { MPCReportDetail, MPCReports } from '@components/containers/index';
import { CommonSwitch } from './common';
import { MPC_ROUTES } from 'constants/routing';

const MPCReportsRoutes = () => {
  const { url } = useRouteMatch();

  const routes = useMemo(
    () => [
      {
        exact: true,
        path: url,
        childComponent: <MPCReports />,
      },
      {
        path: `${url}${MPC_ROUTES.ID}`,
        childComponent: <MPCReportDetail />,
      },
    ],
    [url]
  );

  return (
    <EntitiesNamesProvider>
      <CommonSwitch routes={routes} />
    </EntitiesNamesProvider>
  );
};

export default MPCReportsRoutes;
