import { CancelConfirmDeleteAccount } from '@components/index';
import { confirmDeleteAccount } from '@graphql/mutations/accounts';

const ConfirmDeleteAccountPage = () => {
  return (
    <CancelConfirmDeleteAccount
      deleteMutation={confirmDeleteAccount}
      textMessage="Your Marbyl account has been successfully deleted."
    />
  );
};

export default ConfirmDeleteAccountPage;
