import { SkeletonLoading } from '@components/index';
import React, { useCallback, useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import AccountUserDetail from './detail';
import AccountUserEdit from './edit';
import { getRolesByAccountType, getUserByAccountId } from '@graphql/queries/users';
import { ACCOUNT } from 'constants/idPrefixes';
import { useDeps } from 'contexts/DepsContext';
import { useParams } from 'react-router-dom';
import { getAccountType } from '@utils/getAccountType';
import { updateAccountUser } from '@graphql/mutations/users';

const UserDetailPage = () => {
  const [user, setUser] = useState(null);
  const [roles, setRoles] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const { httpClient } = useDeps();
  const { id } = useParams();

  const toggleEditMode = () => setEditMode(!editMode);

  const loadUser = useCallback(() => {
    httpClient(getUserByAccountId, { accountId: `${ACCOUNT}${id}` })
      .then(({ data: { response } }) => setUser(response))
      .catch();
  }, [id, httpClient]);

  const loadRoles = useCallback(
    (role) => {
      const accountType = getAccountType(role);
      httpClient(getRolesByAccountType, { accountType })
        .then(({ data: { response } }) => setRoles(response))
        .catch();
    },
    [httpClient]
  );

  const handleSubmit = (values) => {
    const payload = {
      accountId: values.accountId,
      accountRole: values.role,
      isActivated: values.isActivated,
    };

    httpClient(updateAccountUser, { ...payload })
      .then(({ data: { response } }) => {
        setUser(response);
        setEditMode(false);
      })
      .catch();
  };

  useEffect(() => {
    Promise.all([loadUser()]);
  }, [loadUser]);

  useEffect(() => {
    if (user) {
      loadRoles(user.role);
    }
  }, [user, loadRoles]);

  if (!user) {
    return <SkeletonLoading />;
  }

  return (
    <Paper sx={{ p: 2, bgcolor: 'primary.main', color: 'secondary.main' }} elevation={2}>
      {editMode ? (
        <AccountUserEdit
          user={user}
          onCancel={toggleEditMode}
          onSubmit={handleSubmit}
          roles={roles}
        />
      ) : (
        <AccountUserDetail user={user} onClickEdit={toggleEditMode} />
      )}
    </Paper>
  );
};

UserDetailPage.propTypes = {};

UserDetailPage.defaultProps = {};

export default UserDetailPage;
