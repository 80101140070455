import { useCallback } from 'react';
import { toast } from 'react-toastify';

const useNotifications = () => {
  const showErrorMessages = useCallback((errors, defaultMessage) => {
    const errorMessage = errors[0]?.message || defaultMessage;

    toast.error(errorMessage);
  }, []);

  return {
    showErrorMessages,
  };
};

export default useNotifications;
