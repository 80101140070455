import { useState, useEffect, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { ContextMenu, HierarchyHeader, SkeletonLoading } from '@components/index';
import { toast } from 'react-toastify';
import {
  StyledTranscriptMainContainer,
  StyledTranscriptContentContainer,
  StyledHighlightMessage,
  StyledTranscriptText,
} from './styled';
import ReviewModal from './ReviewModal';
import { requestAPIGraphQL } from '@services/appSyncAPI';
import { getEpisodeTitle } from '@graphql/queries/episodes';
import { checkPayAccess } from '@graphql/queries/mpc';
import { getTranscript, exportTranscript } from '@graphql/queries/transcripts';
import { useMPC } from 'contexts/MPCContext';
import useNotifications from 'hooks/useNotifications';
import { getTranscriptSelectedTextInfo } from '@utils/transcripts';
import { STRINGS } from 'constants/strings';
import { EPISODE_PREFIX } from 'constants/idPrefixes';

const exportOptions = [
  { format: 'pdf', mimeType: 'application/pdf' },
  { format: 'doc', mimeType: 'application/msword' },
  { format: 'txt', mimeType: 'text/plain' },
  { format: 'html', mimeType: 'text/html' },
];

const Transcript = ({ episodeId }) => {
  const [episodeTitle, setEpisodeTitle] = useState('');
  const [transcriptText, setTranscriptText] = useState([]);
  const [selectedText, setSelectedText] = useState({
    text: '',
    textPositionStart: 0,
    textPositionEnd: 0,
    prevText: '',
    lastText: '',
  });
  const [isReviewModalOpened, setIsReviewModalOpened] = useState(false);
  const [showExportButton, setShowExportButton] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [isExporting, setIsExporting] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openedMenu = Boolean(anchorEl);
  const { selectedAccount } = useMPC();
  const { showErrorMessages } = useNotifications();

  const getEpisodeId = () => `${EPISODE_PREFIX}${episodeId}`;

  const toggleReviewModal = () => setIsReviewModalOpened((prevOpened) => !prevOpened);

  const getCheckPayAccess = useCallback(() => {
    if (isEmpty(selectedAccount)) return;

    requestAPIGraphQL(checkPayAccess, {
      accountId: selectedAccount?.accountId,
    })
      .then(({ data }) => {
        setShowExportButton(data?.MPCCheckPayAccess);
      })
      .catch(({ errors }) => {
        showErrorMessages(errors, 'There was an error trying to check pay access.');
      });
  }, [selectedAccount]);

  const retrieveTitle = useCallback(() => {
    requestAPIGraphQL(getEpisodeTitle, { episodeId: getEpisodeId() })
      .then(
        ({
          data: {
            getMPCEpisodeById: { title },
          },
        }) => {
          setEpisodeTitle(title);
        }
      )
      .catch(({ errors }) => {
        showErrorMessages(
          errors,
          'There was an error trying to retrieve the transcript information.'
        );
      })
      .finally(() => {
        retrieveTranscriptInfo();
      });
  }, [episodeId, requestAPIGraphQL]);

  const retrieveTranscriptInfo = useCallback(() => {
    requestAPIGraphQL(getTranscript, { episodeId: getEpisodeId() })
      .then(({ data: { MPCGetTranscriptByEpisodeId } }) => {
        if (MPCGetTranscriptByEpisodeId) {
          const { results } = MPCGetTranscriptByEpisodeId;
          const { transcript } = results?.transcripts[0];

          setTranscriptText(transcript);
        }
      })
      .catch(({ errors }) => {
        showErrorMessages(
          errors,
          'There was an error trying to retrieve the Transcript.'
        );
      })
      .finally(() => {
        setLoadingData(false);
      });
  }, [episodeId, requestAPIGraphQL]);

  useEffect(() => {
    getCheckPayAccess();
    retrieveTitle();
  }, [retrieveTitle, getCheckPayAccess]);

  const onExportClicked = (event) => setAnchorEl(event.currentTarget);

  const handleCloseMenu = () => setAnchorEl(null);

  const generateFile = (format, buffer) => {
    const mimeType = exportOptions?.find(
      (exportOption) => exportOption.format === format
    )?.mimeType;
    const linkSource = `data:${mimeType};base64,${buffer}`;
    const fileName = `${episodeTitle}.${format}`;
    const downloadElement = document.createElement('a');

    downloadElement.setAttribute('href', linkSource);
    downloadElement.setAttribute('download', fileName);
    downloadElement.style.display = 'none';

    document.body.appendChild(downloadElement);

    downloadElement.click();

    document.body.removeChild(downloadElement);

    handleCloseMenu();
  };

  const exportTranscriptFile = (format) => {
    setIsExporting(true);

    requestAPIGraphQL(exportTranscript, {
      episodeId: getEpisodeId(),
      exportFormat: format?.toUpperCase(),
    })
      .then(({ data: { MPCExportTranscript: exportedTranscript } }) => {
        generateFile(format, exportedTranscript);
      })
      .catch(({ errors }) => {
        showErrorMessages(errors, 'There was an error trying to export the Transcript.');
      })
      .finally(() => {
        setIsExporting(false);
      });
  };

  const getSelectedText = useCallback(() => {
    const text = window?.getSelection()?.toString() || '';

    return text.replace(/(\n\n)/gm, ' ');
  }, [window.getSelection]);

  const onFlagReview = () => {
    if (getSelectedText()?.length === 0) {
      toast.warning('Warning: Text must be selected to submit a review.');
      return;
    } else {
      const selectedTextByUser = getSelectedText();
      const { positionStart, positionEnd, prevText, lastText } =
        getTranscriptSelectedTextInfo(transcriptText, selectedTextByUser);

      setSelectedText({
        text: selectedTextByUser,
        textPositionStart: positionStart,
        textPositionEnd: positionEnd,
        prevText,
        lastText,
      });

      toggleReviewModal();
    }
  };

  const getProcessedText = () => {
    let transcript = transcriptText;
    let transcriptResult = [];

    while (transcript.length > 0) {
      let tempTranscript = '',
        colonIndex = -1;

      tempTranscript = transcript.substring(0, 1000);
      transcript = transcript.substring(1000);
      colonIndex = transcript.indexOf('.') + 1;

      if (colonIndex > -1) {
        tempTranscript += transcript.substring(0, colonIndex);
        transcript = transcript.substring(colonIndex);
      }

      transcriptResult.push(tempTranscript);
    }

    return transcriptResult;
  };

  const renderText = () => {
    const hasTranscript = transcriptText.length > 0;
    const containerStyle = !hasTranscript
      ? { alignItems: 'center', justifyContent: 'center', height: '100%' }
      : { overflowY: 'auto' };
    const typographyVariant = !hasTranscript ? 'h3' : 'body1';
    let text = STRINGS.WE_HAVE_NOT_YET_TRANSCRIBED_THIS_EPISODE;

    if (hasTranscript) {
      const processedText = getProcessedText();

      text = processedText.map((transcript, index) => (
        <p key={`transcript-${index}`} style={{ marginBottom: '20px' }}>
          {transcript}
        </p>
      ));
    }

    return (
      <Box sx={{ display: 'flex', marginBottom: 2, marginTop: 2, ...containerStyle }}>
        <StyledTranscriptText variant={typographyVariant}>{text}</StyledTranscriptText>
      </Box>
    );
  };

  const renderExportMenu = () => {
    const options = exportOptions.map((exportOption) => (
      <MenuItem
        key={`export-${exportOption.format}`}
        onClick={() => exportTranscriptFile(exportOption.format)}
        disabled={isExporting}
      >
        {`.${exportOption.format}`}
      </MenuItem>
    ));

    return (
      <Menu
        id="export-menu"
        anchorEl={anchorEl}
        open={openedMenu}
        onClose={handleCloseMenu}
        MenuListProps={{ 'aria-labelledby': 'export-button' }}
      >
        {options}
      </Menu>
    );
  };

  const renderExportButton = () => {
    if (showExportButton) {
      return (
        <>
          <Button
            id="export-button"
            aria-controls={open ? 'export-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            color="success"
            variant="outlined"
            onClick={onExportClicked}
            disabled={!transcriptText}
          >
            Export
          </Button>
          {renderExportMenu()}
        </>
      );
    }

    return <></>;
  };

  if (loadingData) return <SkeletonLoading isFullHeight />;

  return (
    <ContextMenu
      menuItems={[
        {
          text: 'Flag for review',
          onClick: onFlagReview,
          isDisabled: false,
        },
      ]}
    >
      <StyledTranscriptMainContainer>
        <HierarchyHeader title={episodeTitle} headerType={STRINGS.EPISODE} />
        <StyledTranscriptContentContainer>
          <StyledHighlightMessage>
            {STRINGS.SELECT_AND_RIGHT_CLICK_FOR_REVIEW}
          </StyledHighlightMessage>
          {renderExportButton()}
        </StyledTranscriptContentContainer>
        {renderText()}
        {isReviewModalOpened && (
          <ReviewModal
            episodeId={getEpisodeId()}
            episodeTitle={episodeTitle}
            isOpen={isReviewModalOpened}
            selectedText={selectedText?.text}
            textPositionStart={selectedText?.textPositionStart}
            textPositionEnd={selectedText?.textPositionEnd}
            prevText={selectedText?.prevText}
            lastText={selectedText?.lastText}
            onClose={toggleReviewModal}
          />
        )}
      </StyledTranscriptMainContainer>
    </ContextMenu>
  );
};

Transcript.propTypes = {
  episodeId: PropTypes.string.isRequired,
};

export default Transcript;
