import PropTypes from 'prop-types';

export const props = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      md: PropTypes.number,
      sm: PropTypes.number,
      xs: PropTypes.number,
      label: PropTypes.string.isRequired,
      value: PropTypes.any,
      renderValue: PropTypes.func,
      styles: PropTypes.object,
    })
  ).isRequired,
};
