export const listUsersByAccountId = /* GraphQL */ `
  query listUsersByAccountId($accountId: String!, $limit: Int, $nextToken: String) {
    listUsersByAccountId: MPCListUsersByAccountId(
      accountId: $accountId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        email
        invitedBy
        isAccountOwner
        isActivated
        joinedDate
        userId
        role
        name
        accountType
      }
    }
  }
`;

export const getUserByAccountId = /* GraphQL */ `
  query getUserByAccountId($accountId: String!) {
    response: MPCGetUserAccountById(accountId: $accountId) {
      accountId
      accountType
      email
      invitedBy
      isAccountOwner
      joinedDate
      name
      role
      userId
      isActivated
    }
  }
`;

export const getUserByEmail = /* GraphQL */ `
  query getUserByEmail($email: String!) {
    response: MPCGetUserByEmail(email: $email) {
      PK
      name
      email
      phoneNumber
      givenName
      familyName
    }
  }
`;

export const getRolesByAccountType = /* GraphQL */ `
  query getRolesByAccountType($accountType: String!, $loggedInUserAccountRole: String!) {
    response: MPCGetRolesByAccountType(
      accountType: $accountType
      loggedInUserAccountRole: $loggedInUserAccountRole
    )
  }
`;

export const getUserProfile = /* GraphQL */ `
  query getUserProfile($userId: String!) {
    getUserProfileResponse: MPCGetUserProfile(userId: $userId) {
      user {
        PK
        phoneNumber
        name
        createdAt
        avatar
        email
        bio
        givenName
        familyName
      }
      userAccounts {
        entityName
        entityId
        isActivated
        updatedAt
        deleted
        createdAt
        accountRole
        accountOwner
        accountId
      }
    }
  }
`;

export const getAllEntities = /* GraphQL */ `
  query getAllEntities($limit: Int, $nextToken: String) {
    getAllEntities: MPCGetAllEntities(limit: $limit, nextToken: $nextToken) {
      items {
        PK
        name
        podcastNetworkName
        title
        accountId
      }
      nextToken
    }
  }
`;

export const getAllEntitiesByAccountType = /* GraphQL */ `
  query MyQuery($entityId: String!) {
    response: MPCGetAllEntitiesByAccountType(entityId: $entityId) {
      items {
        PK
        itemType
        accountType
        name
        podcastNetworkName
        accountId
        SK
      }
    }
  }
`;

export const getInvitationTokenInfo = /* GraphQL */ `
  query MPCGetInvitationTokenInfo($token: String!) {
    response: MPCGetInvitationTokenInfo(token: $token) {
      PK
      SK
      accountRole
      invitedBy
      parentAccountId
      parentEntityName
      userId
    }
  }
`;
