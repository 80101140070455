import styled from '@mui/styled-engine';

export const StyledBurger = styled('button')(
  ({ theme, open }) => `
    top: 2rem;
    right: 10vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;

    &:focus {
      outline: none;
    }

    div {
      width: 2rem;
      height: 0.25rem;
      background: ${theme.palette.secondary.main};
      border-radius: 10px;
      transition: all 0.3s linear;
      position: relative;
      transform-origin: 1px;

      :first-of-type {
        transform: ${open ? 'rotate(45deg)' : 'rotate(0)'};
      }

      :nth-of-type(2) {
        opacity: ${open ? '0' : '1'};
        transform: ${open ? 'translateX(20px)' : 'translateX(0)'};
      }

      :nth-of-type(3) {
        transform: ${open ? 'rotate(-45deg)' : 'rotate(0)'};
      }
    }
`
);
