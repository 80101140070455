import React, { useCallback, useEffect, useState } from 'react';
import {
  Grid,
  MenuItem,
  Box,
  Typography,
  Checkbox,
  ListItemText,
  OutlinedInput,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  ListItemIcon,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { props, defaultProps } from './props';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CheckboxSelect = ({ checkbox, select, color }) => {
  const { label: chkLabel, initialValue: chkChecked = false } = checkbox;

  const { handleChange, checkedPlaceholder, uncheckedPlaceholder } = select;

  const [checked, setChecked] = useState(chkChecked);
  const [selectPlaceholder, setSelectPlaceholder] = useState(() => {
    if (checked) return checkedPlaceholder;
    else return uncheckedPlaceholder;
  });
  const [itemsByName, setItemsByName] = useState({});

  const handleChecked = (evt) => {
    const {
      target: { checked },
    } = evt;

    setChecked(checked);
  };

  const renderSelect = useCallback(() => {
    if (checked) return null;

    const {
      required: selRequired,
      size: selSize,
      name: selName,
      value: selValue,
      label: selLabel,
      items,
      handleChange,
      valueName,
      labelName,
      helperText,
    } = select;

    const isAllSelected = items.length > 0 && selValue.length === items.length;

    const onSelectOption = (evt) => {
      const {
        target: { value },
      } = evt;

      if (value[value.length - 1] === 'all') {
        handleChange(
          selValue.length === items.length ? [] : items.map((i) => i[valueName])
        );
        return;
      }

      handleChange(typeof value === 'string' ? value.split(',') : value);
    };

    const renderHelperLabel = () => {
      return selValue.length > 0 ? (
        <Box id="component-error-text" sx={{ ml: 0, mt: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <InfoIcon fontSize="small" color="info" sx={{ mr: 1 }} />
            <Typography color={color} variant="caption">
              {helperText}:{' '}
              {Object.keys(itemsByName).length > 0 &&
                selValue.map((un) => itemsByName[un][labelName]).join(', ')}
            </Typography>
          </Box>
        </Box>
      ) : null;
    };

    return (
      <Grid item md={12}>
        <FormControl
          size={selSize || 'medium'}
          fullWidth
          required={selRequired}
          disabled={checked}
          color={color}
        >
          <InputLabel id={`select-multiple-${selName}-label`}>
            {checked ? selectPlaceholder : selLabel}
          </InputLabel>
          <Select
            disabled={checked}
            labelId={`select-multiple-${selName}-label`}
            id={`select-multiple-${selName}`}
            name={selName}
            multiple
            input={
              <OutlinedInput
                color={color}
                label={checked ? selectPlaceholder : selLabel}
              />
            }
            renderValue={() => {
              return selectPlaceholder;
            }}
            MenuProps={MenuProps}
            value={selValue}
            required={selRequired || false}
            onChange={onSelectOption}
          >
            <MenuItem value="all">
              <ListItemIcon>
                <Checkbox
                  color={color}
                  checked={isAllSelected}
                  indeterminate={selValue.length > 0 && selValue.length < items.length}
                />
              </ListItemIcon>
              <ListItemText primary="Select All" />
            </MenuItem>
            {items.map((_item) => (
              <MenuItem key={_item[valueName]} value={_item[valueName]}>
                <Checkbox
                  color={color}
                  checked={selValue.indexOf(_item[valueName]) > -1}
                />
                <ListItemText primary={_item[labelName]} />
              </MenuItem>
            ))}
          </Select>
          {renderHelperLabel()}
        </FormControl>
      </Grid>
    );
  }, [checked, select, selectPlaceholder, itemsByName, color]);

  useEffect(() => {
    setSelectPlaceholder(checked ? checkedPlaceholder : uncheckedPlaceholder);
  }, [checked, checkedPlaceholder, uncheckedPlaceholder]);

  useEffect(() => {
    if (checked) handleChange([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  useEffect(() => {
    const { items, valueName } = select;

    const reducedItems = items.reduce(
      (obj, i) => ({ ...obj, [i[valueName]]: { ...i } }),
      {}
    );

    setItemsByName(reducedItems);
  }, [select]);

  return (
    <>
      <Grid item md={12}>
        <FormControlLabel
          control={
            <Checkbox
              name={'checkbox-select-checked'}
              checked={checked}
              onChange={handleChecked}
              color={color}
              disableRipple
            />
          }
          label={chkLabel}
        />
      </Grid>
      {renderSelect()}
    </>
  );
};

CheckboxSelect.propTypes = props;
CheckboxSelect.defaultProps = defaultProps;

export default CheckboxSelect;
