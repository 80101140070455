export const STRINGS = {
  LOGOUT: 'Logout',
  EDIT: 'Edit',
  NETWORK: 'Network',
  ADD_NETWORK: 'Add Network',
  EDIT_NETWORK: 'Edit Network',
  PODCASTERS: 'Podcasters',
  PODCASTER: 'Podcaster',
  ADD_PODCASTER: 'Add Podcaster',
  EDIT_PODCASTER: 'Edit Podcaster',
  PODCASTS: 'Podcasts',
  PODCAST: 'Podcast',
  ADD_PODCAST: 'Add Podcast',
  SAVE: 'Save',
  CLOSE: 'Close',
  NEW: 'New',
  NEW_NETWORK: 'New Network',
  NEW_PODCASTER: 'New Podcaster',
  NEW_PODCAST: 'New Podcast',
  LOAD: 'Load',
  CREATE: 'Create',
  CLEAR: 'Clear',
  MORE: 'More',
  LESS: 'Less',
  PROCESS_RSS: 'Process RSS',
  CANCEL: 'Cancel',
  EPISODES: 'Episodes',
  EPISODE: 'Episode',
  LOAD_MORE: 'Load more...',
  SEARCH: 'Search',
  PROVIDED_FEED_URL_IS_INVALID: 'Provided feed URL is invalid',
  CANNOT_LOAD_THIS_URL_TRY_AGAIN: 'Cannot load this url feed. Try again.',
  ENTER_AN_RSS_URL_OR_SEARCH_PODCAST: 'Enter an RSS URL or search for your podcast',
  MARBYLS: 'Marbyls',
  TEXT: 'Text',
  AUTHOR: 'Author',
  CURATION_STATUS: 'Curation Status',
  MODERATION_STATUS: 'Moderation Status',
  UPDATE: 'Update',
  MARBYLIZE: 'Marbylize',
  REPROCESS_RSS_FEED: 'Reprocess RSS Feed',
  COMPLETE: 'Complete',
  UPDATE_PODCAST: 'Update podcast',
  REGIONS_AVAILABLE: 'Regions available',
  PREFERED_SUBSCRIBE_LINK: 'Preferred subscribe link',
  SUBSCRIBE_LINK_TYPE: 'Subscribe link type',
  COUNTRIES: 'Countries',
  ITUNES_ID: 'iTunes ID',
  UPDATE_MARBYL: 'Update marbyl',
  CREATE_USER: 'Create user',
  NEW_USER: 'New user',
  USERS: 'Users',
  UPDATE_USER: 'Update user',
  USER_MANAGEMENT: 'User Management',
  WORLDWIDE: 'Worldwide',
  SIGN_UP: 'Sign Up',
  SIGN_IN: 'Sign In',
  DONT_HAVE_ACCOUNT_YET: "Don't have an account yet?",
  ALREADY_HAVE_ACCOUNT: 'Already have an account?',
  LOGIN: 'Login',
  REPORTED_CONTENT: 'Reported Content',
  TRANSCRIPT_REVIEWS: 'Transcript Reviews',
  REPORTED_TRANSCRIPTION_ERRORS: 'Reported Transcription Errors',
  MARBYL_CHART_VIEW: 'Marbyl Chart View',
  CLAIM_PODCAST: 'Claim podcast',
  NEXT: 'Next',
  ADD_YOUR_PODCAST_RSS: "Add your podcast's RSS",
  VERIFY_THIS_PODCAST_IS_YOURS: 'Verify this podcast is yours',
  TO_VERIFY_THAT_YOU_OWN_THE_PODCAST_WE_WILL_SEND_A_6_DIGIT_CODE_TO_THE_EMAIL_ADDRESS_ASSOCIATED_WITH_YOUR_RSS_FEED:
    'To verify that you own this podcast, we will send a 6 digit code to the mail address ({email}) associated with your RSS feed.',
  PREVIOUS: 'Previous',
  SEND_THE_CODE: 'Send the code',
  CHECK_YOUR_EMAIL: 'Check your email',
  WE_SENT_A_6_DIGIT_CODE_TO_THE_EMAIL_ADDRESS_ASSOCIATED_WITH_YOUR_RSS_FEED_ENTER_THAT_CODE_HERE_NOTE_THE_CODE_WILL_EXPIRE_IN:
    'We sent a 6 digit code to the email address ({email}) associated with your RSS feed. Enter that code here. Note the code will expire in',
  TO_UPDATE_THE_EMAIL_ASSOCIATED_WITH_YOUR_RSS_FEED_CONTACT_YOUR_RSS_HOST_OR_OWNER:
    'To update the email address associated with your RSS feed, contact your RSS host or owner.',
  VERIFY: 'Verify',
  RESEND_CODE: 'Resend code',
  ADD_SOME_DETAILS_ABOUT_YOUR_PODCAST: 'Add some details about your podcast',
  DO_WE_HAVE_EVERYTHING_RIGHT: 'Do we have everything right?',
  CONFIRM_CODE: 'Confirm code',
  CONFIRM_SIGN_UP: 'Confirm Sign Up',
  SUBMIT: 'Submit',
  ARE_YOU_SURE_YOU_WANT_TO_CANCEL_THIS_PROCESS:
    'Are you sure you want to cancel this process?',
  WELCOME_TO_MARBYL: 'Welcome to Marbyl',
  IT_WILL_TAKE_SOME_TIME_BUT_PODCAST_WILL_SHOW_UP_HERE_ONCE_SET_UP:
    'It will take some time but {podcastName} will show up here once it’s set up.',
  DONE: 'Done',
  THERE_MAY_BE_A_DELAY_UNTIL_WE_SUPPORT_YOUR_LANGUAGE_WE_CURRENTLY_ONLY_SUPPORT_PODCASTS_IN_ENGLISH_BUT_PLAN_TO_SUPPORT_MORE_LANGUAGES_IN_THE_FUTURE:
    'There may be a delay until we support your language. We currently only support podcasts in English but plan to support more languages in the future.',
  LIMITED_REGIONS: 'Limited regions',
  PARTNERS: 'Partners',
  NO_RESULTS_WERE_FOUND: 'No results were found.',
  PODCAST_GEOGRAPHICAL_AVAILABILITY: 'Podcast Geographical Availability',
  SPECIFIC_COUNTRIES: 'Specific Countries',
  CODE_HAS_BEEN_SENT: 'Code has been sent!',
  AN_ERROR_OCCURED_ON_SERVER__TRY_AGAIN: 'An error occured on server. Try again.',
  PLEASE_CHECK_FOR_EMAIL_FOR_CONFIRMATION_CODE:
    'Please check your email for confirmation code',
  THIS_EMAIL_WOULD_BE_SENT_TO_BUT_FOR_TESTING_WEARE_SENDING_IT_TO:
    'This email would be sent to {podcastEmail} but for testing we’re sending it to {userEmail}',
  THIS_EMAIL_WOULD_BE_SENT_TO: 'This email would be sent to',
  BUT_FOR_TESTING_WEARE_SENDING_IT_TO: 'but for testing we’re sending it to',
  FOR_TESTING_PURPOSES_WE_WILL_INSTEAD_SEND_THE_EMAIL_TO:
    'For testing purposes, we will instead send the email to',
  THIS_PODCAST_HAS_ALREADY_BEEN_CLAIMED_PLEASE_CONTACT_OWNER_OF_PODCAST_FOR_DETAILS:
    'This podcast has already been claimed. Please contact the owner of the podcast for details.',
  FIND_SAVE_AND_SHARE_THE_BEST_MOMENTS_FROM_PODCASTS:
    'Find, Save and Share \nthe best moments \nfrom podcasts',
  A_MARBYL_IS_A_KEY_MOMENT_FROM_A_PODCAST_YOU_CAN_LISTEN_TO_READ_AND_SHARE_KEEP_LISTENING_AND_SEARCH_FULL_EPISODE_CONTENT:
    'A marbyl is a key moment from a podcast you can listen to, read, and share. \n\nKeep listening and search full episode content.',
  AND_FIND_ANYTHING_TOPICS_PEOPLE_PLACES_IDEAS_WITHIN_PODCASTS_WE_EVEN_SUGGEST_MARBYLS_JUST_FOR_YOU:
    'And find anything — topics, people, places, ideas — within podcasts. \n\nWe even suggest marbyls just for you.',
  FIND_PODCASTS_MOMENTS: 'Find the Podcast \nMoments That Matter\u2122',
  ARE_YOU_A_PODCASTER: 'Are you a podcaster?',
  LEARN_MORE: 'LEARN MORE',
  SPEAK_AND_THEY_SHALL_FIND: 'Speak, and they shall find',
  YOUR_VOICE_MATTERS:
    'Your voice matters. Marbyl matches moments in your podcast with the people who need to hear them. Automatically.',
  SIGN_UP_MESSAGE:
    'Claim your RSS and get notified when we’re ready to expand beyond our beta and open up our ecosystem to the podcaster community!',
  CLIP_AND_CREATE_YOUR_OWN_MARBYLS_SHARE_MARBYLS_ANYWHERE:
    'Clip and create \nyour own marbyls.\nShare marbyls anywhere.',
  OUR_PARTNERS: 'Our Partners',
  SUPPORT_EMAIL: 'support@marbyl.com',
  JOIN_THE_WAITLIST: 'Join the waitlist',
  CLIAM_PODCAST: 'Claim podcast',
  MARBYL_MAKES_YOU_DISCOVERABLE_BEYOND_THE_TOP_100:
    'Marbyl makes your \npodcast discoverable',
  WE_SURFACE_GREAT_MOMENTS_IN_YOUR_PODCAST_SO_THE_BEST_LISTENERS_CAN_FIND_YOU:
    'We surface key moments in audio so \nlisteners can find you',
  YOUR_PODCAST_ON_MARBYL: 'Your Podcast \non Marbyl',
  CLAIM_YOUR_RSS_FEED_AND_WE_WILL_AUTOMATICALLY_TRANSCRIBE_AND_MARBYLIZE_YOUR_CONTENT_LETTING_YOU_AND_YOUR_LISTENERS_SHARE_IT_ANYWHERE:
    'Claim your RSS feed and we will automatically transcribe and marbylize your content, letting you and your listeners share it anywhere.',
  LISTENERS_WANT_YOUR_CONTENT: 'Listeners Want \nYour Content',
  PODCAST_DISCOVERY_TODAY_IS_LIMITED_TO_LONG_FORM_FULL_EPISODE_RECOMMENDATIONS__WE_TRANSCRIBE_YOUR_EPISODES_AND_USE_ADVANCED_AI_TO_INTELLIGENTLY_SOURCE_MOMENTS_FROM_YOUR_CONTENT_PUTTING_IT_IN_FRONT_OF_THE_RIGHT_LISTENERS_AT_THE_RIGHT_TIME:
    'Podcast discovery today is limited to long-form, full episode recommendations.  We transcribe your episodes and use advanced AI to intelligently source moments from your content, putting it in front of the right listeners at the right time.',
  KNOW_WHAT_MOTIVATES_YOUR_LISTENERS: 'Know What Motivates \nYour Listeners',
  MARBYL_GOES_UPSTREAM_IN_A_LISTENERS_SEARCH_AND_CONSUMPTION_ACTIVITY_ACCESS_A_PERSONALIZED_DASHBOARD_FEATURING_INDUSTRY_FIRST_INSIGHTS_INTO_LISTENER_BEHAVIOR_INCLUDING_KEYWORDS_TOPICS_AND_IDEAS_THAT_ARE_DRIVING_YOUR_LISTENS_SHARES_AND_ENGAGEMENT:
    'Marbyl goes upstream in a listener’s search and consumption activity. \n\nAccess a personalized dashboard featuring industry-first insights into listener behavior, including keywords, topics and ideas that are driving your listens, shares and engagement.',
  CLAIM_YOUR_PODCAST: 'Claim your podcast',
  LOG_IN: 'Log in',
  RSS_FEED_NOT_FOUND: 'Rss feed not found.',
  OWNERS: 'Owner(s)',
  HOST_CO_HOST_PODCAST: 'Podcast Host /\nCo-Host',
  EMAIL: 'Email',
  MOBILE_PHONE_SMS: 'Mobile Phone / SMS',
  OWNER_COMPANY_NAME: 'Owner Company Name',
  IF_APPLICABLE: 'If Applicable',
  COMPANY_NAME: 'Company name',
  YOURE_NOW_A_MARBYL_PARTNER: "You're now a Marbyl Partner!",
  WELL_REACH_OUT_WHEN_IS_AVAILABLE_IN_MARBYL_IN_THE_MEANTIME_WELL_BE_EMAILING_YOU_WITH_AN_INVITE_FOR_OUR_PRIVATE_BETA_MAKE_SURE_YOU_SET_YOUR_SPAM_FILTER_TO_ALLOW_FOR_AND_IF_YOU_HAVE_ANY_QUESTIONS_SEND_US_AN_EMAIL:
    "We'll reach out when {podcastName} is available in Marbyl. In the meantime, we'll be emailing you with an invite for our private beta. Make sure you set your spam filter to allow for support@marbyl.com and if you have any questions, send us an email.",
  WELL_REACH_OUT_WHEN_PODCAST_IS_AVAILABLE_IN_MARBYL_PLEASE_BE_SURE_TO_CHECK_BOTH_YOUR_INBOX_AND_SPAM_FOLDERS_FOR_MESSAGES_FROM_SUPPORT__ONCE_YOUR_PODCAST_IS_LIVE_IN_MARBYL_WELL_SEND_YOU_AN_INVITE_TO_ACCESS_OUR_PRIVATE_BETA_IF_YOU_HAVE_ANY_QUESTIONS_SEND_US_AN_EMAIL_AT_SUPPORT_MAIL:
    "We'll reach out when {podcastName} is available in Marbyl. Please be sure to check both your inbox and spam folders for messages from support@marbyl.com. \n\nOnce your podcast is live in Marbyl, we'll send you an invite to access our private beta. If you have any questions, send us an email at support@marbyl.com. \n\nThank you!",
  LEARN_MORE_ABOUT_THE_MARBYL_APP: 'Learn more about the Marbyl app',
  MY_ACCOUNT: 'My Account',
  ONCE_YOU_ADD_YOUR_PODCAST_WELL_TRANSCRIBE_IT_AND_PULL_OUT_GREAT_MOMENTS_FROM_EACH_EPISODE_WE_CALL_THESE_MARBYLS_AND_WELL_CONTINUE_TO_CREATE_NEW_MARBYLS_FROM_EACH_NEW_EPISODE_YOU_RELEASE:
    'Once you add your podcast, we’ll transcribe it and pull out great moments from each episode — we call these marbyls. And we’ll continue to create new marbyls from each new episode you release.',
  MARBYL_OPEN_SOURCE_ATTRIBUTIONS: 'Marbyl Open Source Attributions',
  MARBYL_TERMS_OF_SERVICE: 'Marbyl Terms of Service',
  DESCRIPTION: 'Description',
  NAME: 'Name',
  GEOGRAPHIC_AVAILABILITY: 'Geographic Availability',
  PODCASTER_DETAILS: 'Podcaster Details',
  OWNER_INFORMATION: 'Owner Information',
  ADMIN_SETTINGS: 'Admin Settings',
  STATUS: 'Status',
  AGREEMENT: 'Agreement',
  OPTED_IN_TO_TOS_PP: 'Opted in to TOS/PP',
  RSS_INFORMATION: 'RSS Information',
  TITLE: 'Title',
  SUBTITLE: 'Subtitle',
  INFO_LINK: 'Info Link',
  IMAGE_URL: 'Image URL',
  OWNER: 'Owner',
  LANGUAGE: 'Language',
  TYPE: 'Type',
  COPYRIGHT: 'Copyright',
  CATEGORIES: 'Categories',
  EXPLICIT: 'Explicit',
  PODCAST_AVAILABILITY: 'Podcast Availability',
  PODCAST_IS_ACTIVATED: 'Podcast is Activated',
  DATE_UPDATED: 'Date Updated',
  AVAILABLE_IN_GEOS: 'Available in Geos',
  MARBYLIZATION_SETTINGS: 'Marbylization Settings',
  ENABLED: 'Enabled',
  SCHEME: 'Scheme',
  INGESTION_STATUS: 'Ingestion Status',
  FEED_INGESTION_STATE: 'Feed Ingestion State',
  LAST_INGESTION: 'Last Ingestion',
  DATE: 'Date',
  RESULT: 'Result',
  AVAILABILITY: 'Availability',
  RELOAD_RSS_DATA: 'Reload RSS Data',
  FEED_URL: 'Feed URL',
  SUMMARY: 'Summary',
  ENCLOSURE: 'Enclosure',
  CREATOR: 'Creator',
  PUBLISH_DATE: 'Publish Date',
  SEASON: 'Season',
  EPISODE_TYPE: 'Episode Type',
  DURATION: 'Duration',
  BLOCK: 'Block',
  ACTIVATE_EPISODE: 'Activate Episode',
  DEACTIVATE_EPISODE: 'Deactivate Episode',
  ENABLE_MARBYLIZATION: 'Enable Marbylization',
  DISABLE_MARBYLIZATION: 'Disable Marbylization',
  VIEW_TRANSCRIPT: 'View Transcript',
  TRANSCRIPT: 'Transcript',
  WE_HAVE_NOT_YET_TRANSCRIBED_THIS_EPISODE: 'We have not yet transcribed this episode!',
  SELECT_AND_RIGHT_CLICK_FOR_REVIEW: 'Select & Right Click for Review',
  EPISODE_STATUS: 'Episode Status',
  IS_ACTIVATED: 'Activated',
  ACTIVATION_DATE: 'Activation Date',
  DATE_ENABLED: 'Date Enabled',
  CURRENT_STATE: 'Current State',
  MARBYLIZATION_STATUS: 'Marbylization Status',
  LAST_MARBYLIZATION: 'Last Marbylization',
  MODEL: 'Model',
  DATE_PROCESSED: 'Date Processed',
  VERSION: 'Version',
  VIEW_CONTENT_REPORT: 'View Content Reports',
  MARBYL_TEXT: 'Marbyl Text',
  MARBYL_DETAILS: 'Marbyl Details',
  MARBYL_CREATION_DATE: 'Marbyl Creation Date',
  MARBYL_SOURCE_TYPE: 'Marbyl Source Type',
  MARBYLIZATION_MODEL_USED: 'Marbylization Model Used',
  MARBYLIZATION_MODEL_VERSION: 'Marbylization Model Version',
  GENRE: 'Genre',
  SUB_GENRE: 'Sub Genre',
  EDIT_MARBYL: 'Edit Marbyl',
  ADMIN_INFORMATION: 'Admin Information',
  EPISODE_ACTIVATION_DATE: 'Episode Activation Date',
  PODCAST_NAME_HAS_BEEN_ACTIVATED: '{podcastName} has been activated',
  PODCAST_NAME_HAS_BEEN_DEACTIVATED: '{podcastName} has been deactivated',
  PODCAST_NAME_HAS_BEEN_DElETED: '{podcastName} has been deleted',
  PODCASTS_HAVE_BEEN_ACTIVATED: '{count} Podcasts have been activated',
  PODCASTS_HAVE_BEEN_DEACTIVATED: '{count} Podcasts have been deactivated',
  PODCASTS_HAVE_BEEN_DELETED: '{count} Podcasts have been deleted',
  ARE_YOU_SURE_DO_YOU_WANT_TO_DELETE_PODCAST_NAME:
    'Are you sure you want to delete {podcastName}?',
  ARE_YOU_SURE_DO_YOU_WANT_TO_DELETE_SELECTED_PODCASTS:
    'Are you sure you want to delete {count} selected podcasts?',
  PODCASTER_UPDATED: 'Podcaster updated!',
  DO_YOU_WANT_TO_RELOAD_RSS_FEED: 'Do you want to reload RSS feed?',
  PODCAST_IS_BEEN_UPDATED: 'Podcast is being updated!',

  EPISODES_ACTIVATED: '{count} Episodes have been activated!',
  EPISODES_DEACTIVATED: '{count} Episodes have been deactivated!',
  EPISODE_NAME_ACTIVATED: '{episodeName} have been activated!',
  EPISODE_NAME_DEACTIVATED: '{episodeName} have been deactivated!',

  EPISODES_MARBYLIZATION_ENABLED: '{count} Episodes marbylization has been enabled!',
  EPISODES_MARBYLIZATION_DISABLED: '{count} Episodes marbylization has been disabled!',
  EPISODE_NAME_MARBYLIZATION_ENABLED: 'Marbylization for {episodeName} has been enabled!',
  EPISODE_NAME_MARBYLIZATION_DISABLE:
    'Marbylization for {episodeName} has been disabled!',

  EPISODES_DELETED: 'Episodes have been deleted!',
  EPISODE_NAME_DELETED: '{episodeName} has been deleted!',

  EPISODES_CHANGE_GEO_AVAILABILITY: 'Geo availability for {count} episodes has changed',
  EPISODE_NAME_CHANGE_GEO_AVAILABILITY: 'Geo availability for {episodeName} has changed',

  ARE_YOU_SURE_DO_YOU_WANT_TO_DELETE_EPISODE_NAME:
    'Are you sure you want to delete {episodeName}?',
  ARE_YOU_SURE_DO_YOU_WANT_TO_DELETE_SELECTED_EPISODES:
    'Are you sure you want to delete {count} selected episodes?',

  PODCAST_UPDATED: 'Podcast updated!',
  RSS_FEED_IS_BEING_PROCESSED_FOR_THIS_PODCAST_PLEASE_TRY_LATER:
    'RSS feed is being processed for this podcast. Please try again later.',
  ACTIVE: 'Active',
  DISABLED: 'Disabled',
  CONFIRM: 'Confirm',
  CHANGE_GEO_AVAILABILITY: 'Change Geographic Availability',
  PENDING: 'Pending',
  INACTIVE: 'Inactive',
  THIS_IS_IN_PROGRESS: 'This is in progress!',
  MARBYL_STATUS_UPDATED: 'Marby status updated!',
  WE_ARE_CURRENTLY_PROCESSING_THIS_PODCAST_WELL_SEND_AN_EMAIL_TO_EMAIL_WHEN_ITS_COMPLETE:
    "We are currently processing this podcast. We'll send an email to {email} when it is complete!",
  DELETED: 'Deleted',
  NETWORK_DETAILS: 'Network details',
  LEGAL_INFORMATION: 'Legal information',
  LLC: 'LLC',
  SOLE_PROPRIETORSHIP: 'Sole Proprietorship',
  PARTNERSHIP: 'Partnership',
  BUSINESSS_CORPORATION: 'Businesss Corporation',
  NETWORK_UPDATED: 'Network updated!',
  CONTENT_REPORTS: 'Content Reports',
  CONTENT_REPORT: 'Content Report',
  DATE_CREATED: 'Date Created',
  REPORT_TYPE: 'Report Type',
  CONTACT: 'Contact',
  DETAILS: 'Details',
  REPORT_DETAIL: 'Report Detail',
  CLEAR_FILTERS: 'Clear Filters',
  DO_YOU_WANT_TO_INVITE_THIS_USER_TO_THE_ACCOUNT_WITH_THIS_ROLE:
    'Do you want to invite this user to the account with this role?',
  NEW_ACCOUNT_USER: 'New Account User',
  ENTER_EMAIL: 'Enter Email',
  SELECT_A_ROLE: 'Select a Role',
  NO_USER_WITH_EMAIL_EXISTS_ENTER_USER_NAME:
    'No user with this email exists, please enter the user’s name!',
  SELECT_AN_ACCOUNT_FOR_USER: 'Select an Account for New User',
  SELECT_A_ROLE_FOR_ACCOUNT: 'Select a role for the account',
  A_USER_ALREADY_EXISTS_WITH_THIS_EMAIL:
    'A user already exists with this email, check that the name below is correct!',
  AN_EMAIL_WILL_BE_SENT_TO_EMAIL_ONCE_CONFIRMED_USER_WILL_HAVE_ACCESS_TO_ACCOUNT_WITH_ROLE_OF_ROLE:
    'An email will be sent to {email} Once confirmed, the user will have access to {account} with the role of {role}.',
  ACCOUNT_CONFIRMATION: 'Account Confirmation',
  AN_EMAIL_HAS_BEEN_SENT_TO_EMAIL_ONCE_THEY_CONFIRM_THEIR_INVITATION_THEY_WILL_APPEAR_IN_THE_USERS_LIST:
    'An email has been sent to: {email}.<br/>Once they confirm their invitation, they will appear in the Users List for {account}.',
  YOU_HAVE_BEEN_INVITED_TO_JOIN_THE_ACCOUNT_CLICK_CONFIRM_TO_JOIN:
    'You have been invited to join the account: {accountName}. If you wish to join this account, click confirm!',
  USER_ACCOUNT: 'User Account',
  VIEW_USER_PROFILE: 'View User Profile',
  FULLNAME: 'Full Name',
  INVITED_BY: 'Invited By',
  JOINED_DATE: 'Joined Date',
  TWITTER_LINK: 'https://twitter.com/marbylapp',
  LINKEDIN_LINK: 'https://www.linkedin.com/company/marbylapp/',
  TGM_LINK: 'https://treegoatmedia.com/',
  ROLE: 'Role',
  UPDATE_ACCOUNT_USER: 'Update Account User',
  USER_PROFILE: 'User Profile',
  BIOGRAPHY: 'Biography',
  ASSOCIATED_ACCOUNTS: 'Associated Accounts',
  UPDATE_USER_PROFILE: 'Update User Profile',
  PROFILE_SETTINGS: 'Profile Settings',
  MARBYL_CONTENT_REPORTS: 'Marbyl Content Reports',
  MOBILE_PHONE: 'Mobile Phone',
  PASTE_IN_RSS_FEED_URL: 'Paste in the RSS feed URL',
  LOOK_UP_RSS_FEED_URL: 'Look up the RSS feed URL',
  YES: 'Yes',
  NO: 'No',
  WE_SURFACE_KEY_MOMENTS_IN_AUDO_SO_LISTENERS_CAN_FIND_TOPICS_OF_INTEREST:
    'We surface key moments in audio so \nlisteners can find topics of interest',
  WIN_MARATHON_LOSE_SPRINTS: 'Win the marathon and lose the sprints.',
  YOU_KNOW_HOW_HOW_SOMEONE_SAYS_SOMETHING_FUNNY:
    "You know how someone says something funny or insightful 23 minutes and 7 seconds into a podcast? We surface those moments so listeners can find the content they're looking for.",
  MARBYL_FOR_PODCASTERS: 'Marbyl for Podcasters',
  PROFILE_UPDATED: 'Profile updated!',
  TREE_GOAT_ADMIN: 'Tree Goat Administrator',
  TREE_GOAT_SUPER: 'Tree Goat Superuser',
  TREE_GOAT_USER: 'Tree Goat User',
  PODCASTER_ACCOUNT_OWNER: 'Podcaster Account Owner',
  PODCASTER_ADMIN: 'Podcaster Admin',
  PODCASTER_USER: 'Podcaster User',
  PODCAST_NETWORK_ACCOUNT_OWNER: 'Podcast Network Account Owner',
  PODCAST_NETWORK_ADMIN: 'Podcast Network Administrator',
  PODCAST_NETWORK_USER: 'Podcast Network User',
  USER_ACCOUNT_OWNER: 'User Account Owner',
  THE_INVITATION_DOES_NOT_BELONG_TO_THE_USER:
    'The invitation does not belong to the user',
  INVITATION_CODE_DOES_NOT_EXIST: 'Invitation code does not exist',
  WE_ENCOUNTERED_AN_ERROR_WITH_YOUR_REQUEST_PLEASE_TRY_TAPPING_THE_LINK:
    'We encountered an error with your request. Please try tapping the link via your email confirmation again.',
  MARBYL: 'Marbyl',
  DASHBOARD: 'Dashboard',
  HIERARCHY: 'Hierarchy',
};
